import { DataStore } from '@aws-amplify/datastore';
import {Interview } from '../../../models'
import { Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
Amplify.configure(awsconfig);

class JobBasedInterviewAmplify {
  async save(data) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.save(data).then((user) => {
          console.log(user);
          resolve('success');
        });
      } catch (e) {
        console.log('InterviewSchedule save', e);
        reject(e);
      }
    });
  }

  async updateSpecificVideo(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const original = await DataStore.query(Interview, id);
        console.log('original', original);

        const updatedInterviewSchedule = Interview.copyOf(
          original,
          (updated) => {
            updated.ActionStatus = data.ActionStatus;
            updated.Video1 = data.Video1;
            updated.Video2 = data.Video2;
            updated.Video3 = data.Video3;
            updated.CompletedTime = data.CompletedTime;
          }
        );

        const updatedObject = await DataStore.save(updatedInterviewSchedule);
        console.log('InterviewSchedule update', updatedObject);

        resolve('success');
      } catch (error) {
        console.error('Error updating InterviewSchedule:', error);
        reject(error);
      }
    });
  }
  // async updateSpecificVideoStatus(id, data) {
  //   try {
  //     const original = await DataStore.query(Interview, id);
  //     console.log("original", original);

  //     const updatedInterviewSchedule =  Interview.copyOf(original, updated => {
  //       updated.Status = data.Status;
  //       updated.CompletedTime = data.CompletedTime;
  //     });

  //     const updatedObject = await DataStore.save(updatedInterviewSchedule);
  //     console.log("InterviewSchedule update status", updatedObject);

  //     return "success";
  //   } catch (error) {
  //     console.error("Error updating InterviewSchedule status:", error);
  //     throw error;
  //   }
  // }

  //   async  updateInterview(id, data) {
  //     return new Promise(async (resolve, reject) => {
  //     try{
  //     await DataStore.query(Interview, id).then( async original =>{
  //       console.log("original",original)
  //       await DataStore.save(
  //         Interview.copyOf(original, updated => {
  //           updated.Question4=data.Question4
  //           updated.Question5=data.Question5
  //           updated.Question6=data.Question6
  //         }
  //       )
  //       ).then(user=>{
  //         console.log("InterviewSchedule update",user)
  //          resolve("success");
  //       })
  //     } )

  //       }
  //       catch(e){
  //         console.log("InterviewSchedule update", e)
  //        reject(e)
  //       }
  //     });
  //     }

  //   async  declineInterview(id, data) {
  //     return new Promise(async (resolve, reject) => {
  //     try{
  //       await DataStore.query(Interview, id).then( async original =>{
  //         await DataStore.save(
  //           Interview.copyOf(original, updated => {
  //             updated.Status=data.Status
  //           }
  //         )
  //         ).then(user=>{
  //           console.log("InterviewSchedule update",user)
  //           resolve("success");
  //         })
  //       } )

  //       }
  //       catch(e){
  //         console.log("InterviewSchedule update", e)
  //         reject(e)
  //       }
  //     })
  //     }

  //     async  viewInterview(id, data) {
  //       return new Promise(async (resolve, reject) => {
  //       try{
  //         await DataStore.query(Interview, id).then( async original =>{
  //           await DataStore.save(
  //             Interview.copyOf(original, updated => {
  //               updated.Status=data.Status
  //             }
  //           )
  //           ).then(user=>{
  //             console.log("InterviewSchedule update",user)
  //             resolve("success");
  //           })
  //         } )

  //         }
  //         catch(e){
  //           console.log("InterviewSchedule update", e)
  //           reject(e)
  //         }
  //       })
  //       }

  async getInterviewByID(interviewID) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Interview, (c) => c.id.eq(interviewID), {
          consistent: true,
        }).then((interviewData) => {
          console.log(interviewData);
          resolve(interviewData[0]);
        });
      } catch (e) {
        console.log('InterviewSchedule update', e);
        reject(e);
      }
    });
  }
  async getAllInterviewScheduled(recruiterID) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Interview, (c) => c.recruiterID.eq(recruiterID), {
          sort: (s) => s.createdAt('desc'),
        }).then((interviews) => {
          console.log(interviews);
          resolve(interviews);
        });
      } catch (e) {
        console.log('InterviewSchedule all', e);
        reject(e);
      }
    });
  }
  async getAllInterviewScheduledCandidate(candidateID) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(
          Interview,
          (c) => c.interviewCandidatesId.eq(candidateID),
          {
            sort: (s) => s.createdAt('desc'),
          }
        ).then((interviews) => {
          console.log(interviews);
          resolve(interviews);
        });
      } catch (e) {
        console.log('InterviewSchedule all CA', e);
        reject(e);
      }
    });
  }
  async searchInterviewSchedule(text, status, recruiterID) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Interview, (c) => c.recruiterID.eq(recruiterID), {
          // consistent: true,
          sort: (s) => s.createdAt('desc'),
        }).then(async (result) => {
          console.log('searchInterviewSchedule', result);
          const newData = [];
          for (let i = 0; i < result.length; i++) {
            if (result[i].recruiterID == recruiterID) {
              if (result[i].CandidateEmail.includes(text)) {
                newData.push(result[i]);
              } else if (result[i].CandidateName.includes(text)) {
                newData.push(result[i]);
              } else if (result[i].JobReferenceID.includes(text)) {
                newData.push(result[i]);
              } else if (result[i].CompletedTime.includes(text)) {
                newData.push(result[i]);
              }
              // else {
              //   // Fetch the candidate associated with the interview
              //   result[i].Candidates.then(candidate=>{
              //       if (candidate != null && candidate.Phone.includes(text)) {
              //         console.log("candidate", candidate.Phone)
              //         newData.push(result[i]);
              //       }
              //     })
              //     .catch((error) => {
              //       console.log('Error fetching candidate:', error);
              //     });
              // }
            }
          }
          console.log('newDATA ', newData);
          resolve(newData);
        });
      } catch (e) {
        console.log('InterviewSchedule search', e);
        reject(e);
      }
    });
  }
  async searchInterviewScheduleCandidate(text, status, candidateID) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(
          Interview,
          (c) => c.interviewCandidatesId.eq(candidateID),
          { consistent: true, sort: (s) => s.createdAt('desc') }
        ).then((result) => {
          console.log('searchInterviewSchedule', result);
          const newData = [];
          for (let i = 0; i < result.length; i++) {
            if (result[i].interviewCandidatesId == candidateID) {
              if (result[i].CandidateEmail.includes(text)) {
                newData.push(result[i]);
              } else if (result[i].CandidateName.includes(text)) {
                newData.push(result[i]);
              } else if (result[i].JobReferenceID.includes(text)) {
                newData.push(result[i]);
              }
              // });
            }
          }
          console.log('newDATA ', newData);
          resolve(newData);
        });
      } catch (e) {
        console.log('InterviewSchedule search candidate', e);
        reject(e);
      }
    });
  }
  async searchInterviewScheduleTab(status, interviewInputText, recruiterID) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(
          Interview,
          (c) =>
            c.or((c) => [
              c.ActionStatus.eq(status),
              c.DecisionStatus.eq(status),
            ]),
          { consistent: true, sort: (s) => s.createdAt('desc') }
        ).then((result) => {
          console.log('searchInterviewScheduleTab', result);
          const newData = [];
          for (let i = 0; i < result.length; i++) {
            if (result[i].recruiterID == recruiterID) {
              if (result[i].CandidateEmail.includes(interviewInputText)) {
                newData.push(result[i]);
              } else if (result[i].CandidateName.includes(interviewInputText)) {
                newData.push(result[i]);
              } else if (
                result[i].JobReferenceID.includes(interviewInputText)
              ) {
                newData.push(result[i]);
              }
              // });
            }
          }
          console.log('newDATA ', newData);
          resolve(newData);
        });
      } catch (e) {
        console.log('InterviewSchedule search', e);
        reject(e);
      }
    });
  }

  async searchInterviewScheduleTabCandidate(
    status,
    interviewInputText,
    candidateID
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(
          Interview,
          (c) =>
            c.or((c) => [
              c.ActionStatus.eq(status),
              c.DecisionStatus.eq(status),
            ]),

          { consistent: true, sort: (s) => s.createdAt('desc') }
        ).then((result) => {
          console.log('searchInterviewScheduleTab', result);
          const newData = [];
          for (let i = 0; i < result.length; i++) {
            if (result[i].interviewCandidatesId == candidateID) {
              if (result[i].CandidateEmail.includes(interviewInputText)) {
                newData.push(result[i]);
              } else if (result[i].CandidateName.includes(interviewInputText)) {
                newData.push(result[i]);
              } else if (
                result[i].JobReferenceID.includes(interviewInputText)
              ) {
                newData.push(result[i]);
              }
            }
          }
          console.log('newDATA ', newData);
          resolve(newData);
        });
      } catch (e) {
        console.log('InterviewSchedule search candidate', e);
        reject(e);
      }
    });
  }
}
export default new JobBasedInterviewAmplify();