import React, { useState, useEffect } from 'react';
import RecruiterNavbar from './RecruiterNavbar';
import { Row, Col, Container, Tabs, Tab, Button, Card } from 'react-bootstrap';
import './JobList.css';
import { Form, FormControl } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import Job from '../Service/AWS_Auth/JobDataService';
function JobList() {
  const [jobs, setJobs] = React.useState([]);
  useEffect(async () => {
    let allJobs = await Job.getAllJobsByCompany(
      '70709f3e-1bf3-407a-8793-037aa368e077'
    );
    setJobs(allJobs);
    console.log(jobs);
  }, []);
  return (
    <div>
      <RecruiterNavbar></RecruiterNavbar>
      <Container fluid>
        <Row className="qest_txt">
          <Col>
            <h2> Job List</h2>
          </Col>
        </Row>
      </Container>
      <br></br>
      <Container fluid className="add-job-btn">
        <Link to="/recruiter/job/create">
          <Button className="create_link_btn">Add Job</Button>
        </Link>

        {/* <Form inline>
          <FormControl type="text" placeholder="Search" className="mr-sm-2" />
          <Button type="submit">Seacrh</Button>
        </Form> */}
        {/* <Container>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Search Jobs" />
            <div class="input-group-append">
              <button class="btn btn-secondary" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </Container> */}
      </Container>
      <Container>
        {jobs.length != null ? (
          jobs.map((job) => {
            return (
              <div>
                <Card className="card_content">
                  <Card.Body className="card_body_content">
                    <Row>
                      <Col className="company_details" md={7}>
                        <ul>
                          <li className="card_company_name">{job['Title']}</li>
                          <li className="card_company_email_name">
                            <span class="ti-email"> {job['Description']}</span>
                          </li>
                          <li className="card_company_Phone_no">
                            <span class="ti-mobile"> {job['Skills']}</span>
                          </li>
                        </ul>
                      </Col>
                      <Col className="respond_btns" md={2}>
                        <Row>
                          {' '}
                          <Col>
                            {/* <Button onClick={(e)=>viewOnClick(job.JobSeekerUID)} className='pendning_card_View_btn' variant="primary">VIEW</Button> */}{' '}
                            <NavLink
                              to={'/recruiter/job/view'}
                              state={{ JobID: job['id'] }}
                            >
                              <button
                                className="slide btn-size"
                                variant="primary"
                              >
                                View
                              </button>
                            </NavLink>
                          </Col>
                          <Col>
                            {/* <Button onClick={(e)=>viewOnClick(job.JobSeekerUID)} className='pendning_card_View_btn' variant="primary">VIEW</Button> */}{' '}
                            <NavLink
                              to={'/recruiter/job/edit'}
                              state={{ JobID: job['id'] }}
                            >
                              <button
                                className="slide btn-size"
                                variant="primary"
                              >
                                Edit
                              </button>
                            </NavLink>
                          </Col>
                        </Row>
                        <Row>
                          <Col></Col>
                        </Row>

                        <Row>
                          {/* <Col>
                        <NavLink>
                          <button className="slide btn-size" variant="primary">
                            Edit
                          </button>
                        </NavLink>
                      </Col> */}
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </Container>
    </div>
  );
}

export default JobList;
