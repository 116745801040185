import { DataStore } from '@aws-amplify/datastore';
import { Company } from '../../../models'
import { Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
Amplify.configure(awsconfig);

class CompanyAmplify{

async save(data) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.save(data).then((company) => {
          if (company != null) {
            console.log(company);
            localStorage.setItem('companyId', company.id);
            resolve(company);
          }
        });
      } catch (e) {
        console.log('Company save', e);
        reject(e);
      }
    });
  }
async getALL(){
    return new Promise(async (resolve, reject) => {
        try {
    await DataStore.query(Company).then(models=>{
        console.log(models);
        resolve(models);
    })}
    catch(e){
        console.log("error getAll",e);
        reject(e);
    }
})
}
async getByID(companyId){
    return new Promise(async (resolve, reject) => {
        try {
            await DataStore.query(Company, companyId).then(company=>{
                console.log(company);
        resolve(company);
            })}
    catch(e){
        console.log("error getAll",e);
        reject(e);
    }
})
}
}
export default new CompanyAmplify();