import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import emailjs from '@emailjs/browser';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { NavLink, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import RecruiterNavbar from './RecruiterNavbar';
import Footer from '../HomePage/Footer';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import interviewService from '../Service/AWS_Auth/interviewService';
import candidateService from '../Service/AWS_Auth/CandidateDataService';
import { Candidate, InterviewSchedule, InterviewStatus } from '../../models';
import AwsAmplify from '../Service/AWS_Auth/RecruiterDataService';
import CandidateAmplify from '../Service/AWS_Auth/CandidateDataService';
import './SchedulePage.css';
import {toast} from 'react-toastify'
function SchedulePage() {
  const [optCandidateModal, setOptCandidateModal] = useState(false);
  const [optQuestionModal, setOptQuestionModal] = useState(false);

  const [UserRole, setUserRole] = React.useState('');
  const [users, setUser] = React.useState({});
  const navigate = useNavigate();
  var user = [];
  const [userDetails, setUserDetails] = React.useState();
  const [recruiter, setRecruiter] = React.useState();
  const [allUser, setAllUser] = React.useState({});
  const [question1, setQuestion1] = React.useState('Tell me about yourself ?');
  const [question2, setQuestion2] = React.useState('');
  const [question3, setQuestion3] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState();
  const [newCandidate, setNewCandidate] = React.useState();
  const [recruiterDetails, setRecruiterDetails] = React.useState();
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const canonical = document.querySelector('link[rel=canonical]');
  let url = canonical ? canonical.href : document.location.href;

  const sharelinkValidate = (name, companyname) => {
    var msg = 'HI';
    var title =
      'Invite the canditate through your preferred communication app or copy the content and share with the candidate';
    var text = `Hello ${name},${companyname} is inviting you to attend a scheduled interview from Jobble. Kindly login to our website and move to Interview pending post and accept and make video to get great opportunity in your career  .`;
    handleSharing(msg, title, text);
    window.location.reload();
  };

  const handleSharing = async (url, title, text) => {
    if (navigator.share) {
      const shareDetails = { text, title, text };
      try {
        await navigator
          .share(shareDetails)
          .then(() =>
            console.log('Hooray! Your content was shared to tha world')
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      setShowModal(true);
      console.log(
        ' share Link is currently not supported on this browser. Please provide a callback'
      );
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(question1);

  const sendEmail = (e) => {
    console.log(e);
    emailjs
      .send('service_t288pcc', 'template_3cj2md1', e, '4T3ITSR8NZv24KKIq')
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const dataUpload = async (event) => {
    if (selectedUser == undefined && question1 != '') {
      var data = new Candidate({
        Email: newCandidate,
        Status: false,
      });
      if (question2 == '' && question3 == '') {
        await CandidateAmplify.createCandidate(data).then(async (user) => {
          console.log(user.id);
          if (user != null && user.id != null && question1 != '') {
            var QuestionData = new InterviewSchedule({
              Title: 'Job Title',
              Description: 'Job Description',
              CreatedTime: new Date().toLocaleString(),
              Status: new InterviewStatus({
                Create: true,
                Viewed: false,
                Completed: false,
                Declined: false,
                Selected: false,
                Rejected: false,
              }),
              Recruiter: recruiter,
              recruiterID: recruiter,
              Question4: question1,
              Question5: 'No question',
              Question6: 'No question',
              interviewScheduleCandidateId: user.id,
              CandidateName: 'New User',
              CandidateEmail: newCandidate,
              RecruiterName:
                recruiterDetails.FirstName + recruiterDetails.LastName,
              RecruiterEmail: recruiterDetails.Email,
              RecruiterCompany: recruiterDetails.CompanyName,
            });
            console.log('Interview', QuestionData);
             await interviewService.save(QuestionData).then(val=>{
              toast.success(" Interview has been Scheduled successfully")
              setValidated(true);
              window.history.back();

             }).catch(e=>{
              toast.error(e.toString())
             })

           
          }
        });
      } else {
        await CandidateAmplify.createCandidate(data).then(async (user) => {
          console.log(user.id);
          if (user != null && user.id != null && question1 != '') {
            var QuestionData = new InterviewSchedule({
              Title: 'Job Title',
              Description: 'Job Description',
              CreatedTime:new Date().toLocaleString(),
              Status: new InterviewStatus({
                Create: true,
                Viewed: false,
                Completed: false,
                Declined: false,
                Selected: false,
                Rejected: false,
              }),
              Recruiter: recruiter,
              recruiterID: recruiter,
              Question4: question1,
              Question5: question2,
              Question6: question3,
              interviewScheduleCandidateId: user.id,
              CandidateName: 'New User',
              CandidateEmail: newCandidate,
              RecruiterName:
                recruiterDetails.FirstName + recruiterDetails.LastName,
              RecruiterEmail: recruiterDetails.Email,
              RecruiterCompany: recruiterDetails.CompanyName,
            });
            console.log('Interview', QuestionData);
            await interviewService.save(QuestionData).then(val=>{
              toast.success(" Interview has been Scheduled successfully")
              setValidated(true);
            window.history.back();
            }).catch(e=>{
              toast.error(e.toString())
            })
            
          }
        });
      }
    }

    if (selectedUser != undefined && question1 != '') {
      var name =
        selectedUser.CandidateName != null
          ? selectedUser.CandidateName
          : 'New User';
      if (question2 == '' && question3 == '') {
        var QuestionData = new InterviewSchedule({
          Title: 'Job Title',
          Description: 'Job Description',
          CreatedTime: new Date().toLocaleString(),
          CompletedTime:"",
          Status: new InterviewStatus({
            Create: true,
            Viewed: false,
            Completed: false,
            Declined: false,
            Selected: false,
            Rejected: false,
          }),
          Recruiter: recruiter,
          recruiterID: recruiter,
          Question4: question1,
          Question5: 'No question',
          Question6: 'No question',
          interviewScheduleCandidateId: selectedUser.candidateId,
          CandidateName: name,
          CandidateEmail: selectedUser.name,
          RecruiterName: recruiterDetails.FirstName + recruiterDetails.LastName,
          RecruiterEmail: recruiterDetails.Email,
          RecruiterCompany: recruiterDetails.CompanyName,
        });
        console.log('Interview', QuestionData);
        await interviewService.save(QuestionData).then(val=>{
          toast.success(" Interview has been Scheduled successfully")
          setValidated(true);
        window.history.back();
        }).catch(e=>{
          toast.error(e.toString())
        })
        
      } else {
        var QuestionData = new InterviewSchedule({
          Title: 'Job Title',
          Description: 'Job Description',
          CreatedTime: new Date().toLocaleString(),
          CompletedTime:"",
          Status: new InterviewStatus({
            Create: true,
            Viewed: false,
            Completed: false,
            Declined: false,
            Selected: false,
            Rejected: false,
          }),
          Recruiter: recruiter,
          recruiterID: recruiter,
          Question4: question1,
          Question5: question2,
          Question6: question3,
          interviewScheduleCandidateId: selectedUser.candidateId,
          CandidateName: name,
          CandidateEmail: selectedUser.name,
          RecruiterName: recruiterDetails.FirstName + recruiterDetails.LastName,
          RecruiterEmail: recruiterDetails.Email,
          RecruiterCompany: recruiterDetails.CompanyName,
        });
        console.log('Interview', QuestionData);
        await interviewService.save(QuestionData).then(val=>{
          toast.success(" Interview has been Scheduled successfully")
          setValidated(true);
        window.history.back();
        }).catch(e=>{
          toast.error(e.toString())
        })
        
      }
    }
  };

  const getAllUser = async () => {
    await candidateService.getAllCandidate().then((allCandidates) => {
      let user = [];
      let id = 0;
      allCandidates.forEach((candidate) => {
        user.push({
          id: id,
          name: candidate.Email,
          candidateId: candidate.id,
          CandidateName:
            candidate.FirstName != null
              ? candidate.FirstName + ' ' + candidate.LastName
              : null,
        });
        id++;
      });
      setAllUser(user);
    });
  };
  useEffect(async () => {
    getAllUser();
    var rec = localStorage.getItem('recruiterId');
    setRecruiter(rec);
    let email = localStorage.getItem('email');
    await AwsAmplify.getRecruiter(email).then((userValue) => {
      setRecruiterDetails(userValue);
    });
    // console.log("DateTime",new Date().toLocaleString())
    //   let userValue =await RecruiterProfileDB.getRecById(userDecoded.sub);
    //   setRecruiterDetails(userValue);
    //   setUser(userDecoded);
    //   setUserRole(localStorage.getItem("role"));
    //   setUserDetails(userValue);

    //   let defaultValues = {};
    //   reset({ ...defaultValues });
  }, []);

  const handleOnSelect = (item) => {
    console.log(item);
    setSelectedUser(item);
  };
  const searchKey = async (item) => {
    console.log(item);
    if (item == '') {
      setNewCandidate();
    } else {
      setNewCandidate(item);
    }
  };
  const formatResult = (item) => {
    console.log(item);

    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>
          {' '}
          {item.name}
        </span>
      </>
    );
  };

  const formatClear = () => {
    setSelectedUser();
    setNewCandidate();
  };

  return (
    <>
      <RecruiterNavbar></RecruiterNavbar>
      <Container fluid>
        <Row className="qest_txt">
          <Col>
            <h2> Schedule an Interview</h2>
          </Col>
        </Row>
      </Container>
      <Container>
        <div>
          <div className="poster_details">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(dataUpload)}
            >
              <Row>
                <Col md="3"></Col>
                <Form.Group
                  className="emp_id_col"
                  as={Col}
                  md="6"
                  controlId="validationCustomUsername"
                >
                  <Form.Label className="question_text">
                    Employee mail ID :
                  </Form.Label>

                  <div>
                    <ReactSearchAutocomplete
                      items={allUser}
                      autoFocus
                      fuseOptions={{ threshold: 0.0 }}
                      onSelect={handleOnSelect}
                      onClear={formatClear}
                      formatResult={formatResult}
                      onSearch={searchKey}
                      // showNoResultsText={searchKey}
                    />
                  </div>
                </Form.Group>
                <Col md="3"></Col>
              </Row>

              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                  className="question_text_box"
                >
                  <Form.Label className="question_text">Question 1 </Form.Label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Type your question here"
                    rows="3"
                    onChange={(ev) => setQuestion1(ev.target.value)}
                    defaultValue={question1}
                    value={question1}
                    renderValue={(value) => `${value}`}
                  ></textarea>
                  {/* <Form.Control type="text" placeholder="Question" required onChange={(ev)=> setQuestion1(ev.target.value)}
              defaultValue={question1}
              value={question1}
              renderValue={(value) => `${value}`} /> */}
                  <Form.Control.Feedback type="invalid">
                    Please provide a Question.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                  className="question_text_box"
                >
                  <Form.Label className="question_text">Question 2 </Form.Label>

                  <Form.Label className="question_optional">
                    {' '}
                    (Optional)
                  </Form.Label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Type your question here"
                    rows="3"
                    onChange={(ev) => setQuestion2(ev.target.value)}
                    defaultValue={question2}
                    value={question2}
                    renderValue={(value) => `${value}`}
                  ></textarea>
                  {/* <Form.Control type="text" placeholder="Question" required onChange={(ev)=> setQuestion2(ev.target.value)}
              defaultValue={question2}
              value={question2}
              renderValue={(value) => `${value}`}  /> */}
                  <Form.Control.Feedback type="invalid">
                    Please provide a Question.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                  className="question_text_box"
                >
                  <Form.Label className="question_text">Question 3 </Form.Label>
                  <Form.Label className="question_optional">
                    {' '}
                    (Optional)
                  </Form.Label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Type your question here"
                    rows="3"
                    onChange={(ev) => setQuestion3(ev.target.value)}
                    defaultValue={question3}
                    value={question3}
                    renderValue={(value) => `${value}`}
                  ></textarea>
                  {/* <Form.Control type="text" placeholder="Question" required onChange={(ev)=> setQuestion3(ev.target.value)}
              defaultValue={question3}
              value={question3}
              renderValue={(value) => `${value}`} /> */}
                  <Form.Control.Feedback type="invalid">
                    Please provide a Question.
                  </Form.Control.Feedback>
                </Form.Group>
                {/* <Form.Group as={Col} md="3" controlId="validationCustom04">
          <Form.Label>State</Form.Label>
          <Form.Control type="text" placeholder="State" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid state.
          </Form.Control.Feedback>
        </Form.Group> */}
                {/* <Form.Group as={Col} md="3" controlId="validationCustom05">
          <Form.Label>Zip</Form.Label>
          <Form.Control type="text" placeholder="Zip" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid zip.
          </Form.Control.Feedback>
        </Form.Group> */}
              </Row>
              {/* <Form.Group className="mb-3">
        <Form.Check
          required
          label="Agree to terms and conditions"
          feedback="You must agree before submitting."
          feedbackType="invalid"
        />
      </Form.Group> */}
              <Col md={12} className="send_btn_Col">
                <Button
                  className={
                    (selectedUser != undefined || newCandidate != undefined) &&
                    question1 != ''
                      ? 'send_btn '
                      : 'send_btn disabled'
                  }
                  type="submit"
                >
                  INVITE{' '}
                </Button>
              </Col>
            </Form>
          </div>
        </div>
      </Container>
      <br />
      <br />
      <br />
      <Footer></Footer>
    </>
  );
}

export default SchedulePage;
