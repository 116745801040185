import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Card } from '@mui/material';
import GIF from '../../assets/images/Attheoffice_login.gif';
import { toast } from 'react-toastify';
import './Register.css';
import { useState, useEffect } from 'react';
import AwsAuth from '../Service/AWS_Auth/authenticationServices';
import { useNavigate, useLocation } from 'react-router-dom';

function Verification() {
  // const [email, setemail] = useState("");
  const [code, setcode] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  let url = new URLSearchParams(useLocation().search).get('re');
  let re = new URLSearchParams(useLocation().search).get('per');
  if (url) {
    url = url.replace(/\s/g, '+');
  }
  if (re) {
    re = re.replace(/\s/g, '+');
  }
  useEffect(() => {
    let emailTemp = localStorage.getItem('emailTemp');
    setEmail(emailTemp);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    AwsAuth.confirmSignUp(email, code).then(async (value) => {
      console.log(value, 'check');
      value = 'true';
      if (value == 'true') {
        localStorage.setItem('email', location.state.email);
        localStorage.setItem('user', true);
        var role = localStorage.getItem('role');
        // await AwsAuth.loginConfirm().then((value)=>{
        localStorage.setItem('AuthStatus', true);
        toast.success('You have been successfully registered, Try log in');
        if (url != null || url != undefined) {
          if (re != null || re != undefined) {
            navigate(`/login?re=${url}+&per=${re}`);
          } else {
            navigate(`/login?re=${url}`);
          }
        } else {
          navigate('/login');
        }
        // if(role == "Employee" || role == null){
        //   navigate("/profile")}
        //   else{
        //     navigate("/recprof")
        //   }
        // })
      } else {
        toast.error(value.toString());
      }
    });
  };

  //   const handleKeyPress = (event) => {
  //     if (event.key === 'Enter') {
  //       handleSubmit();
  //     }
  // }

  return (
    <Grid
      container
      component="main"
      sx={{ height: '100vh', bgcolor: '#ffffff' }}
    >
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6}>
        <div className="register_title_txt">
          <Card className="login-card">
            <img src={GIF} width="100%" alt="" />
          </Card>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={6} elevation={6} square>
        <Box
          className="registration-box"
          sx={{
            my: 4,
            mx: 4,
            px: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#6366f1' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5"></Typography>
          {
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="verify_txt">
                    <p>Please check your email for verification code</p>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="Code"
                    label="Confirm code"
                    name="confirm code"
                    color={'primary'}
                    onChange={(e) => setcode(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                className="register_btn"
                onClick={(e) => {
                  handleSubmit(e);
                }}
                // onKeyPress={handleKeyPress}
                fullWidth
                sx={{ mt: 3, mb: 2 }}
              >
                VERIFY
              </Button>
            </Box>
          }
        </Box>
      </Grid>
    </Grid>
  );
}

export default Verification;
