import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import emailjs from '@emailjs/browser';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { NavLink, useNavigate,useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import RecruiterNavbar from './RecruiterNavbar';
import Footer from '../HomePage/Footer';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import interviewService from '../Service/AWS_Auth/interviewService';
import candidateService from '../Service/AWS_Auth/CandidateDataService';
import { Candidate, InterviewSchedule, InterviewStatus } from '../../models';
import './SchedulePage.css';
import AwsAmplify from '../Service/AWS_Auth/CandidateDataService';
import {toast} from 'react-toastify'
function EditScheduleInterview() {
  const navigate = useNavigate();
  const [email,setEmail] = React.useState('');
  const [candidateName,setCandidateName] = React.useState('');
  const [question4, setQuestion4] = React.useState('');
  const [question5, setQuestion5] = React.useState('');
  const [question6, setQuestion6] = React.useState('');
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(question4);
  const location = useLocation();
  const questionDetails =location.state != null ? location.state.CandidateDetails : undefined;

  const dataUpload = async () => {
    if (email != undefined && question4 != '') {
      
      if (question4 == '' && question5 == '') {
        var QuestionData = new InterviewSchedule({
          Question4: question4,
          Question5: 'No question',
          Question6: 'No question',
        });
        console.log('Interview', QuestionData);
        await interviewService.updateInterview(questionDetails,QuestionData).then(val=>{
          toast.success("Interview has been saved successfully")
          setValidated(true);
        window.history.back();
        }).catch(e=>{
          toast.error(e.toString())
        })
        
      } else {
        var QuestionData = new InterviewSchedule({
          Question4: question4,
          Question5: question5,
          Question6: question6,
        });
        console.log('Interview', QuestionData);
      await interviewService.updateInterview(questionDetails,QuestionData).then(val=>{
        toast.success("Interview has been saved successfully")
        setValidated(true);
        window.history.back();
      }).catch(e=>{
        toast.error(e.toString())
      })
        
      }
    }
       
      } ;
  



useEffect(async () => {
  await AwsAmplify.jobByInterview( questionDetails
  ).then(async (userValue) => {
    if (userValue != null && userValue != undefined) {
      await userValue.Candidate.then(async (candidate) => {
          setEmail(candidate.Email);
          setQuestion4(userValue.Question4);
          setQuestion5(userValue.Question5);
          setQuestion6(userValue.Question6);
        
      });
    }
  });

  let defaultValues = {};
  reset({ ...defaultValues });
}, []);

  return (
    <>
      <RecruiterNavbar></RecruiterNavbar>
      <Container fluid>
        <Row className="qest_txt">
          <Col>
            <h2> Edit Scheduled Interview</h2>
          </Col>
        </Row>
      </Container>
      <Container>
        <div>
          <div className="poster_details">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(dataUpload)}
            >
              <Row>
                <Col md="3"></Col>
                <Form.Group
                  className="emp_id_col"
                  as={Col}
                  md="6"
                  controlId="validationCustomUsername"
                >
                  <Form.Label className="question_text">
                    Employee mail ID : {email}
                  </Form.Label>

                 
                </Form.Group>
                <Col md="3"></Col>
              </Row>

              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                  className="question_text_box"
                >
                  <Form.Label className="question_text">Question 1 </Form.Label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Type your question here"
                    rows="3"
                    onChange={(ev) => setQuestion4(ev.target.value)}
                    defaultValue={question4}
                    value={question4}
                    renderValue={(value) => `${value}`}
                  ></textarea>
                  <Form.Control.Feedback type="invalid">
                    Please provide a Question.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                  className="question_text_box"
                >
                  <Form.Label className="question_text">Question 2 </Form.Label>

                  <Form.Label className="question_optional">
                    {' '}
                    (Optional)
                  </Form.Label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Type your question here"
                    rows="3"
                    onChange={(ev) => setQuestion5(ev.target.value)}
                    defaultValue={question5}
                    value={question5}
                    renderValue={(value) => `${value}`}
                  ></textarea>
                  <Form.Control.Feedback type="invalid">
                    Please provide a Question.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                  className="question_text_box"
                >
                  <Form.Label className="question_text">Question 3 </Form.Label>
                  <Form.Label className="question_optional">
                    {' '}
                    (Optional)
                  </Form.Label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Type your question here"
                    rows="3"
                    onChange={(ev) => setQuestion6(ev.target.value)}
                    defaultValue={question6}
                    value={question6}
                    renderValue={(value) => `${value}`}
                  ></textarea>
                  <Form.Control.Feedback type="invalid">
                    Please provide a Question.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Col md={12} className="send_btn_Col">
                <Button
                  className={
                     'send_btn '
                  }
                  type="submit"
                >
                  INVITE{' '}
                </Button>
              </Col>
            </Form>
          </div>
        </div>
      </Container>
      <br />
      <br />
      <br />
      <Footer></Footer>
    </>
  );
}

export default EditScheduleInterview;
