import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Container } from '@mui/system';
import Button from '@mui/material/Button';
import FlagIcon from '@mui/icons-material/Flag';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmailIcon from '@mui/icons-material/Email';
import SchoolIcon from '@mui/icons-material/School';
import Chip from '@mui/material/Chip';
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';
import './JobSearchList.css';
import WcIcon from '@mui/icons-material/Wc';
import ShieldIcon from '@mui/icons-material/Shield';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

function ViewJobCard() {
  const [open, setOpen] = React.useState(true);
  return (
    <div>
      <CssBaseline />{' '}
      <Container className="full_card_body" disableGutters={true} fixed>
        <Container className="card_top_bar">
          <Grid container spacing={0}>
            <Grid className="top_bar_item_1" item xs={3} md={3}>
              (Job Title)
              <Chip className="status_chip" label="Status" />
            </Grid>
            <Grid className="top_bar_item_2" item xs={3} md={3}></Grid>
            <Grid item xs={3} md={3}></Grid>
            <Grid className="top_bar_item_3" item xs={3} md={3}>
              <Chip className="status_chip" label="12" />
            </Grid>
          </Grid>
        </Container>

        <Container className="new_card_content" maxWidth="lg">
          <Grid container>
            <Grid className="content_area_col_1" item xs={12} md={4} lg={4}>
              <ul>
                <li>
                  <span className="jobs_icon_styles"></span>
                  <PersonIcon />
                  (Hiring Manager Name)
                </li>
                <li>
                  <span className="jobs_icon_styles"></span>
                  <EmailIcon /> (Hiring Manager email)
                </li>
              </ul>
            </Grid>
            <Grid className="content_area_col_2" item xs={12} md={4} lg={4}>
              <ul>
                <li>
                  <span className="jobs_icon_styles"></span>
                  <PlaceIcon /> Location
                </li>
                <li>
                  <span className="jobs_icon_styles"></span>
                  <WcIcon /> Gender
                </li>
              </ul>
            </Grid>
            <Grid className="content_area_col_3" item xs={12} md={4} lg={4}>
              <ul>
                <li>
                  <span className="jobs_icon_styles"></span>
                  <NotListedLocationIcon /> Work Location
                </li>
                <li>
                  <span className="jobs_icon_styles"></span>
                  <ShieldIcon /> Min-Max Exp
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {' '}
              <Box sx={{ width: '100%' }}>
                <Button
                  className="job_desc_btn"
                  disabled={open}
                  variant="outlined"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Job Description
                </Button>
                <Collapse in={open}>
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    (Content area)
                  </Alert>
                </Collapse>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container className="card_bottom_bar" maxWidth="lg">
          <Grid container>
            <Grid className="bottom_card_col_1" item xs={8}>
              (<SchoolIcon />
              )(Required Skillset) {}
            </Grid>
            <Grid className="bottom_card_col_2" item xs={4}>
              <Button className="view_icon_btn">
                <VisibilityIcon />
              </Button>{' '}
              {/* <Button className="share_icon_btn">
                {' '}
                <ShareIcon />
              </Button> */}
              <Button className="flag_icon_btn">
                <FlagIcon />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );
}

export default ViewJobCard;
