import React from 'react'
import RecruiterNavbar from './RecruiterNavbar';
import '../HomePage/Home.css';
import '../HomePage/AboutUs.css';
import '../HomePage/HomeResponsive.css';
import { Container, Row, Col} from 'react-bootstrap';
import Footer from '../HomePage/Footer';
import Recruiter from '../../assets/images/homepage_recruiter.jpg';
import Employee from '../../assets/images/homepage_employee.jpg';
import { styled } from '@mui/material/styles';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import LoginIcon from '@mui/icons-material/Login';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import WorkIcon from '@mui/icons-material/Work';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Timeline from '../HomePage/Timeline';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { orange } from '@mui/material/colors';

function AboutUs() {
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'none',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'none',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'orange',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));

  function ColorlibStepIcon1(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <LoginIcon />,
      2: <VideoLabelIcon />,
      3: <GroupAddIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  function ColorlibStepIcon2(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <LoginIcon />,
      2: <VideoCameraFrontIcon />,
      3: <WorkIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const stepsrecruiter = ['Sign-up', 'Create interview', 'Hire candidate'];
  const stepsemployee = [
    'Sign-in using invitation',
    'Attend the interview',
    'Get a job',
  ];
  return (
    <div>
      <>
      <RecruiterNavbar />
      <Container fluid>
        <Row className="aboutus_text">
          <Col>
            <h2> About Us</h2>
          </Col>
        </Row>
        <br></br>
      </Container>
      <Container className="home_page_heading_container">
        
        <Row className="recruiter_steps_row">
          <Col className="recruiter_col_img" md={6}>
            <img src={Recruiter} width="100%"></img>
          </Col>
          <Col className="recruiter_col_txt" md={6}>
            <h2>Recruiter</h2>
            <h4>Are you dealing with this everyday?</h4>
            {/* <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
              {stepsrecruiter.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon1}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
        </Stack> */}
            <div>
              <Row className="three_steps">
                <Col md={12}>
                  <Row>
                    {/* <Col className="step_details_icon" md={1}>
                 
                  <RadioButtonCheckedIcon sx={{ color: orange[500] }}/>
                </Col> */}
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">
                        Hiring Manager is busy with a deadline/project delivery
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col className="step_details_icon" md={1}>
                 
                  <RadioButtonCheckedIcon sx={{ color: orange[500] }}/>
                </Col> */}
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">
                        Candidate not available take calls during business hours
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col className="step_details_icon" md={1}>
                  
                  <RadioButtonCheckedIcon sx={{ color: orange[500] }}/>
                </Col> */}
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">
                        Mutual time not available with Candidate and Hiring
                        manager
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col className="step_details_icon" md={1}>
                 
                  <RadioButtonCheckedIcon sx={{ color: orange[500] }}/>
                </Col> */}
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">No-Show Candidates</span>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col className="step_details_icon" md={1}>
                  
                  <RadioButtonCheckedIcon sx={{ color: orange[500] }}/>
                </Col> */}
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">
                        Internet/Video/Audio Problems
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="step_details_txt" md={12}>
                      <span className="orange_txt">InterviewTape</span>{' '}
                      <span className="details_txt_last">
                        solves these for you
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Col className="employee_steps_img_mob" md={6}>
          <img src={Employee} width="100%"></img>
        </Col>
        <Row className="employee_row">
          <Col className="employee_col_txt" md={6}>
            <h2>Candidate</h2>
            <h4>Have you ever wished that</h4>
            {/* <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
              {stepsemployee.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon2}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
        </Stack> */}

            <div>
              <Row className="three_steps">
                <Col md={12}>
                  <Row>
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">
                        I need a new job, but I can't interview in my work hours
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">
                        Recruiter wants to do live interview on my laptop with
                        camera ON
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">
                        I am not my best after a long day of work
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">
                        Hiring manager not available after work hours and
                        weekends to do{' '}
                        <span className="empty_space">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        an interview
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">
                        I want to be able to show my best skills{' '}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="step_details_txt" md={12}>
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                      <span className="details_txt">
                        I wish I could rephrase my answer
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="employee_steps_img" md={6}>
            <img src={Employee} width="100%"></img>
          </Col>
        </Row>
      </Container>
      <hr></hr>
      <Timeline />
      <br></br>
      <br></br>
      <Footer />
    </>
    </div>
  )
}

export default AboutUs