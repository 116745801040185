import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import { Container } from '@mui/system';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import FlagIcon from '@mui/icons-material/Flag';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import IconButton from '@mui/material/IconButton';
import './ChooseCandidateCard.css';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

function ChooseCandidateCard(props) {
  const handleButtonClick = (e) => {
    console.log('ev', user);
    props.onClick(user, !props.state);
  };
  let user = [];
  var filteredData =
    Array.isArray(props.data) &&
    props.data.length !== 0 &&
    props.data.filter((el) => {
      console.log('el', el);
      return el;
    });
  return (
    <div
      onClick={() => {
        console.log('Hi', user);
        props.onClick(user);
      }}
    >
      <ul className="remove_padding">
        {filteredData &&
          filteredData.map((item) => (
            <Grid container>
              <Grid item xs={12} className="user_search_result_txt">
                <div
                  onClick={() => {
                    console.log('sec', item);
                    user = item;
                  }}
                >
                  <CssBaseline />
                  <Container
                    className="full_card_body"
                    disableGutters={true}
                    fixed
                  >
                    <Container className="card_top_bar">
                      <Grid container spacing={0}>
                        <Grid className="top_bar_item_1" item xs={12} md={6}>
                          {(item.candidate.FirstName != null
                            ? item.candidate.FirstName
                            : '') +
                            ' ' +
                            (item.candidate.LastName != null
                              ? item.candidate.LastName
                              : '')}

                          <span className="candidate_red_status">
                            <Chip
                              className={
                                item.candidate.Status
                                  ? 'user_status_registered'
                                  : 'user_status_unregistered'
                              }
                              sx={{
                                fontSize: '14px',
                                ml: '6px',
                                height: '24px',
                              }}
                              label={
                                item.candidate.Status
                                  ? 'Registered'
                                  : 'Not Registered'
                              }
                            />
                          </span>
                        </Grid>
                        <Grid item xs={12} md={6} className="top_bar_item_2">
                          <Button
                            onClick={(e) => {
                              user = item;
                              handleButtonClick(e);
                            }}
                            className="select_btn"
                          >
                            {' '}
                            {props.state ? 'SELECTED' : 'SELECT'}
                          </Button>

                          {/* {props.display && (
                            <Grid
                              className="top_bar_item_2"
                              item
                              xs={12}
                              md={6}
                            >
                              <Button
                                onClick={(e) => {
                                  user = item;
                                  handleButtonClick(e);
                                }}
                                className="select_btn"
                              >
                                {' '}
                                {props.state ? 'SELECTED' : 'SELECT'}
                              </Button>
                            </Grid>
                          )} */}
                        </Grid>
                      </Grid>
                    </Container>
                    <Container className="card_content" maxWidth="lg">
                      <Grid container spacing={0}>
                        <Grid
                          className="content_area_col_1"
                          item
                          xs={12}
                          md={3}
                        >
                          {!(
                            item.img === undefined ||
                            item.img == '' ||
                            item.img == null
                          ) ? (
                            <Avatar
                              className="user_avatar_set_align"
                              alt={item.candidate.FirstName}
                              src={item.img}
                              sx={{
                                bgcolor: '#6366f1',
                                fontSize: '60px',
                                width: 150,
                                height: 150,
                              }}
                            />
                          ) : (
                            <Avatar
                              className="user_avatar_set_align"
                              alt={item.candidate.FirstName}
                              src="/static/images/avatar/1.jpg"
                              sx={{
                                bgcolor: '#6366f1',
                                fontSize: '60px',
                                width: 150,
                                height: 150,
                              }}
                            />
                          )}
                        </Grid>
                        <Grid
                          className="content_area_col_2"
                          item
                          xs={12}
                          md={4}
                        >
                          <ul>
                            <li>
                              <EmailIcon className="choose_candidate_card_icon" />{' '}
                              {item.candidate.Email}
                            </li>
                            <li>
                              <PhoneAndroidIcon className="choose_candidate_card_icon" />{' '}
                              {item.candidate.Phone}
                            </li>
                          </ul>
                        </Grid>
                        <Grid
                          className="content_area_col_3"
                          item
                          xs={12}
                          md={4}
                        >
                          <ul>
                            <li>
                              <LocalLibraryOutlinedIcon className="choose_candidate_card_icon" />{' '}
                              {item.candidate.YOE}
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs={12} md={1}></Grid>
                      </Grid>
                    </Container>

                    <Container className="card_bottom_bar" maxWidth="lg">
                      <Grid container>
                        <Grid
                          className="bottom_card_col_2"
                          item
                          xs={12}
                          md={12}
                        >
                          <IconButton className="view_icon_btn">
                            <Tooltip title="View">
                              <VisibilityIcon />
                            </Tooltip>
                          </IconButton>
                          <IconButton className="share_icon_btn">
                            <Tooltip title="Share">
                              <ShareIcon />
                            </Tooltip>
                          </IconButton>
                          <IconButton className="flag_icon_btn">
                            <Tooltip title="Archive">
                              <FlagIcon />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Container>
                  </Container>
                </div>
              </Grid>
            </Grid>
          ))}
      </ul>
    </div>
  );
}

export default ChooseCandidateCard;
