import React from 'react';
import RecruiterNavbar from './RecruiterNavbar';
import './RecruiterHomePage.css';
import { Container, Row, Col } from 'react-bootstrap';
import img from '../../assets/images/home.gif';
import { Link } from 'react-router-dom';
import Footer from '../HomePage/Footer';
import Recruiter from '../../assets/images/homepage_recruiter.jpg';
import Employee from '../../assets/images/homepage_employee.jpg';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import LoginIcon from '@mui/icons-material/Login';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import WorkIcon from '@mui/icons-material/Work';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import './RecruiterResponsive.css';
import Timeline from '../HomePage/Timeline';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { orange } from '@mui/material/colors';

function RecruiterHomePage() {
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'none',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'none',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'orange',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));

  function ColorlibStepIcon1(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <LoginIcon />,
      2: <VideoLabelIcon />,
      3: <GroupAddIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  function ColorlibStepIcon2(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <LoginIcon />,
      2: <VideoCameraFrontIcon />,
      3: <WorkIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const stepsrecruiter = ['Sign-up', 'Create interview', 'Hire candidate'];
  const stepsemployee = [
    'Sign-in using invitation',
    'Attend the interview',
    'Get a job',
  ];

  var userLoggedIn = localStorage.getItem('user');
  return (
    <div>
      <RecruiterNavbar />

      <Container className="home_page_heading_container">
        <Row>
          <Col md={6}>
            <div className="home_txt">Cut Your Hiring Time by 30%</div>

            <div className="home_details_txt">
              We help companies fill open job positions fast.
            </div>
            {/* {userLoggedIn == null ? (
              <Row className="home_login_btn">
                <Col md={6}>
                  <div>
                    <h4>For Recruiters</h4>
                    <Link to="/login">
                      <button
                        onClick={(ev) =>
                          localStorage.setItem('role', 'Recruiter')
                        }
                        className="home_btn_hire"
                      >
                        Start Hiring
                      </button>
                    </Link>
                  </div>
                 
                </Col>
                <Col md={6}>
                  <div>
                    <h4>For Job Seekers</h4>
                    <Link to="/login">
                      <button
                        onClick={(ev) =>
                          localStorage.setItem('role', 'Employee')
                        }
                        className="home_btn_signup"
                      >
                        Start Applying
                      </button>
                    </Link>
                  </div>
                 
                </Col>
              </Row>
            ) : (
              <div></div>
            )} */}
            {userLoggedIn == null ? (
              <Row className="home_login_btn">
                <Col></Col>
                <Col>
                  <div>
                    <Link to="/chooseprofile">
                      <button
                        onClick={(ev) =>
                          localStorage.setItem('role', 'Recruiter')
                        }
                        className="home_btn_hire"
                      >
                        Get Started
                      </button>
                    </Link>
                  </div>
                  {/* <div className='learn_more_txt'>
                <a href="">Learn more </a>
                </div> */}
                </Col>
                <Col></Col>
              </Row>
            ) : (
              <div></div>
            )}
          </Col>
          <Col md={6}>
            <img className="home_gif_img" src={img} width="100%"></img>
          </Col>
        </Row>
        </Container>
      <br></br>
      <br></br>
      <Footer />
    </div>
  );
}

export default RecruiterHomePage;
