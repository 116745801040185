import React from 'react';
import Container from '@mui/material/Container';
import '../../components/HomePage/themify-icons.css';
import './Timeline.scss';

function Timeline() {
  return (
    <div>
      <Container fixed>
        <h2>Who are we?</h2>
        <h3>
          InterviewTape makes hiring faster by allowing recruiters to conduct
          non-live video interviews
        </h3>
        <div className="timeline">
          <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
            <div className="timeline__event__icon ">
              <span className="ti-import"></span>
            </div>
            <div className="timeline__event__date">01</div>
            <div className="timeline__event__content ">
              <div className="timeline__event__title">Sign-up</div>
              <div className="timeline__event__description">
                <p>
                  One click setup of screening interview online. Setup the
                  questions you need the candidate to answer. Send invitation to
                  the candidate to complete the interview at their own
                  convenience.{' '}
                </p>
              </div>
            </div>
          </div>
          <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
            <div className="timeline__event__icon">
              <span className="ti-desktop"></span>
            </div>
            <div className="timeline__event__date">02</div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">Interview</div>
              <div className="timeline__event__description">
                <p>
                  Candidate Sign-in using the invitation link sent by the
                  recruiter. They attend the interview and complete the
                  interview process.
                </p>
              </div>
            </div>
          </div>
          <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
            <div className="timeline__event__icon">
              <span className="ti-id-badge"></span>
            </div>
            <div className="timeline__event__date">03</div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">
                Assess , Evaluate & Share
              </div>
              <div className="timeline__event__description">
                <p>
                  Assess & Evaluate the candidate interview response. Share with
                  other recruiters and hiring managers. Make a decision
                </p>
              </div>
            </div>
          </div>
          <div className="timeline__event animated fadeInUp timeline__event--type1">
            <div className="timeline__event__icon">
              <span className="ti-user"></span>
            </div>
            <div className="timeline__event__date">04</div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">Hire</div>
              <div className="timeline__event__description">
                <p>
                  Happy with the candidate response, move them to the next round
                  or fully satisfied, Hire them right away.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Timeline;
