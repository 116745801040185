import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import QuestionPage from './QuestionPage';
import RecruiterNavbar from './RecruiterNavbar';
import Form from 'react-bootstrap/Form';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Jobs from '../Service/AWS_Auth/JobDataService';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router';

const steps = [
  'Select Job ID',
  'Add questions',
  'Select the candidate emailID',
];
function InterviewQuestion() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [allJob, setAllJob] = React.useState({});
  const [selectedJob, setSelectedJob] = React.useState();
  const location = useLocation();
  useEffect(async () => {
    let jobID = location.state != null ? location.state.id : undefined;

    await Jobs.getAllJobsByCompany('70709f3e-1bf3-407a-8793-037aa368e077').then(
      (allJob) => {
        let job = [];
        let id = 0;
        allJob.forEach((Job) => {
          job.push({
            id: id,
            name: Job.InternalID,
            JobId: Job.id,
          });
          id++;
        });
        setAllJob(job);
      }
    );
    if (jobID != null) {
      let job = [];
      await Jobs.getJobsByID(jobID).then((value) => {
        job.push({
          id: 0,
          name: value.InternalID,
          JobId: value.id,
        });
        setSelectedJob(job);
      });
    }
  }, []);
  const isStepOptional = (step) => {
    return step === 1;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    if (selectedJob != undefined && selectedJob != '') {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    console.log('activeStep', activeStep);
    if (activeStep === 1) {
      setSelectedJob(selectedJob);
    }
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const handleOnSelect = (item) => {
    console.log(item);
    setSelectedJob(item);
  };
  const searchKey = async (item) => {
    console.log(item);
    // if (item == '') {
    //   setNewCandidate();
    // } else {
    //   setNewCandidate(item);
    // }
  };
  const formatResult = (item) => {
    console.log(item);

    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>
          {' '}
          {item.name}
        </span>
      </>
    );
  };

  const formatClear = () => {
    // setSelectedUser();
    // setNewCandidate();
  };

  return (
    <div>
      {/* <Navbar/> */}
      <RecruiterNavbar></RecruiterNavbar>
      <Container fluid>
        <Grid container spacing={2}>
          <h2>Schedule a interview</h2>
        </Grid>
      </Container>
      <Container fixed>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              // if (isStepOptional(index)) {
              //   labelProps.optional = (
              //     <Typography variant="caption">Optional</Typography>
              //   );
              // }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Step {activeStep + 1}
              </Typography>
              {activeStep == 0 && (
                <Form
                //  noValidate
                //  validated={validated}
                //  onSubmit={handleSubmit(dataUpload)}
                >
                  {allJob.length != 0 && (
                    <Grid>
                      <Form.Group
                        className="emp_id_col"
                        as={Grid}
                        md="6"
                        controlId="validationCustomUsername"
                      >
                        <Form.Label className="question_text">
                          Select Job ID :
                        </Form.Label>

                        <div>
                          <ReactSearchAutocomplete
                            items={allJob}
                            autoFocus
                            fuseOptions={{ threshold: 0.0 }}
                            onSelect={handleOnSelect}
                            onClear={formatClear}
                            formatResult={formatResult}
                            onSearch={searchKey}
                            inputSearchString={
                              selectedJob != null ? selectedJob.name : ''
                            }
                            // showNoResultsText={searchKey}
                          />
                        </div>
                      </Form.Group>
                    </Grid>
                  )}
                </Form>
              )}
              {activeStep == 1 && <QuestionPage />}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {/* {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )} */}
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </div>
  );
}
export default InterviewQuestion;
