import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import './NewRecruiterProfile.css';
import RecruiterNavbar from './RecruiterNavbar';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  parsePhoneNumber,
  isValidPhoneNumber,
  getNumberType,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

function NewRecruiterProfile() {
  return (
    <>
      <RecruiterNavbar />
      <br />
      <div>
        <Container fixed>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <div className="rec_create_profile">Recruiter Personal Info</div>
            </Grid>
          </Grid>
          <br />
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item md={12} className="rec_create_profile_grid">
                <br />
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className="rec_create_profile_textbox"
                  >
                    <TextField
                      required
                      fullWidth
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className="rec_create_profile_textbox"
                  >
                    <TextField
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    className="rec_create_profile_textbox"
                  >
                    <MuiPhoneNumber
                      required
                      fullWidth
                      label="Phone Number"
                      variant="outlined"
                      defaultCountry={'in'}
                      onChange={(c, t) => {
                        console.log(c, t, isValidPhoneNumber(c));
                        return true;
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    className="rec_create_profile_textbox"
                  >
                    <TextField
                      type="search"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Company Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    className="rec_create_profile_textbox"
                  >
                    <TextField
                      required
                      fullWidth
                      id="outlined-basic"
                      label="LinkedIn"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    className="rec_create_save_btn_grid"
                  >
                    <Button
                      className="rec_create_save_btn"
                      variant="contained"
                      type="submit"
                    >
                      Save & Continue
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
}

export default NewRecruiterProfile;
