import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
class AWSAuth {
  async signUp(email, password) {
    return new Promise(async (resolve, reject) => {
      try {
        await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email: email,
          },
        }).then((user) => {
          console.log(user);
          resolve('true');
        });
      } catch (error) {
        console.log('error signing up:', error);
        reject(error);
      }
    });
  }

  async confirmSignUp(email, code) {
    return new Promise(async (resolve, reject) => {
      try {
        await Auth.confirmSignUp(email, code).then((user) => {
          console.log(user);
          resolve('true');
        });
      } catch (error) {
        console.log('error confirming sign up', error);
        reject(error);
      }
    });
  }

  async resendConfirmationCode(username) {
    return new Promise(async (resolve, reject) => {
      try {
        await Auth.resendSignUp(username);
        console.log('code resent successfully');
        resolve(true);
      } catch (err) {
        console.log('error resending code: ', err);
        reject(err);
      }
    });
  }
  async signIn(email, password) {
    return new Promise(async (resolve, reject) => {
      try {
        await Auth.signIn(email, password).then((value) => {
          console.log(value);
          resolve('true');
        });
      } catch (error) {
        console.log('error signing in', error);
        reject(error);
      }
    });
  }

  async federatedSignIn() {
    return new Promise(async (resolve, reject) => {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      })
        .then(() => {
          // User signed in successfully
          console.log('User signed in with Google OAuth');
          resolve('User signed in with Google OAuth');
        })
        .catch((error) => {
          // Handle sign-in error
          console.log('Error signing in with Google OAuth:', error);
          reject(error);
        });
    });
  }

  async signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  async loginConfirm() {
    return new Promise(async (resolve, reject) => {
      try {
        await Auth.currentSession().then((value) => {
          console.log(value);

          resolve(true);
        }); //currentUserCredentials();
      } catch (error) {
        console.log('error loginConfirm: ', error);
        reject(error);
      }
    });
  }

  async forgetPassword(email) {
    return new Promise(async (resolve, reject) => {
      try {
        await Auth.forgotPassword(email).then((value) => {
          console.log(value);

          resolve(true);
        }); //currentUserCredentials();
      } catch (error) {
        console.log('error loginConfirm: ', error);
        reject(error);
      }
    });
  }

  async forgetPasswordSubmitPassword(email, code, newPassword) {
    return new Promise(async (resolve, reject) => {
      try {
        await Auth.forgotPasswordSubmit(email, code, newPassword).then(
          (value) => {
            console.log(value);

            resolve(true);
          }
        );
      } catch (error) {
        console.log('error loginConfirmForget: ', error);
        reject(error);
      }
    });
  }

  async changePassword(email, oldPassword, newPassword) {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword).then((e) => {
          console.log('Password changed successfully');
          resolve(true);
        });
      } catch (error) {
        console.log('Error changing password:', error);
        reject(error);
      }
    });
  }
}
export default new AWSAuth();
