import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import emailjs from '@emailjs/browser';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import RecruiterNavbar from './RecruiterNavbar';
import Footer from '../HomePage/Footer';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import interviewService from '../Service/AWS_Auth/interviewService';
import candidateService from '../Service/AWS_Auth/CandidateDataService';
import {
  Candidate,
  InterviewSchedule,
  InterviewStatus,
  QuestionBank,
} from '../../models';
import AwsAmplify from '../Service/AWS_Auth/RecruiterDataService';
import CandidateAmplify from '../Service/AWS_Auth/CandidateDataService';
import './QuestionPage.css';
import questions from '../Service/AWS_Auth/QuestionDataService';

function QuestionPage() {
  const [optCandidateModal, setOptCandidateModal] = useState(false);
  const [optQuestionModal, setOptQuestionModal] = useState(false);

  const [UserRole, setUserRole] = React.useState('');
  const [users, setUser] = React.useState({});
  const navigate = useNavigate();
  const location = useLocation();
  var user = [];
  const [userDetails, setUserDetails] = React.useState();
  const [recruiter, setRecruiter] = React.useState();
  const [allUser, setAllUser] = React.useState({});
  const [question1, setQuestion1] = React.useState('Tell me about yourself ?');
  const [question2, setQuestion2] = React.useState('');
  const [question3, setQuestion3] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState();
  const [newCandidate, setNewCandidate] = React.useState();
  const [recruiterDetails, setRecruiterDetails] = React.useState();
  const [validated, setValidated] = useState(false);

  const getQuestion = async () => {
    await questions.getALL().then((value) => {
      let bank = [];
      value.forEach((question) => {
        bank.push(question.QuestionText);
      });
      setPredefinedQuestions(bank);
    });
  };

  const handleSharing = async (url, title, text) => {
    if (navigator.share) {
      const shareDetails = { text, title, text };
      try {
        await navigator
          .share(shareDetails)
          .then(() =>
            console.log('Hooray! Your content was shared to tha world')
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        ' share Link is currently not supported on this browser. Please provide a callback'
      );
    }
  };
  const {
    register,

    reset,
    formState: { errors },
  } = useForm(question1);

  const sendEmail = (e) => {
    console.log(e);
    emailjs
      .send('service_t288pcc', 'template_3cj2md1', e, '4T3ITSR8NZv24KKIq')
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const dataUpload = async (event) => {
    if (selectedUser == undefined && question1 != '') {
      var data = new Candidate({
        Email: newCandidate,
        Status: false,
      });
      if (question2 == '' && question3 == '') {
        await CandidateAmplify.createCandidate(data).then(async (user) => {
          console.log(user.id);
          if (user != null && user.id != null && question1 != '') {
            var QuestionData = new InterviewSchedule({
              Title: 'Job Title',
              Description: 'Job Description',
              CreatedTime: new Date(Date.now()).toDateString(),
              Status: new InterviewStatus({
                Create: true,
                Viewed: false,
                Completed: false,
                Declined: false,
                Selected: false,
                Rejected: false,
              }),
              Recruiter: recruiter,
              recruiterID: recruiter,
              Question4: question1,
              Question5: 'No question',
              Question6: 'No question',
              interviewScheduleCandidateId: user.id,
              CandidateName: 'New User',
              CandidateEmail: newCandidate,
              RecruiterName:
                recruiterDetails.FirstName + recruiterDetails.LastName,
              RecruiterEmail: recruiterDetails.Email,
              RecruiterCompany: recruiterDetails.CompanyName,
            });
            console.log('Interview', QuestionData);
            let interviewUpdate = await interviewService.save(QuestionData);
            setValidated(true);
            window.history.back();
          }
        });
      } else {
        await CandidateAmplify.createCandidate(data).then(async (user) => {
          console.log(user.id);
          if (user != null && user.id != null && question1 != '') {
            var QuestionData = new InterviewSchedule({
              Title: 'Job Title',
              Description: 'Job Description',
              CreatedTime: new Date(Date.now()).toDateString(),
              Status: new InterviewStatus({
                Create: true,
                Viewed: false,
                Completed: false,
                Declined: false,
                Selected: false,
                Rejected: false,
              }),
              Recruiter: recruiter,
              recruiterID: recruiter,
              Question4: question1,
              Question5: question2,
              Question6: question3,
              interviewScheduleCandidateId: user.id,
              CandidateName: 'New User',
              CandidateEmail: newCandidate,
              RecruiterName:
                recruiterDetails.FirstName + recruiterDetails.LastName,
              RecruiterEmail: recruiterDetails.Email,
              RecruiterCompany: recruiterDetails.CompanyName,
            });
            console.log('Interview', QuestionData);
            let interviewUpdate = await interviewService.save(QuestionData);
            setValidated(true);
            window.history.back();
          }
        });
      }
    }

    if (selectedUser != undefined && question1 != '') {
      var name =
        selectedUser.CandidateName != null
          ? selectedUser.CandidateName
          : 'New User';
      if (question2 == '' && question3 == '') {
        var QuestionData = new InterviewSchedule({
          Title: 'Job Title',
          Description: 'Job Description',
          CreatedTime: new Date(Date.now()).toDateString(),
          Status: new InterviewStatus({
            Create: true,
            Viewed: false,
            Completed: false,
            Declined: false,
            Selected: false,
            Rejected: false,
          }),
          Recruiter: recruiter,
          recruiterID: recruiter,
          Question4: question1,
          Question5: 'No question',
          Question6: 'No question',
          interviewScheduleCandidateId: selectedUser.candidateId,
          CandidateName: name,
          CandidateEmail: selectedUser.name,
          RecruiterName: recruiterDetails.FirstName + recruiterDetails.LastName,
          RecruiterEmail: recruiterDetails.Email,
          RecruiterCompany: recruiterDetails.CompanyName,
        });
        console.log('Interview', QuestionData);
        let interviewUpdate = await interviewService.save(QuestionData);
        setValidated(true);
        window.history.back();
      } else {
        var QuestionData = new InterviewSchedule({
          Title: 'Job Title',
          Description: 'Job Description',
          CreatedTime: new Date(Date.now()).toDateString(),
          Status: new InterviewStatus({
            Create: true,
            Viewed: false,
            Completed: false,
            Declined: false,
            Selected: false,
            Rejected: false,
          }),
          Recruiter: recruiter,
          recruiterID: recruiter,
          Question4: question1,
          Question5: question2,
          Question6: question3,
          interviewScheduleCandidateId: selectedUser.candidateId,
          CandidateName: name,
          CandidateEmail: selectedUser.name,
          RecruiterName: recruiterDetails.FirstName + recruiterDetails.LastName,
          RecruiterEmail: recruiterDetails.Email,
          RecruiterCompany: recruiterDetails.CompanyName,
        });
        console.log('Interview', QuestionData);
        let interviewUpdate = await interviewService.save(QuestionData);
        setValidated(true);
        window.history.back();
      }
    }
  };

  const getAllUser = async () => {
    await candidateService.getAllCandidate().then((allCandidates) => {
      let user = [];
      let id = 0;
      allCandidates.forEach((candidate) => {
        user.push({
          id: id,
          name: candidate.Email,
          candidateId: candidate.id,
          CandidateName:
            candidate.FirstName != null
              ? candidate.FirstName + ' ' + candidate.LastName
              : null,
        });
        id++;
      });
      setAllUser(user);
    });
  };
  useEffect(async () => {
    getAllUser();
    var rec = localStorage.getItem('recruiterId');
    setRecruiter(rec);
    let email = localStorage.getItem('email');
    await AwsAmplify.getRecruiter(email).then((userValue) => {
      setRecruiterDetails(userValue);
    });
    getQuestion();
    //   let userValue =await RecruiterProfileDB.getRecById(userDecoded.sub);
    //   setRecruiterDetails(userValue);
    //   setUser(userDecoded);
    //   setUserRole(localStorage.getItem("role"));
    //   setUserDetails(userValue);

    //   let defaultValues = {};
    //   reset({ ...defaultValues });
  }, []);

  const handleOnSelect = (item) => {
    console.log(item);
    setSelectedUser(item);
  };
  const searchKey = async (item) => {
    console.log(item);
    if (item == '') {
      setNewCandidate();
    } else {
      setNewCandidate(item);
    }
  };
  const formatResult = (item) => {
    console.log(item);

    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>
          {' '}
          {item.name}
        </span>
      </>
    );
  };

  const formatClear = () => {
    setSelectedUser();
    setNewCandidate();
  };
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [selectedField, setSelectedField] = useState(null);
  const [predefinedQuestions, setPredefinedQuestions] = useState(['']);

  const handlePreset = (question) => {
    switch (selectedField) {
      case 1:
        if (question !== text2 && question !== text3) {
          setText1(question);
        }
        break;
      case 2:
        if (question !== text1 && question !== text3) {
          setText2(question);
        }
        break;
      case 3:
        if (question !== text1 && question !== text2) {
          setText3(question);
        }
        break;
      default:
        break;
    }
  };

  const handleFieldSelection = (field) => {
    setSelectedField(field);
  };

  const handleNewQuestion = (field, question) => {
    switch (field) {
      case 1:
        setText1(question);
        break;
      case 2:
        setText2(question);
        break;
      case 3:
        setText3(question);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const newQuestion =
      selectedField === 1 ? text1 : selectedField === 2 ? text2 : text3;
    if (!predefinedQuestions.includes(newQuestion)) {
      await questions
        .save(new QuestionBank({ QuestionText: newQuestion }))
        .then((value) => {
          console.log('new question', value);
          setPredefinedQuestions([...predefinedQuestions, newQuestion]);
        });
      //pass to validation
    }
  };

  return (
    <>
      {/* <RecruiterNavbar></RecruiterNavbar> */}

      <Container>
        <Row>
          <Col className="question_text_fields_col" md={6}>
            {' '}
            <div className="Q_text">Q1:</div>
            <input
              type="text"
              value={text1}
              onChange={(e) => setText1(e.target.value)}
              onClick={() => handleFieldSelection(1)}
            />
            <br />
            <div className="Q_text">Q2:</div>
            <input
              type="text"
              value={text2}
              onChange={(e) => setText2(e.target.value)}
              onClick={() => handleFieldSelection(2)}
            />
            <br />
            <div className="Q_text">Q3:</div>
            <input
              type="text"
              value={text3}
              onChange={(e) => setText3(e.target.value)}
              onClick={() => handleFieldSelection(3)}
            />
          </Col>
          <Col className="pre-defined_Q_list_col" md={6}>
            <h1>Pre-definedQuestions:</h1>
            <ul>
              {predefinedQuestions.map((question, index) => (
                <li key={index} onClick={() => handlePreset(question)}>
                  {question}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {' '}
            <div style={{ padding: '10px' }}>
              <button className="slide submit_btn" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <br />
      <Footer></Footer>
    </>
  );
}

export default QuestionPage;
