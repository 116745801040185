import { initializeApp, getApp} from 'firebase/app';
import {getDatabase} from 'firebase/database'
import { getStorage } from "firebase/storage";
function firebaseConfig(){
    var config = {
        apiKey: "AIzaSyAVNSrAmvmeVHRyoBONDJe6DD3US07ii8Y",
        authDomain: "jobble-d82ad.firebaseapp.com",
        databaseURL: "https://jobble-d82ad-default-rtdb.firebaseio.com",
        projectId: "jobble-d82ad",
        storageBucket: "jobble-d82ad.appspot.com",
        messagingSenderId: "279688560251",
        appId: "1:279688560251:web:b5d94d1b98802f8d81acde"
        };
      const app = initializeApp(config); 
      return getDatabase(app);
    

}

export default firebaseConfig;