import React, { useState } from 'react';
import ShareModal from './ShareModal';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import './shareCandidate.css';

function ShareCandidate({ label, text, title, urlID }) {
  // console.log(title)
  const [showModal, setShowModal] = useState(false);
  const canonical = document.querySelector('link[rel=canonical]');
  let url = `${document.location.origin}/newprofileshare/?id=${urlID.id}&per=${urlID.per}`;
  const shareDetails = { url, title, text };

  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() =>
            console.log('Hooray! Your content was shared to tha world')
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      setShowModal(true);
      console.log(
        ' share Link is currently not supported on this browser. Please provide a callback'
      );
    }
  };
  return (
    <>
      {/* <span onClick={handleSharing} className="ti-sharethis-alt"></span> */}
      {/* <button className="slide share_btn" onClick={handleSharing}>
        {' '}
        Share
      </button> */}
      <Button
        className="share_profile_btn"
        fullWidth
        variant="outlined"
        onClick={handleSharing}
      >
        {' '}
        <ShareIcon className="share_candidate_icon_btn" />
        Share
      </Button>
      <ShareModal
        handleClose={setShowModal}
        shareData={shareDetails}
        modalVisible={showModal}
      />
    </>
  );
}
export default ShareCandidate;
