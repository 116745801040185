import React, { useState } from 'react';
import './NewEditProfile.css';
import CandidateNavbar from './CandidateNavbar';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/system';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MuiPhoneNumber from 'material-ui-phone-number';
import { isValidPhoneNumber } from 'libphonenumber-js';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FileUpload from 'react-material-file-upload';
import ContactEmergencyRoundedIcon from '@mui/icons-material/ContactEmergencyRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import WorkHistoryRoundedIcon from '@mui/icons-material/WorkHistoryRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import AwsAmplify from '../Service/AWS_Auth/CandidateDataService';
import AWS_Storage from '../Service/AWS_Auth/VideoStorage';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Storage } from '@aws-amplify/storage';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Candidate } from '../../models';
import authenticationServices from '../Service/AWS_Auth/authenticationServices';
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#6366f1',
//     },
//     secondary: {
//       main: '#fffff',
//     },
//   },
// });

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function NewEditProfile() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [firstName, setFirstName] = React.useState('');
  const [id, setID] = React.useState();
  const [lastName, setLastName] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [homeTown, setHomeTown] = React.useState('');
  const [currentLocation, setCurrentLocation] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [qualificationChoice, setQualificationChoice] = React.useState('');
  const [course, setCourse] = React.useState('');
  const [clgName, setClgName] = React.useState('');
  const [clgCity, setClgCity] = React.useState('');
  const [CGPA, setCGPA] = React.useState(0);
  const [experience, setExperience] = React.useState('');
  const [isFresher, setIsFresher] = useState(true);
  const [isExperienced, setIsExperienced] = useState(false);
  const [files, setFiles] = useState([]);
  const [resume, setResume] = React.useState();
  const [userDetails, setUserDetails] = React.useState();
  const [fresherProjectName, setFresherProjectName] = React.useState('');
  const [fresherProjectDetails, setFresherProjectDetails] = React.useState('');
  const [fresherProjectTools, setFresherProjectTools] = React.useState('');
  const [YOE, setYOE] = React.useState(0);
  const [companyName, setCompanyName] = React.useState('');
  const [role, setRole] = React.useState('');
  const [linkedin, setLinkedin] = React.useState();
  const [github, setGithub] = React.useState();
  const [youtube, setYoutube] = React.useState();
  const [profilePic, setProfilePic] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [picUrl, setPicUrl] = React.useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorResume, setErrorResume] = useState(false);
  const [errorPic, setErrorPic] = useState(null);
  const [datechange, setDatechange] = React.useState(null);
  const [relocation, setRelocation] = useState('No');
  const [work, setWork] = useState('Office');
  const [currentSalary, setCurrentSalary] = useState('');
  const [expectedSalary, setExpectedSalary] = useState('');
  const inputRef = useRef(null);
  const [currentPassword, setCurrentPassword] = React.useState();
  const [newPassword, setNewPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();
  const [currentPasswordErr, setCurrentPasswordErr] = React.useState(false);
  const [newPasswordErr, setNewPasswordErr] = React.useState(false);
  const [confirmPasswordErr, setConfirmPasswordErr] = React.useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(phone);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Fresher = () => {
    setIsFresher(true);
    setIsExperienced(false);
    setExperience('Fresher');
  };
  const Experienced = () => {
    setIsExperienced(true);
    setIsFresher(false);
    setExperience('Experience');
  };
  const dataUpload = async (e) => {
    try {
      var data = new Candidate({
        FirstName: firstName,
        Phone: phone,
        Email: email,
        DOB: dob,
        Gender: gender,
        Qualification: qualificationChoice,
        LastName: lastName,
        HomeLocation: homeTown,
        CurrentLocation: currentLocation,
        College: clgName,
        CollegeCity: clgCity,
        Course: course,
        CGPA: CGPA,
        Experience: experience,
        Resume: resume,
        FresherProjectDetails: fresherProjectDetails,
        FresherProjectName: fresherProjectName,
        FresherProjectTools: fresherProjectTools,
        YOE: YOE,
        CompanyName: companyName,
        CurrentRole: role,
        Relocation: relocation == 'Yes' ? true : false,
        WorkPreference: work,
        CurrentSalary: currentSalary,
        ExpectedSalary: expectedSalary,
        Github: github,
        Linkedin: linkedin,
        Youtube: youtube,
        ProfilePic: profilePic,
        Status: true,
      });
      // let userValue =userDetails!=null ? await UserProfile.updateProfile("Seeker",firstName,lastName,dob,homeTown,currentLocation,phone,gender,qualificationChoice,course,clgName,clgCity,CGPA,"skill",experience,resumeUpload,fresherProjectName,fresherProjectDetails,fresherProjectTools,YOE,companyName,role,user.email,github,youtube,linkedin,profilePic,(user.picture),(user.sub))
      // :await UserProfile.create(data,(user.sub))
      // if(userValue==="success"){
      //   navigate("/instructionPage");
      // }

      //   setValidated(true);
      console.log(id);

      if (id == undefined) {
        await AwsAmplify.save(data)
          .then((user) => {
            if (user.length != 0) {
              if (value == 0) {
                toast.success('Your personal info has been saved successfully');
                setValue(1);
              }
              if (value == 1) {
                toast.success(
                  'Your education info has been saved successfully'
                );
                setValue(2);
              }
              if (value == 2) {
                toast.success(
                  'Your work experience info has been saved successfully'
                );
                setValue(3);
              }
              if (value == 3) {
                toast.success(
                  'Your social link info has been saved successfully'
                );
                setValue(4);
              }
              if (value == 4) {
                toast.success('Your resume has been saved successfully');
                setValue(5);
              }
              // navigate("/instruction",{ state:{id:user.id}});
            }
          })
          .catch((err) => {
            toast.error(err.toString());
          });
      } else {
        await AwsAmplify.update(id, data).then((user) => {
          if (user == 'success') {
            if (value == 0) {
              toast.success('Your personal info has been saved successfully');
              setValue(1);
            }
            if (value == 1) {
              toast.success('Your education info has been saved successfully');
              setValue(2);
            }
            if (value == 2) {
              toast.success(
                'Your work experience info has been saved successfully'
              );
              setValue(3);
            }
            if (value == 3) {
              toast.success(
                'Your social link info has been saved successfully'
              );
              setValue(4);
            }
            if (value == 4) {
              toast.success('Your resume has been saved successfully');
              setValue(5);
            }

            // navigate("/instruction",{ state:{id:id}});
          }
        });
      }
    } catch (e) {
      console.log(e);
      toast.error(e.toString());
    }
  };

  const validateForm = () => {
    if (confirmPassword == null) {
      setConfirmPasswordErr(true);
      return false;
    }
    if (currentPassword == null) {
      setCurrentPasswordErr(true);
      return false;
    }
    if (newPassword == null) {
      setNewPasswordErr(true);
      return false;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordErr(true);
      setNewPasswordErr(true);
      return false;
    }
    if (
      currentPassword != null &&
      currentPassword.length > 0 &&
      newPassword != null &&
      newPassword.length > 0 &&
      newPassword === confirmPassword
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleChangePassword = async (event) => {
    event.preventDefault();
    let email = localStorage.getItem('email');
    console.log('came');
    if (validateForm()) {
      await authenticationServices
        .changePassword(email, currentPassword, newPassword)
        .then((value) => {
          toast.success('Successfully password updated');
        })
        .catch((e) => {
          toast.error(e.toString());
        });
    } else {
      console.log('Invalid form inputs');
      if (confirmPassword == null) {
        setConfirmPasswordErr(true);
      } else {
        setConfirmPasswordErr(false);
      }
      if (currentPassword == null) {
        setCurrentPasswordErr(true);
      } else {
        setCurrentPasswordErr(false);
      }
      if (newPassword == null) {
        setNewPasswordErr(true);
      } else {
        setNewPasswordErr(false);
      }
      if (newPassword !== confirmPassword) {
        setConfirmPasswordErr(true);
        setNewPasswordErr(true);
      }
    }
  };
  useEffect(async () => {
    // userDecoded = jwtDecode(localStorage.getItem("user"));
    // setUser(userDecoded);
    // let userValue =await UserProfile.getUserById(userDecoded.sub);
    let email = localStorage.getItem('email');
    setEmail(email);
    console.log('email', email);
    await AwsAmplify.getCandidate(email)
      .then(async (userValue) => {
        console.log('userValue', userValue);
        setUserDetails(userValue);
        if (userValue.length != 0) {
          if (userValue.Status == true) {
            setID(userValue.id);
            setFirstName(userValue.FirstName);
            setLastName(userValue.LastName);
            setDob(userValue.DOB);
            setHomeTown(userValue.HomeLocation);
            setCurrentLocation(userValue.CurrentLocation);
            setPhone(userValue.Phone);
            userValue.Phone != null
              ? setIsValidPhone(false)
              : setIsValidPhone(true);
            setGender(userValue.Gender);
            setQualificationChoice(
              userValue.Qualification != null ? userValue.Qualification : ''
            );
            // setQualification(userValue.UserProfile.Qualification)
            setCourse(userValue.Course);
            setClgName(userValue.College);
            setClgCity(userValue.CollegeCity);
            setCGPA(userValue.CGPA);
            setExperience(userValue.Experience);
            setResume(userValue.Resume);
            if (userValue.Resume != null && userValue.Resume != '') {
              setErrorResume(false);
              setSelectedFile(true);
            } else {
              setErrorResume(true);
              setSelectedFile(false);
            }
            setFresherProjectDetails(userValue.FresherProjectDetails);
            setFresherProjectName(userValue.FresherProjectName);
            setFresherProjectTools(userValue.FresherProjectTools);
            setYOE(userValue.YOE);
            setCompanyName(userValue.CompanyName);
            setRole(userValue.CurrentRole);
            setRelocation(
              userValue.Relocation != null ? userValue.Relocation : relocation
            );
            setWork(
              userValue.WorkPreference != null ? userValue.WorkPreference : work
            );
            setGithub(userValue.Github != null ? userValue.Github : '');
            setLinkedin(userValue.Linkedin != null ? userValue.Linkedin : '');
            setYoutube(userValue.Youtube != null ? userValue.Youtube : '');
            setProfilePic(
              userValue.ProfilePic != null ? userValue.ProfilePic : null
            );
            if (userValue.Experience == 'Fresher') {
              Fresher();
            }
            if (userValue.Experience == 'Experience') {
              Experienced();
            }
            localStorage.setItem('candidateId', userValue.id);
            localStorage.setItem('fname', userValue.FirstName);
            if (userValue.ProfilePic != null) {
              await Storage.get(userValue.ProfilePic).then((val) => {
                setPicUrl(val);
                setErrorPic(false);
              });
            }
          } else {
            setID(userValue.id);
            setEmail(userValue.Email);
          }
        }
      })
      .catch((err) => {
        toast.error(err.toString());
      });

    let defaultValues = {};
    reset({ ...defaultValues });
  }, []);

  const resumeUpload = async (e, file) => {
    setSelectedFile(file);
    if (file) {
      await AWS_Storage.Resume(file).then(async (value) => {
        setResume(value);
      });
    }
  };
  const handlePhoneNumberChange = (value) => {
    if (isValidPhoneNumber(value)) {
      setIsValidPhone(false);
      setPhone(value);
    } else {
      setIsValidPhone(true);
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };
  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    setIsValid(validateEmail(inputEmail));
  };
  const ProfileUpload = async (e, file) => {
    if (file) {
      await AWS_Storage.ProfilePic(file).then(async (value) => {
        await Storage.get(value).then(async (val) => {
          setPicUrl(val);
          setProfilePic(value);
        });
      });
    }
  };
  return (
    <div>
      <CandidateNavbar />

      <Container maxWidth className="bg_color" sx={{ height: '100vh' }}>
        <div>
          <br />
          <Container fixed>
            <Grid container spacing={2} sx={{ pb: 2 }}>
              <Grid item md={12} xs={12}>
                <div className="edit_profile_title_txt">Edit Profile</div>
              </Grid>
            </Grid>
            <Box>
              <Grid container spacing={6}>
                <Grid item xs={12} md={3}>
                  <Grid container className="tabs_style">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <label htmlFor="contained-button-file">
                          <Avatar
                            className="avatar_style"
                            sx={{ width: 175, height: 175 }}
                            src={picUrl}
                          />
                          <IconButton
                            className="camera_icon_overlay"
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              onChange={async (e) => {
                                const file = e.target.files[0];
                                ProfileUpload(e, file);
                              }}
                              type="file"
                            />
                            <AddAPhotoRoundedIcon />
                          </IconButton>
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item x={12} md={12} sx={{ pb: 2 }}></Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} sx={{ pb: 2 }}></Grid>
                    </Grid>
                    <Grid item xs={12} md={12} className="tabs_list_grid">
                      <Tabs
                        indicatorColor="primary"
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        <Tab
                          className="tab_list_style"
                          icon={<ContactEmergencyRoundedIcon />}
                          iconPosition="start"
                          label="Personal Info"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="tab_list_style"
                          icon={<SchoolRoundedIcon />}
                          iconPosition="start"
                          label="Education"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="tab_list_style"
                          icon={<WorkHistoryRoundedIcon />}
                          iconPosition="start"
                          label="Experience"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="tab_list_style"
                          icon={<PeopleRoundedIcon />}
                          iconPosition="start"
                          label="Social Links "
                          {...a11yProps(3)}
                        />
                        <Tab
                          className="tab_list_style"
                          icon={<SummarizeRoundedIcon />}
                          iconPosition="start"
                          label="Resume"
                          {...a11yProps(4)}
                        />
                        <Tab
                          className="tab_list_style"
                          icon={<PasswordRoundedIcon />}
                          iconPosition="start"
                          label="Change Password"
                          {...a11yProps(5)}
                        />
                      </Tabs>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container>
                    <Grid item md={12} xs={12} className="input_field_style">
                      <TabPanel value={value} index={0}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Set Up Your Personal Information
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          onSubmit={(e) => handleSubmit(dataUpload)}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="First Name"
                                variant="outlined"
                                onChange={(ev) => setFirstName(ev.target.value)}
                                value={firstName}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                onChange={(ev) => setLastName(ev.target.value)}
                                value={lastName}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  required
                                  label="Date of Birth"
                                  disableFuture
                                  value={dob}
                                  onChange={(newValue) => {
                                    setDob(newValue);
                                  }}
                                  renderInput={(props) => {
                                    return (
                                      <TextField
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onChange={(ev) =>
                                          setDob(ev.target.value)
                                        }
                                        value={dob}
                                        renderValue={(value) => `${value}`}
                                        {...props}
                                      />
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  required
                                >
                                  Gender
                                </InputLabel>
                                <Select
                                  required
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="gender"
                                  value={gender}
                                  defaultValue={gender}
                                  onChange={(ev) => setGender(ev.target.value)}
                                >
                                  <MenuItem>
                                    <em>Select an option</em>
                                  </MenuItem>
                                  <MenuItem value="Female">Female</MenuItem>
                                  <MenuItem value="Male">Male</MenuItem>
                                  <MenuItem value="Other">Other</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              xs={12}
                              md={3}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl
                                fullWidth
                                required
                                error={!isValidPhone}
                              >
                                <MuiPhoneNumber
                                  value={phone}
                                  fullWidth
                                  label="Phone Number"
                                  variant="outlined"
                                  defaultCountry={'in'}
                                  error={isValidPhone}
                                  helperText={
                                    isValidPhone &&
                                    'Please enter a valid phone number'
                                  }
                                  onChange={(value) =>
                                    handlePhoneNumberChange(value)
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Hometown"
                                variant="outlined"
                                onChange={(ev) => setHomeTown(ev.target.value)}
                                value={homeTown}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Current Location"
                                variant="outlined"
                                onChange={(ev) =>
                                  setCurrentLocation(ev.target.value)
                                }
                                value={currentLocation}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={4} className="submit_btn">
                              <Button
                                className="profile_save_btn"
                                variant="contained"
                                type="submit"
                              >
                                Save
                              </Button>
                              <Button
                                className="cancel_btn"
                                variant="contained"
                                onClick={(e) => {
                                  navigate('/candidate/newprofileview');
                                }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Set Up Your Education
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          onSubmit={handleSubmit(dataUpload)}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  required
                                >
                                  Highest Qualification
                                </InputLabel>
                                <Select
                                  fullWidth
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Highest Qualification"
                                  onChange={(e) => {
                                    setQualificationChoice(e.target.value);
                                  }}
                                  value={qualificationChoice}
                                >
                                  <MenuItem value={"Bachelor's"}>
                                    Bachelor's
                                  </MenuItem>
                                  <MenuItem value={'Diploma'}>Diploma</MenuItem>
                                  <MenuItem value={"Master's"}>
                                    Master's
                                  </MenuItem>
                                  <MenuItem value={'PhD'}>PhD</MenuItem>
                                  <MenuItem value={'Others'}>Others</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            >
                              {' '}
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="College Name"
                                variant="outlined"
                                onChange={(ev) => setClgName(ev.target.value)}
                                value={clgName}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            >
                              {' '}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            >
                              {' '}
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Course Name"
                                variant="outlined"
                                onChange={(ev) => setCourse(ev.target.value)}
                                value={course}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            >
                              {' '}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            >
                              {' '}
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="City"
                                variant="outlined"
                                onChange={(ev) => setClgCity(ev.target.value)}
                                value={clgCity}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            >
                              {' '}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            >
                              {' '}
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                defaultValue={''}
                                required
                                fullWidth
                                type="number"
                                id="outlined-basic"
                                label="Percentage/CGPA"
                                variant="outlined"
                                onChange={(ev) =>
                                  setCGPA(Number(ev.target.value))
                                }
                                value={CGPA}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            >
                              {' '}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item md={4} xs={12} className="submit_btn">
                              <Button
                                className="profile_save_btn"
                                variant="contained"
                                type="submit"
                              >
                                Save
                              </Button>
                              <Button
                                className="cancel_btn"
                                variant="contained"
                                onClick={(e) => {
                                  navigate('/candidate/newprofileview');
                                }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Set Up Your Experience
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          onSubmit={handleSubmit(dataUpload)}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  I am
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                >
                                  <FormControlLabel
                                    defaultChecked
                                    value="fresher"
                                    control={<Radio />}
                                    label="Fresher"
                                    onClick={Fresher}
                                    onChange={(ev) => setExperience('Fresher')}
                                    checked={experience === 'Fresher'}
                                  />
                                  <FormControlLabel
                                    value="experienced"
                                    control={<Radio />}
                                    label="Experienced"
                                    onClick={Experienced}
                                    onChange={(ev) =>
                                      setExperience('Experience')
                                    }
                                    checked={experience === 'Experience'}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          {isFresher && (
                            <>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Project Name"
                                    variant="outlined"
                                    required
                                    onChange={(ev) =>
                                      setFresherProjectName(ev.target.value)
                                    }
                                    value={fresherProjectName}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Project Details"
                                    variant="outlined"
                                    required
                                    onChange={(ev) =>
                                      setFresherProjectDetails(ev.target.value)
                                    }
                                    value={fresherProjectDetails}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Skills/Tools"
                                    variant="outlined"
                                    required
                                    onChange={(ev) =>
                                      setFresherProjectTools(ev.target.value)
                                    }
                                    value={fresherProjectTools}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                            </>
                          )}
                          {isExperienced && (
                            <>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Years of Experinece"
                                    variant="outlined"
                                    required
                                    onChange={(ev) =>
                                      setYOE(Number(ev.target.value))
                                    }
                                    value={YOE}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Company Name"
                                    variant="outlined"
                                    required
                                    onChange={(ev) =>
                                      setCompanyName(ev.target.value)
                                    }
                                    value={companyName}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Role"
                                    variant="outlined"
                                    required
                                    onChange={(ev) => setRole(ev.target.value)}
                                    value={role}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                            </>
                          )}
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  Relocation
                                </FormLabel>
                                <RadioGroup
                                  required
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={relocation}
                                  onChange={(event) =>
                                    setRelocation(event.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  Work Preference
                                </FormLabel>
                                <RadioGroup
                                  required
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={work}
                                  onChange={(event) =>
                                    setWork(event.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value="Hybrid"
                                    control={<Radio />}
                                    label="Hybrid"
                                    checked={work === 'Hybrid'}
                                  />
                                  <FormControlLabel
                                    value="Office"
                                    control={<Radio />}
                                    label="Office"
                                    checked={work === 'Office'}
                                  />
                                  <FormControlLabel
                                    value="WorkFromHome"
                                    control={<Radio />}
                                    label="Work From Home"
                                    checked={work === 'WorkFromHome'}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Current Salary"
                                variant="outlined"
                                onChange={(ev) =>
                                  setCurrentSalary(ev.target.value)
                                }
                                value={currentSalary}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Expected Salary"
                                variant="outlined"
                                onChange={(ev) =>
                                  setExpectedSalary(ev.target.value)
                                }
                                value={expectedSalary}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item md={4} xs={12} className="submit_btn">
                              <Button
                                className="profile_save_btn"
                                variant="contained"
                                type="submit"
                              >
                                Save
                              </Button>
                              <Button
                                className="cancel_btn"
                                variant="contained"
                                onClick={(e) => {
                                  navigate('/candidate/newprofileview');
                                }}
                              >
                                Cancel
                              </Button>
                            </Grid>

                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Set Up Your Social Links
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          onSubmit={handleSubmit(dataUpload)}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="GitHub"
                                variant="outlined"
                                onChange={(ev) => setGithub(ev.target.value)}
                                value={github}
                                renderValue={(value) => `${value}`}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <GitHubIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="LinkedIn"
                                variant="outlined"
                                onChange={(ev) => setLinkedin(ev.target.value)}
                                value={linkedin}
                                renderValue={(value) => `${value}`}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LinkedInIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Youtube"
                                variant="outlined"
                                onChange={(ev) => setYoutube(ev.target.value)}
                                value={youtube}
                                renderValue={(value) => `${value}`}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <YouTubeIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item md={4} xs={12} className="submit_btn">
                              <Button
                                className="profile_save_btn"
                                variant="contained"
                                type="submit"
                              >
                                Save
                              </Button>
                              <Button
                                className="cancel_btn"
                                variant="contained"
                                onClick={(e) => {
                                  navigate('/candidate/newprofileview');
                                }}
                              >
                                Cancel
                              </Button>
                            </Grid>

                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={4}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Upload Your Resume
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          onSubmit={handleSubmit(dataUpload)}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid item xs={12} md={12}>
                              <FileUpload
                                title="click to select file"
                                required
                                error={errorResume}
                                accept=".doc,.docx,.pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
                                value={files}
                                onChange={(e) => {
                                  console.log('file', e[0]);
                                  const file = e;
                                  setFiles(file);
                                  resumeUpload(e, file[0]);
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item md={4} xs={12} className="submit_btn">
                              <Button
                                className="profile_save_btn"
                                variant="contained"
                                type="submit"
                              >
                                Save
                              </Button>
                              <Button
                                className="cancel_btn"
                                variant="contained"
                                onClick={(e) => {
                                  navigate('/candidate/newprofileview');
                                }}
                              >
                                Cancel
                              </Button>
                            </Grid>

                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={5}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt"> Change Password</div>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2} sx={{ pb: 2 }}>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              required
                              id="outlined-basic"
                              label="Current Password"
                              variant="outlined"
                              onChange={(e) => {
                                setCurrentPassword(e.target.value);
                              }}
                              type="password"
                              error={currentPasswordErr}
                              helperText={
                                currentPasswordErr &&
                                'Enter the current password'
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ pb: 2 }}>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="New Password"
                              variant="outlined"
                              type="password"
                              required
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                              }}
                              error={newPasswordErr}
                              helperText={
                                newPasswordErr && 'Enter the new password'
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ pb: 2 }}>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Confirm Password"
                              variant="outlined"
                              type="password"
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                              required
                              error={confirmPasswordErr}
                              helperText={
                                confirmPasswordErr &&
                                'Enter the confirm password ,same as new password'
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            md={4}
                            className="grid_item_mob_view"
                          ></Grid>
                          <Grid item md={4} xs={12} className="submit_btn">
                            <Button
                              className="profile_save_btn"
                              variant="contained"
                              type="submit"
                              onClick={(e) => handleChangePassword(e)}
                            >
                              Save
                            </Button>
                            <Button
                              className="cancel_btn"
                              variant="contained"
                              onClick={(e) => {
                                navigate('/candidate/newprofileview');
                              }}
                            >
                              Cancel
                            </Button>
                          </Grid>

                          <Grid
                            item
                            md={4}
                            className="grid_item_mob_view"
                          ></Grid>
                        </Grid>
                      </TabPanel>
                    </Grid>
                  </Grid>
                  <br />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </div>
      </Container>
    </div>
  );
}

export default NewEditProfile;
