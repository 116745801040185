import React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import ThumbsUpDownRoundedIcon from '@mui/icons-material/ThumbsUpDownRounded';
import './FeedbackCount.css';
import FeedbackService from '../Service/AWS_Auth/FeedbackService';
import { FeedbackStatus } from '../../models';

function FeedbackCount(props) {
  const timer = React.useRef();
  const [feedbackResponse, setFeedbackResponse] = useState([]);
  const [upCount, setUpCount] = useState(0);
  const [downCount, setDownCount] = useState(0);
  const [neutralCount, setNeutralCount] = useState(0);
  const fetchResponse = async (data) => {
    await FeedbackService.getFeedbackResponse(data.id).then((feedback) => {
      if (feedback.length != 0) {
        console.log(feedback);
        setFeedbackResponse(feedback);
        feedback.map((item) => {
          console.log('item', item.FeedbackStatus);
          if (item.FeedbackStatus === FeedbackStatus.UP) {
            console.log('up', item.FeedbackStatus);
            setUpCount(upCount + 1);
          }
          if (item.FeedbackStatus === FeedbackStatus.DOWN) {
            setDownCount(downCount + 1);
          }
          if (item.FeedbackStatus === FeedbackStatus.NEUTRAL) {
            setNeutralCount(neutralCount + 1);
          }
        });
      }
    });
  };
  React.useEffect(async () => {
    console.log(props.data);
    await fetchResponse(props.data);
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > *': {
            m: 1,
          },
        }}
      >
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button>
            <ThumbUpRoundedIcon />
            <span className="feedback_value">({upCount})</span>
          </Button>
          <Button>
            <ThumbsUpDownRoundedIcon />
            <span className="feedback_value">({neutralCount})</span>
          </Button>
          <Button>
            <ThumbDownRoundedIcon />
            <span className="feedback_value">({downCount})</span>
          </Button>
        </ButtonGroup>
      </Box>
    </>
  );
}

export default FeedbackCount;
