import { useReactMediaRecorder } from 'react-media-recorder';
import VideoRecorder from 'react-video-recorder';
import {
  RecordWebcam,
  useRecordWebcam,
  CAMERA_STATUS,
} from 'react-record-webcam';
// const OPTIONS = {
//   filename: 'test-filename',
//   fileType: 'mp4',
//   width: 1920,
//   height: 1080,
// };
// const RecordView = () => {
//   const { status, startRecording, stopRecording, mediaBlobUrl } =
//     useReactMediaRecorder({
//       video: true,
//       facingMode: { exact: 'environment' },
//     });

//   return (
//     <div>
//       <p>{status}</p>
//       <button onClick={startRecording}>Start Recording</button>
//       <button onClick={stopRecording}>Stop Recording</button>
//       <video src={mediaBlobUrl} controls autoPlay loop />
//     </div>
//   );
// };

export default function Test() {
  // const recordWebcam = useRecordWebcam(OPTIONS);
  // const getRecordingFileHooks = async () => {
  //   const blob = await recordWebcam.getRecording();
  //   console.log({ blob });
  // };

  // const getRecordingFileRenderProp = async (blob) => {
  //   console.log({ blob });
  // };
  return (
    <div>
      <h1>Record your video</h1>
      <VideoRecorder
        onRecordingComplete={(videoBlob) => {
          // Do something with the video...
          console.log('videoBlob', videoBlob);
        }}
      />

      {/* <video
        ref={recordWebcam.webcamRef}
        style={{
          display: `${
            recordWebcam.status === CAMERA_STATUS.OPEN ||
            recordWebcam.status === CAMERA_STATUS.RECORDING
              ? 'block'
              : 'none'
          }`,
        }}
        autoPlay
        muted
      /> */}
      {/* <video
        ref={recordWebcam.previewRef}
        style={{
          display: `${
            recordWebcam.status === CAMERA_STATUS.PREVIEW ? 'block' : 'none'
          }`,
        }}
        controls
      /> */}
    </div>
  );
}
