import React from 'react'
import Navbar from './Navbar'


function TermsandCondition() {
  return (
    <>
    <Navbar />
    <hr></hr>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n"
    }}
  />
  <div data-custom-class="body">
    <div style={{ textAlign: "left", lineHeight: 1, margin: "2rem" }} align="center">
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div
          className="MsoNormal"
          style={{ textAlign: "center", lineHeight: "150%" }}
          align="center"
        >
          <a name="_2cipo4yr3w5d" />
          <div
            className="MsoNormal"
            style={{ lineHeight: "22.5px" }}
            align="center"
          >
            <div
              className="MsoNormal"
              style={{ lineHeight: "150%" }}
              align="center"
            >
              <a name="_gm5sejt4p02f" />
              <div
                className="MsoNormal"
                data-custom-class="title"
                style={{ textAlign: "left", lineHeight: "1.5" }}
                align="center"
              >
                <strong>
                  <span style={{ lineHeight: "22.5px", fontSize: 26 }}>
                    <bdt className="block-component" />
                    <bdt className="question">TERMS AND CONDITIONS</bdt>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </strong>
              </div>
              <div
                className="MsoNormal"
                style={{ lineHeight: "22.5px", textAlign: "left" }}
                align="center"
              >
                <a name="_7m5b3xg56u7y" />
              </div>
              <div
                className="MsoNormal"
                data-custom-class="subtitle"
                style={{ textAlign: "left", lineHeight: "22.5px" }}
                align="center"
              >
                <br />
              </div>
              <div
                className="MsoNormal"
                data-custom-class="subtitle"
                style={{ textAlign: "left", lineHeight: "1.5" }}
                align="center"
              >
                <span
                  style={{
                    color: "rgb(89, 89, 89)",
                    fontSize: "14.6667px",
                    textAlign: "justify"
                  }}
                >
                  <strong>
                    Last updated{" "}
                    <bdt
                      className="block-container question question-in-editor"
                      data-id="e2088df5-25ea-aec9-83d4-6284f5a7e043"
                      data-type="question"
                    >
                      January 24, 2023
                    </bdt>
                  </strong>
                </span>
              </div>
            </div>
          </div>
          <div
            className="MsoNormal"
            style={{ lineHeight: "17.25px", textAlign: "left" }}
            align="center"
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ lineHeight: "17.25px", textAlign: "left" }}
            align="center"
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ lineHeight: "17.25px", textAlign: "left" }}
            align="center"
          >
            <br />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                  </strong>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#agreement">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  1. AGREEMENT TO TERMS
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#ip">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  2. INTELLECTUAL PROPERTY RIGHTS
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#userreps">
                  3. USER REPRESENTATIONS
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#userreg">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="user_account_option"
                      data-type="statement"
                    >
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                  </bdt>
                  4. USER REGISTRATION
                </span>
              </a>
            </span>
            <bdt className="statement-end-if-in-editor" data-type="close">
              <span style={{ fontSize: 15 }} />
            </bdt>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#prohibited">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  5. PROHIBITED ACTIVITIES
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#ugc">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  6. USER GENERATED CONTRIBUTIONS
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#license">
                  7. CONTRIBUTION LICENSE
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#reviews">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="a378120a-96b1-6fa3-279f-63d5b96341d3"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="review_option"
                        data-type="statement"
                      >
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </bdt>
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#mobile">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="mobile_app_option"
                        data-type="statement"
                      >
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </bdt>
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ textAlign: "left" }} align="center">
            <span style={{ fontSize: 15 }}>
              <bdt className="block-component" />
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#socialmedia">
                  8. SOCIAL MEDIA
                </a>
                <a data-custom-class="link" href="#agreement">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    >
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                  </span>
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#submissions">
                  9. SUBMISSIONS
                </a>
              </span>
            </span>
          </div>
          <div style={{ textAlign: "left" }} align="center">
            <span style={{ fontSize: 15 }}>
              <bdt className="block-component" />
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#advertisers">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="14038561-dad7-be9d-370f-f8aa487b2570"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="advertiser_option"
                        data-type="statement"
                      >
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </bdt>
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#sitemanage">
                  10. SITE MANAGEMENT
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#privacypolicy1">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="bdd90fa9-e664-7d0b-c352-2b8e77dd3bb4"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="privacy_policy_option"
                        data-type="statement"
                      >
                        <span style={{ fontSize: 15 }}>
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="87a7471d-cf82-1032-fdf8-601d37d7b017"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="privacy_policy_followup"
                                data-type="statement"
                                style={{ fontSize: "14.6667px" }}
                              >
                                <span style={{ fontSize: 15 }} />
                              </bdt>
                            </bdt>
                          </bdt>
                        </span>
                      </bdt>
                    </bdt>
                  </bdt>
                </span>
              </a>
              <a data-custom-class="link" href="#privacypolicy2">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  11. PRIVACY POLICY
                </span>
              </a>
              <a data-custom-class="link" href="#advertisers" />
              <a data-custom-class="link" href="#agreement">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" data-type="close">
                    <span style={{ fontSize: 15 }} />
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#dmca">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component">
                    <bdt className="block-component" />
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#copyright1">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component">
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="statement-end-if-in-editor" />
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <a data-custom-class="link" href="#copyright1">
              <span style={{ fontSize: 15 }} />
            </a>
            <a data-custom-class="link" href="#terms" />
            <a data-custom-class="link" href="#terms">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                12. TERM AND TERMINATION
              </span>
            </a>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#modifications">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  13. MODIFICATIONS AND INTERRUPTIONS
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#law">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  14. GOVERNING LAW
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#disputes">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  15. DISPUTE RESOLUTION
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#corrections">
                  16. CORRECTIONS
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#disclaimer">
                <span style={{ color: "rgb(89, 89, 89)" }}>17. DISCLAIMER</span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#liability">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  18. LIMITATIONS OF LIABILITY
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#indemnification">
                  19. INDEMNIFICATION
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#userdata">
                <span style={{ color: "rgb(89, 89, 89)" }}>20. USER DATA</span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#electronic">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                </span>
              </a>
            </span>
          </div>
          <div
            className="MsoNormal"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt className="block-component" />
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#misc">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  22. MISCELLANEOUS
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#contact">
                  23. CONTACT US
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <br />
          </div>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="agreement"
          style={{ lineHeight: "115%" }}
        >
          <a name="_a7mwfgcrtsqn" />
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              1. AGREEMENT TO TERMS
            </span>
          </strong>
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: 1 }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              msoFareastFontFamily: "Calibri",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity ("you")
            and{" "}
            <bdt
              className="block-container question question-in-editor"
              data-id="4ab94aa9-19d1-61e0-711e-42c7d186232b"
              data-type="question"
            >
              InterviewTape
            </bdt>
            <bdt className="block-component" /> ("
            <bdt className="block-component" />
            <strong>Company</strong>
            <bdt className="statement-end-if-in-editor" />
            ," "<strong>we</strong>," "<strong>us</strong>," or "
            <strong>our</strong>"), concerning your access to and use of the{" "}
            <bdt
              className="block-container question question-in-editor"
              data-id="92c3b320-1d8b-c74c-db68-d12810736807"
              data-type="question"
            >
              <a
                href="https://www.interviewtape.com/"
                target="_blank"
                data-custom-class="link"
              >
                https://www.interviewtape.com/
              </a>
            </bdt>{" "}
            website as well as any other media form, media channel, mobile
            website or mobile application related, linked, or otherwise
            connected thereto (collectively, the "Site").
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  msoFareastFontFamily: "Calibri",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166
                }}
              >
                <bdt className="question">
                  <bdt className="block-component" />
                </bdt>
              </span>
              <bdt className="block-component" />
            </span>
          </span>{" "}
          You agree that by accessing the Site, you have read, understood, and
          agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
          WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM
          USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: 1 }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              msoFareastFontFamily: "Calibri",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            Supplemental terms and conditions or documents that may be posted on
            the Site from time to time are hereby expressly incorporated herein
            by reference. We reserve the right, in our sole discretion, to make
            changes or modifications to these Terms of Use{" "}
            <bdt className="block-component" />
            at any time and for any reason
            <bdt className="statement-end-if-in-editor" />. We will alert you
            about any changes by updating the "Last updated" date of these Terms
            of Use, and you waive any right to receive specific notice of each
            such change. Please ensure that you check the applicable Terms every
            time you use our Site so that you understand which Terms apply. You
            will be subject to, and will be deemed to have been made aware of
            and to have accepted, the changes in any revised Terms of Use by
            your continued use of the Site after the date such revised Terms of
            Use are posted.
          </span>
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: 1 }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              msoFareastFontFamily: "Calibri",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            The information provided on the Site is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Site from other locations do so on
            their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              msoFareastFontFamily: "Calibri",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt className="block-component" />
            </span>
          </span>
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: 1 }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <bdt data-type="conditional-block">
            <bdt
              className="block-component"
              data-record-question-key="user_o18_option"
              data-type="statement"
            >
              <span style={{ fontSize: 15 }} />
            </bdt>
            <bdt
              className="block-container if"
              data-type="if"
              id="a2595956-7028-dbe5-123e-d3d3a93ed076"
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <span
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  >
                    All users who are minors in the jurisdiction in which they
                    reside (generally under the age of 18) must have the
                    permission of, and be directly supervised by, their parent
                    or guardian to use the Site. If you are a minor, you must
                    have your parent or guardian read and agree to these Terms
                    of Use prior to you using the Site.
                  </span>
                </bdt>
              </bdt>
            </bdt>
          </bdt>
          <bdt className="statement-end-if-in-editor" data-type="close">
            <span style={{ fontSize: 15 }} />
          </bdt>
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "1.5" }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="ip"
          style={{ lineHeight: "1.5" }}
        >
          <a name="_4rd71iod99ud" />
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    fontSize: 19
                  }}
                >
                  2.&nbsp;
                </span>
              </strong>
              INTELLECTUAL PROPERTY RIGHTS
            </span>
          </strong>
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: 1 }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              msoFareastFontFamily: "Calibri",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            Unless otherwise indicated, the Site is our proprietary property and
            all source code, databases, functionality, software, website
            designs, audio, video, text, photographs, and graphics on the Site
            (collectively, the "Content") and the trademarks, service marks, and
            logos contained therein (the "Marks") are owned or controlled by us
            or licensed to us, and are protected by copyright and trademark laws
            and various other intellectual property rights and unfair
            competition laws of the United States, international copyright laws,
            and international conventions. The Content and the Marks are
            provided on the Site "AS IS" for your information and personal use
            only. Except as expressly provided in these Terms of Use, no part of
            the Site and no Content or Marks may be copied, reproduced,
            aggregated, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted, distributed, sold, licensed, or
            otherwise exploited for any commercial purpose whatsoever, without
            our express prior written permission.
          </span>
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: 1 }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              msoFareastFontFamily: "Calibri",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            Provided that you are eligible to use the Site, you are granted a
            limited license to access and use the Site and to download or print
            a copy of any portion of the Content to which you have properly
            gained access solely for your personal, non-commercial use. We
            reserve all rights not expressly granted to you in and to the Site,
            the Content and the Marks.
          </span>
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "1.5" }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="userreps"
          style={{ lineHeight: "1.5" }}
        >
          <a name="_vhkegautf00d" />
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    fontSize: 19
                  }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      3.
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              USER REPRESENTATIONS
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div
          className="MsoNormal"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            lineHeight: "115%"
          }}
        >
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              By using the Site, you represent and warrant that:
            </span>
            <bdt
              className="block-container if"
              data-type="if"
              id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_account_option"
                  data-type="statement"
                />
                <bdt data-type="body">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                    &nbsp;(
                  </span>
                  <span
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  >
                    1
                  </span>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                    ) all registration information you submit will be true,
                    accurate, current, and complete; (
                  </span>
                  <span
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  >
                    2
                  </span>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                    ) you will maintain the accuracy of such information and
                    promptly update such registration information as necessary
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
                    >
                      <bdt data-type="conditional-block">
                        <bdt data-type="body">
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "11pt"
                            }}
                          >
                            ;
                          </span>
                        </bdt>
                      </bdt>
                      <bdt
                        className="statement-end-if-in-editor"
                        data-type="close"
                      />
                      &nbsp;
                    </bdt>
                    <span
                      style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                    >
                      (
                    </span>
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "14.6667px"
                      }}
                    >
                      3
                    </span>
                    <span
                      style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                    >
                      ) you have the legal capacity and you agree to comply with
                      these Terms of Use;
                    </span>
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="user_u13_option"
                          data-type="statement"
                        />
                      </bdt>
                    </bdt>
                  </span>
                </bdt>
              </bdt>
            </bdt>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
              &nbsp;(
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
              4
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
              ) you are not a minor in the jurisdiction in which you reside
              <bdt
                className="block-container if"
                data-type="if"
                id="76948fab-ec9e-266a-bb91-948929c050c9"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_o18_option"
                    data-type="statement"
                  />
                  <bdt data-type="body">
                    , or if a minor, you have received parental permission to
                    use the Site
                  </bdt>
                </bdt>
                <bdt className="statement-end-if-in-editor" data-type="close" />
              </bdt>
              ; (
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
              5
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
              ) you will not access the Site through automated or non-human
              means, whether through a bot, script, or otherwise; (
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
              6
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
              ) you will not use the Site for any illegal or unauthorized
              purpose; and (
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
              7
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
              ) your use of the Site will not violate any applicable law or
              regulation.
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }} />
          </div>
        </div>
        <div
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: 1 }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            lineHeight: "115%"
          }}
        >
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Site (or any portion thereof).
            </span>
          </div>
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "1.5" }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <a name="_esuoutkhaf53" />{" "}
          <bdt data-type="conditional-block">
            <bdt
              className="block-component"
              data-record-question-key="user_account_option"
              data-type="statement"
            >
              <span style={{ fontSize: 15 }} />
            </bdt>
            <bdt data-type="body">
              <div
                className="MsoNormal"
                data-custom-class="heading_1"
                id="userreg"
                style={{ lineHeight: "17.25px" }}
              >
                <strong>
                  <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19
                            }}
                          >
                            4.
                          </span>
                        </strong>
                      </span>
                      &nbsp;
                    </strong>
                    USER REGISTRATION
                  </span>
                </strong>
              </div>
            </bdt>
          </bdt>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <bdt data-type="conditional-block">
            &nbsp;{" "}
            <bdt data-type="body">
              &nbsp;&nbsp;
              <div
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)"
                  }}
                >
                  You may be required to register with the Site. You agree to
                  keep your password confidential and will be responsible for
                  all use of your account and password. We reserve the right to
                  remove, reclaim, or change a username you select if we
                  determine, in our sole discretion, that such username is
                  inappropriate, obscene, or otherwise objectionable.
                </span>
              </div>
            </bdt>
          </bdt>
        </div>
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "1.5" }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "1.5" }}
        align="center"
      >
        <br />
      </div>
      <div
        className="MsoNormal"
        style={{ textAlign: "left", lineHeight: "150%" }}
        align="center"
      >
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt className="statement-end-if-in-editor" data-type="close">
            <span style={{ fontSize: 15 }} />
          </bdt>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
          <a name="_1voziltdxegg" />
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="prohibited"
            style={{ lineHeight: "17.25px" }}
          >
            <strong>
              <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        5.
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                PROHIBITED ACTIVITIES
              </span>
            </strong>
          </div>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              You may not access or use the Site for any purpose other than that
              for which we make the Site available. The Site may not be used in
              connection with any commercial endeavors except those that are
              specifically endorsed or approved by us.
            </span>
          </div>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "17.25px" }}
          >
            <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
              <div
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)"
                  }}
                >
                  As a user of the Site, you agree not to:
                </span>
              </div>
            </div>
            <ul>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)"
                  }}
                >
                  <span
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 15,
                      fontStyle: "normal",
                      fontVariantLigatures: "normal",
                      fontVariantCaps: "normal",
                      fontWeight: 400,
                      letterSpacing: "normal",
                      orphans: 2,
                      textAlign: "justify",
                      textIndent: "-29.4px",
                      textTransform: "none",
                      whiteSpace: "normal",
                      widows: 2,
                      wordSpacing: 0,
                      WebkitTextStrokeWidth: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                      textDecorationStyle: "initial",
                      textDecorationColor: "initial",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    Systematically retrieve data or other content from the Site
                    to create or compile, directly or indirectly, a collection,
                    compilation, database, or directory without written
                    permission from us.
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Trick, defraud, or mislead us and other users,
                          especially in any attempt to learn sensitive account
                          information such as user passwords.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Circumvent, disable, or otherwise interfere with
                          security-related features of the Site, including
                          features that prevent or restrict the use or copying
                          of any Content or enforce limitations on the use of
                          the Site and/or the Content contained therein.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Disparage, tarnish, or otherwise harm, in our opinion,
                          us and/or the Site.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Use any information obtained from the Site in order to
                          harass, abuse, or harm another person.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Make improper use of our support services or submit
                          false reports of abuse or misconduct.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Use the Site in a manner inconsistent with any
                          applicable laws or regulations.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Engage in unauthorized framing of or linking to the
                          Site.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Upload or transmit (or attempt to upload or to
                          transmit) viruses, Trojan horses, or other material,
                          including excessive use of capital letters and
                          spamming (continuous posting of repetitive text), that
                          interferes with any party's uninterrupted use and
                          enjoyment of the Site or modifies, impairs, disrupts,
                          alters, or interferes with the use, features,
                          functions, operation, or maintenance of the Site.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Engage in any automated use of the system, such as
                          using scripts to send comments or messages, or using
                          any data mining, robots, or similar data gathering and
                          extraction tools.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Delete the copyright or other proprietary rights
                          notice from any Content.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Attempt to impersonate another user or person or use
                          the username of another user.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Upload or transmit (or attempt to upload or to
                          transmit) any material that acts as a passive or
                          active information collection or transmission
                          mechanism, including without limitation, clear
                          graphics interchange formats ("gifs"), 1×1 pixels, web
                          bugs, cookies, or other similar devices (sometimes
                          referred to as "spyware" or "passive collection
                          mechanisms" or "pcms").
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Interfere with, disrupt, or create an undue burden on
                          the Site or the networks or services connected to the
                          Site.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Harass, annoy, intimidate, or threaten any of our
                          employees or agents engaged in providing any portion
                          of the Site to you.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Attempt to bypass any measures of the Site designed to
                          prevent or restrict access to the Site, or any portion
                          of the Site.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Copy or adapt the Site's software, including but not
                          limited to Flash, PHP, HTML, JavaScript, or other
                          code.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Except as permitted by applicable law, decipher,
                          decompile, disassemble, or reverse engineer any of the
                          software comprising or in any way making up a part of
                          the Site.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Except as may be the result of standard search engine
                          or Internet browser usage, use, launch, develop, or
                          distribute any automated system, including without
                          limitation, any spider, robot, cheat utility, scraper,
                          or offline reader that accesses the Site, or using or
                          launching any unauthorized script or other software.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Use a buying agent or purchasing agent to make
                          purchases on the Site.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Make any unauthorized use of the Site, including
                          collecting usernames and/or email addresses of users
                          by electronic or other means for the purpose of
                          sending unsolicited email, or creating user accounts
                          by automated means or under false pretenses.
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
              <li
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span style={{ fontSize: 15 }}>
                  <span
                    style={{
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontWeight: 400,
                        letterSpacing: "normal",
                        orphans: 2,
                        textAlign: "justify",
                        textIndent: "-29.4px",
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: 2,
                        wordSpacing: 0,
                        WebkitTextStrokeWidth: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        textDecorationStyle: "initial",
                        textDecorationColor: "initial",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          Use the Site as part of any effort to compete with us
                          or otherwise use the Site and/or the Content for any
                          revenue-generating endeavor or commercial enterprise.
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="forloop-component" />
                </span>
              </li>
            </ul>
            <div
              className="MsoNormal"
              style={{ textAlign: "left", lineHeight: "1.5" }}
            >
              <a name="_zbbv9tgty199" />
            </div>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="ugc"
                  style={{ lineHeight: "17.25px", textAlign: "left" }}
                >
                  <strong>
                    <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: 19
                              }}
                            >
                              6.
                            </span>
                          </strong>
                        </span>
                        &nbsp;
                      </strong>
                      USER GENERATED CONTRIBUTIONS
                    </span>
                  </strong>
                </div>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <bdt className="block-component" />
                    The Site may invite you to chat, contribute to, or
                    participate in blogs, message boards, online forums, and
                    other functionality, and may provide you with the
                    opportunity to create, submit, post, display, transmit,
                    perform, publish, distribute, or broadcast content and
                    materials to us or on the Site, including but not limited to
                    text, writings, video, audio, photographs, graphics,
                    comments, suggestions, or personal information or other
                    material (collectively, "Contributions"). Contributions may
                    be viewable by other users of the Site and through
                    third-party websites. As such, any Contributions you
                    transmit may be treated as non-confidential and
                    non-proprietary. When you create or make available any
                    Contributions, you thereby represent and warrant that:
                    <bdt className="else-block" />
                  </span>
                </div>
              </bdt>
            </bdt>
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <ul>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      The creation,{" "}
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        distribution, transmission, public display, or
                        performance, and the accessing, downloading, or copying
                        of your Contributions do not and will not infringe the
                        proprietary rights, including but not limited to the
                        copyright, patent, trademark, trade secret, or moral
                        rights of any third party.
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                        You are the creator and owner of or have the necessary
                        licenses, rights, consents, releases, and permissions to
                        use and to authorize us, the Site, and other users of
                        the Site to use your Contributions in any manner
                        contemplated by the Site and these Terms of Use.
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      You have the written consent, release, and/or permission
                      of each and every identifiable individual person in your
                      Contributions to use the name or likeness of each and
                      every such identifiable individual person to enable
                      inclusion and use of your Contributions in any manner
                      contemplated by the Site and these Terms of Use.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      Your Contributions are not false, inaccurate, or
                      misleading.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      Your Contributions are not unsolicited or unauthorized
                      advertising, promotional materials, pyramid schemes, chain
                      letters, spam, mass mailings, or other forms of
                      solicitation.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      Your Contributions are not obscene, lewd, lascivious,
                      filthy, violent, harassing, libelous, slanderous, or
                      otherwise objectionable (as determined by us).
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      Your Contributions do not ridicule, mock, disparage,
                      intimidate, or abuse anyone.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      Your Contributions are not used to harass or threaten (in
                      the legal sense of those terms) any other person and to
                      promote violence against a specific person or class of
                      people.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      Your Contributions do not violate any applicable law,
                      regulation, or rule.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      Your Contributions do not violate the privacy or publicity
                      rights of any third party.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      Your Contributions do not violate any applicable law
                      concerning child pornography, or otherwise intended to
                      protect the health or well-being of minors.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      Your Contributions do not include any offensive comments
                      that are connected to race, national origin, gender,
                      sexual preference, or physical handicap.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 14, color: "rgb(89, 89, 89)" }}>
                      Your Contributions do not otherwise violate, or link to
                      material that violates, any provision of these Terms of
                      Use, or any applicable law or regulation.
                    </span>
                  </li>
                </ul>
              </bdt>
            </bdt>
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    Any use of the Site in violation of the foregoing violates
                    these Terms of Use and may result in, among other things,
                    termination or suspension of your rights to use the Site.
                  </span>
                </div>
              </bdt>
            </bdt>
          </div>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="license"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <strong>
                    <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: 19
                              }}
                            >
                              7.
                            </span>
                          </strong>
                        </span>
                        &nbsp;
                      </strong>
                      CONTRIBUTION LICENSE
                    </span>
                  </strong>
                </div>
              </bdt>
            </bdt>
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  style={{ lineHeight: "17.25px", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <bdt className="block-component" />
                  </span>
                </div>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    By posting your Contributions to any part of the Site
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="19652acc-9a2a-5ffe-6189-9474402fa6cc"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="socialnetwork_link_option"
                          data-type="statement"
                        />
                        <bdt data-type="body">
                          &nbsp;or making Contributions accessible to the Site
                          by linking your account from the Site to any of your
                          social networking accounts
                        </bdt>
                      </bdt>
                      <bdt
                        className="statement-end-if-in-editor"
                        data-type="close"
                      />
                    </bdt>
                    , you automatically grant, and you represent and warrant
                    that you have the right to grant, to us an unrestricted,
                    unlimited, irrevocable, perpetual, non-exclusive,
                    transferable, royalty-free, fully-paid, worldwide right, and
                    license to host, use, copy, reproduce, disclose, sell,
                    resell, publish, broadcast, retitle, archive, store, cache,
                    publicly perform, publicly display, reformat, translate,
                    transmit, excerpt (in whole or in part), and distribute such
                    Contributions (including, without limitation, your image and
                    voice) for any purpose, commercial, advertising, or
                    otherwise, and to prepare derivative works of, or
                    incorporate into other works, such Contributions, and grant
                    and authorize sublicenses of the foregoing. The use and
                    distribution may occur in any media formats and through any
                    media channels.
                  </span>
                </div>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    This license will apply to any form, media, or technology
                    now known or hereafter developed, and includes our use of
                    your name, company name, and franchise name, as applicable,
                    and any of the trademarks, service marks, trade names,
                    logos, and personal and commercial images you provide. You
                    waive all moral rights in your Contributions, and you
                    warrant that moral rights have not otherwise been asserted
                    in your Contributions.
                  </span>
                </div>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    We do not assert any ownership over your Contributions. You
                    retain full ownership of all of your Contributions and any
                    intellectual property rights or other proprietary rights
                    associated with your Contributions. We are not liable for
                    any statements or representations in your Contributions
                    provided by you in any area on the Site. You are solely
                    responsible for your Contributions to the Site and you
                    expressly agree to exonerate us from any and all
                    responsibility and to refrain from any legal action against
                    us regarding your Contributions.
                  </span>
                </div>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              We have the right, in our sole and absolute discretion, (1) to
              edit, redact, or otherwise change any Contributions; (2) to
              re-categorize any Contributions to place them in more appropriate
              locations on the Site; and (3) to pre-screen or delete any
              Contributions at any time and for any reason, without notice. We
              have no obligation to monitor your Contributions.
              <bdt className="else-block" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <bdt className="block-container if" data-type="if">
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="review_option"
                  data-type="statement"
                >
                  <span style={{ fontSize: 15 }} />
                </bdt>
              </bdt>
            </bdt>
          </div>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
          <span style={{ fontSize: 15 }}>
            <a name="_6nl7u6ag6use" />
          </span>
        </div>
        <bdt
          className="block-container if"
          data-type="if"
          id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
        >
          <bdt data-type="conditional-block">
            <bdt
              className="block-component"
              data-record-question-key="mobile_app_option"
              data-type="statement"
            >
              <span style={{ fontSize: 15 }} />
            </bdt>
          </bdt>
        </bdt>
      </div>
      <div align="center">
        <span style={{ fontSize: 15 }}>
          <bdt className="block-component" />
        </span>
      </div>
      <div
        className="MsoNormal"
        data-custom-class="heading_1"
        id="socialmedia"
        style={{ lineHeight: "115%" }}
      >
        <strong>
          <span
            style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
          >
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    8.
                  </span>
                </strong>
              </span>
              &nbsp;
            </strong>
            SOCIAL MEDIA
          </span>
        </strong>
      </div>
      <div style={{ lineHeight: 1 }}>
        <br />
      </div>
      <div
        className="MsoNormal"
        data-custom-class="body_text"
        style={{ lineHeight: "1.5" }}
      >
        <span
          style={{
            fontSize: "11.0pt",
            lineHeight: "115%",
            fontFamily: "Arial",
            color: "#595959",
            msoThemecolor: "text1",
            msoThemetint: 166
          }}
        >
          As part of the functionality of the Site, you may link your account
          with online accounts you have with third-party service providers (each
          such account, a "Third-Party Account") by either: (1) providing your
          Third-Party Account login information through the Site; or (2)
          allowing us to access your Third-Party Account, as is permitted under
          the applicable terms and conditions that govern your use of each
          Third-Party Account. You represent and warrant that you are entitled
          to disclose your Third-Party Account login information to us and/or
          grant us access to your Third-Party Account, without breach by you of
          any of the terms and conditions that govern your use of the applicable
          Third-Party Account, and without obligating us to pay any fees or
          making us subject to any usage limitations imposed by the third-party
          service provider of the Third-Party Account. By granting us access to
          any Third-Party Accounts, you understand that (1) we may access, make
          available, and store (if applicable) any content that you have
          provided to and stored in your Third-Party Account (the "Social
          Network Content") so that it is available on and through the Site via
          your account, including without limitation any friend lists and (2) we
          may submit to and receive from your Third-Party Account additional
          information to the extent you are notified when you link your account
          with the Third-Party Account. Depending on the Third-Party Accounts
          you choose and subject to the privacy settings that you have set in
          such Third-Party Accounts, personally identifiable information that
          you post to your Third-Party Accounts may be available on and through
          your account on the Site. Please note that if a Third-Party Account or
          associated service becomes unavailable or our access to such Third
          Party Account is terminated by the third-party service provider, then
          Social Network Content may no longer be available on and through the
          Site. You will have the ability to disable the connection between your
          account on the Site and your Third-Party Accounts at any time. PLEASE
          NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS
          ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
          AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We make no
          effort to review any Social Network Content for any purpose, including
          but not limited to, for accuracy, legality, or non-infringement, and
          we are not responsible for any Social Network Content. You acknowledge
          and agree that we may access your email address book associated with a
          Third-Party Account and your contacts list stored on your mobile
          device or tablet computer solely for purposes of identifying and
          informing you of those contacts who have also registered to use the
          Site. You can deactivate the connection between the Site and your
          Third-Party Account by contacting us using the contact information
          below or through your account settings (if applicable). We will
          attempt to delete any information stored on our servers that was
          obtained through such Third-Party Account, except the username and
          profile picture that become associated with your account.
        </span>
      </div>
      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }} align="center">
        <span style={{ fontSize: 15 }}>
          <bdt className="statement-end-if-in-editor" />
        </span>
      </div>
      <div
        className="MsoNormal"
        data-custom-class="heading_1"
        id="submissions"
        style={{ lineHeight: "115%" }}
      >
        <strong>
          <span
            style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
          >
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    9.
                  </span>
                </strong>
              </span>
              &nbsp;
            </strong>
            SUBMISSIONS
          </span>
        </strong>
      </div>
      <div style={{ lineHeight: 1 }}>
        <br />
      </div>
      <div
        className="MsoNormal"
        data-custom-class="body_text"
        style={{ lineHeight: "1.5" }}
      >
        <span
          style={{
            fontSize: "11.0pt",
            lineHeight: "115%",
            fontFamily: "Arial",
            color: "#595959",
            msoThemecolor: "text1",
            msoThemetint: 166
          }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information regarding the Site
            ("Submissions") provided by you to us are non-confidential and shall
            become our sole property. We shall own exclusive rights, including
            all intellectual property rights, and shall be entitled to the
            unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are
            original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any
            alleged or actual infringement or misappropriation of any
            proprietary right in your Submissions.
          </span>
        </span>
      </div>
      <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div align="center">
        <span style={{ fontSize: 15 }}>
          <bdt className="block-component" />
        </span>
      </div>
      <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
          <span style={{ fontSize: 15 }}>
            <a name="_29ce8o9pbtmi" />
          </span>
        </div>
        <bdt
          className="block-container if"
          data-type="if"
          id="14038561-dad7-be9d-370f-f8aa487b2570"
        >
          <bdt data-type="conditional-block">
            <bdt
              className="block-component"
              data-record-question-key="advertiser_option"
              data-type="statement"
            >
              <span style={{ fontSize: 15 }} />
            </bdt>
          </bdt>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="sitemanage"
            style={{ lineHeight: "115%" }}
          >
            <a name="_wj13r09u8u3u" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        10.
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                SITE MANAGEMENT
              </span>
            </strong>
          </div>
        </bdt>
      </div>
      <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
        <br />
      </div>
      <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            We reserve the right, but not the obligation, to: (1) monitor the
            Site for violations of these Terms of Use; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Terms of Use, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Site or otherwise disable all files and content that
            are excessive in size or are in any way burdensome to our systems;
            and (5) otherwise manage the Site in a manner designed to protect
            our rights and property and to facilitate the proper functioning of
            the Site.
          </span>
        </div>
      </div>
      <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
        <br />
      </div>
      <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <a name="_jugvcvcw0oj9" />
        </div>
        <bdt
          className="block-container if"
          data-type="if"
          id="bdd90fa9-e664-7d0b-c352-2b8e77dd3bb4"
        >
          <bdt data-type="conditional-block">
            <bdt
              className="block-component"
              data-record-question-key="privacy_policy_option"
              data-type="statement"
            >
              <span style={{ fontSize: 15 }} />
            </bdt>
          </bdt>
          <div style={{ textAlign: "justify", lineHeight: "1.5" }}>
            <bdt
              className="block-container if"
              data-type="if"
              id="87a7471d-cf82-1032-fdf8-601d37d7b017"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="privacy_policy_followup"
                  data-type="statement"
                  style={{ fontSize: "14.6667px" }}
                >
                  <span style={{ fontSize: 15 }} />
                </bdt>{" "}
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="privacypolicy2"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "24.5333px",
                          color: "black",
                          backgroundImage: "initial",
                          backgroundPosition: "initial",
                          backgroundSize: "initial",
                          backgroundRepeat: "initial",
                          backgroundAttachment: "initial",
                          backgroundOrigin: "initial",
                          backgroundClip: "initial",
                          fontSize: 19
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19
                            }}
                          >
                            <strong>
                              <span
                                style={{
                                  lineHeight: "115%",
                                  fontFamily: "Arial",
                                  fontSize: 19
                                }}
                              >
                                11.
                              </span>
                            </strong>
                          </span>
                          &nbsp;
                        </strong>
                        PRIVACY POLICY
                      </span>
                    </strong>
                  </div>
                  <div
                    className="MsoNormal"
                    style={{
                      fontSize: "14.6667px",
                      textAlign: "left",
                      lineHeight: 1
                    }}
                  >
                    <br />
                  </div>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{
                      fontSize: "14.6667px",
                      textAlign: "left",
                      lineHeight: "1.5"
                    }}
                  >
                    <span
                      style={{
                        fontSize: 15,
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      We care about data privacy and security.&nbsp;
                    </span>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        By using the Site, you agree to be bound by our Privacy
                        Policy posted on the Site, which is incorporated into
                        these Terms of Use. Please be advised the Site is hosted
                        in{" "}
                        <span
                          style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <bdt className="block-component" />
                            <bdt className="question">India</bdt>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                        . If you access the Site from any other region of the
                        world with laws or other requirements governing personal
                        data collection, use, or disclosure that differ from
                        applicable laws in{" "}
                        <span
                          style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <bdt className="block-component" />
                            <bdt className="question">India</bdt>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                        , then through your continued use of the Site, you are
                        transferring your data to{" "}
                        <span
                          style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <bdt className="block-component" />
                            <bdt className="question">India</bdt>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                        , and you agree to have your data transferred to and
                        processed in{" "}
                        <span
                          style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <bdt className="block-component" />
                            <bdt className="question">India</bdt>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                        . <bdt className="block-component" />
                      </span>
                    </span>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div style={{ textAlign: "justify", lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ textAlign: "justify", lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <bdt className="block-container if" data-type="if">
              <bdt
                className="statement-end-if-in-editor"
                data-type="close"
                style={{ fontSize: "14.6667px" }}
              >
                <span style={{ fontSize: 15 }} />
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "115%" }}>
            <span style={{ fontSize: 15 }}>
              <a name="_n081pott8yce" />
            </span>
          </div>
          <bdt className="block-component">
            <bdt className="block-component" />
          </bdt>
        </bdt>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <span style={{ fontSize: 15 }}>
            <a name="_sg28ikxq3swh" />
          </span>{" "}
          <bdt className="block-component">
            <bdt className="block-component" />
            <bdt className="block-component" />
            <bdt className="statement-end-if-in-editor" />
          </bdt>
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="terms"
            style={{ lineHeight: "115%" }}
          >
            <a name="_k3mndam4w6w1" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        12.
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                TERM AND TERMINATION
              </span>
            </strong>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: 15,
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)"
              }}
            >
              These Terms of Use shall remain in full force and effect while you
              use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS
              OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
              WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
              (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
              REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH
              OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
              TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
              TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE{" "}
              <bdt
                className="block-container if"
                data-type="if"
                id="a6e121c2-36b4-5066-bf9f-a0a33512e768"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_account_option"
                    data-type="statement"
                  />
                  <bdt data-type="body">YOUR ACCOUNT AND&nbsp;</bdt>
                </bdt>
                <bdt className="statement-end-if-in-editor" data-type="close" />
              </bdt>
              ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
              WARNING, IN OUR SOLE DISCRETION.
            </span>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.
            </span>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="modifications"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_e2dep1hfgltt" />
            <strong>
              <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                <span style={{ fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          13.
                        </span>
                      </strong>
                    </span>
                    &nbsp;
                  </strong>
                  MODIFICATIONS AND INTERRUPTIONS
                </span>
              </span>
            </strong>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              We reserve the right to change, modify, or remove the contents of
              the Site at any time or for any reason at our sole discretion
              without notice. However, we have no obligation to update any
              information on our Site. We also reserve the right to modify or
              discontinue all or part of the Site without notice at any time. We
              will not be liable to you or any third party for any modification,
              price change, suspension, or discontinuance of the Site.
            </span>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              We cannot guarantee the Site will be available at all times. We
              may experience hardware, software, or other problems or need to
              perform maintenance related to the Site, resulting in
              interruptions, delays, or errors. We reserve the right to change,
              revise, update, suspend, discontinue, or otherwise modify the Site
              at any time or for any reason without notice to you. You agree
              that we have no liability whatsoever for any loss, damage, or
              inconvenience caused by your inability to access or use the Site
              during any downtime or discontinuance of the Site. Nothing in
              these Terms of Use will be construed to obligate us to maintain
              and support the Site or to supply any corrections, updates, or
              releases in connection therewith.
            </span>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="law"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_p6vbf8atcwhs" />
            <strong>
              <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                <span style={{ fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          14.
                        </span>
                      </strong>
                    </span>
                    &nbsp;
                  </strong>
                  GOVERNING LAW
                </span>
              </span>
            </strong>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "115%" }}>
            <bdt className="block-component">
              <span style={{ fontSize: 15 }} />
            </bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)"
                }}
              >
                These Terms shall be governed by and defined following the laws
                of{" "}
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)"
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <bdt className="block-component" />
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <bdt className="question">India</bdt>
                          </span>
                        </span>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            . <bdt className="question">InterviewTape</bdt> and yourself
            irrevocably consent that the courts of{" "}
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)"
                }}
              >
                <bdt className="block-component" />
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)"
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <bdt className="question">India</bdt>
                  </span>
                </span>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
            &nbsp;shall have exclusive jurisdiction to resolve any dispute which
            may arise in connection with these terms.
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)"
                }}
              >
                <span style={{ fontSize: 15 }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="disputes"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_v5i5tjw62cyw" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19
                            }}
                          >
                            15.
                          </span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                DISPUTE RESOLUTION
              </span>
            </strong>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div className="MsoNormal" style={{ lineHeight: "115%" }}>
            <bdt
              className="block-container if"
              data-type="if"
              id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="dispute_option"
                  data-type="statement"
                >
                  <bdt className="block-component" />
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              You agree to irrevocably submit all disputes related to Terms or
              the relationship established by this Agreement to the jurisdiction
              of the <bdt className="block-component" />
              <bdt className="question">__________</bdt>
              <bdt className="statement-end-if-in-editor" />
            </span>{" "}
            courts. <bdt className="question">InterviewTape</bdt> shall also
            maintain the right to bring proceedings as to the substance of the
            matter in the courts of the country where you reside or, if these
            Terms are entered into in the course of your trade or profession,
            the state of your principal place of business.
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <bdt className="block-container if" data-type="if">
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="dispute_option"
                  data-type="statement"
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="dispute_option"
                          data-type="statement"
                        >
                          <bdt className="statement-end-if-in-editor" />
                        </bdt>
                      </bdt>
                    </bdt>
                  </span>
                </bdt>
              </bdt>
            </bdt>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="corrections"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_mjgzo07ttzx5" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        16.
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                CORRECTIONS
              </span>
            </strong>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              There may be information on the Site that contains typographical
              errors, inaccuracies, or omissions, including descriptions,
              pricing, availability, and various other information. We reserve
              the right to correct any errors, inaccuracies, or omissions and to
              change or update the information on the Site at any time, without
              prior notice.
            </span>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="disclaimer"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_gvi74blrahf9" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        17.
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                DISCLAIMER
              </span>
            </strong>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
              THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
              RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
              WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND
              YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
              ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE'S CONTENT OR THE
              CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
              LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
              INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
              PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
              ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
              USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
              AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION
              OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS,
              VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
              THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
              OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE
              OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
              TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
              WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
              PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
              THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
              APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE
              WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
              ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
              PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
              THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
              JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
            </span>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="liability"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_4pjah3d0455q" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        18.
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                LIMITATIONS OF LIABILITY
              </span>
            </strong>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.{" "}
              <bdt
                className="block-container if"
                data-type="if"
                id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="limitations_liability_option"
                    data-type="statement"
                  />
                </bdt>
              </bdt>
            </span>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="indemnification"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_k5ap68aj1dd4" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        19.
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                INDEMNIFICATION
              </span>
            </strong>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys' fees and expenses, made by any third party due to or
              arising out of:{" "}
              <bdt
                className="block-container if"
                data-type="if"
                id="475fffa5-05ca-def8-ac88-f426b238903c"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_post_content_option"
                    data-type="statement"
                  />
                  <bdt data-type="body">(1) your Contributions;&nbsp;</bdt>
                </bdt>
                <bdt className="statement-end-if-in-editor" data-type="close" />
              </bdt>
              (<span style={{ fontSize: "14.6667px" }}>2</span>) use of the
              Site; (<span style={{ fontSize: "14.6667px" }}>3</span>) breach of
              these Terms of Use; (
              <span style={{ fontSize: "14.6667px" }}>4</span>) any breach of
              your representations and warranties set forth in these Terms of
              Use; (<span style={{ fontSize: "14.6667px" }}>5</span>) your
              violation of the rights of a third party, including but not
              limited to intellectual property rights; or (
              <span style={{ fontSize: "14.6667px" }}>6</span>) any overt
              harmful act toward any other user of the Site with whom you
              connected via the Site. Notwithstanding the foregoing, we reserve
              the right, at your expense, to assume the exclusive defense and
              control of any matter for which you are required to indemnify us,
              and you agree to cooperate, at your expense, with our defense of
              such claims. We will use reasonable efforts to notify you of any
              such claim, action, or proceeding which is subject to this
              indemnification upon becoming aware of it.
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }} />
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="userdata"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_ftgg17oha0ep" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19
                            }}
                          >
                            20.
                          </span>
                        </strong>
                      </span>
                      &nbsp;
                    </strong>
                  </span>
                </strong>
                USER DATA
              </span>
            </strong>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              We will maintain certain data that you transmit to the Site for
              the purpose of managing the performance of the Site, as well as
              data relating to your use of the Site. Although we perform regular
              routine backups of data, you are solely responsible for all data
              that you transmit or that relates to any activity you have
              undertaken using the Site. You agree that we shall have no
              liability to you for any loss or corruption of any such data, and
              you hereby waive any right of action against us arising from any
              such loss or corruption of such data.
            </span>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="electronic"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_dkovrslqodui" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        21.
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </span>
            </strong>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              Visiting the Site, sending us emails, and completing online forms
              constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Site, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE SITE. You hereby waive any rights or requirements under any
              statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt className="block-component" />
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="misc"
            style={{ lineHeight: "115%" }}
          >
            <a name="_d4jvmcnxg0wt" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        22.
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                MISCELLANEOUS
              </span>
            </strong>
          </div>
        </div>
        <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
          <br />
        </div>
        <div style={{ textAlign: "left" }} align="center">
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              These Terms of Use and any policies or operating rules posted by
              us on the Site or in respect to the Site constitute the entire
              agreement and understanding between you and us. Our failure to
              exercise or enforce any right or provision of these Terms of Use
              shall not operate as a waiver of such right or provision. These
              Terms of Use operate to the fullest extent permissible by law. We
              may assign any or all of our rights and obligations to others at
              any time. We shall not be responsible or liable for any loss,
              damage, delay, or failure to act caused by any cause beyond our
              reasonable control. If any provision or part of a provision of
              these Terms of Use is determined to be unlawful, void, or
              unenforceable, that provision or part of the provision is deemed
              severable from these Terms of Use and does not affect the validity
              and enforceability of any remaining provisions. There is no joint
              venture, partnership, employment or agency relationship created
              between you and us as a result of these Terms of Use or use of the
              Site. You agree that these Terms of Use will not be construed
              against us by virtue of having drafted them. You hereby waive any
              and all defenses you may have based on the electronic form of
              these Terms of Use and the lack of signing by the parties hereto
              to execute these Terms of Use.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt className="block-component" />
            </span>
          </div>
          <div style={{ textAlign: "left", lineHeight: "1.5" }} align="center">
            <br />
          </div>
          <div style={{ textAlign: "left" }} align="center">
            <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div
              className="MsoNormal"
              data-custom-class="heading_1"
              id="contact"
              style={{ lineHeight: "1.5" }}
            >
              <a name="_t4pq5cwn486q" />
              <strong>
                <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                  <span style={{ fontSize: 19 }}>
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19
                            }}
                          >
                            23.
                          </span>
                        </strong>
                      </span>
                      &nbsp;
                    </strong>
                    CONTACT US
                  </span>
                </span>
              </strong>
            </div>
          </div>
          <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
            <br />
          </div>
          <div style={{ textAlign: "left" }} align="center">
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166
                }}
              >
                In order to resolve a complaint regarding the Site or to receive
                further information regarding use of the Site, please contact us
                at:
              </span>
            </div>
          </div>
          <div style={{ textAlign: "left", lineHeight: 1 }} align="center">
            <br />
          </div>
          <div style={{ textAlign: "left" }} align="center">
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <span
                style={{
                  fontSize: 15,
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "rgb(89, 89, 89)"
                }}
              >
                <bdt
                  className="block-container question question-in-editor"
                  data-id="8a6919c4-2010-e7d6-2305-d74dfb08909d"
                  data-type="question"
                >
                  <strong>InterviewTape</strong>
                </bdt>
              </span>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <bdt className="question">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span style={{ fontSize: 15 }}>
                            <bdt className="block-component" />
                          </span>
                        </strong>
                      </span>
                    </bdt>
                  </span>
                </strong>
              </span>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <strong>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span style={{ fontSize: 15 }}>
                      <bdt className="question">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <strong>
                            <span style={{ fontSize: 15 }}>
                              <bdt className="block-component" />
                            </span>
                          </strong>
                        </span>
                      </bdt>
                    </span>
                  </strong>
                </span>
              </strong>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <strong>
                <strong>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span style={{ fontSize: 15 }}>
                        <bdt className="question">__________</bdt>
                        <bdt className="block-component" />
                      </span>
                    </strong>
                    <strong>
                      <span style={{ fontSize: 15 }}>
                        <bdt className="block-component" />
                      </span>
                    </strong>
                  </span>
                </strong>
              </strong>
            </div>
            <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <strong>
                <strong>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span style={{ fontSize: 15 }}>
                        <bdt className="question">
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <strong>
                              <span style={{ fontSize: 15 }}>
                                <bdt className="block-component" />
                              </span>
                            </strong>
                          </span>
                        </bdt>
                      </span>
                    </strong>
                  </span>
                </strong>
              </strong>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <strong>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span style={{ fontSize: 15 }}>
                      <bdt className="block-component" />
                    </span>
                  </strong>
                </span>
              </strong>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <strong>
                <span style={{ fontSize: 15 }}>
                  <strong>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15 }}>
                        <strong>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="block-component" />
                          </span>
                        </strong>
                      </span>
                    </span>
                  </strong>
                </span>
              </strong>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <strong>
                <strong>
                  <span
                    style={{
                      fontSize: 15,
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <bdt
                      className="block-container question question-in-editor"
                      data-id="fdc2b5b8-c95f-244b-23a7-287f82541348"
                      data-type="question"
                    >
                      <strong>__________</strong>
                    </bdt>
                  </span>
                </strong>
              </strong>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    "
            }}
          />
          <strong>
            <strong></strong>
          </strong>
        </div>
        <strong>
          
        </strong>
      </div>
    </div>
  </div>
</>
    
  )
}

export default TermsandCondition