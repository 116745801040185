import React from 'react';
import Navbar from './Navbar';
import './NewProfile.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import VideoRecorder from 'react-video-recorder';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  parsePhoneNumber,
  isValidPhoneNumber,
  getNumberType,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

const steps = ['Personal Info', 'Instructions', 'Record'];

function NewProfile() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [selectedOption, setSelectedOption] = useState('');
  const [error, setError] = useState('');

  const [formValues, setFormValues] = useState({
    firstName: {
      step: 0,
      value: '',
      error: false,
      errorMessage: 'You must enter a first name',
    },
    lastName: {
      step: 0,
      value: '',
      error: false,
      errorMessage: 'You must enter a last name',
    },
    // gender: {
    //   step: 0,
    //   value: '',
    //   error: false,
    //   errorMessage: 'You must select a gender',
    // },
  });

  // const handleSelectChange = (event) => {
  //   console.log('gender');
  //   const value = event.target.value;
  //   setSelectedOption(value);
  //   setError(value ? '' : 'Please select an option');
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setSelectedOption(value);
    setError(value ? '' : 'Please select an option');
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value,
      },
    });
  };

  const validateFormDataForEmpty = (step) => {
    const formFields = Object.keys(formValues); //.filter(f => f.step === step)
    let newFormValues = { ...formValues };
    let valid = true;

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentStep = formValues[currentField].step;
      if (currentStep !== step) continue;

      const currentValue = formValues[currentField].value;

      if (currentValue === '') {
        console.log('if currentField', currentField);
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true,
          },
        };
        valid = false;
      } else {
        console.log('else currentField', currentField);
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: false,
          },
        };
      }
    }

    setFormValues(newFormValues);
    return valid;
  };

  const validateFormData = (step) => {
    let valid = validateFormDataForEmpty(step);
    console.log('validateFormData', valid);
    if (valid) {
      //Do Custom Validation here
      if (step == 0) {
        //check DOB < current date
      }
    }
    return valid;
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    console.log('calling next', activeStep);
    const valid = validateFormData(activeStep);
    console.log('form state', valid);
    if (!valid) return;

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
      <Navbar />

      <Container fixed className="create_profile_stepper">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              // if (isStepOptional(index)) {
              //   labelProps.optional = (
              //     <Typography variant="caption">Optional</Typography>
              //   );
              // }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="hide">
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Step {activeStep + 1}
                </Typography>
              </div>

              <Container fixed className="personal_info_container">
                {activeStep == 0 && (
                  <Box
                    component="form"
                    onChange={handleChange}
                    sx={{
                      '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="E-mail"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="First Name"
                          variant="outlined"
                          required
                          name="firstName"
                          value={formValues.firstName.value}
                          error={formValues.firstName.error}
                          helperText={
                            formValues.firstName.error &&
                            formValues.firstName.errorMessage
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Last Name"
                          variant="outlined"
                          required
                          name="lastName"
                          value={formValues.lastName.value}
                          error={formValues.lastName.error}
                          helperText={
                            formValues.lastName.error &&
                            formValues.lastName.errorMessage
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} className="profile_gender">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" required>
                            Gender
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="gender"
                            value={selectedOption}
                            onChange={handleChange}
                            error={!!error}
                            helperText={error}
                          >
                            <MenuItem value="">
                              <em>Select an option</em>
                            </MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </Select>
                          {error && (
                            <div className="gender_errror_msg">{error}</div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {/* <MuiTelInput
                          required
                          fullWidth
                          defaultCountry="IN"
                          value={value}
                          onChange={handlePhoneChange}
                        /> */}
                        <MuiPhoneNumber
                          required
                          fullWidth
                          label="Phone Number"
                          variant="outlined"
                          defaultCountry={'in'}
                          onChange={(c, t) => {
                            console.log(c, t, isValidPhoneNumber(c));
                            return true;
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                  </Box>
                )}
              </Container>

              {activeStep == 1 && (
                <div>
                  <Container fixed className="video_info_container">
                    <Grid container>
                      <br />
                      <Grid item xs={12} md={2}></Grid>
                      <Grid item xs={12} md={8}>
                        <div className="profile_note_instruction_txt">
                          <h3>
                            <u>What to record?</u>
                          </h3>
                        </div>
                        <div className="profile_note_list_txt">
                          <ul className="profile_note_list_txt">
                            <li>
                              <p>
                                In the next screens, you will be asked one or
                                more questions. Please record your response to
                                the question using your mobile/laptop camera.
                              </p>
                            </li>
                            <li>
                              <p>
                                Maximum video response time for each question
                                will be <u>60 seconds</u>
                              </p>
                            </li>
                            <li>
                              <p>
                                All questions needs to be answered{' '}
                                <u>mandatorily</u>
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="profile_note_instruction_txt">
                          <h3>
                            <u>Tips to shoot a great video</u>
                          </h3>
                        </div>
                        <div className="profile_note_list_txt">
                          <ul className="profile_note_list_txt">
                            <li>
                              <p>
                                Find a quiet, private, well-lit place, free from
                                possible interruptions.
                              </p>
                            </li>
                            <li>
                              <p>
                                Make sure your <u>face is fully visible</u>.
                              </p>
                            </li>
                            <li>
                              <p>Ensure your internet connection is stable.</p>
                            </li>
                            <li>
                              <p>
                                Place your phone in silent (or) do not disturb
                                mode.
                              </p>
                            </li>
                            <li>
                              <p>
                                Check that your computer's / phone's
                                webcam/camera & audio are working.
                              </p>
                            </li>
                            <li>
                              <p>
                                Close any unnecessary web browser tabs, apps and
                                desktop applications.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={2}></Grid>
                    </Grid>
                  </Container>
                </div>
              )}
              {activeStep == 2 && (
                <div>
                  <Container fixed className="video_record_container">
                    <Grid conatiner>
                      <br />
                      <Grid item xs={12} md={12} className="question_grid">
                        <h4>Question: Tell me about yourself</h4>
                      </Grid>
                      <Grid item xs={12} md={12} className="record_note_txt">
                        <p>
                          <i>
                            Note: Answer the above question by clicking on the
                            record button below to record your video response.
                            You can re-record if you need to. Click Next/Finish
                            once done.
                          </i>
                        </p>
                      </Grid>
                      <Grid item xs={12} md={8} className="recorder_grid">
                        <VideoRecorder
                          isOnInitially
                          isFliped
                          showReplayControls
                          // mimeType={text('mimeType')}
                          countdownTime="3000"
                          timeLimit="60000"
                          onRecordingComplete={(videoBlob) => {
                            // Do something with the video...
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  className="back_btn"
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {/* {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )} */}

                <Button className="next_btn" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default NewProfile;
