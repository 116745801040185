// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const FeedbackStatus = {
  "UP": "UP",
  "DOWN": "DOWN",
  "NEUTRAL": "NEUTRAL"
};

const ActionStatus = {
  "CREATED": "CREATED",
  "VIEWED": "VIEWED",
  "COMPLETED": "COMPLETED"
};

const DecisionStatus = {
  "PENDING": "PENDING",
  "DECLINED": "DECLINED",
  "SELECTED": "SELECTED",
  "REJECTED": "REJECTED"
};

const JobStatus = {
  "OPEN": "OPEN",
  "CLOSE": "CLOSE",
  "HOLD": "HOLD",
  "ARCHIVE": "ARCHIVE"
};

const { InterviewFeedback, Recruiter, Company, InterviewSchedule, Candidate, Interview, QuestionBank, Job, QuestionBankJob, InterviewStatus } = initSchema(schema);

export {
  InterviewFeedback,
  Recruiter,
  Company,
  InterviewSchedule,
  Candidate,
  Interview,
  QuestionBank,
  Job,
  QuestionBankJob,
  FeedbackStatus,
  ActionStatus,
  DecisionStatus,
  JobStatus,
  InterviewStatus
};