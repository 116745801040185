import { React, useState, useEffect } from 'react';
// import type { WebcamRenderProps, RecordWebcamHook } from "react-record-webcam";
import './RecordPage.css';
import VideoRecorder from 'react-video-recorder';
import { Container, Grid } from '@mui/material';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../HomePage/Navbar';
import interviewService from '../Service/AWS_Auth/JobBasedInterviewScheduleService';
import {
  ActionStatus,
  Candidate,
  DecisionStatus,
  Interview,
  InterviewSchedule,
  InterviewStatus,
} from '../../models';
import AWS_Storage from '../Service/AWS_Auth/VideoStorage';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import { getInterview } from '../../graphql/queries';
import { updateInterview } from '../../graphql/mutations';
import { API } from 'aws-amplify';
const WebcamSpecificQuestionNoAuth = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let id = new URLSearchParams(useLocation().search).get('id');
  console.log(id, 'id');
  const token = new URLSearchParams(useLocation().search).get('token');
  const decoded = decodeURIComponent(token);
  const expirationTime = parseInt(atob(decoded));
  const now = new Date().getTime();

  const [isValidUrl, setIsValidUrl] = useState(true);
  const [video1Url, setVideo1Url] = useState('');
  const [video2Url, setVideo2Url] = useState('');
  const [video3Url, setVideo3Url] = useState('');
  const [video1Blob, setVideo1Blob] = useState();
  const [video2Blob, setVideo2Blob] = useState();
  const [video3Blob, setVideo3Blob] = useState();
  var currentTime = new Date().toLocaleString();
  const [Questions, setQuestions] = useState({});

  useEffect(async () => {
    //fetch interview based on interviewID

    if (now > expirationTime) {
      // URL has expired
      setIsValidUrl(false);
    } else {
      // navigate(`/candidate/interview/direct/record/?id=${id}`);
      setIsValidUrl(true);
      try {
        const interview = await API.graphql({
          query: getInterview,
          variables: { id: id },
          authMode: 'API_KEY',
          headers: {
            'x-api-key': 'da2-ahtasosez5fo7gvtwgzkkuhbpe',
          },
        });
        console.log('inter', interview.data.getInterview);
        setQuestions(interview.data.getInterview);
        console.log(interview.data.getInterview.interviewCandidatesId);
        return interview.data.getInterview;
      } catch (err) {
        console.log(err);
      }
      await interviewService.getInterviewByID(id).then((value) => {
        console.log('inter', value);
        setQuestions(value);
        console.log(value.interviewCandidatesId);
      });
    }
  }, []);

  const downloadVideo1 = async () => {
    return new Promise(async (resolve, reject) => {
      await AWS_Storage.videoBlob(
        video1Blob,
        'video4/' + Questions.interviewCandidatesId.toString()
      )
        .then((video1Url) => {
          console.log('video1Url', video1Url);
          setVideo1Url(video1Url);
          resolve(video1Url);
        })
        .catch((err) => {
          toast.error(err.toString());
          reject(err);
        });
    });
  };
  const downloadVideo2 = async () => {
    return new Promise(async (resolve, reject) => {
      await AWS_Storage.videoBlob(
        video2Blob,
        'video5/' + Questions.interviewCandidatesId.toString()
      )
        .then((video2Url) => {
          setVideo2Url(video2Url);
          resolve(video2Url);
        })
        .catch((err) => {
          toast.error(err.toString());
          reject(err);
        });
    });
  };
  const downloadVideo3 = async () => {
    return new Promise(async (resolve, reject) => {
      await AWS_Storage.videoBlob(
        video3Blob,
        'video6/' + Questions.interviewCandidatesId.toString()
      )
        .then((video3Url) => {
          setVideo3Url(video3Url);
          resolve(video3Url);
        })
        .catch((err) => {
          toast.error(err.toString());
          reject(err);
        });
    });
  };

  const onSubmit = async (video3) => {
    console.log('video4', video1Url);

    let data = {
      id: id,
      ActionStatus: ActionStatus.COMPLETED,
      CompletedTime: new Date().toLocaleString(),
      Video1: video1Url,
      Video2: video2Url,
      Video3: video3,
      _version: Questions._version,
    };
    console.log('data', data);
    const interview = await API.graphql({
      query: updateInterview,
      variables: {
        input: data,
      },
      fetchPolicy: 'no-cache',
      authMode: 'API_KEY',
      headers: {
        'x-api-key': 'da2-ahtasosez5fo7gvtwgzkkuhbpe',
      },
    })
      .then((val) => {
        console.log('api ', val);
        if (val.data.updateInterview != null) {
          if (localStorage.getItem('AuthStatus') == 'true') {
            toast.success('Your interview has been successfully submitted');
            navigate('/candidate/interviewPage');
          } else {
            toast.success(
              'Your interview has been successfully submitted, Login to continue'
            );
            // navigate('/');
          }
        }
      })
      .catch((e) => {
        console.log('error', e);
      });
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const tabCount = 3;
  return (
    <div>
      {isValidUrl ? (
        Questions.Video1 == null || Questions.Video1 == '' ? (
          <Container maxWidth className="zero_padding">
            <Navbar />
            <Container>
              <Grid container className="qest_txt">
                <Grid item>
                  <h2> Record Screen</h2>
                </Grid>
              </Grid>
              <br></br>
            </Container>
            <Container className="record_video_view">
              <Tabs defaultTab={selectedTab.toString()}>
                <TabList className="tab_view">
                  <Tab tabFor="0">Tab 1</Tab>
                  <Tab tabFor="1">Tab 2</Tab>
                  <Tab tabFor="2">Tab 3</Tab>
                </TabList>
                <TabPanel tabId="0">
                  <Grid container>
                    <Grid item className="rec_preview_tab" md={12}>
                      <div className="Q_txt">
                        1.Question :<strong>{Questions.Question1}</strong>
                        <div className="tips_txt">
                          <p>Tips :</p>
                          <ul>
                            <li>Do not recite your resume</li>
                            <li>
                              Provide a concise, thoughtful overview of your
                              past, present, and future
                            </li>
                            <li>
                              Provide details about your past experience,
                              projects you worked and your role
                            </li>
                            <li>Keep it short - 30 secs to a minute</li>
                            <li>
                              Communicate things that will give you an edge
                            </li>
                          </ul>
                        </div>
                        <div className="media_position">
                          <br></br>

                          <Grid container>
                            <Grid item md={1} xs={0}></Grid>
                            <Grid
                              item
                              className="video_screen_size"
                              xs={12}
                              md={10}
                            >
                              {/* <Grid container>
                           <Grid item className="video_height">
                             <div style={{ height: '100%' }}> */}
                              {/* <h1>One minute</h1> */}
                              <VideoRecorder
                                isOnInitially
                                isFliped
                                showReplayControls
                                // mimeType={text('mimeType')}
                                countdownTime={3000}
                                timeLimit={60000}
                                onRecordingComplete={(videoBlob) => {
                                  // Do something with the video...
                                  console.log('videoBlob', videoBlob);
                                  setVideo1Blob(videoBlob);
                                }}
                              />
                              {/* </div>
                           </Grid>
                         </Grid> */}
                            </Grid>
                            <Grid item xs={0} md={1}></Grid>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                {Questions.Question2 != 'No question' ? (
                  <TabPanel tabId="1">
                    <Grid container>
                      <Grid item md={12}>
                        <div className="Q_txt">
                          2.Question :<strong>{Questions.Question2}</strong>
                          <div className="tips_txt">
                            <p>Tips :</p>
                            <ul>
                              <li>Do not recite your resume</li>
                              <li>
                                Provide a concise, thoughtful overview of your
                                past, present, and future
                              </li>
                              <li>
                                Provide details about your past experience,
                                projects you worked and your role
                              </li>
                              <li>Keep it short - 60 secs to a minute</li>
                              <li>
                                Communicate things that will give you an edge
                              </li>
                            </ul>
                          </div>
                          <div className="media_position">
                            <br></br>

                            <Grid container>
                              <Grid item md={1} xs={0}></Grid>
                              <Grid
                                item
                                className="video_screen_size"
                                xs={12}
                                md={10}
                              >
                                {/* <Grid container>
                           <Grid item className="video_height">
                             <div style={{ height: '100%' }}> */}
                                {/* <h1>One minute</h1> */}
                                <VideoRecorder
                                  isOnInitially
                                  isFliped
                                  showReplayControls
                                  // mimeType={text('mimeType')}
                                  countdownTime={3000}
                                  timeLimit={60000}
                                  onRecordingComplete={(videoBlob) => {
                                    // Do something with the video...
                                    console.log('videoBlob', videoBlob);
                                    setVideo2Blob(videoBlob);
                                  }}
                                />
                                {/* </div>
                           </Grid>
                         </Grid> */}
                              </Grid>
                              <Grid item xs={0} md={1}></Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </TabPanel>
                ) : (
                  <></>
                )}
                {Questions.Question3 != 'No question' ? (
                  <TabPanel tabId="2">
                    <Grid container>
                      <Grid item md={12}>
                        <div className="Q_txt">
                          3.Question :<strong>{Questions.Question3}</strong>
                          <div className="tips_txt">
                            <p>Tips :</p>
                            <ul>
                              <li>Do not recite your resume</li>
                              <li>
                                Provide a concise, thoughtful overview of your
                                past, present, and future
                              </li>
                              <li>
                                Provide details about your past experience,
                                projects you worked and your role
                              </li>
                              <li>Keep it short - 30 secs to a minute</li>
                              <li>
                                Communicate things that will give you an edge
                              </li>
                            </ul>
                          </div>
                          <div className="media_position">
                            <br></br>

                            <Grid container>
                              <Grid item md={1} xs={0}></Grid>
                              <Grid
                                item
                                className="video_screen_size"
                                xs={12}
                                md={10}
                              >
                                {/* <Grid container>
                           <Grid item className="video_height">
                             <div style={{ height: '100%' }}> */}
                                {/* <h1>One minute</h1> */}
                                <VideoRecorder
                                  isOnInitially
                                  isFliped
                                  showReplayControls
                                  // mimeType={text('mimeType')}
                                  countdownTime={3000}
                                  timeLimit={60000}
                                  onRecordingComplete={(videoBlob) => {
                                    // Do something with the video...
                                    console.log('videoBlob', videoBlob);
                                    setVideo3Blob(videoBlob);
                                  }}
                                />
                                {/* </div>
                           </Grid>
                         </Grid> */}
                              </Grid>
                              <Grid item xs={0} md={1}></Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </TabPanel>
                ) : (
                  <></>
                )}
              </Tabs>

              <div className="video_bottom_txt">
                {selectedTab <= 1 && video1Blob != undefined && (
                  <Button // || video2Blob!=undefined
                    onClick={(e) => {
                      setSelectedTab((selectedTab + 1) % tabCount);
                      if (selectedTab == 0) {
                        if (video1Blob != undefined) {
                          downloadVideo1().then(async (val) => {
                            console.log('val', val);
                            if (Questions.Question2 == 'No question') {
                              let data = {
                                ActionStatus: ActionStatus.COMPLETED,
                                CompletedTime: new Date().toLocaleString(),
                                Video1: val,
                                Video2: '',
                                Video3: '',
                                _version: Questions._version,
                              };
                              await API.graphql({
                                query: updateInterview,
                                variables: {
                                  input: {
                                    id: id,
                                    ...data, // Pass the updated fields here
                                  },
                                },
                                fetchPolicy: 'no-cache',
                                authMode: 'API_KEY',
                                headers: {
                                  'x-api-key': 'da2-ahtasosez5fo7gvtwgzkkuhbpe',
                                },
                              })
                                .then((val) => {
                                  console.log('api ', val);
                                  if (val.data.updateInterview != null) {
                                    if (
                                      localStorage.getItem('AuthStatus') ==
                                      'true'
                                    ) {
                                      toast.success(
                                        'Your interview has been successfully submitted'
                                      );
                                      navigate('/candidate/interviewPage');
                                    } else {
                                      toast.success(
                                        'Your interview has been successfully submitted, Login to continue'
                                      );
                                      navigate('/');
                                    }
                                  }
                                })
                                .catch((e) => {
                                  console.log('error', e);
                                });
                            }
                          });
                        }
                      }
                      if (selectedTab == 1) {
                        if (video2Blob != undefined) {
                          downloadVideo2().then(async (val) => {
                            if (Questions.Question3 == 'No question') {
                              let data = {
                                ActionStatus: ActionStatus.COMPLETED,
                                CompletedTime: new Date().toLocaleString(),
                                Video1: video1Url,
                                Video2: val,
                                Video3: '',
                                _version: Questions._version,
                              };
                              await API.graphql({
                                query: updateInterview,
                                variables: {
                                  input: {
                                    id: id,
                                    ...data, // Pass the updated fields here
                                  },
                                },
                                fetchPolicy: 'no-cache',
                                authMode: 'API_KEY',
                                headers: {
                                  'x-api-key': 'da2-ahtasosez5fo7gvtwgzkkuhbpe',
                                },
                              })
                                .then((val) => {
                                  console.log('api ', val);
                                  if (val.data.updateInterview != null) {
                                    if (
                                      localStorage.getItem('AuthStatus') ==
                                      'true'
                                    ) {
                                      toast.success(
                                        'Your interview has been successfully submitted'
                                      );
                                      navigate('/candidate/interviewPage');
                                    } else {
                                      toast.success(
                                        'Your interview has been successfully submitted, Login to continue'
                                      );
                                      navigate('/');
                                    }
                                  }
                                })
                                .catch((e) => {
                                  console.log('error', e);
                                });
                            }
                          });
                        }
                      }
                      // if(selectedTab==2){
                      //   downloadVideo3();
                      // }
                    }}
                  >
                    Save & Continue
                  </Button>
                )}
              </div>

              <div className="video_bottom_txt">
                {selectedTab == 2 && (
                  <Button
                    onClick={() => {
                      //&& video3Blob!=undefined
                      if (video3Blob != undefined) {
                        downloadVideo3().then((val) => {
                          onSubmit(val);
                        });
                      } else {
                        onSubmit('');
                      }
                    }}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Container>
          </Container>
        ) : (
          <Container>
            <div>The interview has been already submitted.</div>
          </Container>
        )
      ) : (
        <Container>
          <div>The interview link has expired.</div>
        </Container>
      )}
    </div>
  );
};

export default WebcamSpecificQuestionNoAuth;
