import { DataStore } from '@aws-amplify/datastore';
import { Candidate, InterviewSchedule } from '../../../models';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
Amplify.configure(awsconfig);

class CandidateAmplify {
  async save(data) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(data.Email);
        // Check if a user with the same email already exists in the datastore
        const existingUser = await DataStore.query(Candidate, (c) =>
          c.Email.eq(data.Email)
        );

        if (existingUser.length == 0) {
          // Create a new user since one with the same email doesn't exist
          const newUser = await DataStore.save(data);
          console.log(newUser);
          localStorage.setItem('candidateId', newUser.id);
          resolve(newUser);
        } else {
          reject('User mail ID already exist');
        }
      } catch (e) {
        console.log('Candidate save', e);
        reject(e);
      }
    });
  }
  async createCandidate(data) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.save(data).then((user) => {
          if (user != null) {
            console.log(user);
            resolve(user);
          } else {
            resolve([]);
          }
        });
      } catch (e) {
        console.log('Candidate save', e);
        reject(e);
      }
    });
  }

  async update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Candidate, id).then(async (original) => {
          await DataStore.save(
            Candidate.copyOf(original, (updated) => {
              updated.FirstName = data.FirstName;
              updated.Phone = data.Phone;
              updated.Email = data.Email;
              updated.DOB = data.DOB;
              updated.Qualification = data.Qualification;
              updated.LastName = data.LastName;
              updated.HomeLocation = data.HomeLocation;
              updated.CurrentLocation = data.CurrentLocation;
              updated.College = data.College;
              updated.CollegeCity = data.CollegeCity;
              updated.Course = data.Course;
              updated.CGPA = data.CGPA;
              updated.Experience = data.Experience;
              updated.Resume = data.Resume;
              updated.FresherProjectDetails = data.FresherProjectDetails;
              updated.FresherProjectName = data.FresherProjectName;
              updated.FresherProjectTools = data.FresherProjectTools;
              updated.YOE = data.YOE;
              updated.CompanyName = data.CompanyName;
              updated.CurrentRole = data.CurrentRole;
              updated.Github = data.Github;
              updated.Linkedin = data.Linkedin;
              updated.Youtube = data.Youtube;
              updated.ProfilePic = data.ProfilePic;
              updated.Gender = data.Gender;
              updated.Status = data.Status;
            })
          )
            .then((user) => {
              console.log('Candidate update', user);
              resolve('success');
            })
            .catch((e) => {
              console.log('Candidate update', e);
              reject(e);
            });
        });
      } catch (e) {
        console.log('Candidate update', e);
        reject(e);
      }
    });
  }

  async updateVideo(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Candidate, id).then(async (original) => {
          await DataStore.save(
            Candidate.copyOf(original, (updated) => {
              updated.Video1 = data.Video_1;
              updated.Video2 = data.Video_2;
              updated.Video3 = data.Video_3;
            })
          ).then((user) => {
            console.log('Candidate update', user);
            resolve('success');
          });
        });
      } catch (e) {
        console.log('Candidate update', e);
        reject(e);
      }
    });
  }

  async getCandidate(email) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Candidate, (c) => c.Email.eq(email), {
          consistent: true,
        }).then((user) => {
          if (user.length != 0) {
            console.log(user[0]);
            resolve(user[0]);
          } else {
            resolve([]);
          }
        });
      } catch (e) {
        console.log('error getCandidate: ', e);
        reject(e);
      }
    });
  }

  async getCandidateByID(id) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Candidate, (c) => c.id.eq(id), {
          consistent: true,
        }).then((user) => {
          if (user.length != 0) {
            console.log(user[0]);
            resolve(user[0]);
          } else {
            resolve([]);
          }
        });
      } catch (e) {
        console.log('error getCandidate: ', e);
        reject(e);
      }
    });
  }
  async getLast10Candidate() {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Candidate, (c) => c, {
          sort: (s) => s.createdAt('desc'),
          limit: 10,
          consistent: true,
        }).then((candidate) => {
          console.log(candidate);
          resolve(candidate);
        });
      } catch (e) {
        console.log('error getAllCandidate: ', e);
        reject(e);
      }
    });
  }
  async SearchCandidate(text) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Candidate, (c) =>
          c.or((c) => [
            c.Email.contains(text),
            c.FirstName.contains(text),
            c.Phone.contains(text),
            c.LastName.contains(text),
          ])
        ).then((candidate) => {
          console.log(candidate);
          resolve(candidate);
        });
      } catch (e) {
        console.log('error SearchCandidate: ', e);
        reject(e);
      }
    });
  }
  async jobs(candidateId) {
    return new Promise(async (resolve, reject) => {
      try {
        const models = await DataStore.query(InterviewSchedule, (i) =>
          i.interviewScheduleCandidateId.eq(candidateId)
        );
        const arrayResult = Array.from(models);
        arrayResult.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        resolve(arrayResult);

        const subscription = DataStore.observe(InterviewSchedule).subscribe(
          async () => {
            const models = await DataStore.query(InterviewSchedule, (i) =>
              i.interviewScheduleCandidateId.eq(candidateId)
            );
            const arrayResult = Array.from(models);
            arrayResult.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            resolve(arrayResult);
          }
        );
      } catch (e) {
        console.log('error jobs: ', e);
        reject(e);
      }
    });
  }
  async jobByRecruiter(recruiterId) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await DataStore.query(InterviewSchedule, (i) =>
          i.recruiterID.eq(recruiterId)
        );
        const arrayResult = Array.from(result);
        arrayResult.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        resolve(arrayResult);

        const subscription = DataStore.observe(InterviewSchedule).subscribe(
          async () => {
            const result = await DataStore.query(InterviewSchedule, (i) =>
              i.recruiterID.eq(recruiterId)
            );
            const arrayResult = Array.from(result);
            arrayResult.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            resolve(arrayResult);
          }
        );
      } catch (e) {
        console.log('error jobByRecruiter: ', e);
        reject(e);
      }
    });
  }
  async jobByInterview(interviewId) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(InterviewSchedule, (i) =>
          i.id.eq(interviewId)
        ).then((models) => {
          console.log(models[0]);
          resolve(models[0]);
        });
      } catch (e) {
        console.log('error jobByInterview: ', e);
        resolve(e);
      }
    });
  }
}
export default new CandidateAmplify();
