import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Navbar from '../HomePage/Navbar';
import './RecruiterProfileView.css';
import companyLogo from '../../assets/images/1.png';
import '../HomePage/themify-icons.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../HomePage/Home.css';
import profile_pic from '../../assets/images/user-profile.png';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import VideoModal from '../HomePage/VideoModal';
import Card from 'react-bootstrap/Card';
import { NavLink } from 'react-router-dom';
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBTypography,
} from 'mdb-react-ui-kit';

import AwsAmplify from '../Service/AWS_Auth/RecruiterDataService';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Image } from '@aws-amplify/ui-react';
import { Storage } from '@aws-amplify/storage';
function RecruiterProfileView() {
  const [optCandidateModal, setOptCandidateModal] = useState(false);
  const [optQuestionModal, setOptQuestionModal] = useState(false);
  const toggleShow1 = () => setOptCandidateModal(!optCandidateModal);
  const toggleShow2 = () => setOptQuestionModal(!optQuestionModal);
  const [user, setUser] = React.useState({});
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [userDetails, setUserDetails] = React.useState();
  const [companyName, setCompanyName] = React.useState('');
  const [UserRole, setUserRole] = React.useState('');
  const [ProfilePicture, setProfilePicture] = React.useState('');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(phone);
  var userDecoded;
  const [id, setID] = React.useState();
  const [linkedin, setLinkedin] = React.useState();
  const [email, setEmail] = React.useState('');
  const [picUrl, setPicUrl] = React.useState('');
  useEffect(async () => {
    let email = localStorage.getItem('email');
    setEmail(email);
    setUserRole(localStorage.getItem('role'));
    let userValue = await AwsAmplify.getRecruiter(email);
    setUserDetails(userValue);
    if (userValue != null && userValue != undefined) {
      localStorage.setItem('recruiterId', userValue.id);
      localStorage.setItem('fname', userValue.FirstName);
      localStorage.setItem('lname', userValue.LastName);
      await Storage.get(userValue.Profilepic).then((pic) => {
        localStorage.setItem('profilePic', pic);
      });
      localStorage.setItem('companyId', userValue.recruiterCompanyId);
      setID(userValue.id);
      setFirstName(userValue.FirstName);
      setLastName(userValue.LastName);
      setCompanyName(userValue.CompanyName);
      setPhone(userValue.Phone);
      setProfilePicture(userValue.Profilepic);
      setLinkedin(userValue.Linkedin);
      await Storage.get(userValue.Profilepic).then((val) => {
        setPicUrl(val);
      });
    }
    let defaultValues = {};
    reset({ ...defaultValues });
  }, []);
  return (
    <div>
      <Navbar />
      <Container fluid>
        <Row>
          <Col className="employee_details_txt">
            <h2>Company Details</h2>
          </Col>
        </Row>
        <Container>
          <Row className="user_profile_details">
            <Col>
              <Row className="profile_details_row">
                <Col sm={4} className="profile_pic_col">
                  <Image
                    className="company_profile"
                    src={picUrl}
                    width="300px"
                    height="200px"
                  />
                </Col>
                <Col className="emp_details_col" sm={8}>
                  <Row className="emp_details_row">
                    <Col sm={12} md={6}>
                      <h4>{firstName + ' ' + lastName}</h4>
                    </Col>
                    <Col className="edit_profile_txt" sm={12} md={6}>
                      <NavLink to="/recruiter/edit_profile">
                        <li>
                          <span class="ti-pencil-alt"></span>
                        </li>
                      </NavLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4} className="details_list">
                      <ul>
                        <li>
                          <span class="ti-email"></span>
                          {email}
                        </li>
                      </ul>
                    </Col>

                    <Col sm={4} className="details_list">
                      <ul>
                        <li>
                          <span class="ti-mobile"></span>
                          {' ' + phone}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row className="btn_links">
                    <Col sm={6}>
                      <NavLink to="">
                        <Button className="linkedin_btn">
                          <span class="ti-linkedin"> </span>
                        </Button>
                      </NavLink>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <br></br>

          <MDBCard className="company_details_Card">
            <MDBCardHeader>Company Description</MDBCardHeader>
            <MDBCardBody>
              <MDBTypography blockquote className="mb-0">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <footer className="blockquote-footer">
                  <cite title="Source Title">H.R</cite>
                </footer>
              </MDBTypography>
            </MDBCardBody>
          </MDBCard>
          <br></br>
          <MDBCard className="company_req_Card">
            <MDBCardHeader>Skills Required</MDBCardHeader>
            <MDBCardBody>
              <MDBTypography blockquote className="mb-0">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <footer className="blockquote-footer">
                  <cite title="Source Title">H.R</cite>
                </footer>
              </MDBTypography>
            </MDBCardBody>
          </MDBCard>
        </Container>
      </Container>
    </div>
  );
}

export default RecruiterProfileView;
