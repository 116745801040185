import React from 'react';
import RecruiterNavbar from './RecruiterNavbar';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Navigate, NavLink } from 'react-router-dom';
import ShareCandidate from '../HomePage/shareCandidate';
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBTypography,
} from 'mdb-react-ui-kit';
import './ViewJob.css';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Image } from '@aws-amplify/ui-react';
import { AiOutlineCloseSquare } from '@react-icons/all-files/ai/AiOutlineCloseSquare.esm';
import { TbHandStop } from '@react-icons/all-files/';

function ViewJob(props) {
  const [jobsPendingList, setJobsPendingList] = React.useState([]);
  const [jobsCompletedList, setJobsCompletedList] = React.useState([]);
  const [jobsRejectedList, setJobsRejectedList] = React.useState([]);
  const [jobsAcceptedList, setJobsAcceptedList] = React.useState([]);
  const [jobsDeclinedList, setJobsDeclinedList] = React.useState([]);
  var jobsPending = [];
  var jobsCompleted = [];
  var jobsRejected = [];
  var jobAccepted = [];
  var jobDeclined = [];
  const [picUrl, setPicUrl] = React.useState('');

  return (
    <div>
      <RecruiterNavbar></RecruiterNavbar>
      <Container fluid>
        <Row>
          <Col className="view_jobs_txt">
            <h2>Job Details</h2>
          </Col>
        </Row>
        <Container className="job_details_container">
          <Row>
            <Col md={4}>
              <ul>
                <li>Job Title:</li>
                <li>Description:</li>
                <li>Required Skills:</li>
              </ul>
            </Col>
            <Col md={4}>
              <ul>
                {' '}
                <li>Work location:</li>
                <li>Salary-range:</li>
                <li>Years of Experience:</li>
              </ul>
            </Col>

            <Col md={3} className={'job_details_col_3'}>
              <Row>
                <Col>
                  {' '}
                  <button
                    className="slide_decline close_job_btn  "
                    variant="primary"
                  >
                    Close-Job{' '}
                  </button>
                </Col>
                <Col>
                  <button className="slide hold_job_btn" variant="primary">
                    Hold-Job
                  </button>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <NavLink to={'/recruiter/schedule'}>
                    <button
                      Nav
                      className=" schedule_jobpage_btn "
                      variant="primary"
                    >
                      Schedule
                    </button>
                  </NavLink>
                </Col>
              </Row>
            </Col>
            <Col md={1} className={'job_details_col_4'}>
              <NavLink to="/recruiter/createjob">
                <li className="job_edit_icon">
                  <span class="ti-pencil-alt"></span>
                </li>
              </NavLink>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey="pending"
                id="justify-tab-example"
                className="mb-3 action_bar"
                justify
              >
                <Tab eventKey="pending" title="Pending">
                  {jobsPendingList !== undefined && jobsPendingList.length ? (
                    jobsPendingList.map((job) => {
                      return (
                        <div>
                          <Card className="card_content">
                            <Card.Body className="card_body_content">
                              <Row>
                                <Col className="company_logo" md={3}>
                                  <Image
                                    className="profile_pic"
                                    src={job.CandidateProfile}
                                    width="300px"
                                    height="300px"
                                  />
                                </Col>
                                <Col className="company_details" md={7}>
                                  <ul>
                                    <li className="card_company_name">
                                      {job.CandidateName}
                                    </li>
                                    <li className="card_company_email_name">
                                      <span class="ti-email">
                                        {' '}
                                        {job.CandidateEmail}
                                      </span>
                                    </li>
                                    <li className="card_company_Phone_no">
                                      <span class="ti-mobile">
                                        {' '}
                                        {job.CandidatePhone}
                                      </span>
                                    </li>
                                    <li className="card_company_Phone_no">
                                      <span class="ti-time">
                                        {' '}
                                        {job.CreatedTimeStamp}
                                      </span>
                                    </li>
                                  </ul>
                                </Col>
                                <Col className="respond_btns" md={2}>
                                  <Row>
                                    {' '}
                                    <Col>
                                      <ShareCandidate
                                        label={'New Job post '}
                                        text={'Hi'}
                                        title={'Check out new msg'}
                                        urlID={
                                          (props = {
                                            jobInfo: job.InterviewID,
                                          })
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      {/* <Button onClick={(e)=>viewOnClick(job.JobSeekerUID)} className='pendning_card_View_btn' variant="primary">VIEW</Button> */}
                                      <NavLink
                                        to="/recruiter/candidate_profile"
                                        state={{ CandidateDetails: job }}
                                      >
                                        {' '}
                                        <button
                                          className="slide btn-size"
                                          variant="primary"
                                        >
                                          View
                                        </button>
                                      </NavLink>
                                    </Col>
                                    {/* <Col>
                                    <NavLink
                                      to="/recruiter/schedule/edit"
                                      state={{ CandidateDetails: job }}
                                    >
                                      <button
                                        className="slide btn-size edit"
                                        variant="primary"
                                      >
                                        Edit
                                      </button>
                                    </NavLink>
                                  </Col> */}
                                  </Row>
                                  <Row>
                                    <Col></Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <Card className="card_content">
                        <Card.Body className="card_body_content">
                          <h4>No Job post available</h4>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="completed" title="Completed">
                  {jobsCompletedList !== undefined &&
                  jobsCompletedList.length ? (
                    jobsCompletedList.map((job) => {
                      return (
                        <div>
                          <Card className="card_content">
                            <Card.Body className="card_body_content">
                              <Row>
                                <Col className="company_details" md={10}>
                                  <ul>
                                    <li className="card_company_name">
                                      {job.CandidateName}
                                    </li>
                                    <li className="card_company_email_name">
                                      <span class="ti-email">
                                        {' '}
                                        {job.CandidateEmail}
                                      </span>
                                    </li>
                                    <li className="card_company_Phone_no">
                                      <span class="ti-mobile">
                                        {job.CandidatePhone}
                                      </span>
                                    </li>
                                    <li className="card_company_Phone_no">
                                      <span class="ti-time">
                                        {' '}
                                        {job.CreatedTimeStamp}
                                      </span>
                                    </li>
                                  </ul>
                                </Col>
                                <Col className="respond_btns" md={2}>
                                  <Row>
                                    <Col>
                                      {' '}
                                      <ShareCandidate
                                        label={'New Job post '}
                                        text={'Hi'}
                                        title={'Check out new msg'}
                                        urlID={
                                          (props = {
                                            jobInfo: job.InterviewID,
                                          })
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <NavLink
                                        to="/recruiter/candidate_profile"
                                        state={{ CandidateDetails: job }}
                                      >
                                        {' '}
                                        <button
                                          className="slide btn-size"
                                          variant="primary"
                                        >
                                          View
                                        </button>
                                      </NavLink>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      {/* <button
                                      className="slide btn-size"
                                      variant="primary"
                                      onClick={(e) =>
                                        sharelinkValidate(
                                          job.JobSeekerName,
                                          job.CompanyName
                                        )
                                      }
                                    >
                                      Share
                                    </button> */}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>{' '}
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <Card className="card_content">
                        <Card.Body className="card_body_content">
                          <h4>No job post available</h4>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </Tab>

                <Tab eventKey="accepted" title="Selected">
                  {jobsAcceptedList !== undefined && jobsAcceptedList.length ? (
                    jobsAcceptedList.map((job) => {
                      return (
                        <div>
                          <Card className="card_content">
                            <Card.Body className="card_body_content">
                              <Row>
                                <Col className="company_details" md={10}>
                                  <ul>
                                    <li className="card_company_name">
                                      {job.CandidateName}
                                    </li>
                                    <li className="card_company_email_name">
                                      <span class="ti-email">
                                        {job.CandidateEmail}
                                      </span>
                                    </li>
                                    <li className="card_company_Phone_no">
                                      <span class="ti-mobile">
                                        {job.CandidatePhone}
                                      </span>
                                    </li>
                                  </ul>
                                </Col>
                                <Col className="respond_btns" md={2}>
                                  <Row>
                                    <Col>
                                      <button
                                        className=" slide btn-size"
                                        variant="primary"
                                      >
                                        View
                                      </button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col></Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <Card className="card_content">
                        <Card.Body className="card_body_content">
                          <h4>No Job post available</h4>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="rejected" title="Rejected">
                  {jobsRejectedList !== undefined && jobsRejectedList.length ? (
                    jobsRejectedList.map((job) => {
                      return (
                        <div>
                          <Card className="card_content">
                            <Card.Body className="card_body_content">
                              <Row>
                                <Col className="company_details" md={10}>
                                  <ul>
                                    <li className="card_company_name">
                                      {job.CandidateName}
                                    </li>
                                    <li className="card_company_email_name">
                                      <span class="ti-email">
                                        {' '}
                                        {job.CandidateEmail}
                                      </span>
                                    </li>
                                    <li className="card_company_Phone_no">
                                      <span class="ti-mobile">
                                        {job.CandidatePhone}
                                      </span>
                                    </li>
                                  </ul>
                                </Col>
                                <Col className="respond_btns" md={2}>
                                  <Row>
                                    <Col>
                                      <button
                                        className=" slide btn-size"
                                        variant="primary"
                                      >
                                        View
                                      </button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <button
                                        className=" slide btn-size"
                                        variant="primary"
                                      >
                                        Dismiss
                                      </button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <Card className="card_content">
                        <Card.Body className="card_body_content">
                          <h4>No Job post available</h4>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="Declined" title="Declined">
                  {jobsDeclinedList !== undefined && jobsDeclinedList.length ? (
                    jobsDeclinedList.map((job) => {
                      return (
                        <div>
                          <Card className="card_content">
                            <Card.Body className="card_body_content">
                              <Row>
                                <Col className="company_details" md={10}>
                                  <ul>
                                    <li className="card_company_name">
                                      {job.CandidateName}
                                    </li>
                                    <li className="card_company_email_name">
                                      <span class="ti-email">
                                        {' '}
                                        {job.CandidateEmail}
                                      </span>
                                    </li>
                                    <li className="card_company_Phone_no">
                                      <span class="ti-mobile">
                                        {job.CandidatePhone}
                                      </span>
                                    </li>
                                  </ul>
                                </Col>
                                <Col className="respond_btns" md={2}>
                                  <Row>
                                    <Col>
                                      <button
                                        className=" slide btn-size"
                                        variant="primary"
                                      >
                                        View
                                      </button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <button
                                        className=" slide btn-size"
                                        variant="primary"
                                      >
                                        Dismiss
                                      </button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <Card className="card_content">
                        <Card.Body className="card_body_content">
                          <h4>No Job post available</h4>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default ViewJob;
