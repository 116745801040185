import { initializeApp, getApp} from "firebase/app";
import { getStorage,ref,uploadBytesResumable, getDownloadURL} from "firebase/storage";
import firebaseConfig from "./firebaseConfig";
// import {ref,set,get,update,remove,child} from "firebase/storage"
// Get a non-default Storage bucket

const app = initializeApp ({
    apiKey: "AIzaSyAVNSrAmvmeVHRyoBONDJe6DD3US07ii8Y",
    authDomain: "jobble-d82ad.firebaseapp.com",
    databaseURL: "https://jobble-d82ad-default-rtdb.firebaseio.com",
    projectId: "jobble-d82ad",
    storageBucket: "jobble-d82ad.appspot.com",
    messagingSenderId: "279688560251",
    appId: "1:279688560251:web:b5d94d1b98802f8d81acde"
});
const firebaseApp = getApp();
const firebaseConfiguration = firebaseConfig();
// Firebase storage reference
const storage = getStorage(app);
// const storage = firebaseConfig()

// Create a child reference
// const videosRef = ref(firebaseConfiguration, 'video');
// imagesRef now points to 'images'

// Child references can also take paths delimited by '/'
function upload(type,file){
  
    return new Promise(async (resolve , reject)=>{try{ 
        var video_url;
    // console.log(file);
    const storageRef = ref(storage, `/${type}/${file.name}`);
 
        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file)
 
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
 
                // update progress
                // setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    video_url=url
                    resolve(video_url);
                });
                return video_url;
            }
        )
        }
        catch(err){
            reject(err)
            alert(err)
        }
});
}
export default upload;