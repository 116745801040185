import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { Card } from '@mui/material';
import GIF from '../../assets/images/Collab_register.gif';
import { toast } from 'react-toastify';
import './Register.css';
import AwsAuth from '../Service/AWS_Auth/authenticationServices';
import Navbar from '../HomePage/Navbar';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Registration() {
  const [email, setemail] = useState('');
  const [pass, setpass] = useState('');
  const [conpass, setconpass] = useState('');
  const [data, setdata] = useState([]);
  const [code, setcode] = useState('');
  const [pic, setpic] = useState();
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  let url = new URLSearchParams(useLocation().search).get('re');
  let re = new URLSearchParams(useLocation().search).get('per');
  if (url) {
    url = url.replace(/\s/g, '+');
  }
  if (re) {
    re = re.replace(/\s/g, '+');
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    var emaildata = [];
    if (data.length === 0) {
      emaildata = [];
    } else {
      data.map((item) => emaildata.push(item.email));
    }

    if (
      email === '' ||
      pass === '' ||
      // phone === "" ||
      // pic === "" ||
      conpass === ''
    ) {
      alert('fields can not Empty...!!');
    } else if (!pattern.test(email)) {
      alert('email is not valid...!!');
    } else if (emaildata.includes(email)) {
      alert('email is already exist..!!');
    } else if (!(pass.length >= 8)) {
      alert('Password must be more then or equal to 8 character');
    } else if (pass !== conpass) {
      alert("password Does't Match try again..");
    } else {
      AwsAuth.signUp(email, pass).then((value) => {
        if (value == 'true') {
          setConfirm(true);
          localStorage.setItem('emailTemp', email);
          navigate(
            (url != null || url != undefined) && (re != null || re != undefined)
              ? `/verification?re=${url}+&per=${re}`
              : `/verification?re=${url}`,
            { state: { email: email } }
          );
        } else {
          toast.error(value.toString());
        }
      });
      //     createUserWithEmailAndPassword(auth, email, pass)
      //     .then((userCredential) => {
      //       // Signed in
      //       const user = userCredential.user.accessToken;
      //       localStorage.setItem("user",user)
      //       toast.success("Registration Successfully");
      //       var role =localStorage.getItem("role")
      //       if(role == "Employee"){
      //       navigate("/profile")}
      //       else{
      //         navigate("/recprof")
      //       }

      //       // ...
      //   })
      // .catch((error) => {
      //   const errorCode = error.code;
      //   const errorMessage = error.message;
      //   toast.error(errorMessage);
      //   // ..
      // });
    }
  };

  return (
    // <GoogleOAuthProvider clientId="279688560251-012f628nhk4mkgfhlk719bvtcifuak6s.apps.googleusercontent.com">
    <>
      <Navbar></Navbar>
      <Grid
        container
        component="main"
        sx={{ height: '100vh', boxShadow: 'none' }}
      >
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6} className="set_grid_bg_color">
          <div className="register_title_txt">
            <Card className="login-card">
              {/*<Typography
                style={{ marginBlock: "50px" }}
                variant="h5"
                className="text-center"
              >
                {/* <img
                  src="https://www.webbrainstechnologies.com/wp-content/uploads/2016/02/logo-3.png"
                  height={"50px"}
                  width={"100px"}
                  alt=""
                  srcset=""
                /> 
              </Typography>*/}
              {/* <h3 className="m-4 text-center title">Hi, Welcome</h3> */}
              {/* <CardMedia
                className="login-card-img"
                width="100%"
                component="img"
                height="100%"
                image="https://minimal-kit-react.vercel.app/static/illustrations/illustration_login.png"
                alt="green iguana"
              /> */}{' '}
              <img className="register_GIF" src={GIF} width="100%" alt="" />
            </Card>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          elevation={6}
          square
          className="set_grid_bg_color"
        >
          <Box
            className="registration-box"
            sx={{
              my: 4,
              mx: 4,
              px: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#6366f1' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>

            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    className="login_text_field"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    onChange={(e) => setemail(e.target.value)}
                    autoComplete="email"
                    color={'primary'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="login_text_field"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    onChange={(e) => setpass(e.target.value)}
                    id="password"
                    color={'primary'}
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="login_text_field"
                    required
                    fullWidth
                    name="conpass"
                    onChange={(e) => setconpass(e.target.value)}
                    label="Confirm Password"
                    type="password"
                    color={'primary'}
                    id="conpass"
                  />
                </Grid>
              </Grid>
              <Grid>
                <Grid item md={12} className="termsandpolicy_txt">
                  <span className="termsandpolicy_txt_margin">
                    By clicking the signup button you agree to the{' '}
                    <a href="/termsandconditions" target="_blank">
                      Terms & Condition
                    </a>{' '}
                    &{' '}
                    <a href="/privacypolicy" target="_blank">
                      Privacy Policy
                    </a>{' '}
                    of InterviewTape.com
                  </span>
                </Grid>
              </Grid>
              <br />

              <Button
                className="register_btn"
                type="submit"
                onClick={(e) => {
                  handleSubmit(e);
                }}
                fullWidth
                sx={{ mt: 3, mb: 2 }}
              >
                SIGN UP
              </Button>
              {/* <h5>or</h5>
              
              <Grid className='register_box'>
              <p className="signin_txt">Sign-in using </p>
                <GoogleLogin
              size="large"
              type="icon"
              theme="filled_black"
              shape="circle"
              logo_alignment="center"
                onSuccess={credentialResponse => {
                  var user = jwtDecode(credentialResponse.credential)
                  localStorage.setItem("user",credentialResponse.credential);
                  var role =localStorage.getItem("role")
                  if(role == "Employee"){
                  navigate("/profile")}
                  else{
                    navigate("/recprof")
                  }
                }}
                useOneTap
                onError={() => {
                  console.log('Login Failed');
                }}
              />
              </Grid>
              
              <br></br>
              <Grid container>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Link
                    className="register_login_txt"
                    to={"/"}
                    variant="body2"
                  >
                    {"Already have an account? login"}
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
    // </GoogleOAuthProvider>
  );
}
