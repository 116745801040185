/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import './RecruiterProfile.css';
import RecruiterNavbar from './RecruiterNavbar';
import profile from '../../assets/images/profile.png';
import { Container, Row, Col, FormControl } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import firebaseConfig from '../Firebase/firebaseConfig';
import { ref, set, get, update, remove, child } from 'firebase/database';
import firebaseStorage from '../Firebase/firebaseStorage';
import { useEffect } from 'react';
import { useRef } from 'react';
import { async } from '@firebase/util';
import { useNavigate } from 'react-router-dom';
import './RecruiterHome.scss';
import AwsAmplify from '../Service/AWS_Auth/RecruiterDataService';
import CompanyAmplify from '../Service/AWS_Auth/CompanyDataService';
import { Recruiter, Company } from '../../models';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import AWS_Storage from '../Service/AWS_Auth/VideoStorage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import VideoStorage from '../Service/AWS_Auth/VideoStorage';
import { toast } from 'react-toastify';
import { Amplify } from 'aws-amplify';
import { Image } from '@aws-amplify/ui-react';
import awsconfig from '../../aws-exports';
import { Storage } from '@aws-amplify/storage';
export default function RecruiterProfile() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [userDetails, setUserDetails] = React.useState();
  const [companyName, setCompanyName] = React.useState(null);
  const [companyId, setCompanyId] = React.useState(null);
  const [linkedin, setLinkedin] = React.useState();
  const [UserRole, setUserRole] = React.useState('');
  const [profilePic, setProfilePic] = React.useState('');
  const [allCompany, setAllCompany] = React.useState('');
  const [selectedCompany, setSelectedCompany] = React.useState();
  const [picUrl, setPicUrl] = React.useState('');
  const [picError,setPicError] = React.useState(false);
  var userDecoded;
  const [email, setEmail] = React.useState('');
  const [id, setID] = React.useState();
  const inputRef = useRef(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(phone);
  const dataUpload = async () => {
    try{
    if (picUrl=='') {
      setPicError(true);
      return;
    }
    var data = new Recruiter({
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Email: email,
      Linkedin: linkedin,
      CompanyName: companyName,
      recruiterCompanyId: companyId,
      Profilepic: profilePic,
    });
    console.log('data', data);
    console.log('id', id);

    if (id == undefined) {
      await AwsAmplify.save(data).then((user) => {
        if (user == 'success') {
          navigate('/recruiter/profile');
        }
      }).catch(e=>{
        toast.error(e.toString())
      });
    } else {
      await AwsAmplify.update(id, data).then((user) => {
        if (user == 'success') {
          navigate('/recruiter/profile');
        }
      }).catch(e=>{
        toast.error(e.toString())
      });
    }
    // let userValue =userDetails!=null ? await RecruiterProfileDB.updateProfile(UserRole,firstName,lastName,companyName,phone,profilePic,user):await RecruiterProfileDB.create(data,(user.sub))
    // if(userValue==="success"){
    //   navigate("/recruiterView");
    // }
  }
  catch(e){
    toast.error(e.toString());
  }
  };
  const getAllCompany = async () => {
    await CompanyAmplify.getALL().then((allCompany) => {
      let company = [];
      let i = 0;
      allCompany.forEach((child) => {
        company.push({
          id: i,
          companyId: child.id,
          name: child.Name,
          Phone: child.Phone,
          Website: child.Website,
          Email: child.Email,
        });
        i++;
      });
      setAllCompany(company);
    }).catch(e=>{
      toast.error(e.toString())
    });
  };
  useEffect(async () => {
    let email = localStorage.getItem('email');
    setEmail(email);
    setUserRole(localStorage.getItem('role'));
    await AwsAmplify.getRecruiter(email).then(async (userValue) => {
      setUserDetails(userValue);
      getAllCompany();
      if (userValue != null && userValue != undefined) {
        setID(userValue.id);
        setFirstName(userValue.FirstName);
        setLastName(userValue.LastName);
        setCompanyName(userValue.CompanyName);
        setPhone(userValue.Phone);
        setProfilePic(userValue.Profilepic);
        setLinkedin(userValue.Linkedin);
        localStorage.setItem('recruiterId', userValue.id);
        localStorage.setItem('fname', userValue.FirstName);
        localStorage.setItem('lname', userValue.LastName);
        await Storage.get(userValue.Profilepic).then((pic) => {
          localStorage.setItem('profilePic', pic);
        });
        localStorage.setItem('companyId',userValue.recruiterCompanyId)
        if (userValue.Profilepic != null) {
          await Storage.get(userValue.Profilepic).then((val) => {
            setPicUrl(val);
            setPicError(false);
          });
        }
      }
    }).catch(e=>{
      toast.error(e.toString())
    });

    let defaultValues = {};
    reset({ ...defaultValues });
  }, []);

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };
  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    // 👇️ reset file input
    event.target.value = null;

    // 👇️ is now empty

    // 👇️ can still access file object here
    let pictureUpload = await AWS_Storage.ProfilePic(fileObj).then(
      async (val) => {
        setProfilePic(val);
        await Storage.get(val).then((val) => {
          setPicUrl(val);
        });
      }
    );
  };
  const handleOnSelect = (item) => {
    console.log(item);
  };
  const formatResult = (item) => {
    console.log(item);
    setCompanyName(item.name);
    setCompanyId(item.companyId);

    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>
          {' '}
          {item.name}
        </span>
      </>
    );
  };
  return (
    <div>
      <RecruiterNavbar />
      <Container fluid>
        <Row className="profile_editInfo_text">
          <Col>
            <h2> Edit Company Details</h2>
          </Col>
        </Row>
        <br></br>
      </Container>
      <Container className="profile_info_page">
        <Row>
          <Col className="edit_info" md={3}>
            <Row>
              <Col className="profile_pic_card" md={12}>
                <Image
                  className="company_profile"
                  src={picUrl}
                  width="200px"
                  height="200px"
                />
                {picError && <p className="text-danger">{"Upload valid profile image"}</p>}
              </Col>

              <Col md={12}>
                {/* <button className="slide profile_pic_btn">Take Photo</button> */}
                <div>
                  <input
                    style={{ display: 'none' }}
                    ref={inputRef}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <button
                    className=" slide profile_pic_btn_html"
                    onClick={handleClick}
                  >
                    Choose from Gallery
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="profile_details" md={9}>
            {/* <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}></Form> */}
            <Form noValidate onSubmit={handleSubmit(dataUpload)}>
              <Row className="mb-3">
                <h5>Personal Info :</h5>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>First name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    {...register('firstName', {
                      required: true,
                      maxLength: 20,
                    })}
                    aria-invalid={errors.firstName ? 'true' : 'false'}
                    onChange={(ev) => setFirstName(ev.target.value)}
                    value={firstName}
                    renderValue={(value) => `${value}`}
                  />
                  {errors.firstName?.type === 'required' && (
                    <p className="error_msg">Please check the First Name</p>
                  )}
                  {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Last name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    {...register('lastName', { required: true, maxLength: 20 })}
                    aria-invalid={errors.lastName ? 'true' : 'false'}
                    onChange={(ev) => setLastName(ev.target.value)}
                    value={lastName}
                    renderValue={(value) => `${value}`}
                  />
                  {errors.lastName?.type === 'required' && (
                    <p className="error_msg">Please check the Last Name</p>
                  )}
                  {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationCustomUsername"
                >
                  <Form.Label>Phone Number *</Form.Label>

                  <PhoneInput
                    country="IN"
                    value={phone}
                    // {...register("phone", { required: true})}
                    // aria-invalid={errors.phone ? "true" : "false"}
                    // required
                    onChange={(ev) => setPhone(ev)}
                  />
                  {/* <Form.Control
              type="number"
              placeholder="+91"
              aria-describedby="inputGroupPrepend"
             {...register("phone", { required: true, maxLength:10, minLength:10 })}
              aria-invalid={errors.phone ? "true" : "false"} 
              required
              onChange={(ev)=> setPhone(ev.target.value)}
              defaultValue={phone}
              value={phone}
              renderValue={(value) => `${value}`}
            /> */}
                  {errors.phone && (
                    <p className="error_msg">Please check the Phone Number</p>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please enter your phone number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Company name *</Form.Label>
                  {id == undefined ? (
                    <ReactSearchAutocomplete
                      items={allCompany}
                      autoFocus
                      // {...register("companyName", { required: true, maxLength: 50 })}
                      // aria-invalid={errors.companyName ? "true" : "false"}
                      formatResult={formatResult}
                      handleOnSelect={handleOnSelect}
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      readOnly
                      placeholder="Company name"
                      value={companyName}
                      renderValue={(value) => `${value}`}
                    />
                  )}
                  {/* <Form.Control
            type="text"
            placeholder="Company name"
            {...register("companyName", { required: true, maxLength: 20 })}
            aria-invalid={errors.companyName ? "true" : "false"}
            onChange={(ev)=> setCompanyName(ev.target.value)}
            value={companyName}
            renderValue={(value) => `${value}`}
          /> */}
                  {/* {errors.companyName?.type === 'required'&& companyName==null && <p className="error_msg">Please check the Company Name</p>} */}
                  {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Linkedin *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Linkedin"
                    // {...register("Linkedin", { required: true, maxLength: 100 })}
                    // aria-invalid={errors.Linkedin ? "true" : "false"}
                    onChange={(ev) => setLinkedin(ev.target.value)}
                    value={linkedin}
                    renderValue={(value) => `${value}`}
                  />
                  {/* {errors.Linkedin?.type === 'required' && <p className="error_msg">Please enter your linkedin</p>} */}
                  {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                </Form.Group>
              </Row>

              <Row>
                <Col className="save_btn" sm={true}>
                  <button className="slide continue_btn" type="submit">
                    Save & Continue
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
