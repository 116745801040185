import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import { Container } from '@mui/system';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import UpdateIcon from '@mui/icons-material/Update';
import RestoreIcon from '@mui/icons-material/Restore';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import SchoolIcon from '@mui/icons-material/School';
import styles from './NewCandidateProfile.module.css';
import EditIcon from '@mui/icons-material/Edit';
import { NavLink } from 'react-router-dom';
import ShieldIcon from '@mui/icons-material/Shield';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DownloadIcon from '@mui/icons-material/Download';

function NewCandidateProfile() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <CssBaseline />{' '}
      <Container className={styles.full_card_body} disableGutters={true} fixed>
        <Container className={styles.card_top_bar}>
          <Grid container spacing={0}>
            <Grid className={styles.top_bar_item_1} item xs={3} md={3}>
              (Name)
            </Grid>
            <Grid className={styles.top_bar_item_2} item xs={3} md={3}></Grid>
            <Grid item xs={3} md={3}></Grid>
            <Grid className={styles.top_bar_item_3} item xs={3} md={3}>
              (Status)
            </Grid>
          </Grid>
        </Container>
        <Container className={styles.card_content} maxWidth="lg">
          <Grid container spacing={0}>
            <Grid
              className={styles.content_area_col_1}
              item
              xs={12}
              md={3}
              lg={3}
            >
              <Avatar
                alt="J"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 150, height: 150 }}
              />
            </Grid>
            <Grid
              className={styles.content_area_col_2}
              item
              xs={12}
              md={4}
              lg={4}
            >
              <ul>
                <li>
                  {' '}
                  <EmailIcon />( Email ID)
                </li>
                <li>
                  <PhoneAndroidIcon /> (Phone No)
                </li>
                <li>
                  <ShieldIcon /> (Years of Experience)
                </li>
                <li>
                  <LocationOnIcon /> (Location)
                </li>
              </ul>
            </Grid>
            <Grid
              className={styles.content_area_col_3}
              item
              xs={12}
              md={4}
              lg={4}
            >
              <ul>
                <li>
                  <WorkIcon /> (Job Title)
                </li>
                <li>
                  <BusinessIcon /> (Company Name)
                </li>
                <li>
                  <UpdateIcon /> (Scheduled time)
                </li>
                <li>
                  <RestoreIcon /> (Completed time)
                </li>
                <li></li>
              </ul>
            </Grid>
            <Grid
              className={styles.content_area_col_4}
              item
              xs={12}
              md={1}
              lg={1}
              spacing={1}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Button className={styles.edit_icon_btn}>
                    <EditIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Button className={styles.share_icon_btn}>
                    {' '}
                    <ShareIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Button className={styles.share_icon_btn}>
                    {' '}
                    <DownloadIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container className={styles.card_bottom_bar} maxWidth="lg">
          <Grid container>
            <Grid className={styles.bottom_card_col_1} item xs={8}>
              (<SchoolIcon />) Skillset :
              <span className={styles.skill_tags}>
                {' '}
                "Skill_1 ,Skill_2, Skill_3, Skill_4, Skill_5"
              </span>
            </Grid>
            <Grid className={styles.bottom_card_col_2} item xs={4}>
              <NavLink to="/"></NavLink>
              {/* <Button className={styles.share_icon_btn}>
                {' '}
                <ShareIcon />
              </Button> */}
              {/* <Button className={styles.flag_icon_btn}>
                <FlagIcon />
              </Button> */}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

export default NewCandidateProfile;
