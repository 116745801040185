import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import styles from './Feedback.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import ThumbsUpDownRoundedIcon from '@mui/icons-material/ThumbsUpDownRounded';
import { Grid, TextareaAutosize } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useNavigate } from 'react-router-dom';
import FeedbackService from '../Service/AWS_Auth/FeedbackService';
import { FeedbackStatus, InterviewFeedback } from '../../models';
import { toast } from 'react-toastify';

function Feedback(props) {
  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState('normal');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
  const [thumbsOpinion, setThumbsOpinion] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [interviewDetails, setInterviewDetails] = React.useState({});
  const [feedbackData, setFeedBackData] = React.useState({});
  const navigate = useNavigate();
  const handleThumbsOpinion = (event, newThumbsOpinion) => {
    setThumbsOpinion(newThumbsOpinion);

    if (localStorage.getItem('AuthStatus') == 'true') {
      console.log(
        newThumbsOpinion,
        'newThumbsOpinion',
        localStorage.getItem('AuthStatus')
      );
    } else {
      localStorage.setItem('role', 'Recruiter');
      toast.info('SignIn to give your feedback');
      navigate(
        `/login?re=${document.location.pathname}` +
          `${document.location.search}`
      );
    }
  };
  const fetchFeedback = async (data) => {
    await FeedbackService.getFeedback(data.recruiterID, data.id).then(
      (feedback) => {
        if (feedback.length != 0) {
          setFeedBackData(feedback[0]);
          if (feedback[0].FeedbackStatus == FeedbackStatus.NEUTRAL) {
            setThumbsOpinion('maybe');
          }
          if (feedback[0].FeedbackStatus == FeedbackStatus.UP) {
            setThumbsOpinion('yes');
          }
          if (feedback[0].FeedbackStatus == FeedbackStatus.DOWN) {
            setThumbsOpinion('no');
          }
          setComment(feedback[0].Comments);
        }
      }
    );
  };

  React.useEffect(async () => {
    console.log('userValue', props.data);
    setInterviewDetails(props.data);
    await fetchFeedback(props.data);
    // await fetchResponse(props.data);
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  // const buttonSx = {
  //   ...(success && {
  //     color: 'white',
  //     bgcolor: green[500],
  //     '&:hover': {
  //       bgcolor: green[700],
  //     },
  //   }),
  // };

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(async () => {
        if (feedbackData == null || feedbackData == undefined) {
          let data = new InterviewFeedback({
            Comments: comment,
            RecruiterName: interviewDetails.RecruiterName,
            RecruiterEmail: interviewDetails.RecruiterEmail,
            FeedbackStatus:
              thumbsOpinion === 'yes'
                ? FeedbackStatus.UP
                : thumbsOpinion === 'no'
                ? FeedbackStatus.DOWN
                : FeedbackStatus.NEUTRAL,
            Recruiter: interviewDetails.recruiterID,
            Interview: interviewDetails.id,
            interviewFeedbackRecruiterId: interviewDetails.recruiterID,
            interviewFeedbackInterviewId: interviewDetails.id,
          });
          await FeedbackService.save(data);
        } else {
          let data = new InterviewFeedback({
            Comments: comment,
            FeedbackStatus:
              thumbsOpinion === 'yes'
                ? FeedbackStatus.UP
                : thumbsOpinion === 'no'
                ? FeedbackStatus.DOWN
                : FeedbackStatus.NEUTRAL,
          });
          await FeedbackService.updateFeedback(feedbackData.id, data);
        }

        setSuccess(true);
        setLoading(false);
        window.location.reload();
      }, 2000);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <div className={styles.feedback_title_txt}>Feedback</div>
          {localStorage.getItem('AuthStatus') != 'true' && (
            <div className={styles.feedback_signin_txt}>
              Please{' '}
              <a
                href={
                  `/login?re=${document.location.pathname}` +
                  `${document.location.search}`
                }
              >
                Sign in
              </a>{' '}
              to give feedback!
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <ToggleButtonGroup
            className={styles.toggle_btn_color}
            color="primary"
            size="large"
            value={thumbsOpinion}
            exclusive
            onChange={handleThumbsOpinion}
            aria-label="text alignment"
            defaultValue={thumbsOpinion}
          >
            <ToggleButton
              className={styles.toggle_btn_yes}
              value="yes"
              aria-label="left aligned"
              sx={{ border: 1 }}
              selected={thumbsOpinion === 'yes'}
            >
              <ThumbUpRoundedIcon />
            </ToggleButton>
            <ToggleButton
              className={styles.toggle_btn_maybe}
              value="maybe"
              aria-label="centered"
              sx={{ border: 1 }}
              selected={thumbsOpinion === 'maybe'}
            >
              <ThumbsUpDownRoundedIcon />
            </ToggleButton>
            <ToggleButton
              className={styles.toggle_btn_no}
              value="no"
              aria-label="right aligned"
              sx={{ border: 1 }}
              selected={thumbsOpinion === 'no'}
            >
              <ThumbDownRoundedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <p className={styles.comment_txt}>Comment :</p>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <FormControl className={styles.feedback_form} fullWidth>
            {/* <FormLabel className={styles.comment_title_txt}>
              Your comment
            </FormLabel> */}
            <br />
            <TextareaAutosize
              className={styles.feedback_textarea}
              maxRows={5}
              defaultValue={comment}
              placeholder="Type Your Comment here........."
              onChange={(e) => {
                if (localStorage.getItem('AuthStatus') == 'true') {
                  console.log(localStorage.getItem('AuthStatus'));
                  setComment(e.target.value);
                } else {
                  localStorage.setItem('role', 'Recruiter');
                  console.log(
                    `${document.location.pathname}` +
                      `${document.location.search}`
                  );
                  toast.info('SignIn to give your feedback');
                  navigate(
                    `/login?re=${document.location.pathname}` +
                      `${document.location.search}`
                  );
                }
              }}
            />
            <Grid container className={styles.comment_btn_row}>
              <Grid item xs={12}>
                {' '}
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    className={styles.comments_submit_btn}
                    variant="contained"
                    // sx={buttonSx}
                    disabled={loading}
                    onClick={handleButtonClick}
                  >
                    Comment
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={50}
                      sx={{
                        color: '#6366f1',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>

            {/* <Textarea
              placeholder="Type your feedback comments here…"
              minRows={3}
              onChange={(e) => {
                if (localStorage.getItem('AuthStatus') == 'true') {
                  console.log(localStorage.getItem('AuthStatus'));
                  setComment(e.target.value);
                } else {
                  localStorage.setItem('role', 'Recruiter');
                  console.log(
                    `${document.location.pathname}` +
                      `${document.location.search}`
                  );
                  toast.info('SignIn to give your feedback');
                  navigate(
                    `/login?re=${document.location.pathname}` +
                      `${document.location.search}`
                  );
                }
              }}
              defaultValue={comment}
              endDecorator={
                <Box
                  sx={{
                    display: 'flex',
                    gap: 'var(--Textarea-paddingBlock)',
                    pt: 'var(--Textarea-paddingBlock)',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    flex: 'auto',
                  }}
                >
                  <Box sx={{ m: 1, position: 'relative' }}>
                    <Button
                      className={styles.comments_submit_btn}
                      variant="contained"
                      // sx={buttonSx}
                      disabled={loading}
                      onClick={handleButtonClick}
                    >
                      Comment
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Box>
                </Box>
              }
              sx={{
                minWidth: 300,
                fontWeight,
                fontStyle: italic ? 'italic' : 'initial',
              }}
            /> */}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default Feedback;
