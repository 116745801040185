import { DataStore } from '@aws-amplify/datastore';
import {InterviewFeedback } from '../../../models'
import { Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
Amplify.configure(awsconfig);

class FeedbackAmplify{

async save (data){
  return new Promise(async (resolve, reject) => {
  try{
      await DataStore.save(
        data
      ).then(user=>{
        console.log(user)
        resolve("success");
      })
    }
  catch(e){
    console.log("InterviewFeedback save", e)
    reject(e);
  }
});
}

async  updateFeedback(id, data) {
  return new Promise(async (resolve, reject) => {
  try{
  await DataStore.query(InterviewFeedback, id).then( async original =>{
    console.log("original",original)
    await DataStore.save(
      InterviewFeedback.copyOf(original, updated => {
        updated.Comments=data.Comments
        updated.FeedbackStatus=data.FeedbackStatus
      }
    )
    ).then(feedback=>{
      console.log("InterviewFeedback update",feedback)
       resolve("success");
    })
  } )
   
    }
    catch(e){
      console.log("InterviewSchedule update", e)
     reject(e)
    }
  });
  }

async getFeedback(recruiterID, interviewID) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(InterviewFeedback, c =>
          c.interviewFeedbackRecruiterId.eq(recruiterID) && c.interviewFeedbackInterviewId.eq(interviewID)
        ).then(feedback => {
          console.log(feedback);
          resolve(feedback);
        });
      } catch (e) {
        console.log("getFeedback error:", e);
        reject(e);
      }
    });
  }

async getFeedbackResponse(interviewID) {
  return new Promise(async (resolve, reject) => {
    try {
      await DataStore.query(InterviewFeedback, c =>
         c.interviewFeedbackInterviewId.eq(interviewID)
      ).then(feedback => {
        console.log(feedback);
        resolve(feedback);
      });
    } catch (e) {
      console.log("getFeedback error:", e);
      reject(e);
    }
  });
}
}
export default new FeedbackAmplify();
