import React from 'react';
import Navbar from '../HomePage/Navbar';
import './Login.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import KeyIcon from '@mui/icons-material/Key';
import Typography from '@mui/material/Typography';
import GIF from '../../assets/images/login_page_gif.gif';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';

function CreatePassword() {
  return (
    <>
      <Navbar />
      <div>
        <Grid container>
          <Grid item xs={0} md={6}>
            <div className="login_title_txt">
              <Card className="login-card">
                <img className="loginPage_GIF" src={GIF} width="100%" alt="" />
              </Card>
            </div>
          </Grid>
          <Grid item xs={12} md={6} elevation={6} square>
            <Box
              sx={{
                my: 50,
                mx: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: '#6366f1' }}>
                <KeyIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Set new Password
              </Typography>
              <small>
                Your new password must be different to previously used password.
              </small>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  className="login_text_field"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <TextField
                  className="login_text_field"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Confirm Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <Link>
                  <Button
                    className="login_btn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Reset Password
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default CreatePassword;
