import * as React from 'react';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Card from '../HomePage/Card';
import './NewInterviewPage.css';
import { Grid, InputAdornment } from '@mui/material';
import { TabContext, TabPanel } from '@material-ui/lab';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import JobBasedInterviewScheduleService from '../Service/AWS_Auth/JobBasedInterviewScheduleService';
import CloseIcon from '@mui/icons-material/Close';
import { Storage } from '@aws-amplify/storage';
import { useNavigate } from 'react-router-dom';
import Navbar from '../HomePage/Navbar';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

function NewInterviewPage() {
  const [value, setValue] = React.useState('All');
  const [allInterviews, setAllInterview] = React.useState([]);
  const [interviewInputText, setInterviewInputText] = React.useState('');
  var interviewList = [];
  const [tab, setTab] = React.useState();
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClear = async () => {
    setInterviewInputText('');
    await getAllInterview();
  };
  const getAllInterview = async () => {
    // let jobID = location.state != null ? location.state.id:undefined
    let recruiterID = localStorage.getItem('recruiterId');
    await JobBasedInterviewScheduleService.getAllInterviewScheduled(
      recruiterID
    ).then(async (allInterviewData) => {
      if (allInterviewData.length != 0) {
        await allInterviewData.forEach(async (Interview) => {
          await Interview.Candidates.then(async (candidate) => {
            if (candidate != undefined) {
              if (candidate.ProfilePic != null && candidate.ProfilePic != '') {
                await Storage.get(candidate.ProfilePic).then((pic) => {
                  interviewList.push({
                    interviewData: Interview,
                    candidateData: candidate,
                    pic: pic,
                  });
                });
              } else {
                interviewList.push({
                  interviewData: Interview,
                  candidateData: candidate,
                  pic: 'pic',
                });
              }
            } else {
              allInterviewData.length = allInterviewData.length - 1;
            }
          });
          if (interviewList.length == allInterviewData.length) {
            setAllInterview(interviewList);
            console.log('allInterview', interviewList);
          }
        });
      } else {
        setAllInterview([]);
      }
    });
  };
  const searchInterviewSchedule = async (text) => {
    let interview = [];
    let recruiterID = localStorage.getItem('recruiterId');
    await JobBasedInterviewScheduleService.searchInterviewSchedule(
      text,
      tab,
      recruiterID
    ).then(async (data) => {
      console.log('result', data, tab);

      await data.forEach(async (element) => {
        await element.Candidates.then(async (candidate) => {
          if (candidate != undefined) {
            if (candidate.ProfilePic != null && candidate.ProfilePic != '') {
              await Storage.get(candidate.ProfilePic).then((pic) => {
                interview.push({
                  interviewData: element,
                  candidateData: candidate,
                  pic: pic,
                });
              });
            } else {
              interview.push({
                interviewData: element,
                candidateData: candidate,
                pic: 'pic',
              });
            }
          } else {
            data.length = data.length - 1;
          }
        });
        if (interview.length == data.length) {
          setAllInterview(interview);
          console.log('searchInterview', interview);
        }

        if (element.length == 0) {
          setAllInterview([]);
        }
      });
    });
  };
  const searchInterviewScheduleTab = async (status) => {
    let interview = [];
    let recruiterID = localStorage.getItem('recruiterId');
    await JobBasedInterviewScheduleService.searchInterviewScheduleTab(
      status,
      interviewInputText,
      recruiterID
    ).then(async (data) => {
      console.log('result', data);

      await data.forEach(async (element) => {
        await element.Candidates.then(async (candidate) => {
          if (candidate != undefined) {
            if (candidate.ProfilePic != null && candidate.ProfilePic != '') {
              await Storage.get(candidate.ProfilePic).then((pic) => {
                interview.push({
                  interviewData: element,
                  candidateData: candidate,
                  pic: pic,
                });
              });
            } else {
              interview.push({
                interviewData: element,
                candidateData: candidate,
                pic: 'pic',
              });
            }
          } else {
            data.length = data.length - 1;
          }
        });

        if (interview.length == data.length) {
          setAllInterview(interview);
          console.log('searchInterviewTab', interview);
        }
      });
      if (data.length == 0) {
        setAllInterview([]);
      }
    });
  };
  useEffect(async () => {
    await getAllInterview();
  }, []);
  let interviewInputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInterviewInputText(lowerCase);
    searchInterviewSchedule(e.target.value);
    setValue('All');
  };
  return (
    <>
      <Navbar />
      <Container maxWidth={'lg'}>
        {' '}
        <Grid container>
          <Grid item xs={6}>
            {' '}
            <div className="title_txt"> Interviews</div>
          </Grid>
          <Grid className="schedule_interview_btn_col" item xs={6}>
            {' '}
            <Button
              className="schedule_interview_btn"
              variant="contained"
              size="large"
              onClick={(e) => {
                navigate('/newSchedulePage');
              }}
            >
              <AddIcon className="add_icon" /> Schedule
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid className="header_col_2" item xs={12} md={6}>
            <Box sx={{ width: '100%' }}>
              <Tabs
                indicatorColor="primary"
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                aria-label="secondary tabs example"
              >
                <Tab
                  sx={{ bgcolor: '#ffffff' }}
                  value="All"
                  label="All"
                  onClick={(e) => {
                    var lowerCase = ''.toLowerCase();
                    setTab('ALL');
                    searchInterviewSchedule(interviewInputText);
                  }}
                />
                <Tab
                  sx={{ bgcolor: '#ffffff' }}
                  value="Pending"
                  label="Pending"
                  onClick={(e) => {
                    var lowerCase = 'PENDING'.toLowerCase();
                    setTab('PENDING');
                    searchInterviewScheduleTab('PENDING');
                  }}
                />
                <Tab
                  sx={{ bgcolor: '#ffffff' }}
                  value="Completed"
                  label="Completed"
                  onClick={(e) => {
                    var lowerCase = 'COMPLETED'.toLowerCase();
                    setTab('COMPLETED');
                    searchInterviewScheduleTab('COMPLETED');
                  }}
                />
                <Tab
                  sx={{ bgcolor: '#ffffff' }}
                  value="Selected"
                  label="Selected"
                  onClick={(e) => {
                    var lowerCase = 'SELECTED'.toLowerCase();
                    setTab('SELECTED');
                    searchInterviewScheduleTab('SELECTED');
                  }}
                />
                <Tab sx={{ bgcolor: '#ffffff' }} value="five" label="Flagged" />
              </Tabs>
            </Box>
          </Grid>
          <Grid item md={2}></Grid>
          <Grid className="header_col_3" item xs={12} md={4}>
            {' '}
            <div className="search">
              <TextField
                sx={{
                  bgcolor: 'white',
                }}
                id="outlined-basic"
                onChange={interviewInputHandler}
                variant="outlined"
                fullWidth
                placeholder="Search Interviews..."
                value={interviewInputText}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRoundedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {interviewInputText !== '' && (
                        <IconButton onClick={handleClear}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />
            </div>
          </Grid>
        </Grid>
        <br></br>
        <Container className="card_cont" maxWidth={'lg'}>
          <Grid container>
            <Grid item xs={12}>
              <TabContext value={value}>
                {value == 'All' && interviewInputText == '' && (
                  <div>{`Recent interviews "${allInterviews.length}"`}</div>
                )}
                {value == 'Pending' && interviewInputText == '' && (
                  <div>{`Recent pending interviews "${allInterviews.length}" `}</div>
                )}
                {value == 'Completed' && interviewInputText == '' && (
                  <div>{`Recent completed interviews "${allInterviews.length}" `}</div>
                )}
                {value == 'Selected' && interviewInputText == '' && (
                  <div>
                    {`Recent selected interviews "${allInterviews.length}"`}{' '}
                  </div>
                )}
                {interviewInputText != '' && (
                  <div>
                    {allInterviews.length}{' '}
                    {allInterviews.length > 1 ? `Interviews` : `Interview`}
                    {` has been found for "${interviewInputText}"`}
                  </div>
                )}
                <TabPanel className="card_border_area" value="All">
                  {allInterviews.length != 0 && <Card data={allInterviews} />}
                </TabPanel>
                <TabPanel className="card_border_area" value="Pending">
                  {allInterviews.length != 0 && <Card data={allInterviews} />}
                </TabPanel>
                <TabPanel className="card_border_area" value="Completed">
                  {allInterviews.length != 0 && <Card data={allInterviews} />}
                </TabPanel>
                <TabPanel className="card_border_area" value="Selected">
                  {allInterviews.length != 0 && <Card data={allInterviews} />}
                </TabPanel>
                <TabPanel className="card_border_area" value="five">
                  {' '}
                  <Card />
                  <Card />
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>{' '}
        </Container>
      </Container>
    </>
  );
}

export default NewInterviewPage;