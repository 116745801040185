import React, { useState, useEffect } from 'react';
import './NewCandidateCreate.css';
import { Box, Container } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Candidate } from '../../models';
import { useNavigate } from 'react-router-dom';
import Navbar from '../HomePage/Navbar';
import MuiPhoneNumber from 'material-ui-phone-number';
import AWS_Storage from '../Service/AWS_Auth/VideoStorage';
import {
  parsePhoneNumber,
  isValidPhoneNumber,
  getNumberType,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import CandidateDataService from '../Service/AWS_Auth/CandidateDataService';
import { Storage } from '@aws-amplify/storage';
import { toast } from 'react-toastify';
import FileUpload from 'react-material-file-upload';
function NewCreateCandidate() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Fname: '',
    Lname: '',
    email: '',
    experience: '',
    phone: '',
    homeLocation: '',
    currentLocation: '',
    gender: '',
    currentRole: '',
    resume: '',
    profileUrl: '',
    profile: '',
  });
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    setErrors(validationErrors);
    let companyId = localStorage.getItem('companyId');
    if (Object.keys(validationErrors).length === 0) {
      var data = new Candidate({
        FirstName: formData.Fname,
        LastName: formData.Lname,
        Email: formData.email,
        Phone: formData.phone,
        Experience: 'Experience',
        YOE: Number(formData.experience),
        CurrentRole: formData.currentRole,
        HomeLocation: formData.homeLocation,
        CurrentLocation: formData.currentLocation,
        Gender: formData.gender,
        Resume: formData.resume,
        ProfilePic: formData.profile,
      });
      await CandidateDataService.save(data)
        .then((value) => {
          if (value) {
            navigate('/newSchedulePage', { state: { candidate: data.id } });
          }
        })
        .catch((e) => {
          toast.error(e);
        });
    }
  };
  const validateFormData = (formData) => {
    const errors = {};
    if (!formData.Fname) {
      errors.Fname = 'This field is required';
    }
    if (!formData.Lname) {
      errors.Lname = 'This field is required';
    }
    if (!formData.experience) {
      errors.experience = 'This field is required';
    }
    if (!formData.currentRole) {
      errors.currentRole = 'This field is required';
    }
    if (!formData.email) {
      errors.email = 'This field is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email format';
    }
    if (!formData.gender) {
      errors.gender = 'This field is required';
    }
    if (!formData.phone) {
      errors.phone = 'This field is required';
    }
    if (!isValidPhoneNumber(formData.phone)) {
      errors.phone = 'Invalid phone format';
    }
    if (!formData.homeLocation) {
      errors.homeLocation = 'This field is required';
    }
    if (!formData.currentLocation) {
      errors.currentLocation = 'This field is required';
    }
    if (!formData.resume) {
      errors.resume = 'please upload the candidate resume';
    }
    return errors;
  };
  const resumeUpload = async (file) => {
    if (file) {
      await AWS_Storage.Resume(file).then(async (value) => {
        setFormData({
          ...formData,
          resume: value,
        });
      });
    }
  };
  const ProfileUpload = async (file) => {
    console.log('file', file);
    if (file) {
      await AWS_Storage.ProfilePic(file).then(async (value) => {
        await Storage.get(value).then(async (val) => {
          setFormData({ formData, profileUrl: val });
          console.log('url', val);
        });

        setFormData({
          ...formData,
          profile: value,
        });
      });
    }
  };

  return (
    <>
      <Navbar />
      <Container maxWidth className="bg_color">
        <Container fixed>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <div className="create_job_title">Create Candidate</div>
            </Grid>
          </Grid>
          <br />
          <Box className="box_styles" component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2} className="create_job_context">
              <Grid item md={12} className="create_job_grid">
                <br />
                <Grid container spacing={2}>
                  <Grid item className="hide_mobile" md={1}></Grid>
                  <Grid item xs={12} md={3} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      value={formData.Fname}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          Fname: event.target.value,
                        })
                      }
                    />
                    {errors.Fname && (
                      <div className="error">{errors.Fname}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      value={formData.Lname}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          Lname: event.target.value,
                        })
                      }
                    />
                    {errors.Lname && (
                      <div className="error">{errors.Lname}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Email ID"
                      variant="outlined"
                      value={formData.email}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          email: event.target.value,
                        })
                      }
                    />
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={1} className="hide_mobile"></Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      type="number"
                      id="outlined-basic"
                      label="Experience"
                      variant="outlined"
                      value={formData.experience}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          experience: event.target.value,
                        })
                      }
                    />
                    {errors.experience && (
                      <div className="error">{errors.experience}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Current Role"
                      variant="outlined"
                      value={formData.currentRole}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          currentRole: event.target.value,
                        })
                      }
                    />
                    {errors.currentRole && (
                      <div className="error">{errors.currentRole}</div>
                    )}
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item md={1} className="hide_mobile"></Grid>

                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Home Location"
                      variant="outlined"
                      value={formData.homeLocation}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          homeLocation: event.target.value,
                        })
                      }
                    />
                    {errors.homeLocation && (
                      <div className="error">{errors.homeLocation}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Current Location"
                      variant="outlined"
                      value={formData.currentLocation}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          currentLocation: event.target.value,
                        })
                      }
                    />
                    {errors.currentLocation && (
                      <div className="error">{errors.currentLocation}</div>
                    )}
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={1} className="hide_mobile"></Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label" required>
                        Gender
                      </InputLabel>
                      <Select
                        className="job_txt_field"
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="gender"
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            gender: event.target.value,
                          })
                        }
                      >
                        <MenuItem value="">
                          <em>Select an option</em>
                        </MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="Nothing Specific">
                          Nothing Specific
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {errors.gender && (
                      <div className="error">{errors.gender}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <MuiPhoneNumber
                      required
                      fullWidth
                      label="Phone Number"
                      variant="outlined"
                      defaultCountry={'in'}
                      value={formData.phone}
                      error={errors.phone}
                      helperText={errors.phone && errors.phone}
                      onChange={(c, t) => {
                        console.log(c, t, isValidPhoneNumber(c));

                        setFormData({
                          ...formData,
                          phone: c,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={1} className="hide_mobile"></Grid>

                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <div> Resume *</div>
                    <div>
                      <FileUpload
                        accept=".doc,.docx,.pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
                        value={files}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          resumeUpload(file);
                        }}
                      />
                      {/* <input
                        type="file"
                        // accept=".doc, .docx"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          resumeUpload(file);
                        }}
                      /> */}
                    </div>
                    {errors.resume && (
                      <div className="error">{errors.resume}</div>
                    )}
                  </Grid>

                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <div> Profile Picture </div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} className="btn_align_center">
                        <Button
                          className="profile_upload_btn"
                          variant="contained"
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={async (e) => {
                              const file = e.target.files[0];
                              ProfileUpload(file);
                            }}
                          />
                          Upload Image
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className="set_avatar_border"
                        sx={{ pb: 2 }}
                      >
                        <Avatar
                          className="avatar_style_pic_upload"
                          alt={''}
                          src={formData.profile}
                          sx={{ width: 175, height: 175 }}
                        />
                      </Grid>
                    </Grid>

                    {/* <input
                        type="file"
                        accept="image/*"
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          ProfileUpload(file);
                        }}
                      /> */}
                    {/* {formData.profileUrl != '' && (
                        <Image
                          src={formData.profileUrl}
                          height="200px"
                          width="300px"
                        />
                      )} */}
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} className="save_continue_btn_grid">
                    <Button
                      className="save_continue_btn"
                      variant="contained"
                      type="submit"
                    >
                      Save & Continue
                    </Button>
                  </Grid>
                </Grid>
                <br />
                <br />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Container>
    </>
  );
}

export default NewCreateCandidate;
