import { Storage } from "@aws-amplify/storage"

class AWS_storage {
  async video(blob, fileName) {
    return new Promise(async (resolve, reject) => {
      try {
        getFileBlob(blob, (url) => {
          const videoUrl = Storage.put(fileName, url, {
            contentType: 'video/mp4', // contentType is optional
          }).then((value) => {
            console.log('value video url', value);
            resolve(value.key);
          });
        });
      } catch (error) {
        console.log('Error uploading file: ', error);
        reject(error);
      }
    });
  }
  async videoBlob(blob, fileName) {
    return new Promise(async (resolve, reject) => {
      try {
        Storage.put(fileName, blob, {
          contentType: 'video/mp4', // contentType is optional
        }).then((value) => {
          console.log('value video url', value);
          resolve(value.key);
        });
      } catch (error) {
        console.log('Error uploading file: ', error);
        reject(error);
      }
    });
  }

  async videoWhatsUp(blob, fileName) {
    return new Promise(async (resolve, reject) => {
      try {
        Storage.put(fileName, blob, {
          contentType: 'video/mp4',
          // contentType is optional
        }).then((value) => {
          console.log('value video url', value);
          resolve(value.key);
        });
      } catch (error) {
        console.log('Error uploading file: ', error);
        reject(error);
      }
    });
  }

  async Resume(file) {
    try {
      let resume = await Storage.put(`Resume/${file.name}`, file).then(
        (value) => {
          console.log('value', value.key);
          return value.key;
        }
      );
      return resume;
    } catch (error) {
      console.log('Error uploading file: ', error);
      return error;
    }
  }

  async ProfilePic(file) {
    try {
      let resume = await Storage.put(`Profile/${file.name}`, file).then(
        (value) => {
          console.log('value', value.key);
          return value.key;
        }
      );
      return resume;
    } catch (error) {
      console.log('Error uploading file: ', error);
      return error;
    }
  }
}
var getFileBlob = function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function() {
      cb(xhr.response);
    });
    xhr.send();
  };
export default new AWS_storage();