import { Grid } from '@mui/material';
import { useState } from 'react';
import { Container } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import React from 'react';
import './FeedbackHistory.css';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import FeedbackService from '../Service/AWS_Auth/FeedbackService';
import { FeedbackStatus, InterviewFeedback } from '../../models';
function FeedbackHistory(props) {
  const timer = React.useRef();
  const [feedbackResponse, setFeedbackResponse] = useState([]);
  const fetchResponse = async (data) => {
    await FeedbackService.getFeedbackResponse(data.id).then((feedback) => {
      if (feedback.length != 0) {
        console.log(feedback);
        setFeedbackResponse(feedback);
      }
    });
  };
  React.useEffect(async () => {
    console.log(props.data);
    await fetchResponse(props.data);
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  return (
    <Container className="feedback_history_container" maxWidth={false}>
      <Grid container>
        <Grid className="feedback_header" item xs={12}>
          Feedbacks({feedbackResponse.length})
        </Grid>
      </Grid>

      {feedbackResponse &&
        feedbackResponse.map((item) => (
          <ListItem>
            <ListItemAvatar>
              <Avatar alt={item.RecruiterName} src="/broken-image.jpg"></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.RecruiterName}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  ></Typography>
                  {item.Comments}
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      <Divider variant="inset" />
    </Container>
  );
}

export default FeedbackHistory;
