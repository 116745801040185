import React, { useState, useEffect } from 'react';
import './NewCreateJob.css';
import { Box, Container } from '@material-ui/core';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import JobData from '../Service/AWS_Auth/JobDataService';
import Company from '../Service/AWS_Auth/CompanyDataService';
import { Job, JobStatus } from '../../models';
import { useNavigate } from 'react-router-dom';
import Navbar from '../HomePage/Navbar';

function NewCreateJob() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    jobtitle: '',
    internalID: '',
    jobdescription: '',
    HMemail: '',
    HMname: '',
    location: '',
    workfromhome: '',
    skills: '',
    extraskills: '',
    minexperience: '',
    maxexperience: '',
    gender: '',
  });
  const [errors, setErrors] = useState({});
  const [company, setCompany] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    setErrors(validationErrors);
    let companyId = localStorage.getItem('companyId');
    if (Object.keys(validationErrors).length === 0) {
      var data = new Job({
        InternalID: formData.internalID,
        Title: formData.jobtitle,
        Description: formData.jobdescription,
        MinimumExperience: parseInt(formData.minexperience),
        MaximumExperience: parseInt(formData.maxexperience),
        Skills: formData.skills + ' ' + formData.extraskills,
        RemoteWorking: formData.workfromhome,
        Status: JobStatus.OPEN,
        Location: formData.location,
        HiringManagerName: formData.HMname,
        HiringManagerEmail: formData.HMemail,
        jobCompanyId: companyId,
        Gender: formData.gender,
      });
      let status = await JobData.save(data);
      if (status == 'success') {
        // window.history.back();
        navigate('/newSchedulePage', { state: { id: data.id } });
      }
    }
  };
  const validateFormData = (formData) => {
    const errors = {};
    if (!formData.jobtitle) {
      errors.jobtitle = 'This field is required';
    }
    if (!formData.HMname) {
      errors.HMname = 'This field is required';
    }
    if (!formData.HMemail) {
      errors.HMemail = 'This field is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.HMemail)) {
      errors.HMemail = 'Invalid email format';
    }
    if (!formData.internalID) {
      errors.internalID = 'This field is required';
    }
    if (!formData.jobdescription) {
      errors.jobdescription = 'This field is required';
    }
    if (!formData.location) {
      errors.location = 'This field is required';
    }
    if (!formData.workfromhome) {
      errors.workfromhome = 'This field is required';
    }
    if (!formData.skills) {
      errors.skills = 'This field is required';
    }
    if (!formData.extraskills) {
      errors.extraskills = 'This field is required';
    }
    if (!formData.minexperience) {
      errors.minexperience = 'This field is required';
    }
    if (!formData.maxexperience) {
      errors.maxexperience = 'This field is required';
    }
    if (!formData.gender) {
      errors.gender = 'This field is required';
    }

    return errors;
  };
  useEffect(() => {
    let companyId = localStorage.getItem('companyId');
    let companyObject = Company.getByID(companyId);
    setCompany(companyObject);
  }, []);
  return (
    <>
      <Navbar />
      <Container maxWidth className="bg_color">
        <Container fixed>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <div className="create_job_title">Create Job</div>
            </Grid>
          </Grid>
          <br />
          <Box className="box_styles" component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2} className="create_job_context">
              <Grid item md={12} className="create_job_grid">
                <br />
                <Grid container spacing={2}>
                  <Grid item className="hide_mobile" md={1}></Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Job Title"
                      variant="outlined"
                      value={formData.jobtitle}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          jobtitle: event.target.value,
                        })
                      }
                    />
                    {errors.jobtitle && (
                      <div className="error">{errors.jobtitle}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Job Reference ID"
                      variant="outlined"
                      value={formData.internalID}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          internalID: event.target.value,
                        })
                      }
                    />
                    {errors.internalID && (
                      <div className="error">{errors.internalID}</div>
                    )}
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={1} className="hide_mobile"></Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Hiring Manager Name"
                      variant="outlined"
                      value={formData.HMname}
                      onChange={(event) =>
                        setFormData({ ...formData, HMname: event.target.value })
                      }
                    />
                    {errors.HMname && (
                      <div className="error">{errors.HMname}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Hiring Manager E-mail"
                      variant="outlined"
                      value={formData.HMemail}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          HMemail: event.target.value,
                        })
                      }
                    />
                    {errors.HMemail && (
                      <div className="error">{errors.HMemail}</div>
                    )}
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={1} className="hide_mobile"></Grid>
                  <Grid item xs={12} md={10} sx={{ pb: 2 }}>
                    <TextareaAutosize
                      required
                      className="txt_area_padding"
                      maxRows={5}
                      aria-label="maximum height"
                      placeholder="Job Description"
                      value={formData.jobdescription}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          jobdescription: event.target.value,
                        })
                      }
                    />
                    {errors.jobdescription && (
                      <div className="error">{errors.jobdescription}</div>
                    )}
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={1} className="hide_mobile"></Grid>
                  <Grid item xs={12} md={3} sx={{ pb: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label" required>
                        Gender
                      </InputLabel>
                      <Select
                        className="job_txt_field"
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="gender"
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            gender: event.target.value,
                          })
                        }
                      >
                        <MenuItem value="">
                          <em>Select an option</em>
                        </MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Nothing Specific">
                          Nothing Specific
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Location"
                      variant="outlined"
                      value={formData.location}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          location: event.target.value,
                        })
                      }
                    />
                    {errors.location && (
                      <div className="error">{errors.location}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ pb: 2 }}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Work From Home
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                          onChange={(event) =>
                            setFormData({ ...formData, workfromhome: 'Yes' })
                          }
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                          onChange={(event) =>
                            setFormData({ ...formData, workfromhome: 'No' })
                          }
                        />
                        <FormControlLabel
                          value="hybrid"
                          control={<Radio />}
                          label="Hybrid"
                          onChange={(event) =>
                            setFormData({ ...formData, workfromhome: 'Hybrid' })
                          }
                        />
                        {errors.workfromhome && (
                          <div className="error">{errors.workfromhome}</div>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={1} className="hide_mobile"></Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Must have Skills"
                      variant="outlined"
                      value={formData.skills}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          skills: event.target.value,
                        })
                      }
                    />
                    {errors.location && (
                      <div className="error">{errors.skills}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Must have Skills"
                      variant="outlined"
                      value={formData.extraskills}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          extraskills: event.target.value,
                        })
                      }
                    />
                    {errors.location && (
                      <div className="error">{errors.extraskills}</div>
                    )}
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={1} className="hide_mobile"></Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      type="number"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Minimum Years of Experience"
                      variant="outlined"
                      value={formData.minexperience}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          minexperience: event.target.value,
                        })
                      }
                    />
                    {errors.minexperience && (
                      <div className="error">{errors.minexperience}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} sx={{ pb: 2 }}>
                    <TextField
                      className="job_txt_field"
                      type="number"
                      required
                      fullWidth
                      id="outlined-basic"
                      label="Maximum Years of Experience"
                      variant="outlined"
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          maxexperience: event.target.value,
                        })
                      }
                    />
                    {errors.maxexperience && (
                      <div className="error">{errors.maxexperience}</div>
                    )}
                  </Grid>
                  <Grid item md={1} className="hide_mobile"></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    className="save_continue_btn_grid"
                    sx={{ pb: 2 }}
                  >
                    <Button
                      className="save_continue_btn"
                      variant="contained"
                      type="submit"
                    >
                      Save & Continue
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Container>
    </>
  );
}

export default NewCreateJob;
