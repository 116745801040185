import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import React, { useState, useEffect } from 'react';
import { Container } from '@mui/system';
import ShareIcon from '@mui/icons-material/Share';
import FlagIcon from '@mui/icons-material/Flag';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import UpdateIcon from '@mui/icons-material/Update';
import RestoreIcon from '@mui/icons-material/Restore';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import Tooltip from '@mui/material/Tooltip';
import './Card.css';
import Chip from '@mui/material/Chip';
import { NavLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

function Card(props) {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <div>
      {data != null &&
        data.length != 0 &&
        data.map((item, i) => (
          <div key={i}>
            <Grid container className="new_card_container">
              <Grid item xs={12}>
                <CssBaseline />{' '}
                <Container
                  className="new_full_card_body"
                  disableGutters={true}
                  fixed
                >
                  <Container className="card_top_bar">
                    <Grid container spacing={0}>
                      <Grid className="top_bar_item_1" item xs={6} md={6}>
                        {item.interviewData.CandidateName}
                      </Grid>

                      <Grid className="top_bar_item_3" item xs={6} md={6}>
                        <Chip
                          color="primary"
                          label={item.interviewData.ActionStatus}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                  <Container className="new_card_content" maxWidth="lg">
                    <Grid container spacing={0}>
                      <Grid
                        className="content_area_col_1"
                        item
                        xs={12}
                        md={3}
                        lg={3}
                      >
                        {/* {!(item.pic===undefined || item.pic ==""|| item.pic==null)? */}

                        <Avatar
                          className="company_avatar"
                          alt={item.interviewData.CandidateName}
                          src={item.pic}
                          sx={{
                            fontSize: '60px',
                            bgcolor: '#6366f1',
                            width: 150,
                            height: 150,
                          }}
                        />
                        {/* :<Avatar
              alt={item.interviewData.CandidateName}
              src="/static/images/avatar/1.jpg"
              sx={{ width: 150, height: 150 }}
            />
              } */}
                      </Grid>
                      <Grid
                        className="new_content_area_col_2"
                        item
                        xs={12}
                        md={4}
                        lg={4}
                      >
                        <ul>
                          <li>
                            {' '}
                            <EmailIcon className="icon_colour" />
                            {item.interviewData.CandidateEmail}
                          </li>
                          <li>
                            <PhoneAndroidIcon className="icon_colour" />
                            {item.candidateData.Phone}
                          </li>
                          <li>
                            <UpdateIcon className="icon_colour" />
                            Scheduled at: {item.interviewData.CreatedTime}
                          </li>
                        </ul>
                      </Grid>
                      <Grid
                        className="new_content_area_col_3"
                        item
                        xs={12}
                        md={4}
                        lg={4}
                      >
                        <ul>
                          <li>
                            <WorkIcon className="icon_colour" />
                            Ref.ID: {item.interviewData.JobReferenceID}
                          </li>
                          <li>
                            <BusinessIcon className="icon_colour" />
                            {item.interviewData.JobTitle}
                          </li>
                          <li>
                            <RestoreIcon className="icon_colour" />
                            Completed at:{' '}
                            {item.interviewData.CompletedTime == '' ||
                            item.interviewData.CompletedTime == null
                              ? ' __'
                              : item.interviewData.CompletedTime}
                          </li>
                        </ul>
                      </Grid>
                      <Grid item md={4}></Grid>
                    </Grid>
                  </Container>
                  <Container className="new_card_bottom_bar" maxWidth="lg">
                    <Grid container>
                      <Grid className="bottom_card_col_2" item xs={12} md={12}>
                        <NavLink
                          to={`/interviewedit/?id=${item.interviewData.id}`}
                        >
                          <IconButton className="view_icon_btn">
                            <Tooltip title="View">
                              <VisibilityIcon />
                            </Tooltip>
                          </IconButton>
                        </NavLink>{' '}
                        <IconButton
                          className="share_icon_btn"
                          onClick={(e) => console.log('e', e)}
                        >
                          {' '}
                          {/* <ShareCandidate /> */}
                          <Tooltip title="Share">
                            <ShareIcon />
                          </Tooltip>
                        </IconButton>
                        <IconButton className="flag_icon_btn">
                          <Tooltip title="Archive">
                            <FlagIcon />
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Container>
                </Container>
              </Grid>
            </Grid>
          </div>
        ))}
    </div>
  );
}

export default Card;
