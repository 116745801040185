import React from 'react';
import { useEffect } from 'react';
import './InterviewEdit.css';
import Avatar from '@mui/material/Avatar';
import { Container, Grid } from '@material-ui/core';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import WorkIcon from '@mui/icons-material/Work';
import PhoneAndroidRoundedIcon from '@mui/icons-material/PhoneAndroidRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import VideoModal from '../HomePage/VideoModal';
import Feedback from '../RecruiterPage/Feedback';
import WcRoundedIcon from '@mui/icons-material/WcRounded';
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import TuneIcon from '@mui/icons-material/Tune';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import BadgeIcon from '@mui/icons-material/Badge';
import { useLocation } from 'react-router-dom';
import { Storage } from '@aws-amplify/storage';
import { API } from 'aws-amplify';
import { getInterview, getJob } from '../../graphql/queries';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import ShareCandidate from '../HomePage/shareCandidate';
import FeedbackHistory from './FeedbackHistory';
import FeedbackCount from './FeedbackCount';

function InterviewEdit(props) {
  // let url = `${document.location.origin}/candidateSharedView/profile/?id=${urlID.jobInfo}`;
  const [userDetails, setUserDetails] = React.useState();
  let id = new URLSearchParams(useLocation().search).get('id');
  const secretKey = 'interViewTape';
  const encryptedLink = CryptoJS.AES.encrypt(id, secretKey).toString();
  const encryptedPermission = CryptoJS.AES.encrypt(
    'public',
    secretKey
  ).toString();
  const encryptedPermissionPrivate = CryptoJS.AES.encrypt(
    'private',
    secretKey
  ).toString();
  const [candidateName, setCandidateName] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [candidateEmail, setCandidateEmail] = React.useState('');
  const [role, setRole] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [yoe, setYOE] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [workLocation, setWorkLocation] = React.useState('');
  const [recruiterName, setRecruiterName] = React.useState('');
  const [picUrl, setPicUrl] = React.useState('');
  const [github, setGithub] = React.useState('');
  const [linkedin, setLinkedin] = React.useState('');
  const [youtube, setYoutube] = React.useState('');
  const [resume, setResume] = React.useState('');
  const [video1, setVideo1] = React.useState('');
  const [video2, setVideo2] = React.useState('');
  const [video3, setVideo3] = React.useState('');
  const [video4, setVideo4] = React.useState('');
  const [job, setJob] = React.useState();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  async function fetchSharedInterview() {
    try {
      const interview = await API.graphql({
        query: getInterview,
        variables: { id: id },
        authMode: 'API_KEY',
        headers: {
          'x-api-key': 'da2-ahtasosez5fo7gvtwgzkkuhbpe',
        },
      });

      return interview.data.getInterview;
    } catch (err) {
      console.log('fetch err', err);
    }
  }
  async function fetchJob(jobID) {
    try {
      const interview = await API.graphql({
        query: getJob,
        variables: { id: jobID },
        authMode: 'API_KEY',
        headers: {
          'x-api-key': 'da2-ahtasosez5fo7gvtwgzkkuhbpe',
        },
      });

      return interview.data.getJob;
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(async () => {
    let userValue = await fetchSharedInterview(); //AwsAmplify.jobByInterview(id);
    console.log(userValue);

    // Encrypt the plaintext values using AES encryption with the secret key

    if (userValue != null && userValue != undefined) {
      setUserDetails(userValue);
      let job = await fetchJob(userValue.jobID);
      console.log(job);
      if (job != null && job != undefined) {
        setJob(job);
      }
      setCandidateName(
        userValue.Candidates.FirstName + userValue.Candidates.LastName
      );
      setCandidateEmail(userValue.Candidates.Email);
      setGender(userValue.Candidates.Gender);
      setPhone(userValue.Candidates.Phone);
      setRole(
        userValue.Candidates.Experience == 'Experience'
          ? userValue.Candidates.CurrentRole
          : userValue.Candidates.Experience
      );
      setLocation(userValue.Candidates.HomeLocation);
      setRecruiterName(
        userValue.Recruiter.FirstName + userValue.Recruiter.LastName
      );
      setYOE(userValue.Candidates.YOE);
      await Storage.get(userValue.Candidates.ProfilePic).then((pic) => {
        setPicUrl(pic);
      });
      setGithub(userValue.Candidates.Github);
      setLinkedin(userValue.Candidates.Linkedin);
      setYoutube(userValue.Candidates.Youtube);
      setWorkLocation(userValue.Candidates.CurrentLocation);
      await Storage.get(userValue.Candidates.Resume).then((val) => {
        setResume(val);
      });
      userValue.Candidates.Video1 != null ||
      userValue.Candidates.Video1 != undefined
        ? userValue.Candidates.Video1 != ''
          ? await Storage.get(userValue.Candidates.Video1).then((val) => {
              setVideo1(val);
            })
          : setVideo1('')
        : setVideo1('');
      userValue.Video1 != null || userValue.Video1 != undefined
        ? userValue.Video1 != ''
          ? await Storage.get(userValue.Video1).then((val) => {
              setVideo2(val);
            })
          : setVideo2('')
        : setVideo2('');
      userValue.Video2 != null || userValue.Video2 != undefined
        ? userValue.Video2 != ''
          ? await Storage.get(userValue.Video2).then((val) => {
              setVideo3(val);
            })
          : setVideo3('')
        : setVideo3('');
      userValue.Video3 != null || userValue.Video3 != undefined
        ? userValue.Video3 != ''
          ? await Storage.get(userValue.Video3).then((val) => {
              setVideo4(val);
            })
          : setVideo4('')
        : setVideo4('');
    }
  }, []);
  return (
    <>
      <Container maxWidth>
        <br />
        <br />
        <Container fixed className="fixed_bg_color">
          <Grid container spacing={2} className="share_container">
            <Grid item xs={12} md={3} className="avatar_grid">
              <Avatar
                className="avatar_resize"
                src={picUrl}
                sx={{ height: 225 }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} className="social_icon_grid">
                  {github != null ? (
                    <Button>
                      <a href={github} target="_blank">
                        <GitHubIcon
                          className="social_icons"
                          sx={{ color: '#cdcdcd', fontSize: 38 }}
                        />
                      </a>
                    </Button>
                  ) : (
                    <></>
                  )}
                  {linkedin != null ? (
                    <Button>
                      <a href={linkedin} target="_blank">
                        <LinkedInIcon
                          className="social_icons"
                          sx={{ color: '#cdcdcd', fontSize: 40 }}
                        />
                      </a>
                    </Button>
                  ) : (
                    <></>
                  )}
                  {youtube != null ? (
                    <Button>
                      <a href={youtube} target="_blank">
                        <YouTubeIcon
                          className="social_icons"
                          sx={{ color: '#cdcdcd', fontSize: 40 }}
                        />
                      </a>
                    </Button>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} className="avatar_grid">
                  <div className="share_name_txt">{candidateName}</div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <EmailRoundedIcon className="share_info_icons" />
                        {candidateEmail}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <PhoneAndroidRoundedIcon className="share_info_icons" />
                        {phone}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <WcRoundedIcon className="share_info_icons" />
                        {gender}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <BusinessRoundedIcon className="share_info_icons" />
                        {role}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <BadgeIcon className="share_info_icons" />
                        {yoe} year
                      </span>
                    </Grid>
                  </Grid>
                  {/* <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <ManageAccountsRoundedIcon className="share_info_icons" />
                        {recruiterName}
                      </span>
                    </Grid>
                  </Grid> */}
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <HomeWorkRoundedIcon className="share_info_icons" />
                        {workLocation}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <LocationOnRoundedIcon className="share_info_icons" />
                        {location}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className="share_profile_btn_grid"
                    >
                      <Button
                        className="share_profile_btn"
                        fullWidth
                        variant="outlined"
                        onClick={handleClickOpen}
                      >
                        <ShareIcon className="share_profile_icon_btn" />
                        Share
                      </Button>
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <DialogTitle
                          className="dialog_title"
                          id="responsive-dialog-title"
                        >
                          <TuneIcon /> {'Share Options'}
                        </DialogTitle>
                        <DialogContent>
                          <Grid container>
                            <Grid item xs={12}>
                              <div className="dialog_title_txt">
                                Detailed View :
                              </div>
                              <div className="details_txt">
                                To share all the info of the candidate.
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={10}>
                              {' '}
                              <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { m: 1, width: '40ch' },
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <FormControl disabled>
                                  <InputLabel htmlFor="component-outlined">
                                    Link
                                  </InputLabel>
                                  <OutlinedInput
                                    id="component-outlined"
                                    defaultValue={`${document.location.origin}/newprofileshare/?id=${encryptedLink}&per=${encryptedPermission}`}
                                    label="Name"
                                  />
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid className="icon_grid" item xs={1}>
                              {' '}
                              <IconButton
                                className="copylink_option_view_icon"
                                onClick={(e) => {
                                  var text = `${document.location.origin}/newprofileshare/?id=${encryptedLink}&per=${encryptedPermission}`;
                                  navigator.clipboard.writeText(text).then(
                                    function () {
                                      toast.info(
                                        'Copying to clipboard was successful!'
                                      );
                                    },
                                    function (err) {
                                      console.error(
                                        'Could not copy text: ',
                                        err
                                      );
                                    }
                                  );
                                }}
                              >
                                <LinkIcon />
                              </IconButton>
                            </Grid>
                            <Grid className="icon_grid" item xs={1}>
                              {' '}
                              <IconButton className="share_option_view_icon">
                                {' '}
                                <ShareCandidate
                                  text={'Share the copied link of interview'}
                                  title={'Share this interview'}
                                  urlID={
                                    (props = {
                                      id: encryptedLink,
                                      per: encryptedPermission,
                                    })
                                  }
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              {' '}
                              <div className="dialog_title_txt">
                                Abstract View :
                              </div>{' '}
                              <div className="details_txt">
                                To hide the personal info and show only the
                                necessary details of the candidate.
                              </div>
                            </Grid>{' '}
                          </Grid>
                          <Grid container>
                            <Grid item xs={10}>
                              {' '}
                              <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { m: 1, width: '40ch' },
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <FormControl disabled>
                                  <InputLabel htmlFor="component-outlined">
                                    Link
                                  </InputLabel>
                                  <OutlinedInput
                                    id="component-outlined"
                                    defaultValue={`${document.location.origin}/newprofileshare/?id=${encryptedLink}&per=${encryptedPermissionPrivate}`}
                                    label="Name"
                                  />
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid className="icon_grid" item xs={1}>
                              {' '}
                              <IconButton
                                className="copylink_option_view_icon"
                                onClick={(e) => {
                                  var text = `${document.location.origin}/newprofileshare/?id=${encryptedLink}&per=${encryptedPermissionPrivate}`;
                                  navigator.clipboard.writeText(text).then(
                                    function () {
                                      toast.info(
                                        'Copying to clipboard was successful!'
                                      );
                                    },
                                    function (err) {
                                      console.error(
                                        'Could not copy text: ',
                                        err
                                      );
                                    }
                                  );
                                }}
                              >
                                <LinkIcon />
                              </IconButton>
                            </Grid>
                            <Grid className="icon_grid" item xs={1}>
                              {' '}
                              <IconButton className="share_option_view_icon">
                                {' '}
                                <ShareCandidate
                                  text={'Share the copied link of interview'}
                                  title={'Share this interview'}
                                  urlID={
                                    (props = {
                                      id: encryptedLink,
                                      per: encryptedPermissionPrivate,
                                    })
                                  }
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          {/* <Button autoFocus onClick={handleClose}>
                Disagree
              </Button>
              <Button onClick={handleClose} autoFocus>
                Agree
              </Button> */}
                        </DialogActions>
                      </Dialog>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className="share_profile_btn_grid"
                    >
                      {resume != null ? (
                        <a
                          className="profile_resume_link"
                          href={resume}
                          target="_blank"
                        >
                          <Button
                            className="share_profile_resume_btn"
                            fullWidth
                            variant="outlined"
                          >
                            <DownloadIcon className="resume_download_icon_btn" />
                            Resume
                          </Button>
                        </a>
                      ) : (
                        <></>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      className="share_profile_btn_grid"
                    >
                      <Button
                        className="share_profile_resume_btn"
                        fullWidth
                        variant="outlined"
                        onClick={(e) => {
                          const now = new Date().getTime();
                          const expirationTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
                          const token = encodeURIComponent(
                            btoa(`${now + expirationTime}`)
                          );
                          var text = `${document.location.origin}/candidate/interview/direct/record/?id=${id}&token=${token}`;
                          navigator.clipboard.writeText(text).then(
                            function () {
                              toast.info(
                                'Copying interview link to clipboard was successful!'
                              );
                            },
                            function (err) {
                              console.error('Could not copy text: ', err);
                            }
                          );
                        }}
                      >
                        <WorkIcon className="resume_download_icon_btn" />
                        Copy Interview Link
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid className="feedback_count_group" container>
                    <Grid item xs={12}>
                      {userDetails && <FeedbackCount data={userDetails} />}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2} className="share_container">
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} className="interview_title_grid">
                <span className="interview_title_txt">Interview</span>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12} className="job_detail_grid">
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <span className="job_title_txt">
                    {userDetails != null ? userDetails.JobTitle : ''}
                  </span>
                  <span className="job_reference_id">
                    [
                    <a href="#">
                      {userDetails != null ? userDetails.JobReferenceID : ''}
                    </a>
                    ]
                  </span>
                  <span className="job_status_chip completed">
                    {userDetails != null ? userDetails.ActionStatus : ''}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <span className="job_description_txt">
                    {job != null ? job.Description : ''}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <span className="job_skills_txt">
                    Skills: {job != null ? job.Skills : ''}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <div className="job_interview_by_txt">Recruiter</div>
                  <div className="job_interview_by_context">
                    {recruiterName}
                  </div>
                  <div className="job_interview_by_context">
                    {userDetails != null ? userDetails.Recruiter.Email : ''}
                  </div>
                </Grid>
                <Grid item md={4} xs={12}>
                  <div className="job_interview_by_txt">Hiring Manager</div>
                  <div className="job_interview_by_context">
                    {job != null ? job.HiringManagerName : ''}
                  </div>
                  <div className="job_interview_by_context">
                    {job != null ? job.HiringManagerEmail : ''}
                  </div>
                </Grid>
                <Grid item md={4} xs={12}></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <div className="job_interview_by_txt">Scheduled on</div>
                  <div className="job_interview_by_context">
                    {userDetails != null ? userDetails.CreatedTime : ''}
                  </div>
                </Grid>
                <Grid item md={4} xs={12}>
                  <div className="job_interview_by_txt">Completed On</div>
                  <div className="job_interview_by_context">
                    {userDetails != null ? userDetails.CompletedTime : ''}
                  </div>
                </Grid>
                <Grid item md={4} xs={12}></Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*-----------------------------------------------------------------------------------*/}
          {/* <Grid container spacing={2} className="share_container">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <div className="interview_title_txt">Tapes</div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Card variant="outlined" className="card_style">
                  <CardHeader
                    className="card_header_txt"
                    avatar={<Avatar alt="1" src="/"></Avatar>}
                    title="Tell me about yourself"
                    action={<VideoModal />}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Card variant="outlined" className="card_style">
                  <CardHeader
                    avatar={<Avatar alt="2" src="/"></Avatar>}
                    title="Tell me about yourself Tell me about yourself Tell me about yourself"
                    action={<VideoModal />}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Card variant="outlined" className="card_style">
                  <CardHeader
                    avatar={<Avatar alt="3" src="/"></Avatar>}
                    title="Tell me about yourself"
                    action={<VideoModal />}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid> */}
          {/*-----------------------------------------------------------------------------------*/}
          {/*-------------------------------------TEST----------------------------------------------*/}
          <Grid container spacing={2} className="share_container">
            <Grid container spacing={2}>
              <Grid item md={10}>
                <div className="interview_title_txt">Tapes</div>
              </Grid>
              <Grid className="interview_edit_icon_grid" item md={2}>
                <IconButton className="interview_edit_icon">
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12}>
                {video1 != null && (
                  <VideoModal
                    video={video1}
                    question={'Tell me about yourself ?'}
                    id={'1'}
                  />
                )}
              </Grid>
              <Grid item md={12}>
                {
                  <VideoModal
                    video={video2}
                    question={userDetails != null ? userDetails.Question1 : ''}
                    id={'2'}
                  />
                }
              </Grid>
              <Grid item md={12}>
                {userDetails != null &&
                  userDetails.Question2 !== 'No question' &&
                  video3 != null && (
                    <VideoModal
                      video={video3}
                      question={
                        userDetails != null ? userDetails.Question2 : ''
                      }
                      id={'3'}
                    />
                  )}
              </Grid>
              <Grid item md={12}>
                {userDetails != null &&
                  userDetails.Question3 !== 'No question' &&
                  video4 != null && (
                    <VideoModal
                      video={video4}
                      question={
                        userDetails != null ? userDetails.Question3 : ''
                      }
                      id={'4'}
                    />
                  )}
              </Grid>
            </Grid>
          </Grid>
          {/*-----------------------------------TEST------------------------------------------------*/}
          <Grid container spacing={2} className="share_feedback_container ">
            <Grid item md={12}>
              {userDetails != null && <Feedback data={userDetails} />}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="share_feedback_container ">
            <Grid item md={12}>
              {userDetails != null && <FeedbackHistory data={userDetails} />}
            </Grid>
          </Grid>
          <br />
        </Container>
        <br />
      </Container>
    </>
  );
}

export default InterviewEdit;
