import React from 'react'
import { MDBFooter } from 'mdb-react-ui-kit';
import "./Footer.css";
function Footer() {
  return (
    <div className='footer'>
    <MDBFooter bgColor='dark' className='text-center text-lg-left'>
      <div className='text-center text-white p-3 fixed-bottom' style={{ backgroundColor: 'black' }}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className="footer_txt" href="/Home">InterviewTape</a>
      </div>
    </MDBFooter>
    </div>
  )
}

export default Footer