import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import './LoginResponsive.css';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoginIcon from '@mui/icons-material/Login';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Card from '@mui/material/Card';
import GIF from '../../assets/images/Attheoffice_login.gif';
import './Login.css';
import AwsAuth from '../Service/AWS_Auth/authenticationServices';
import AwsAmplify from '../Service/AWS_Auth/CandidateDataService';
import RecruiterAmplify from '../Service/AWS_Auth/RecruiterDataService';
import Navbar from '../HomePage/Navbar';
import { useNavigate, useLocation } from 'react-router-dom';
import { Storage } from '@aws-amplify/storage';
import {
  GoogleOAuthProvider,
  googleLogout,
  useGoogleLogin,
} from '@react-oauth/google';
import GoogleLogin from 'react-google-login';
import jwtDecode from 'jwt-decode';
import { Auth, Amplify } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsConfig from '../../aws-exports';
export default function LoginPage() {
  Amplify.configure(awsConfig);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [toaster, settoaster] = useState(true);
  const navigate = useNavigate();
  var userDecoded;
  const [user, setUser] = React.useState({});
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [userDetails, setUserDetails] = React.useState();
  const [companyName, setCompanyName] = React.useState('');
  const [UserRole, setUserRole] = React.useState('');
  var userDecoded;
  let url = new URLSearchParams(useLocation().search).get('re');
  let re = new URLSearchParams(useLocation().search).get('per');

  if (url) {
    url = url.replace(/\s/g, '+');
  }
  if (re) {
    re = re.replace(/\s/g, '+');
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (email === '' || pass === '') {
      toast.error('Fields Can not be Empty');
    } else if (!pattern.test(email)) {
      toast.error('email is not valid...!!');
    } else {
      const data = {
        email: email,
        pass: pass,
      };

      AwsAuth.signIn(email, pass)
        .then(async (value) => {
          console.log('value', value);
          localStorage.setItem('email', email);
          const auth = await AwsAuth.loginConfirm();
          localStorage.setItem('AuthStatus', auth);
          if (value == 'true') {
            var role = localStorage.getItem('role');

            if (role == null || role == 'Employee') {
              await AwsAmplify.getCandidate(email).then((userValue) => {
                console.log('value', userValue);
                if (userValue == undefined || userValue.length == 0) {
                  navigate('/candidate/neweditprofile');
                } else {
                  if (userValue.Status == true) {
                    navigate('/candidate/newprofileview');
                  } else {
                    navigate('/candidate/neweditprofile');
                  }
                  localStorage.setItem('candidateId', userValue.id);
                  localStorage.setItem('fname', userValue.FirstName);
                }
              });
            } else {
              if (url != null && url != undefined && url != 'null') {
                if (re != null && re != undefined && re != 'null') {
                  if (url && re) {
                    // url =url.replace(/\s/g, '+') //url!=null||url!=undefined?:null;
                    // re = re.replace(/\s/g, '+')
                    console.log(url, re);

                    await RecruiterAmplify.getRecruiter(email).then(
                      async (userValue) => {
                        console.log('value', userValue);

                        localStorage.setItem('recruiterId', userValue.id);
                        localStorage.setItem('fname', userValue.FirstName);
                        localStorage.setItem('lname', userValue.LastName);
                        await Storage.get(userValue.Profilepic).then((pic) => {
                          localStorage.setItem('profilePic', pic);
                        });
                        localStorage.setItem(
                          'companyId',
                          userValue.recruiterCompanyId
                        );
                      }
                    );
                    navigate(url + `&per=` + re);
                  }
                } else {
                  // url =url.replace(/\s/g, '+')
                  await RecruiterAmplify.getRecruiter(email).then(
                    async (userValue) => {
                      console.log('value', userValue);

                      localStorage.setItem('recruiterId', userValue.id);
                      localStorage.setItem('fname', userValue.FirstName);
                      localStorage.setItem('lname', userValue.LastName);
                      await Storage.get(userValue.Profilepic).then((pic) => {
                        localStorage.setItem('profilePic', pic);
                      });
                      localStorage.setItem(
                        'companyId',
                        userValue.recruiterCompanyId
                      );
                    }
                  );
                  navigate(url);
                }
              } else {
                await RecruiterAmplify.getRecruiter(email).then(
                  async (userValue) => {
                    console.log('value', userValue);
                    if (userValue == undefined || userValue.length == 0) {
                      navigate('/recruiter/newedit');
                    } else {
                      navigate('/newInterviewPage');
                      localStorage.setItem('recruiterId', userValue.id);
                      localStorage.setItem('fname', userValue.FirstName);
                      localStorage.setItem('lname', userValue.LastName);
                      await Storage.get(userValue.Profilepic).then((pic) => {
                        localStorage.setItem('profilePic', pic);
                      });
                      localStorage.setItem(
                        'companyId',
                        userValue.recruiterCompanyId
                      );
                    }
                  }
                );
              }
            }

            toast.success('Successfully logged in,Welcome back');
            localStorage.setItem('user', true);
          } else {
            toast.error(value.toString());
          }
        })
        .catch((err) => {
          toast.error(err.toString());
        });
      // signInWithEmailAndPassword(auth, email, pass)
      // .then(async (userCredential) => {
      //   // Signed in
      //   const user = userCredential.user.accessToken;
      //   localStorage.setItem("user",user)
      //   settoaster(true)
      //   var role =localStorage.getItem("role")

      //   userDecoded = jwtDecode(user);
      //   if(role == "Employee"){
      //   let userValue =await UserProfile.getUserById(userDecoded.sub);
      //       if(userValue==null){
      //          navigate("/profile")
      //         }
      //       else{
      //         navigate("/profileView")
      //         }
      //       }
      //   else{
      //     let userValue =await RecruiterProfileDB.getRecById(userDecoded.sub);
      //       if(userValue==null){
      //     navigate("/recprof")
      //       }
      //       else{
      //         navigate("/recruiterView")
      //         }
      //       }
      //   // ...
      // })
      // .catch((error) => {
      //   const errorCode = error.code;
      //   const errorMessage = error.message;
      //   toast.error(errorMessage);
      // });
    }
  };
  if (toaster) {
    if (window.document.referrer === `http://localhost:3000/create-user`) {
      toast.success('Registration Successfully');
    }
    if (window.document.referrer === `http://localhost:3000/Log%20Out`) {
      toast.success('Successfully Logout');
    }
  }

  const logOut = (e) => {
    e.preventDefault();
    googleLogout();
  };
  // const exchangeAccessTokenForIdToken = async (accessToken) => {
  //   try {
  //     const response = await fetch('https://oauth2.googleapis.com/token', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //       body: new URLSearchParams({
  //         grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
  //         audience:
  //           '395984277447-1k3dc6oghplu9tbhsjlgdnds67ntlfvc.apps.googleusercontent.com',
  //         subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
  //         subject_token: accessToken,
  //       }),
  //     });
  //     console.log('response', response);
  //     if (response.ok) {
  //       const data = await response.json();
  //       return data.id_token;
  //     } else {
  //       throw new Error(
  //         'Failed to exchange access token for OIDC identity token'
  //       );
  //     }
  //   } catch (err) {
  //     console.log('exchangeAccess', err);
  //   }
  // };
  const login = useGoogleLogin({
    clientId:
      '395984277447-1k3dc6oghplu9tbhsjlgdnds67ntlfvc.apps.googleusercontent.com',
    onSuccess: async (codeResponse) => {
      setUser(codeResponse);
      console.log('Success', codeResponse);
      // Exchange access token for OIDC identity token
      // exchangeAccessTokenForIdToken(codeResponse.access_token)
      //   .then(async (idToken) => {
      // Use the OIDC identity token as needed
      console.log('OIDC identity token:', codeResponse.access_token);
      // Sign in the user with AWS Amplify using the obtained email and access token
      await AwsAuth.federatedSignIn(codeResponse.access_token)
        .then(async (value) => {
          console.log('value', value);
          localStorage.setItem('email', email);
          const auth = await AwsAuth.loginConfirm();
          localStorage.setItem('AuthStatus', auth);
          if (value == 'true') {
            var role = localStorage.getItem('role');

            if (role == null || role == 'Employee') {
              await AwsAmplify.getCandidate(email).then((userValue) => {
                console.log('value', userValue);
                if (userValue == undefined || userValue.length == 0) {
                  navigate('/candidate/neweditprofile');
                } else {
                  if (userValue.Status == true) {
                    navigate('/candidate/newprofileview');
                  } else {
                    navigate('/candidate/neweditprofile');
                  }
                  localStorage.setItem('candidateId', userValue.id);
                  localStorage.setItem('fname', userValue.FirstName);
                }
              });
            } else {
              if (url != null && url != undefined && url != 'null') {
                if (re != null && re != undefined && re != 'null') {
                  if (url && re) {
                    // url =url.replace(/\s/g, '+') //url!=null||url!=undefined?:null;
                    // re = re.replace(/\s/g, '+')
                    console.log(url, re);

                    await RecruiterAmplify.getRecruiter(email).then(
                      async (userValue) => {
                        console.log('value', userValue);

                        localStorage.setItem('recruiterId', userValue.id);
                        localStorage.setItem('fname', userValue.FirstName);
                        localStorage.setItem('lname', userValue.LastName);
                        await Storage.get(userValue.Profilepic).then((pic) => {
                          localStorage.setItem('profilePic', pic);
                        });
                        localStorage.setItem(
                          'companyId',
                          userValue.recruiterCompanyId
                        );
                      }
                    );
                    navigate(url + `&per=` + re);
                  }
                } else {
                  // url =url.replace(/\s/g, '+')
                  await RecruiterAmplify.getRecruiter(email).then(
                    async (userValue) => {
                      console.log('value', userValue);

                      localStorage.setItem('recruiterId', userValue.id);
                      localStorage.setItem('fname', userValue.FirstName);
                      localStorage.setItem('lname', userValue.LastName);
                      await Storage.get(userValue.Profilepic).then((pic) => {
                        localStorage.setItem('profilePic', pic);
                      });
                      localStorage.setItem(
                        'companyId',
                        userValue.recruiterCompanyId
                      );
                    }
                  );
                  navigate(url);
                }
              } else {
                await RecruiterAmplify.getRecruiter(email).then(
                  async (userValue) => {
                    console.log('value', userValue);
                    if (userValue == undefined || userValue.length == 0) {
                      navigate('/recruiter/newedit');
                    } else {
                      navigate('/newInterviewPage');
                      localStorage.setItem('recruiterId', userValue.id);
                      localStorage.setItem('fname', userValue.FirstName);
                      localStorage.setItem('lname', userValue.LastName);
                      await Storage.get(userValue.Profilepic).then((pic) => {
                        localStorage.setItem('profilePic', pic);
                      });
                      localStorage.setItem(
                        'companyId',
                        userValue.recruiterCompanyId
                      );
                    }
                  }
                );
              }
            }

            toast.success('Successfully logged in,Welcome back');
            localStorage.setItem('user', true);
          } else {
            toast.error(value.toString());
          }
          // });
        })
        .catch((error) => {
          // Handle error fetching user email
          console.log('Error fetching user email from Google:', error);
        });
    },
    onError: (error) => console.log('Login Failed:', error),
  });
  return (
    <div>
      <Navbar></Navbar>

      <Grid
        container
        component="main"
        sx={{ height: '100vh', boxShadow: 'none' }}
      >
        <CssBaseline />
        <Grid item xs={0} md={6} className="set_grid_bg_color">
          <div className="login_title_txt">
            <Card className="login-card">
              <img className="loginPage_GIF" src={GIF} width="100%" alt="" />
            </Card>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          elevation={6}
          square
          className="set_grid_bg_color"
        >
          <Box
            sx={{
              my: 50,
              mx: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#6366f1' }}>
              <LoginIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                className="login_text_field"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  settoaster(false);
                }}
                autoComplete="email"
                autoFocus
                color={'primary'}
              />
              <TextField
                className="login_text_field"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                onChange={(e) => setPass(e.target.value)}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Link to="/forgotpassword" className="forgot_txt">
                    Forgot Password?
                  </Link>
                </Grid>
              </Grid>
              <Button
                className="login_btn"
                type="submit"
                fullWidth
                variant="contained"
                // color='secondary'
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>

              <h5>or</h5>
              <Grid className="register_box">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    AwsAuth.federatedSignIn();
                    // login();
                  }}
                >
                  Sign in with Google 🚀{' '}
                </button>
                <button onClick={(e) => logOut(e)}>Log out</button>
              </Grid>
              <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Link
                    className="login_signup_txt"
                    to={
                      (url != null || url != undefined) &&
                      (re != null || re != undefined)
                        ? `/register?re=${url}+&per=${re}`
                        : `/register?re=${url}`
                    } //navigate(url+`&per=`+ re);
                    variant="body2"
                  >
                    {"Don't have an account?"}
                    <Button
                      className="signup_btn"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      SignUp
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
