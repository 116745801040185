/* eslint-disable eqeqeq */
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import './RecruiterHome.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import profile_pic from '../../assets/images/user-profile.png';
import ShareCandidate from '../HomePage/shareCandidate';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import { NavLink } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import jwtDecode from 'jwt-decode';
import { useForm } from 'react-hook-form';
import RecruiterNavbar from './RecruiterNavbar';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Collapse from 'react-bootstrap/Collapse';
import emailjs from '@emailjs/browser';
import './RecruiterHome.scss';
import './RecruiterHome.css';
import CandidateDataService from '../Service/AWS_Auth/CandidateDataService';
import { Candidate, InterviewSchedule, InterviewStatus } from '../../models';
import interviewService from '../Service/AWS_Auth/interviewService';
import { Image } from '@aws-amplify/ui-react';
import { Storage } from '@aws-amplify/storage';
import { toast } from 'react-toastify';
function RecruiterHome(props) {
  const [optCandidateModal, setOptCandidateModal] = useState(false);
  const [optQuestionModal, setOptQuestionModal] = useState(false);
  const [recruiter, setRecruiter] = React.useState();
  const [selectedUser, setSelectedUser] = React.useState();
  const [recruiterDetails, setRecruiterDetails] = React.useState();
  const [UserRole, setUserRole] = React.useState('');
  const [jobsPendingList, setJobsPendingList] = React.useState([]);
  const [jobsCompletedList, setJobsCompletedList] = React.useState([]);
  const [jobsRejectedList, setJobsRejectedList] = React.useState([]);
  const [jobsAcceptedList, setJobsAcceptedList] = React.useState([]);
  const [jobsDeclinedList, setJobsDeclinedList] = React.useState([]);
  const [userDetails, setUserDetails] = React.useState();
  var jobsPending = [];
  var jobsCompleted = [];
  var jobsRejected = [];
  var jobAccepted = [];
  var jobDeclined = [];
  const [showModal, setShowModal] = useState(false);
  const canonical = document.querySelector('link[rel=canonical]');
  let url = canonical ? canonical.href : document.location.href;

  const sharelinkValidate = (name, companyname) => {
    var msg = 'HI';
    var title =
      'Invite the canditate through your preferred communication app or copy the content and share with the candidate';
    var text = `Hello ${name},${companyname} is inviting you to attend a scheduled interview from Jobble. Kindly login to our website and move to Interview pending post and accept and make video to get great opportunity in your career  .`;
    handleSharing(msg, title, text);
    window.location.reload();
  };
  const handleSharing = async (url, title, text) => {
    // console.log("inside handle sharelink")
    if (navigator.share) {
      const shareDetails = { text, title, text };
      try {
        await navigator
          .share(shareDetails)
          .then(() =>
            console.log('Hooray! Your content was shared to tha world')
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      setShowModal(true);
      console.log(
        ' share Link is currently not supported on this browser. Please provide a callback'
      );
    }
  };

  useEffect(async () => {
    let recruiterID = localStorage.getItem('recruiterId');
    // await  getAllJobs(recruiterID)
    await CandidateDataService.jobByRecruiter(recruiterID)
      .then((jobList) => {
        jobList.forEach(async (job) => {
          // console.log(childData)
          if (
            job.Status.Create == true &&
            job.Status.Completed == false &&
            job.Status.Declined == false
          ) {
            await job.Recruiter.then(async (recruiter) => {
              await job.Candidate.then(async (candidate) => {
                await Storage.get(candidate.ProfilePic).then((val) => {
                  jobsPending.push({
                    CompanyName: recruiter.CompanyName,
                    RecruiterEmail: recruiter.Email,
                    CompanyPhone: recruiter.Phone,
                    CreatedTimeStamp: job.CreatedTime,
                    CompanyPicture: recruiter.Profilepic,
                    Question1: job.Question4,
                    Question2: job.Question5,
                    Question3: job.Question6,
                    RecruiterID: recruiter.id,
                    InterviewID: job.id,
                    CandidateName:
                      candidate.Status == true
                        ? candidate.FirstName + ' ' + candidate.LastName
                        : 'Not yet created',
                    CandidateEmail: candidate.Email,
                    CandidatePhone:
                      candidate.Status == true
                        ? candidate.Phone
                        : 'Not yet created',
                    CandidateProfile: val,
                    CandidateID: candidate.id,
                  });
                });
              });
              // setJobsPendingList(jobsPending);
              setJobsPendingList([...jobsPendingList, ...jobsPending]);
              // })
            });
          }
          if (
            job.Status.Create == true &&
            job.Status.Viewed == true &&
            job.Status.Completed == true &&
            job.Status.Declined == false
          ) {
            await job.Recruiter.then(async (recruiter) => {
              await job.Candidate.then(async (candidate) => {
                await Storage.get(candidate.ProfilePic).then((val) => {
                  jobsCompleted.push({
                    CompanyName: recruiter.CompanyName,
                    RecruiterEmail: recruiter.Email,
                    CompanyPhone: recruiter.Phone,
                    CreatedTimeStamp: job.CreatedTime,
                    CompanyPicture: recruiter.Profilepic,
                    Question1: job.Question4,
                    Question2: job.Question5,
                    Question3: job.Question6,
                    RecruiterID: recruiter.id,
                    InterviewID: job.id,
                    CandidateID: candidate.id,
                    CandidateName:
                      candidate.FirstName + ' ' + candidate.LastName,
                    CandidateEmail: candidate.Email,
                    CandidatePhone: candidate.Phone,
                    CandidateProfile: val,
                  });
                });
              });
              // setJobsCompletedList(jobsCompleted);
              setJobsCompletedList([...jobsCompletedList, ...jobsCompleted]);
            });
          }
          if (
            job.Status.Create == true &&
            job.Status.Viewed == true &&
            job.Status.Completed == true &&
            job.Status.Rejected == true
          ) {
            await job.Recruiter.then(async (recruiter) => {
              await job.Candidate.then(async (candidate) => {
                await Storage.get(candidate.ProfilePic).then((val) => {
                  jobsRejected.push({
                    CompanyName: recruiter.CompanyName,
                    RecruiterEmail: recruiter.Email,
                    CompanyPhone: recruiter.Phone,
                    CreatedTimeStamp: job.CreatedTime,
                    CompanyPicture: recruiter.Profilepic,
                    Question1: job.Question4,
                    Question2: job.Question5,
                    Question3: job.Question6,
                    RecruiterID: recruiter.id,
                    InterviewID: job.id,
                    CandidateID: candidate.id,
                    CandidateName:
                      candidate.FirstName + ' ' + candidate.LastName,
                    CandidateEmail: candidate.Email,
                    CandidatePhone: candidate.Phone,
                    CandidateProfile: val,
                  });
                });
              });
              // setJobsRejectedList(jobsRejected);
              setJobsRejectedList([...jobsRejectedList, ...jobsRejected]);
            });
          }
          if (
            job.Status.Create == true &&
            job.Status.Viewed == true &&
            job.Status.Completed == true &&
            job.Status.Selected == true
          ) {
            await job.Recruiter.then(async (recruiter) => {
              await job.Candidate.then(async (candidate) => {
                await Storage.get(candidate.ProfilePic).then((val) => {
                  jobAccepted.push({
                    CompanyName: recruiter.CompanyName,
                    RecruiterEmail: recruiter.Email,
                    CompanyPhone: recruiter.Phone,
                    CreatedTimeStamp: job.CreatedTime,
                    CompanyPicture: recruiter.Profilepic,
                    Question1: job.Question4,
                    Question2: job.Question5,
                    Question3: job.Question6,
                    RecruiterID: recruiter.id,
                    InterviewID: job.id,
                    CandidateID: candidate.id,
                    CandidateName:
                      candidate.FirstName + ' ' + candidate.LastName,
                    CandidateEmail: candidate.Email,
                    CandidatePhone: candidate.Phone,
                    CandidateProfile: candidate.ProfilePic,
                  });
                });
              });

              // setJobsAcceptedList(jobAccepted);
              setJobsAcceptedList([...jobsAcceptedList, ...jobAccepted]);
            });
          }
          if (job.Status.Create == true && job.Status.Declined == true) {
            await job.Recruiter.then(async (recruiter) => {
              await job.Candidate.then(async (candidate) => {
                await Storage.get(candidate.ProfilePic).then((val) => {
                  jobDeclined.push({
                    CompanyName: recruiter.CompanyName,
                    RecruiterEmail: recruiter.Email,
                    CompanyPhone: recruiter.Phone,
                    CreatedTimeStamp: job.CreatedTime,
                    CompanyPicture: recruiter.Profilepic,
                    Question1: job.Question4,
                    Question2: job.Question5,
                    Question3: job.Question6,
                    RecruiterID: recruiter.id,
                    InterviewID: job.id,
                    CandidateID: candidate.id,
                    CandidateName:
                      candidate.FirstName + ' ' + candidate.LastName,
                    CandidateEmail: candidate.Email,
                    CandidatePhone: candidate.Phone,
                    CandidateProfile: candidate.ProfilePic,
                  });
                });
              });

              // setJobsAcceptedList(jobAccepted);
              setJobsDeclinedList([...jobsDeclinedList, ...jobDeclined]);
            });
          }
        });
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  }, []);

  return (
    <div>
      <RecruiterNavbar></RecruiterNavbar>
      <Container fluid>
        <Row className="qest_txt">
          <Col>
            <h2> Interviews</h2>
          </Col>
        </Row>
      </Container>
      {/* <Container fluid className="create_link_Container">
        <NavLink to="/recruiter/schedule">
          <Button className="create_link_btn ">Schedule an Interview</Button>
        </NavLink>
      </Container> */}

      <br></br>
      <Container>
        <Row>
          <Col>
            <Tabs
              defaultActiveKey="pending"
              id="justify-tab-example"
              className="mb-3 action_bar"
              justify
            >
              <Tab eventKey="pending" title="Pending">
                {jobsPendingList !== undefined && jobsPendingList.length ? (
                  jobsPendingList.map((job) => {
                    return (
                      <div>
                        <Card className="card_content">
                          <Card.Body className="card_body_content">
                            <Row>
                              <Col className="company_logo" md={3}>
                                <Image
                                  className="profile_pic"
                                  src={job.CandidateProfile}
                                  width="300px"
                                  height="300px"
                                />
                              </Col>
                              <Col className="company_details" md={7}>
                                <ul>
                                  <li className="card_company_name">
                                    {job.CandidateName}
                                  </li>
                                  <li className="card_company_email_name">
                                    <span class="ti-email">
                                      {' '}
                                      {job.CandidateEmail}
                                    </span>
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-mobile">
                                      {' '}
                                      {job.CandidatePhone}
                                    </span>
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-time">
                                      {' '}
                                      {job.CreatedTimeStamp}
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col className="respond_btns" md={2}>
                                <Row>
                                  {' '}
                                  <Col>
                                    <ShareCandidate
                                      label={'New Job post '}
                                      text={'Hi'}
                                      title={'Check out new msg'}
                                      urlID={
                                        (props = {
                                          jobInfo: job.InterviewID,
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    {/* <Button onClick={(e)=>viewOnClick(job.JobSeekerUID)} className='pendning_card_View_btn' variant="primary">VIEW</Button> */}
                                    <NavLink
                                      to="/recruiter/candidate_profile"
                                      state={{ CandidateDetails: job }}
                                    >
                                      {' '}
                                      <button
                                        className="slide btn-size"
                                        variant="primary"
                                      >
                                        View
                                      </button>
                                    </NavLink>
                                  </Col>
                                  {/* <Col>
                                    <NavLink
                                      to="/recruiter/schedule/edit"
                                      state={{ CandidateDetails: job }}
                                    >
                                      <button
                                        className="slide btn-size edit"
                                        variant="primary"
                                      >
                                        Edit
                                      </button>
                                    </NavLink>
                                  </Col> */}
                                </Row>
                                <Row>
                                  <Col></Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <Card className="card_content">
                      <Card.Body className="card_body_content">
                        <h4>No Job post available</h4>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Tab>
              <Tab eventKey="completed" title="Completed">
                {jobsCompletedList !== undefined && jobsCompletedList.length ? (
                  jobsCompletedList.map((job) => {
                    return (
                      <div>
                        <Card className="card_content">
                          <Card.Body className="card_body_content">
                            <Row>
                              <Col className="company_details" md={10}>
                                <ul>
                                  <li className="card_company_name">
                                    {job.CandidateName}
                                  </li>
                                  <li className="card_company_email_name">
                                    <span class="ti-email">
                                      {' '}
                                      {job.CandidateEmail}
                                    </span>
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-mobile">
                                      {job.CandidatePhone}
                                    </span>
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-time">
                                      {' '}
                                      {job.CreatedTimeStamp}
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col className="respond_btns" md={2}>
                                <Row>
                                  <Col>
                                    {' '}
                                    <ShareCandidate
                                      label={'New Job post '}
                                      text={'Hi'}
                                      title={'Check out new msg'}
                                      urlID={
                                        (props = {
                                          jobInfo: job.InterviewID,
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <NavLink
                                      to="/recruiter/candidate_profile"
                                      state={{ CandidateDetails: job }}
                                    >
                                      {' '}
                                      <button
                                        className="slide btn-size"
                                        variant="primary"
                                      >
                                        View
                                      </button>
                                    </NavLink>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    {/* <button
                                      className="slide btn-size"
                                      variant="primary"
                                      onClick={(e) =>
                                        sharelinkValidate(
                                          job.JobSeekerName,
                                          job.CompanyName
                                        )
                                      }
                                    >
                                      Share
                                    </button> */}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>{' '}
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <Card className="card_content">
                      <Card.Body className="card_body_content">
                        <h4>No job post available</h4>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Tab>

              <Tab eventKey="accepted" title="Selected">
                {jobsAcceptedList !== undefined && jobsAcceptedList.length ? (
                  jobsAcceptedList.map((job) => {
                    return (
                      <div>
                        <Card className="card_content">
                          <Card.Body className="card_body_content">
                            <Row>
                              <Col className="company_details" md={10}>
                                <ul>
                                  <li className="card_company_name">
                                    {job.CandidateName}
                                  </li>
                                  <li className="card_company_email_name">
                                    <span class="ti-email">
                                      {job.CandidateEmail}
                                    </span>
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-mobile">
                                      {job.CandidatePhone}
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col className="respond_btns" md={2}>
                                <Row>
                                  <Col>
                                    <button
                                      className=" slide btn-size"
                                      variant="primary"
                                    >
                                      View
                                    </button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col></Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <Card className="card_content">
                      <Card.Body className="card_body_content">
                        <h4>No Job post available</h4>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Tab>
              <Tab eventKey="rejected" title="Rejected">
                {jobsRejectedList !== undefined && jobsRejectedList.length ? (
                  jobsRejectedList.map((job) => {
                    return (
                      <div>
                        <Card className="card_content">
                          <Card.Body className="card_body_content">
                            <Row>
                              <Col className="company_details" md={10}>
                                <ul>
                                  <li className="card_company_name">
                                    {job.CandidateName}
                                  </li>
                                  <li className="card_company_email_name">
                                    <span class="ti-email">
                                      {' '}
                                      {job.CandidateEmail}
                                    </span>
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-mobile">
                                      {job.CandidatePhone}
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col className="respond_btns" md={2}>
                                <Row>
                                  <Col>
                                    <button
                                      className=" slide btn-size"
                                      variant="primary"
                                    >
                                      View
                                    </button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <button
                                      className=" slide btn-size"
                                      variant="primary"
                                    >
                                      Dismiss
                                    </button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <Card className="card_content">
                      <Card.Body className="card_body_content">
                        <h4>No Job post available</h4>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Tab>
              <Tab eventKey="Declined" title="Declined">
                {jobsDeclinedList !== undefined && jobsDeclinedList.length ? (
                  jobsDeclinedList.map((job) => {
                    return (
                      <div>
                        <Card className="card_content">
                          <Card.Body className="card_body_content">
                            <Row>
                              <Col className="company_details" md={10}>
                                <ul>
                                  <li className="card_company_name">
                                    {job.CandidateName}
                                  </li>
                                  <li className="card_company_email_name">
                                    <span class="ti-email">
                                      {' '}
                                      {job.CandidateEmail}
                                    </span>
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-mobile">
                                      {job.CandidatePhone}
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col className="respond_btns" md={2}>
                                <Row>
                                  <Col>
                                    <button
                                      className=" slide btn-size"
                                      variant="primary"
                                    >
                                      View
                                    </button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <button
                                      className=" slide btn-size"
                                      variant="primary"
                                    >
                                      Dismiss
                                    </button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <Card className="card_content">
                      <Card.Body className="card_body_content">
                        <h4>No Job post available</h4>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RecruiterHome;
