import React from 'react';
import ModalVideo from 'react-modal-video';
import { Grid } from '@mui/material';
import 'react-modal-video/scss/modal-video.scss';
import styles from './VideoModal.module.css';
import { toast } from 'react-toastify';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Playbutton from '../../assets/images/play_logo.png';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

class VideoModal extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <>
        <ModalVideo
          allowFullScreen
          channel="custom"
          isOpen={this.state.isOpen}
          // videoId="O6AxxYhCxeQ"
          url={this.props.video}
          onClose={() => this.setState({ isOpen: false })}
        />
        {/* {this.props.video != '' ? ( */}
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Card
              variant="outlined"
              className={styles.card_style}
              onClick={(e) => {
                if (this.props.video != '') {
                  console.log(this.props.video, 'this.props.video');
                  this.openModal();
                } else {
                  toast.info('This video is not recorded by candidate');
                }
              }}
            >
              <CardHeader
                avatar={<Avatar alt={this.props.id} src="/"></Avatar>}
                title={this.props.question}
                classes={{
                  title: styles.question,
                }}
                action={
                  <IconButton
                    className={styles.video_play_btn}
                    onClick={(e) => {
                      if (this.props.video != '') {
                        this.openModal();
                      } else {
                        toast.info('This video is not recorded by candidate');
                      }
                    }}
                  >
                    <img
                      src={Playbutton}
                      width="65px"
                      height="65px"
                      alt="play"
                      className={styles.play_btn_img}
                    ></img>
                  </IconButton>
                }
              />
            </Card>
          </Grid>
        </Grid>
        {/* ) : (
          //-----------------------------Test-------------------------------------//
          <></>
        )} */}
      </>
    );
  }
}

export default VideoModal;
