import React from 'react';
import { useEffect, useState } from 'react';
import Navbar from '../HomePage/Navbar';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  parsePhoneNumber,
  isValidPhoneNumber,
  getNumberType,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import AwsAmplify from '../Service/AWS_Auth/RecruiterDataService';
import CompanyAmplify from '../Service/AWS_Auth/CompanyDataService';
import { toast } from 'react-toastify';
import { Storage } from '@aws-amplify/storage';
import { useNavigate } from 'react-router-dom';
import CompanyDataService from '../Service/AWS_Auth/CompanyDataService';
import { Company, Recruiter } from '../../models';
import RecruiterDataService from '../Service/AWS_Auth/RecruiterDataService';
const steps = ['Recruiter Info', 'Company Details'];

function Test() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [selectedOption, setSelectedOption] = useState('');
  const [error, setError] = useState('');
  const [id, setID] = React.useState();
  const [allCompany, setAllCompany] = React.useState('');
  const [website, setWebsite] = React.useState('');
  const [companyExist, setCompanyExist] = React.useState(false);
  const [companyID, setCompanyID] = React.useState('');
  const [formValues, setFormValues] = useState({
    email: {
      step: 0,
      value: '',
      error: false,
      errorMessage: 'You must enter your registered emailID',
    },
    firstName: {
      step: 0,
      value: '',
      error: false,
      errorMessage: 'You must enter a first name',
    },
    lastName: {
      step: 0,
      value: '',
      error: false,
      errorMessage: 'You must enter a last name',
    },
    phone: {
      step: 0,
      value: '',
      error: false,
      errorMessage: 'You must enter a phone number',
    },
    companyName: {
      step: 1,
      value: '',
      error: false,
      errorMessage: 'You must select a company name',
    },
    // profilePic:{
    //   step: 1,
    //   value: '',
    //   error: false,
    //   errorMessage: 'You must select a profile picture',
    // }
  });

  const getAllCompany = async () => {
    let email = localStorage.getItem('email');
    await CompanyAmplify.getALL()
      .then((allCompany) => {
        let company = [];
        // let emails = [];
        let i = 0;
        allCompany.forEach((child) => {
          company.push({
            id: i,
            companyId: child.id,
            name: child.Name,
            Phone: child.Phone,
            Website: child.Website,
            Email: child.Email.split('@')[1],
          });
          i++;
          // emails.push(child.Email.split('@')[1])
        });
        setAllCompany(company);
        if (company.length === allCompany.length) {
          company.some((element) => {
            if (element.Email === email.split('@')[1]) {
              console.log(element.Email);
              setCompanyExist(true);
              setCompanyID(element.companyId);
              setFormValues({
                ...formValues,
                companyName: {
                  value: element.name,
                  step: 1,
                  error: false,
                  errorMessage: 'You must select a company name',
                },
                email: formValues.email,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                phone: formValues.phone,
                // profilePic: formValues.profilePic,
              });
              return element;
            } else {
              console.log(email.split('@')[1].split('.')[0]);
              setCompanyExist(false);
              setFormValues({
                ...formValues,
                companyName: {
                  value: email.split('@')[1].split('.')[0],
                  step: 1,
                  error: false,
                  errorMessage: 'You must select a company name',
                },
                email: formValues.email,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                phone: formValues.phone,
                // profilePic: formValues.profilePic,
              });
            }
          });
        }
        return email;
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

  useEffect(async () => {
    let email = localStorage.getItem('email');
    await AwsAmplify.getRecruiter(email)
      .then(async (userValue) => {
        let allEmail = await getAllCompany();

        if (userValue != undefined && userValue.length != 0) {
          setID(userValue.id);
          localStorage.setItem('recruiterId', userValue.id);
          localStorage.setItem('fname', userValue.FirstName);
          localStorage.setItem('lname', userValue.LastName);
          await Storage.get(userValue.Profilepic).then((pic) => {
            localStorage.setItem('profilePic', pic);
          });
          localStorage.setItem('companyId', userValue.recruiterCompanyId);
          console.log(email.split('@')[1]);

          if (userValue.Profilepic != null) {
            await Storage.get(userValue.Profilepic).then((val) => {
              setCompanyID(userValue.recruiterCompanyId);
              setFormValues((prevState) => {
                return {
                  ...prevState,
                  email: {
                    value: email,
                    step: 0,
                    error: false,
                    errorMessage: 'You must enter your registered emailID',
                  },
                  firstName: {
                    value: userValue.FirstName,
                    step: 0,
                    error: false,
                    errorMessage: 'You must enter a first name',
                  },
                  lastName: {
                    value: userValue.LastName,
                    step: 0,
                    error: false,
                    errorMessage: 'You must enter a last name',
                  },
                  phone: {
                    value: userValue.Phone,
                    step: 0,
                    error: false,
                    errorMessage: 'You must enter a phone number',
                  },
                };
              });
            });
          }
        } else {
          setFormValues((prevState) => {
            return {
              ...prevState,
              email: {
                value: email,
                step: 0,
                error: false,
                errorMessage: 'You must enter your registered emailID',
              },
            };
          });
        }
        console.log(
          'name',
          formValues.firstName.value,
          formValues.lastName.value
        );
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setSelectedOption(value);
    setError(value ? '' : 'Please select an option');
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value,
      },
    });
  };

  const validateFormDataForEmpty = (step) => {
    const formFields = Object.keys(formValues); //.filter(f => f.step === step)
    let newFormValues = { ...formValues };
    let valid = true;

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      console.log('currentField', formValues[currentField]);
      const currentStep = formValues[currentField].step;
      if (currentStep !== step) continue;

      const currentValue = formValues[currentField].value;

      if (currentValue === '') {
        console.log('if currentField', currentField, currentValue);
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true,
          },
        };
        valid = false;
      } else {
        console.log('else currentField', currentField, currentValue);
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: false,
          },
        };
      }
    }

    setFormValues(newFormValues);
    return valid;
  };

  const validateFormData = async (step) => {
    let valid = validateFormDataForEmpty(step);
    console.log('validateFormData', valid);
    if (valid) {
      if (step == 1) {
        //save company if new
        if (companyExist == false) {
          let data = new Company({
            Name: formValues.companyName.value,
            Phone: formValues.phone.value,
            Website: website,
            Email: formValues.email.value,
            CreatedDateTime: new Date().toLocaleString(),
          });
          await CompanyDataService.save(data).then(async (company) => {
            console.log('company reg', company);
            let recruiter = new Recruiter({
              FirstName: formValues.firstName.value,
              LastName: formValues.lastName.value,
              Phone: formValues.phone.value,
              Email: formValues.email.value,
              Linkedin: '',
              CompanyName: company.Name,
              recruiterCompanyId: company.id,
              Profilepic: '',
            });
            await RecruiterDataService.save(recruiter);
          });
        } else {
          let recruiter = new Recruiter({
            FirstName: formValues.firstName.value,
            LastName: formValues.lastName.value,
            Phone: formValues.phone.value,
            Email: formValues.email.value,
            Linkedin: '',
            CompanyName: formValues.companyName.value,
            recruiterCompanyId: companyID,
            Profilepic: '',
          });
          await RecruiterDataService.save(recruiter);
        }
      }
    }
    return valid;
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    console.log('calling next', activeStep);
    const valid = validateFormData(activeStep);
    console.log('form state', valid);
    if (!valid) return;

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleDone = () => {
    navigate('/newInterviewPage');
  };

  return (
    <div>
      <Navbar />
      <Container fixed className="create_profile_stepper">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              // if (isStepOptional(index)) {
              //   labelProps.optional = (
              //     <Typography variant="caption">Optional</Typography>
              //   );
              // }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleDone}>Done</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="hide">
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Step {activeStep + 1}
                </Typography>
              </div>

              <Container fixed className="personal_info_container">
                {activeStep == 0 && (
                  <Box
                    component="form"
                    onChange={handleChange}
                    sx={{
                      '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="E-mail"
                          variant="outlined"
                          disabled
                          value={formValues.email.value}
                          InputLabelProps={{
                            shrink: true,
                            style: { position: 'absolute' },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MuiPhoneNumber
                          required
                          fullWidth
                          label="Phone Number"
                          variant="outlined"
                          defaultCountry={'in'}
                          value={formValues.phone.value}
                          error={formValues.phone.error}
                          helperText={
                            formValues.phone.error &&
                            formValues.phone.errorMessage
                          }
                          onChange={(c, t) => {
                            console.log(c, t, isValidPhoneNumber(c));
                            if (isValidPhoneNumber(c)) {
                              setFormValues((prevState) => {
                                return {
                                  ...prevState,
                                  phone: {
                                    value: c,
                                    step: 0,
                                    error: false,
                                    errorMessage:
                                      'You must enter your registered emailID',
                                  },
                                };
                              });
                              return true;
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="First Name"
                          variant="outlined"
                          required
                          name="firstName"
                          value={formValues.firstName.value}
                          error={formValues.firstName.error}
                          helperText={
                            formValues.firstName.error &&
                            formValues.firstName.errorMessage
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { position: 'absolute' },
                          }}
                          onChange={(e) => {
                            setFormValues((prevState) => {
                              return {
                                ...prevState,
                                firstName: {
                                  value: e.target.value,
                                  step: 0,
                                  error: false,
                                  errorMessage: 'You must enter a first name',
                                },
                              };
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Last Name"
                          variant="outlined"
                          required
                          name="lastName"
                          value={formValues.lastName.value}
                          error={formValues.lastName.error}
                          helperText={
                            formValues.lastName.error &&
                            formValues.lastName.errorMessage
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { position: 'absolute' },
                          }}
                          onChange={(e) => {
                            setFormValues((prevState) => {
                              return {
                                ...prevState,
                                lastName: {
                                  value: e.target.value,
                                  step: 0,
                                  error: false,
                                  errorMessage: 'You must enter a last name',
                                },
                              };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br />
                  </Box>
                )}
              </Container>

              {activeStep == 1 && (
                <div>
                  <Container fixed className="company_info_container">
                    <Grid container>
                      <br />

                      <Grid item xs={6}>
                        {' '}
                        <Box
                          component="form"
                          sx={{
                            '& > :not(style)': { m: 10, width: '50ch' },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Company Name"
                              variant="outlined"
                              required
                              name="CompanyName"
                              value={formValues.companyName.value}
                              error={formValues.companyName.error}
                              helperText={
                                formValues.companyName.error &&
                                formValues.companyName.errorMessage
                              }
                              InputLabelProps={{
                                shrink: true,
                                style: { position: 'absolute' },
                              }}
                              onChange={(e) => {
                                setFormValues((prevState) => {
                                  return {
                                    ...prevState,
                                    companyName: {
                                      value: e.target.value,
                                      step: 1,
                                      error: false,
                                      errorMessage:
                                        'You must enter a company name',
                                    },
                                  };
                                });
                              }}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        {' '}
                        <Box
                          component="form"
                          sx={{
                            '& > :not(style)': { m: 10, width: '50ch' },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Website"
                              variant="outlined"
                              name="website"
                              value={website}
                              InputLabelProps={{
                                shrink: true,
                                style: { position: 'absolute' },
                              }}
                              onChange={(e) => {
                                setWebsite(e.target.value);
                              }}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              )}

              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  className="back_btn"
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {/* {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )} */}

                <Button className="next_btn" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default Test;
