import React from 'react';
import './Login.css';
import Navbar from '../HomePage/Navbar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import GIF from '../../assets/images/login_page_gif.gif';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import AwsAuth from '../Service/AWS_Auth/authenticationServices';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function CheckMail() {
  const [code, setcode] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (email != '' && password != '' && code != '') {
        console.log('email', email);
        await AwsAuth.forgetPasswordSubmitPassword(email, code, password)
          .then((value) => {
            console.log(value);
            toast.success('New password is successfully updated');
            navigate('/login');
          })
          .catch((e) => {
            toast.error(e);
          });
      } else {
        toast.error('Please enter the required field');
      }
    } catch (e) {
      toast.error(e);
    }
  };
  useEffect(() => {
    let emailTemp = localStorage.getItem('emailTemp');
    setEmail(emailTemp);
  }, []);
  return (
    <>
      <Navbar />
      <div>
        <Grid container>
          <Grid item xs={0} md={6}>
            <div className="login_title_txt">
              <Card className="login-card">
                <img className="loginPage_GIF" src={GIF} width="100%" alt="" />
              </Card>
            </div>
          </Grid>
          <Grid item xs={12} md={6} elevation={6} square>
            <Box
              sx={{
                my: 50,
                mx: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: '#6366f1' }}>
                <EmailIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Check your email
              </Typography>

              <small>
                We have sent a verification code to your mail, enter here
              </small>
              <Grid item xs={12} md={6} elevation={6} square>
                <Box
                  sx={{
                    my: 50,
                    mx: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography component="h1" variant="h5"></Typography>
                  {
                    <Box component="form" noValidate sx={{ mt: 3 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="Code"
                            label="Confirm code"
                            name="confirm code"
                            color={'primary'}
                            onChange={(e) => setcode(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="Password"
                            label="Password"
                            name="password"
                            color={'primary'}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        className="register_btn"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                        // onKeyPress={handleKeyPress}
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                      >
                        VERIFY
                      </Button>
                    </Box>
                  }
                </Box>
              </Grid>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <Link to="/forgotpassword">
                  <Button
                    className="resend_btn"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Resend
                  </Button>
                </Link>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    Didn't receive the email. Click the above button to re-send.
                  </Grid>
                </Grid>

                {/* <Link to="/login">
                  <Button
                    className="login_back_btn"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: 'white',
                        color: '#6366f1',
                        fontSize: 'small',
                      }}
                    >
                      <ArrowBackIcon />
                    </Avatar>
                    Back
                  </Button>
                </Link> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default CheckMail;
