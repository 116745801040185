/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInterviewFeedback = /* GraphQL */ `
  mutation CreateInterviewFeedback(
    $input: CreateInterviewFeedbackInput!
    $condition: ModelInterviewFeedbackConditionInput
  ) {
    createInterviewFeedback(input: $input, condition: $condition) {
      id
      Comments
      RecruiterName
      RecruiterEmail
      FeedbackStatus
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      Interview {
        id
        Question1
        Question2
        Question3
        ActionStatus
        CreatedTime
        CompletedTime
        Video1
        Video2
        Video3
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        JobReferenceID
        JobTitle
        recruiterID
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        DecisionStatus
        jobID
        Candidates {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewCandidatesId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewFeedbackRecruiterId
      interviewFeedbackInterviewId
    }
  }
`;
export const updateInterviewFeedback = /* GraphQL */ `
  mutation UpdateInterviewFeedback(
    $input: UpdateInterviewFeedbackInput!
    $condition: ModelInterviewFeedbackConditionInput
  ) {
    updateInterviewFeedback(input: $input, condition: $condition) {
      id
      Comments
      RecruiterName
      RecruiterEmail
      FeedbackStatus
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      Interview {
        id
        Question1
        Question2
        Question3
        ActionStatus
        CreatedTime
        CompletedTime
        Video1
        Video2
        Video3
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        JobReferenceID
        JobTitle
        recruiterID
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        DecisionStatus
        jobID
        Candidates {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewCandidatesId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewFeedbackRecruiterId
      interviewFeedbackInterviewId
    }
  }
`;
export const deleteInterviewFeedback = /* GraphQL */ `
  mutation DeleteInterviewFeedback(
    $input: DeleteInterviewFeedbackInput!
    $condition: ModelInterviewFeedbackConditionInput
  ) {
    deleteInterviewFeedback(input: $input, condition: $condition) {
      id
      Comments
      RecruiterName
      RecruiterEmail
      FeedbackStatus
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      Interview {
        id
        Question1
        Question2
        Question3
        ActionStatus
        CreatedTime
        CompletedTime
        Video1
        Video2
        Video3
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        JobReferenceID
        JobTitle
        recruiterID
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        DecisionStatus
        jobID
        Candidates {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewCandidatesId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewFeedbackRecruiterId
      interviewFeedbackInterviewId
    }
  }
`;
export const createInterviewSchedule = /* GraphQL */ `
  mutation CreateInterviewSchedule(
    $input: CreateInterviewScheduleInput!
    $condition: ModelInterviewScheduleConditionInput
  ) {
    createInterviewSchedule(input: $input, condition: $condition) {
      id
      Title
      Description
      CreatedTime
      CompletedTime
      Status {
        Create
        Viewed
        Completed
        Declined
        Selected
        Rejected
      }
      Video4
      Video5
      Video6
      recruiterID
      Question4
      Question5
      Question6
      CandidateName
      CandidateEmail
      RecruiterName
      RecruiterEmail
      RecruiterCompany
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      Candidate {
        id
        FirstName
        Phone
        Email
        DOB
        Qualification
        LastName
        HomeLocation
        CurrentLocation
        College
        CollegeCity
        Course
        CGPA
        Experience
        Resume
        FresherProjectDetails
        FresherProjectName
        FresherProjectTools
        YOE
        CompanyName
        CurrentRole
        Github
        Linkedin
        Youtube
        ProfilePic
        Video1
        Video2
        Video3
        Gender
        Status
        WorkPreference
        Relocation
        CurrentSalary
        ExpectedSalary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewScheduleCandidateId
      owner
    }
  }
`;
export const updateInterviewSchedule = /* GraphQL */ `
  mutation UpdateInterviewSchedule(
    $input: UpdateInterviewScheduleInput!
    $condition: ModelInterviewScheduleConditionInput
  ) {
    updateInterviewSchedule(input: $input, condition: $condition) {
      id
      Title
      Description
      CreatedTime
      CompletedTime
      Status {
        Create
        Viewed
        Completed
        Declined
        Selected
        Rejected
      }
      Video4
      Video5
      Video6
      recruiterID
      Question4
      Question5
      Question6
      CandidateName
      CandidateEmail
      RecruiterName
      RecruiterEmail
      RecruiterCompany
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      Candidate {
        id
        FirstName
        Phone
        Email
        DOB
        Qualification
        LastName
        HomeLocation
        CurrentLocation
        College
        CollegeCity
        Course
        CGPA
        Experience
        Resume
        FresherProjectDetails
        FresherProjectName
        FresherProjectTools
        YOE
        CompanyName
        CurrentRole
        Github
        Linkedin
        Youtube
        ProfilePic
        Video1
        Video2
        Video3
        Gender
        Status
        WorkPreference
        Relocation
        CurrentSalary
        ExpectedSalary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewScheduleCandidateId
      owner
    }
  }
`;
export const deleteInterviewSchedule = /* GraphQL */ `
  mutation DeleteInterviewSchedule(
    $input: DeleteInterviewScheduleInput!
    $condition: ModelInterviewScheduleConditionInput
  ) {
    deleteInterviewSchedule(input: $input, condition: $condition) {
      id
      Title
      Description
      CreatedTime
      CompletedTime
      Status {
        Create
        Viewed
        Completed
        Declined
        Selected
        Rejected
      }
      Video4
      Video5
      Video6
      recruiterID
      Question4
      Question5
      Question6
      CandidateName
      CandidateEmail
      RecruiterName
      RecruiterEmail
      RecruiterCompany
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      Candidate {
        id
        FirstName
        Phone
        Email
        DOB
        Qualification
        LastName
        HomeLocation
        CurrentLocation
        College
        CollegeCity
        Course
        CGPA
        Experience
        Resume
        FresherProjectDetails
        FresherProjectName
        FresherProjectTools
        YOE
        CompanyName
        CurrentRole
        Github
        Linkedin
        Youtube
        ProfilePic
        Video1
        Video2
        Video3
        Gender
        Status
        WorkPreference
        Relocation
        CurrentSalary
        ExpectedSalary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewScheduleCandidateId
      owner
    }
  }
`;
export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate(
    $input: CreateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    createCandidate(input: $input, condition: $condition) {
      id
      FirstName
      Phone
      Email
      DOB
      Qualification
      LastName
      HomeLocation
      CurrentLocation
      College
      CollegeCity
      Course
      CGPA
      Experience
      Resume
      FresherProjectDetails
      FresherProjectName
      FresherProjectTools
      YOE
      CompanyName
      CurrentRole
      Github
      Linkedin
      Youtube
      ProfilePic
      Video1
      Video2
      Video3
      Gender
      Status
      WorkPreference
      Relocation
      CurrentSalary
      ExpectedSalary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateCandidate = /* GraphQL */ `
  mutation UpdateCandidate(
    $input: UpdateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    updateCandidate(input: $input, condition: $condition) {
      id
      FirstName
      Phone
      Email
      DOB
      Qualification
      LastName
      HomeLocation
      CurrentLocation
      College
      CollegeCity
      Course
      CGPA
      Experience
      Resume
      FresherProjectDetails
      FresherProjectName
      FresherProjectTools
      YOE
      CompanyName
      CurrentRole
      Github
      Linkedin
      Youtube
      ProfilePic
      Video1
      Video2
      Video3
      Gender
      Status
      WorkPreference
      Relocation
      CurrentSalary
      ExpectedSalary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteCandidate = /* GraphQL */ `
  mutation DeleteCandidate(
    $input: DeleteCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    deleteCandidate(input: $input, condition: $condition) {
      id
      FirstName
      Phone
      Email
      DOB
      Qualification
      LastName
      HomeLocation
      CurrentLocation
      College
      CollegeCity
      Course
      CGPA
      Experience
      Resume
      FresherProjectDetails
      FresherProjectName
      FresherProjectTools
      YOE
      CompanyName
      CurrentRole
      Github
      Linkedin
      Youtube
      ProfilePic
      Video1
      Video2
      Video3
      Gender
      Status
      WorkPreference
      Relocation
      CurrentSalary
      ExpectedSalary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createRecruiter = /* GraphQL */ `
  mutation CreateRecruiter(
    $input: CreateRecruiterInput!
    $condition: ModelRecruiterConditionInput
  ) {
    createRecruiter(input: $input, condition: $condition) {
      id
      FirstName
      Phone
      Email
      Linkedin
      Company {
        id
        Name
        Phone
        Website
        Email
        CreatedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      Profilepic
      CompanyName
      LastName
      InterviewSchedules {
        items {
          id
          Title
          Description
          CreatedTime
          CompletedTime
          Video4
          Video5
          Video6
          recruiterID
          Question4
          Question5
          Question6
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewScheduleCandidateId
          owner
        }
        nextToken
        startedAt
      }
      Interviews {
        items {
          id
          Question1
          Question2
          Question3
          ActionStatus
          CreatedTime
          CompletedTime
          Video1
          Video2
          Video3
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          JobReferenceID
          JobTitle
          recruiterID
          DecisionStatus
          jobID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewCandidatesId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      recruiterCompanyId
      owner
    }
  }
`;
export const updateRecruiter = /* GraphQL */ `
  mutation UpdateRecruiter(
    $input: UpdateRecruiterInput!
    $condition: ModelRecruiterConditionInput
  ) {
    updateRecruiter(input: $input, condition: $condition) {
      id
      FirstName
      Phone
      Email
      Linkedin
      Company {
        id
        Name
        Phone
        Website
        Email
        CreatedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      Profilepic
      CompanyName
      LastName
      InterviewSchedules {
        items {
          id
          Title
          Description
          CreatedTime
          CompletedTime
          Video4
          Video5
          Video6
          recruiterID
          Question4
          Question5
          Question6
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewScheduleCandidateId
          owner
        }
        nextToken
        startedAt
      }
      Interviews {
        items {
          id
          Question1
          Question2
          Question3
          ActionStatus
          CreatedTime
          CompletedTime
          Video1
          Video2
          Video3
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          JobReferenceID
          JobTitle
          recruiterID
          DecisionStatus
          jobID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewCandidatesId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      recruiterCompanyId
      owner
    }
  }
`;
export const deleteRecruiter = /* GraphQL */ `
  mutation DeleteRecruiter(
    $input: DeleteRecruiterInput!
    $condition: ModelRecruiterConditionInput
  ) {
    deleteRecruiter(input: $input, condition: $condition) {
      id
      FirstName
      Phone
      Email
      Linkedin
      Company {
        id
        Name
        Phone
        Website
        Email
        CreatedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      Profilepic
      CompanyName
      LastName
      InterviewSchedules {
        items {
          id
          Title
          Description
          CreatedTime
          CompletedTime
          Video4
          Video5
          Video6
          recruiterID
          Question4
          Question5
          Question6
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewScheduleCandidateId
          owner
        }
        nextToken
        startedAt
      }
      Interviews {
        items {
          id
          Question1
          Question2
          Question3
          ActionStatus
          CreatedTime
          CompletedTime
          Video1
          Video2
          Video3
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          JobReferenceID
          JobTitle
          recruiterID
          DecisionStatus
          jobID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewCandidatesId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      recruiterCompanyId
      owner
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      Name
      Phone
      Website
      Email
      CreatedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      Name
      Phone
      Website
      Email
      CreatedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      Name
      Phone
      Website
      Email
      CreatedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createInterview = /* GraphQL */ `
  mutation CreateInterview(
    $input: CreateInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    createInterview(input: $input, condition: $condition) {
      id
      Question1
      Question2
      Question3
      ActionStatus
      CreatedTime
      CompletedTime
      Video1
      Video2
      Video3
      CandidateName
      CandidateEmail
      RecruiterName
      RecruiterEmail
      RecruiterCompany
      JobReferenceID
      JobTitle
      recruiterID
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      DecisionStatus
      jobID
      Candidates {
        id
        FirstName
        Phone
        Email
        DOB
        Qualification
        LastName
        HomeLocation
        CurrentLocation
        College
        CollegeCity
        Course
        CGPA
        Experience
        Resume
        FresherProjectDetails
        FresherProjectName
        FresherProjectTools
        YOE
        CompanyName
        CurrentRole
        Github
        Linkedin
        Youtube
        ProfilePic
        Video1
        Video2
        Video3
        Gender
        Status
        WorkPreference
        Relocation
        CurrentSalary
        ExpectedSalary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewCandidatesId
      owner
    }
  }
`;
export const updateInterview = /* GraphQL */ `
  mutation UpdateInterview(
    $input: UpdateInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    updateInterview(input: $input, condition: $condition) {
      id
      Question1
      Question2
      Question3
      ActionStatus
      CreatedTime
      CompletedTime
      Video1
      Video2
      Video3
      CandidateName
      CandidateEmail
      RecruiterName
      RecruiterEmail
      RecruiterCompany
      JobReferenceID
      JobTitle
      recruiterID
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      DecisionStatus
      jobID
      Candidates {
        id
        FirstName
        Phone
        Email
        DOB
        Qualification
        LastName
        HomeLocation
        CurrentLocation
        College
        CollegeCity
        Course
        CGPA
        Experience
        Resume
        FresherProjectDetails
        FresherProjectName
        FresherProjectTools
        YOE
        CompanyName
        CurrentRole
        Github
        Linkedin
        Youtube
        ProfilePic
        Video1
        Video2
        Video3
        Gender
        Status
        WorkPreference
        Relocation
        CurrentSalary
        ExpectedSalary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewCandidatesId
      owner
    }
  }
`;
export const deleteInterview = /* GraphQL */ `
  mutation DeleteInterview(
    $input: DeleteInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    deleteInterview(input: $input, condition: $condition) {
      id
      Question1
      Question2
      Question3
      ActionStatus
      CreatedTime
      CompletedTime
      Video1
      Video2
      Video3
      CandidateName
      CandidateEmail
      RecruiterName
      RecruiterEmail
      RecruiterCompany
      JobReferenceID
      JobTitle
      recruiterID
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      DecisionStatus
      jobID
      Candidates {
        id
        FirstName
        Phone
        Email
        DOB
        Qualification
        LastName
        HomeLocation
        CurrentLocation
        College
        CollegeCity
        Course
        CGPA
        Experience
        Resume
        FresherProjectDetails
        FresherProjectName
        FresherProjectTools
        YOE
        CompanyName
        CurrentRole
        Github
        Linkedin
        Youtube
        ProfilePic
        Video1
        Video2
        Video3
        Gender
        Status
        WorkPreference
        Relocation
        CurrentSalary
        ExpectedSalary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewCandidatesId
      owner
    }
  }
`;
export const createQuestionBank = /* GraphQL */ `
  mutation CreateQuestionBank(
    $input: CreateQuestionBankInput!
    $condition: ModelQuestionBankConditionInput
  ) {
    createQuestionBank(input: $input, condition: $condition) {
      id
      QuestionText
      Jobs {
        items {
          id
          questionBankId
          jobId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateQuestionBank = /* GraphQL */ `
  mutation UpdateQuestionBank(
    $input: UpdateQuestionBankInput!
    $condition: ModelQuestionBankConditionInput
  ) {
    updateQuestionBank(input: $input, condition: $condition) {
      id
      QuestionText
      Jobs {
        items {
          id
          questionBankId
          jobId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteQuestionBank = /* GraphQL */ `
  mutation DeleteQuestionBank(
    $input: DeleteQuestionBankInput!
    $condition: ModelQuestionBankConditionInput
  ) {
    deleteQuestionBank(input: $input, condition: $condition) {
      id
      QuestionText
      Jobs {
        items {
          id
          questionBankId
          jobId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      InternalID
      Title
      Description
      MinimumExperience
      MaximumExperience
      Skills
      RemoteWorking
      Gender
      Status
      Location
      HiringManagerName
      HiringManagerEmail
      Interviews {
        items {
          id
          Question1
          Question2
          Question3
          ActionStatus
          CreatedTime
          CompletedTime
          Video1
          Video2
          Video3
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          JobReferenceID
          JobTitle
          recruiterID
          DecisionStatus
          jobID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewCandidatesId
          owner
        }
        nextToken
        startedAt
      }
      questionbanks {
        items {
          id
          questionBankId
          jobId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      Company {
        id
        Name
        Phone
        Website
        Email
        CreatedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobCompanyId
      owner
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      InternalID
      Title
      Description
      MinimumExperience
      MaximumExperience
      Skills
      RemoteWorking
      Gender
      Status
      Location
      HiringManagerName
      HiringManagerEmail
      Interviews {
        items {
          id
          Question1
          Question2
          Question3
          ActionStatus
          CreatedTime
          CompletedTime
          Video1
          Video2
          Video3
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          JobReferenceID
          JobTitle
          recruiterID
          DecisionStatus
          jobID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewCandidatesId
          owner
        }
        nextToken
        startedAt
      }
      questionbanks {
        items {
          id
          questionBankId
          jobId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      Company {
        id
        Name
        Phone
        Website
        Email
        CreatedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobCompanyId
      owner
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      InternalID
      Title
      Description
      MinimumExperience
      MaximumExperience
      Skills
      RemoteWorking
      Gender
      Status
      Location
      HiringManagerName
      HiringManagerEmail
      Interviews {
        items {
          id
          Question1
          Question2
          Question3
          ActionStatus
          CreatedTime
          CompletedTime
          Video1
          Video2
          Video3
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          JobReferenceID
          JobTitle
          recruiterID
          DecisionStatus
          jobID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewCandidatesId
          owner
        }
        nextToken
        startedAt
      }
      questionbanks {
        items {
          id
          questionBankId
          jobId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      Company {
        id
        Name
        Phone
        Website
        Email
        CreatedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobCompanyId
      owner
    }
  }
`;
export const createQuestionBankJob = /* GraphQL */ `
  mutation CreateQuestionBankJob(
    $input: CreateQuestionBankJobInput!
    $condition: ModelQuestionBankJobConditionInput
  ) {
    createQuestionBankJob(input: $input, condition: $condition) {
      id
      questionBankId
      jobId
      questionBank {
        id
        QuestionText
        Jobs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      job {
        id
        InternalID
        Title
        Description
        MinimumExperience
        MaximumExperience
        Skills
        RemoteWorking
        Gender
        Status
        Location
        HiringManagerName
        HiringManagerEmail
        Interviews {
          nextToken
          startedAt
        }
        questionbanks {
          nextToken
          startedAt
        }
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobCompanyId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateQuestionBankJob = /* GraphQL */ `
  mutation UpdateQuestionBankJob(
    $input: UpdateQuestionBankJobInput!
    $condition: ModelQuestionBankJobConditionInput
  ) {
    updateQuestionBankJob(input: $input, condition: $condition) {
      id
      questionBankId
      jobId
      questionBank {
        id
        QuestionText
        Jobs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      job {
        id
        InternalID
        Title
        Description
        MinimumExperience
        MaximumExperience
        Skills
        RemoteWorking
        Gender
        Status
        Location
        HiringManagerName
        HiringManagerEmail
        Interviews {
          nextToken
          startedAt
        }
        questionbanks {
          nextToken
          startedAt
        }
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobCompanyId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteQuestionBankJob = /* GraphQL */ `
  mutation DeleteQuestionBankJob(
    $input: DeleteQuestionBankJobInput!
    $condition: ModelQuestionBankJobConditionInput
  ) {
    deleteQuestionBankJob(input: $input, condition: $condition) {
      id
      questionBankId
      jobId
      questionBank {
        id
        QuestionText
        Jobs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      job {
        id
        InternalID
        Title
        Description
        MinimumExperience
        MaximumExperience
        Skills
        RemoteWorking
        Gender
        Status
        Location
        HiringManagerName
        HiringManagerEmail
        Interviews {
          nextToken
          startedAt
        }
        questionbanks {
          nextToken
          startedAt
        }
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobCompanyId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
