import React from 'react';
import Navbar from '../HomePage/Navbar';
import './Login.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import KeyIcon from '@mui/icons-material/Key';
import Typography from '@mui/material/Typography';
import GIF from '../../assets/images/login_page_gif.gif';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AwsAuth from '../Service/AWS_Auth/authenticationServices';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [toaster, settoaster] = useState(true);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (email === '') {
      toast.error('Fields Can not be Empty');
    } else if (!pattern.test(email)) {
      toast.error('email is not valid...!!');
    } else {
      let result = await AwsAuth.forgetPassword(email);
      if (result == true) {
        navigate('/checkmail');
      }
    }
  };

  return (
    <>
      <Navbar />
      <div>
        <Grid container>
          <Grid item xs={0} md={6}>
            <div className="login_title_txt">
              <Card className="login-card">
                <img className="loginPage_GIF" src={GIF} width="100%" alt="" />
              </Card>
            </div>
          </Grid>
          <Grid item xs={12} md={6} elevation={6} square>
            <Box
              sx={{
                my: 50,
                mx: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: '#6366f1' }}>
                <KeyIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
              <small>No worries, we'll send you reset instructions.</small>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  className="login_text_field"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => {
                    setEmail(e.target.value);
                    settoaster(false);
                    localStorage.setItem('emailTemp', e.target.value);
                  }}
                />
                {/* <Link to="/checkmail"> */}
                <Button
                  className="login_btn"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Reset Password
                </Button>
                {/* </Link> */}

                <Link to="/login">
                  <Button
                    className="login_back_btn"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: 'white',
                        color: '#6366f1',
                        fontSize: 'small',
                      }}
                    >
                      <ArrowBackIcon />
                    </Avatar>
                    Back
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ForgotPassword;
