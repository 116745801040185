import React, { useState,useEffect } from 'react';
import { Container,Row,Col, Button } from 'react-bootstrap'
import "./CompanyProfile.css";
import "./themify-icons.css";
// import "./Home.css";
import { NavLink,  useNavigate,useLocation  } from 'react-router-dom';
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBTypography
  } from 'mdb-react-ui-kit';
  import inovark_logo from "../../assets/images/inovark_logo.png";
  import Share from "./Share";
 
import AwsAmplify from '../Service/AWS_Auth/RecruiterDataService' 
import {Image } from '@aws-amplify/ui-react';
import { Storage } from "@aws-amplify/storage"


function CompanyProfile() {
  const [optSmModal, setOptSmModal] = useState(false);
  const location = useLocation();
 const RecruiterID = location.state.RecruiterDetails
  const toggleShow = () => setOptSmModal(!optSmModal);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailID, setEmailID] = React.useState("");
 const [phone,setPhone] = React.useState("");
const [userDetails,setUserDetails] = React.useState();
 const [companyName,setCompanyName]=React.useState("");
 const [ProfilePicture,setProfilePicture] = React.useState("")
 const [id,setID] = React.useState();
 const [linkedin,setLinkedin]=React.useState();
 const [picUrl,setPicUrl] = React.useState("");
  useEffect(async () => {

   await AwsAmplify.getRecruiterById(RecruiterID).then(async userValue=>{
    setUserDetails(userValue);
    if(userValue!= null &&userValue!=undefined){
      setID(userValue.id)
      setFirstName(userValue.FirstName)
      setLastName(userValue.LastName)
      setCompanyName(userValue.CompanyName)
      setPhone(userValue.Phone)
      setProfilePicture(userValue.Profilepic)
      setLinkedin(userValue.Linkedin)
      setEmailID(userValue.Email)
      await Storage.get(userValue.Profilepic).then(val=>{
        setPicUrl(val);
      })
    }
   })
    
  }, []);


  return (
    <div> 
      
        <Container fluid>
          <Row>
            <Col className='employee_details_txt'>
              <h2>Company Details</h2>
            </Col>
          </Row>
          <Container>
            <Row className='user_profile_details'>
            <Col>
              <Row className='profile_details_row'>
                <Col sm={4} className='profile_pic_col'>
                <Image className="profile_pic"  src={picUrl} width="100%" />;
                </Col>
                <Col className='emp_details_col' sm={8}>
                  <Row className='emp_details_row'> 
                    <Col sm={8} md={6}>
                      <h4>{firstName + " "+ lastName}</h4>
                    </Col >
                    {/* <Col className='edit_profile_txt' sm={4} md={6}>
                     <Share label={"New Job post "} text={"Hi"} title={"Check out new msg"}/>
                    </Col> */}
                  </Row>
                  <Row className='emp_details_row'>
                    <Col sm={12}>
                      
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} className='details_list'>
                      <ul>
                       
                        <li><span class="ti-mobile"></span>
                          {phone}</li>
                        
                      </ul>
                    </Col>
                    <Col sm={6} className='details_list'>
                      <ul>
                        {/* <li><span class="ti-world"></span>
                          https://www.example.com</li> */}
                        <li><span class="ti-email"></span>
                         {emailID}</li>
                    
                      </ul>
                    </Col>
                  </Row>
            <Row className='btn_links'>
              <Col sm={6}>
              
               <NavLink to=""><Button className='linkedin_btn'><span class="ti-linkedin"> </span></Button></NavLink> 
               {/* <NavLink to=""><Button className='linkedin_btn'>
                <a href={"https://www." + linkedin  } target="_blank" >
                  <span class="ti-linkedin"></span></a>
                  </Button> </NavLink>  */}
              </Col>
              
              </Row>
                </Col>
              </Row>
              
            </Col>
          </Row>


        
    <br></br>


    <MDBCard className='company_details_Card'>
      <MDBCardHeader>Company Description</MDBCardHeader>
      <MDBCardBody>
        <MDBTypography blockquote className='mb-0'>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <footer className='blockquote-footer'>
           <cite title='Source Title'>H.R</cite>
          </footer>
        </MDBTypography>
      </MDBCardBody>
    </MDBCard>
    <br></br>
    <MDBCard  className='company_req_Card'>
      <MDBCardHeader>Skills Required</MDBCardHeader>
      <MDBCardBody>
        <MDBTypography blockquote className='mb-0'>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <footer className='blockquote-footer'>
           <cite title='Source Title'>H.R</cite>
          </footer>
        </MDBTypography>
      </MDBCardBody>
    </MDBCard>
        
  </Container>
</Container>
</div>
  )
}

export default CompanyProfile