/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getInterviewFeedback = /* GraphQL */ `
  query GetInterviewFeedback($id: ID!) {
    getInterviewFeedback(id: $id) {
      id
      Comments
      RecruiterName
      RecruiterEmail
      FeedbackStatus
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      Interview {
        id
        Question1
        Question2
        Question3
        ActionStatus
        CreatedTime
        CompletedTime
        Video1
        Video2
        Video3
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        JobReferenceID
        JobTitle
        recruiterID
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        DecisionStatus
        jobID
        Candidates {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewCandidatesId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewFeedbackRecruiterId
      interviewFeedbackInterviewId
    }
  }
`;
export const listInterviewFeedbacks = /* GraphQL */ `
  query ListInterviewFeedbacks(
    $filter: ModelInterviewFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewFeedbacks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Comments
        RecruiterName
        RecruiterEmail
        FeedbackStatus
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        Interview {
          id
          Question1
          Question2
          Question3
          ActionStatus
          CreatedTime
          CompletedTime
          Video1
          Video2
          Video3
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          JobReferenceID
          JobTitle
          recruiterID
          DecisionStatus
          jobID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewCandidatesId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewFeedbackRecruiterId
        interviewFeedbackInterviewId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInterviewFeedbacks = /* GraphQL */ `
  query SyncInterviewFeedbacks(
    $filter: ModelInterviewFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInterviewFeedbacks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Comments
        RecruiterName
        RecruiterEmail
        FeedbackStatus
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        Interview {
          id
          Question1
          Question2
          Question3
          ActionStatus
          CreatedTime
          CompletedTime
          Video1
          Video2
          Video3
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          JobReferenceID
          JobTitle
          recruiterID
          DecisionStatus
          jobID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewCandidatesId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewFeedbackRecruiterId
        interviewFeedbackInterviewId
      }
      nextToken
      startedAt
    }
  }
`;
export const getInterviewSchedule = /* GraphQL */ `
  query GetInterviewSchedule($id: ID!) {
    getInterviewSchedule(id: $id) {
      id
      Title
      Description
      CreatedTime
      CompletedTime
      Status {
        Create
        Viewed
        Completed
        Declined
        Selected
        Rejected
      }
      Video4
      Video5
      Video6
      recruiterID
      Question4
      Question5
      Question6
      CandidateName
      CandidateEmail
      RecruiterName
      RecruiterEmail
      RecruiterCompany
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      Candidate {
        id
        FirstName
        Phone
        Email
        DOB
        Qualification
        LastName
        HomeLocation
        CurrentLocation
        College
        CollegeCity
        Course
        CGPA
        Experience
        Resume
        FresherProjectDetails
        FresherProjectName
        FresherProjectTools
        YOE
        CompanyName
        CurrentRole
        Github
        Linkedin
        Youtube
        ProfilePic
        Video1
        Video2
        Video3
        Gender
        Status
        WorkPreference
        Relocation
        CurrentSalary
        ExpectedSalary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewScheduleCandidateId
      owner
    }
  }
`;
export const listInterviewSchedules = /* GraphQL */ `
  query ListInterviewSchedules(
    $filter: ModelInterviewScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Title
        Description
        CreatedTime
        CompletedTime
        Status {
          Create
          Viewed
          Completed
          Declined
          Selected
          Rejected
        }
        Video4
        Video5
        Video6
        recruiterID
        Question4
        Question5
        Question6
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        Candidate {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewScheduleCandidateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInterviewSchedules = /* GraphQL */ `
  query SyncInterviewSchedules(
    $filter: ModelInterviewScheduleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInterviewSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Title
        Description
        CreatedTime
        CompletedTime
        Status {
          Create
          Viewed
          Completed
          Declined
          Selected
          Rejected
        }
        Video4
        Video5
        Video6
        recruiterID
        Question4
        Question5
        Question6
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        Candidate {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewScheduleCandidateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const interviewSchedulesByRecruiterID = /* GraphQL */ `
  query InterviewSchedulesByRecruiterID(
    $recruiterID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interviewSchedulesByRecruiterID(
      recruiterID: $recruiterID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Title
        Description
        CreatedTime
        CompletedTime
        Status {
          Create
          Viewed
          Completed
          Declined
          Selected
          Rejected
        }
        Video4
        Video5
        Video6
        recruiterID
        Question4
        Question5
        Question6
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        Candidate {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewScheduleCandidateId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getCandidate = /* GraphQL */ `
  query GetCandidate($id: ID!) {
    getCandidate(id: $id) {
      id
      FirstName
      Phone
      Email
      DOB
      Qualification
      LastName
      HomeLocation
      CurrentLocation
      College
      CollegeCity
      Course
      CGPA
      Experience
      Resume
      FresherProjectDetails
      FresherProjectName
      FresherProjectTools
      YOE
      CompanyName
      CurrentRole
      Github
      Linkedin
      Youtube
      ProfilePic
      Video1
      Video2
      Video3
      Gender
      Status
      WorkPreference
      Relocation
      CurrentSalary
      ExpectedSalary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listCandidates = /* GraphQL */ `
  query ListCandidates(
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        FirstName
        Phone
        Email
        DOB
        Qualification
        LastName
        HomeLocation
        CurrentLocation
        College
        CollegeCity
        Course
        CGPA
        Experience
        Resume
        FresherProjectDetails
        FresherProjectName
        FresherProjectTools
        YOE
        CompanyName
        CurrentRole
        Github
        Linkedin
        Youtube
        ProfilePic
        Video1
        Video2
        Video3
        Gender
        Status
        WorkPreference
        Relocation
        CurrentSalary
        ExpectedSalary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCandidates = /* GraphQL */ `
  query SyncCandidates(
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCandidates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        FirstName
        Phone
        Email
        DOB
        Qualification
        LastName
        HomeLocation
        CurrentLocation
        College
        CollegeCity
        Course
        CGPA
        Experience
        Resume
        FresherProjectDetails
        FresherProjectName
        FresherProjectTools
        YOE
        CompanyName
        CurrentRole
        Github
        Linkedin
        Youtube
        ProfilePic
        Video1
        Video2
        Video3
        Gender
        Status
        WorkPreference
        Relocation
        CurrentSalary
        ExpectedSalary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRecruiter = /* GraphQL */ `
  query GetRecruiter($id: ID!) {
    getRecruiter(id: $id) {
      id
      FirstName
      Phone
      Email
      Linkedin
      Company {
        id
        Name
        Phone
        Website
        Email
        CreatedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      Profilepic
      CompanyName
      LastName
      InterviewSchedules {
        items {
          id
          Title
          Description
          CreatedTime
          CompletedTime
          Video4
          Video5
          Video6
          recruiterID
          Question4
          Question5
          Question6
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewScheduleCandidateId
          owner
        }
        nextToken
        startedAt
      }
      Interviews {
        items {
          id
          Question1
          Question2
          Question3
          ActionStatus
          CreatedTime
          CompletedTime
          Video1
          Video2
          Video3
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          JobReferenceID
          JobTitle
          recruiterID
          DecisionStatus
          jobID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewCandidatesId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      recruiterCompanyId
      owner
    }
  }
`;
export const listRecruiters = /* GraphQL */ `
  query ListRecruiters(
    $filter: ModelRecruiterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecruiters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRecruiters = /* GraphQL */ `
  query SyncRecruiters(
    $filter: ModelRecruiterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRecruiters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      Name
      Phone
      Website
      Email
      CreatedDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Phone
        Website
        Email
        CreatedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanies = /* GraphQL */ `
  query SyncCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Phone
        Website
        Email
        CreatedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInterview = /* GraphQL */ `
  query GetInterview($id: ID!) {
    getInterview(id: $id) {
      id
      Question1
      Question2
      Question3
      ActionStatus
      CreatedTime
      CompletedTime
      Video1
      Video2
      Video3
      CandidateName
      CandidateEmail
      RecruiterName
      RecruiterEmail
      RecruiterCompany
      JobReferenceID
      JobTitle
      recruiterID
      Recruiter {
        id
        FirstName
        Phone
        Email
        Linkedin
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        Profilepic
        CompanyName
        LastName
        InterviewSchedules {
          nextToken
          startedAt
        }
        Interviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        recruiterCompanyId
        owner
      }
      DecisionStatus
      jobID
      Candidates {
        id
        FirstName
        Phone
        Email
        DOB
        Qualification
        LastName
        HomeLocation
        CurrentLocation
        College
        CollegeCity
        Course
        CGPA
        Experience
        Resume
        FresherProjectDetails
        FresherProjectName
        FresherProjectTools
        YOE
        CompanyName
        CurrentRole
        Github
        Linkedin
        Youtube
        ProfilePic
        Video1
        Video2
        Video3
        Gender
        Status
        WorkPreference
        Relocation
        CurrentSalary
        ExpectedSalary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      interviewCandidatesId
      owner
    }
  }
`;
export const listInterviews = /* GraphQL */ `
  query ListInterviews(
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Question1
        Question2
        Question3
        ActionStatus
        CreatedTime
        CompletedTime
        Video1
        Video2
        Video3
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        JobReferenceID
        JobTitle
        recruiterID
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        DecisionStatus
        jobID
        Candidates {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewCandidatesId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInterviews = /* GraphQL */ `
  query SyncInterviews(
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInterviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Question1
        Question2
        Question3
        ActionStatus
        CreatedTime
        CompletedTime
        Video1
        Video2
        Video3
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        JobReferenceID
        JobTitle
        recruiterID
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        DecisionStatus
        jobID
        Candidates {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewCandidatesId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const interviewsByRecruiterID = /* GraphQL */ `
  query InterviewsByRecruiterID(
    $recruiterID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interviewsByRecruiterID(
      recruiterID: $recruiterID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Question1
        Question2
        Question3
        ActionStatus
        CreatedTime
        CompletedTime
        Video1
        Video2
        Video3
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        JobReferenceID
        JobTitle
        recruiterID
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        DecisionStatus
        jobID
        Candidates {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewCandidatesId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const interviewsByJobID = /* GraphQL */ `
  query InterviewsByJobID(
    $jobID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interviewsByJobID(
      jobID: $jobID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Question1
        Question2
        Question3
        ActionStatus
        CreatedTime
        CompletedTime
        Video1
        Video2
        Video3
        CandidateName
        CandidateEmail
        RecruiterName
        RecruiterEmail
        RecruiterCompany
        JobReferenceID
        JobTitle
        recruiterID
        Recruiter {
          id
          FirstName
          Phone
          Email
          Linkedin
          Profilepic
          CompanyName
          LastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          recruiterCompanyId
          owner
        }
        DecisionStatus
        jobID
        Candidates {
          id
          FirstName
          Phone
          Email
          DOB
          Qualification
          LastName
          HomeLocation
          CurrentLocation
          College
          CollegeCity
          Course
          CGPA
          Experience
          Resume
          FresherProjectDetails
          FresherProjectName
          FresherProjectTools
          YOE
          CompanyName
          CurrentRole
          Github
          Linkedin
          Youtube
          ProfilePic
          Video1
          Video2
          Video3
          Gender
          Status
          WorkPreference
          Relocation
          CurrentSalary
          ExpectedSalary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        interviewCandidatesId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getQuestionBank = /* GraphQL */ `
  query GetQuestionBank($id: ID!) {
    getQuestionBank(id: $id) {
      id
      QuestionText
      Jobs {
        items {
          id
          questionBankId
          jobId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listQuestionBanks = /* GraphQL */ `
  query ListQuestionBanks(
    $filter: ModelQuestionBankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionBanks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        QuestionText
        Jobs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncQuestionBanks = /* GraphQL */ `
  query SyncQuestionBanks(
    $filter: ModelQuestionBankFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuestionBanks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        QuestionText
        Jobs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      InternalID
      Title
      Description
      MinimumExperience
      MaximumExperience
      Skills
      RemoteWorking
      Gender
      Status
      Location
      HiringManagerName
      HiringManagerEmail
      Interviews {
        items {
          id
          Question1
          Question2
          Question3
          ActionStatus
          CreatedTime
          CompletedTime
          Video1
          Video2
          Video3
          CandidateName
          CandidateEmail
          RecruiterName
          RecruiterEmail
          RecruiterCompany
          JobReferenceID
          JobTitle
          recruiterID
          DecisionStatus
          jobID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          interviewCandidatesId
          owner
        }
        nextToken
        startedAt
      }
      questionbanks {
        items {
          id
          questionBankId
          jobId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      Company {
        id
        Name
        Phone
        Website
        Email
        CreatedDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobCompanyId
      owner
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        InternalID
        Title
        Description
        MinimumExperience
        MaximumExperience
        Skills
        RemoteWorking
        Gender
        Status
        Location
        HiringManagerName
        HiringManagerEmail
        Interviews {
          nextToken
          startedAt
        }
        questionbanks {
          nextToken
          startedAt
        }
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobCompanyId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncJobs = /* GraphQL */ `
  query SyncJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        InternalID
        Title
        Description
        MinimumExperience
        MaximumExperience
        Skills
        RemoteWorking
        Gender
        Status
        Location
        HiringManagerName
        HiringManagerEmail
        Interviews {
          nextToken
          startedAt
        }
        questionbanks {
          nextToken
          startedAt
        }
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobCompanyId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getQuestionBankJob = /* GraphQL */ `
  query GetQuestionBankJob($id: ID!) {
    getQuestionBankJob(id: $id) {
      id
      questionBankId
      jobId
      questionBank {
        id
        QuestionText
        Jobs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      job {
        id
        InternalID
        Title
        Description
        MinimumExperience
        MaximumExperience
        Skills
        RemoteWorking
        Gender
        Status
        Location
        HiringManagerName
        HiringManagerEmail
        Interviews {
          nextToken
          startedAt
        }
        questionbanks {
          nextToken
          startedAt
        }
        Company {
          id
          Name
          Phone
          Website
          Email
          CreatedDateTime
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobCompanyId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listQuestionBankJobs = /* GraphQL */ `
  query ListQuestionBankJobs(
    $filter: ModelQuestionBankJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionBankJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionBankId
        jobId
        questionBank {
          id
          QuestionText
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        job {
          id
          InternalID
          Title
          Description
          MinimumExperience
          MaximumExperience
          Skills
          RemoteWorking
          Gender
          Status
          Location
          HiringManagerName
          HiringManagerEmail
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          jobCompanyId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncQuestionBankJobs = /* GraphQL */ `
  query SyncQuestionBankJobs(
    $filter: ModelQuestionBankJobFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuestionBankJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        questionBankId
        jobId
        questionBank {
          id
          QuestionText
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        job {
          id
          InternalID
          Title
          Description
          MinimumExperience
          MaximumExperience
          Skills
          RemoteWorking
          Gender
          Status
          Location
          HiringManagerName
          HiringManagerEmail
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          jobCompanyId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const questionBankJobsByQuestionBankId = /* GraphQL */ `
  query QuestionBankJobsByQuestionBankId(
    $questionBankId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionBankJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionBankJobsByQuestionBankId(
      questionBankId: $questionBankId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionBankId
        jobId
        questionBank {
          id
          QuestionText
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        job {
          id
          InternalID
          Title
          Description
          MinimumExperience
          MaximumExperience
          Skills
          RemoteWorking
          Gender
          Status
          Location
          HiringManagerName
          HiringManagerEmail
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          jobCompanyId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const questionBankJobsByJobId = /* GraphQL */ `
  query QuestionBankJobsByJobId(
    $jobId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionBankJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionBankJobsByJobId(
      jobId: $jobId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionBankId
        jobId
        questionBank {
          id
          QuestionText
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        job {
          id
          InternalID
          Title
          Description
          MinimumExperience
          MaximumExperience
          Skills
          RemoteWorking
          Gender
          Status
          Location
          HiringManagerName
          HiringManagerEmail
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          jobCompanyId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
