import React, { useState } from 'react';
import CandidateNavbar from './CandidateNavbar';
import './NewProfileView.css';
import Avatar from '@mui/material/Avatar';
import { Container, Grid } from '@material-ui/core';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import PhoneAndroidRoundedIcon from '@mui/icons-material/PhoneAndroidRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import VideoModal from './VideoModal.jsx';
import Feedback from '../RecruiterPage/Feedback';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import WcRoundedIcon from '@mui/icons-material/WcRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useRef } from 'react';
import AwsAmplify from '../Service/AWS_Auth/CandidateDataService';
import { useForm } from 'react-hook-form';
import { Storage } from '@aws-amplify/storage';
function NewProfileView() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [firstName, setFirstName] = React.useState('');
  const [id, setID] = React.useState();
  const [lastName, setLastName] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [homeTown, setHomeTown] = React.useState('');
  const [currentLocation, setCurrentLocation] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [qualificationChoice, setQualificationChoice] = React.useState('');
  const [course, setCourse] = React.useState('');
  const [clgName, setClgName] = React.useState('');
  const [clgCity, setClgCity] = React.useState('');
  const [CGPA, setCGPA] = React.useState(0);
  const [experience, setExperience] = React.useState('');
  const [isFresher, setIsFresher] = useState(true);
  const [isExperienced, setIsExperienced] = useState(false);
  const [files, setFiles] = useState([]);
  const [resume, setResume] = React.useState();
  const [userDetails, setUserDetails] = React.useState();
  const [fresherProjectName, setFresherProjectName] = React.useState('');
  const [fresherProjectDetails, setFresherProjectDetails] = React.useState('');
  const [fresherProjectTools, setFresherProjectTools] = React.useState('');
  const [YOE, setYOE] = React.useState(0);
  const [companyName, setCompanyName] = React.useState('');
  const [role, setRole] = React.useState('');
  const [linkedin, setLinkedin] = React.useState();
  const [github, setGithub] = React.useState();
  const [youtube, setYoutube] = React.useState();
  const [profilePic, setProfilePic] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [picUrl, setPicUrl] = React.useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorResume, setErrorResume] = useState(false);
  const [errorPic, setErrorPic] = useState(null);
  const [datechange, setDatechange] = React.useState(null);
  const [relocation, setRelocation] = useState('No');
  const [work, setWork] = useState('Office');
  const [currentSalary, setCurrentSalary] = useState('');
  const [expectedSalary, setExpectedSalary] = useState('');
  const inputRef = useRef(null);
  const [currentPassword, setCurrentPassword] = React.useState();
  const [newPassword, setNewPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();
  const [currentPasswordErr, setCurrentPasswordErr] = React.useState(false);
  const [newPasswordErr, setNewPasswordErr] = React.useState(false);
  const [confirmPasswordErr, setConfirmPasswordErr] = React.useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(phone);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Fresher = () => {
    setIsFresher(true);
    setIsExperienced(false);
    setExperience('Fresher');
  };
  const Experienced = () => {
    setIsExperienced(true);
    setIsFresher(false);
    setExperience('Experience');
  };
  useEffect(async () => {
    // userDecoded = jwtDecode(localStorage.getItem("user"));
    // setUser(userDecoded);
    // let userValue =await UserProfile.getUserById(userDecoded.sub);
    let email = localStorage.getItem('email');
    setEmail(email);
    console.log('email', email);
    await AwsAmplify.getCandidate(email)
      .then(async (userValue) => {
        console.log('userValue', userValue);
        setUserDetails(userValue);
        if (userValue.length != 0) {
          if (userValue.Status == true) {
            setID(userValue.id);
            setFirstName(userValue.FirstName);
            setLastName(userValue.LastName);
            setDob(userValue.DOB);
            setHomeTown(userValue.HomeLocation);
            setCurrentLocation(userValue.CurrentLocation);
            setPhone(userValue.Phone);
            userValue.Phone != null
              ? setIsValidPhone(false)
              : setIsValidPhone(true);
            setGender(userValue.Gender);
            setQualificationChoice(
              userValue.Qualification != null ? userValue.Qualification : ''
            );
            // setQualification(userValue.UserProfile.Qualification)
            setCourse(userValue.Course);
            setClgName(userValue.College);
            setClgCity(userValue.CollegeCity);
            setCGPA(userValue.CGPA);
            setExperience(userValue.Experience);
            setResume(userValue.Resume);
            if (userValue.Resume != null && userValue.Resume != '') {
              setErrorResume(false);
              setSelectedFile(true);
            } else {
              setErrorResume(true);
              setSelectedFile(false);
            }
            setFresherProjectDetails(userValue.FresherProjectDetails);
            setFresherProjectName(userValue.FresherProjectName);
            setFresherProjectTools(userValue.FresherProjectTools);
            setYOE(userValue.YOE);
            setCompanyName(userValue.CompanyName);
            setRole(userValue.CurrentRole);
            setRelocation(
              userValue.Relocation != null ? userValue.Relocation : relocation
            );
            setWork(
              userValue.WorkPreference != null ? userValue.WorkPreference : work
            );
            setGithub(userValue.Github != null ? userValue.Github : '');
            setLinkedin(userValue.Linkedin != null ? userValue.Linkedin : '');
            setYoutube(userValue.Youtube != null ? userValue.Youtube : '');
            setProfilePic(
              userValue.ProfilePic != null ? userValue.ProfilePic : null
            );
            if (userValue.Experience == 'Fresher') {
              Fresher();
            }
            if (userValue.Experience == 'Experience') {
              Experienced();
            }
            localStorage.setItem('candidateId', userValue.id);
            localStorage.setItem('fname', userValue.FirstName);
            if (userValue.ProfilePic != null) {
              await Storage.get(userValue.ProfilePic).then((val) => {
                setPicUrl(val);
                setErrorPic(false);
              });
            }
          } else {
            setID(userValue.id);
            setEmail(userValue.Email);
          }
        }
      })
      .catch((err) => {
        toast.error(err.toString());
      });

    let defaultValues = {};
    reset({ ...defaultValues });
  }, []);

  return (
    <>
      <CandidateNavbar />
      <Container maxWidth>
        <br />
        <br />
        <Container fixed className="fixed_bg_color">
          <Grid container spacing={2} className="share_container">
            <Grid item xs={12} md={3} className="avatar_grid">
              <Avatar
                className="avatar_resize"
                src={picUrl}
                sx={{ height: 225 }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} className="social_icon_grid">
                  {github != null ? (
                    <Button>
                      <a href={github} target="_blank">
                        <GitHubIcon
                          className="social_icons"
                          sx={{ color: '#cdcdcd', fontSize: 38 }}
                        />
                      </a>
                    </Button>
                  ) : (
                    <></>
                  )}
                  {linkedin != null ? (
                    <Button>
                      <a href={linkedin} target="_blank">
                        <LinkedInIcon
                          className="social_icons"
                          sx={{ color: '#cdcdcd', fontSize: 40 }}
                        />
                      </a>
                    </Button>
                  ) : (
                    <></>
                  )}
                  {youtube != null ? (
                    <Button>
                      <a href={youtube} target="_blank">
                        <YouTubeIcon
                          className="social_icons"
                          sx={{ color: '#cdcdcd', fontSize: 40 }}
                        />
                      </a>
                    </Button>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} className="avatar_grid">
                  <div className="share_name_txt">
                    {firstName + ' ' + lastName}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <EmailRoundedIcon className="share_info_icons" />
                        {email}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <PhoneAndroidRoundedIcon className="share_info_icons" />
                        {phone}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <WcRoundedIcon className="share_info_icons" />
                        {gender}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <LocationOnRoundedIcon className="share_info_icons" />
                        {currentLocation}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <BusinessRoundedIcon className="share_info_icons" />
                        {role}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <WorkRoundedIcon className="share_info_icons" />
                        {YOE}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <HomeWorkRoundedIcon className="share_info_icons" />
                        {work}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} className="avatar_grid">
                      <span className="share_info_txt">
                        <HomeRoundedIcon className="share_info_icons" />
                        {homeTown}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className="share_profile_btn_grid"
                    >
                      <Link to="/candidate/neweditprofile">
                        <Button
                          className="view_profile_edit_btn"
                          fullWidth
                          variant="outlined"
                        >
                          <EditRoundedIcon className="edit_profile_icon_btn" />
                          Edit Profile
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2} className="view_container">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <div className="interview_title_txt">Tapes</div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <VideoModal />
              </Grid>
            </Grid>
          </Grid>

          <br />
        </Container>
        <br />
      </Container>
    </>
  );
}

export default NewProfileView;
