/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://ge73p7rlevhytig5gxiqo6twx4.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ahtasosez5fo7gvtwgzkkuhbpe",
    "aws_cognito_identity_pool_id": "ap-south-1:474ba952-9116-4e2f-8f4d-a8b9672776e7",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_NZxdMDhVs",
    "aws_user_pools_web_client_id": "k5higig13fpu5j5pg4b6fpkkn",
    "oauth": {
        "domain": "screenmef4d36242-f4d36242-staging.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.interviewtape.com/,http://localhost:3000/",
        "redirectSignOut": "https://www.interviewtape.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "screenme-storage-bba85353105343-staging",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
