import * as React from 'react';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { Container } from '@mui/system';
import './NewSchedulePage.css';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ChooseCandidateCard from '../RecruiterPage/ChooseCandidateCard';
import Jobs from '../Service/AWS_Auth/JobDataService';
import List from './JobSearchList';
import { Grid, TextField, IconButton } from '@material-ui/core';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import candidateService from '../Service/AWS_Auth/CandidateDataService';
import questions from '../Service/AWS_Auth/QuestionDataService';
import {
  ActionStatus,
  Candidate,
  DecisionStatus,
  Interview,
  QuestionBank,
} from '../../models';
import JobBasedInterviewScheduleService from '../Service/AWS_Auth/JobBasedInterviewScheduleService';
import AwsAmplify from '../Service/AWS_Auth/RecruiterDataService';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../HomePage/Navbar';
import { Storage } from '@aws-amplify/storage';
import AddIcon from '@mui/icons-material/Add';
import PublishIcon from '@mui/icons-material/Publish';
import AWS_Storage from '../Service/AWS_Auth/VideoStorage';
import CircularProgress from '@mui/material/CircularProgress';
import CandidateDataService from '../Service/AWS_Auth/CandidateDataService';

const steps = ['Select Job ID', 'Choose Candidate', 'Enter Questions'];

function NewSchedulePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [allJob, setAllJob] = React.useState({});
  const [selectedJob, setSelectedJob] = React.useState([]);
  const [allEmail, setAllEmail] = React.useState({});
  const [selectedEmail, setSelectedEmail] = React.useState([]);
  const [text1, setText1] = useState('');
  const [value, setValue] = React.useState(0);
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [text4, setText4] = useState('');
  const [question1, setQuestion1] = React.useState('Tell me about yourself ?');
  const [question2, setQuestion2] = React.useState('');
  const [question3, setQuestion3] = React.useState('');
  const [question4, setQuestion4] = React.useState('');
  const [selectedField, setSelectedField] = useState(null);
  const [predefinedQuestions, setPredefinedQuestions] = useState(['']);
  const [recruiterDetails, setRecruiterDetails] = useState({});
  const [recruiter, setRecruiter] = React.useState();
  const [video1Url, setVideo1Url] = React.useState('');
  const [video2Url, setVideo2Url] = React.useState('');
  const [video3Url, setVideo3Url] = React.useState('');
  const [video4Url, setVideo4Url] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const ref = React.useRef(null);
  const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

  const jobId = location.state != null ? location.state.id : undefined;
  const candidateId =
    location.state != null ? location.state.candidate : undefined;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    const newCompleted = completed;
    setActiveStep(newActiveStep);
    newCompleted[activeStep] = true;
    // setCompleted(newCompleted);
  };
  const getQuestion = async () => {
    await questions.getALL().then((value) => {
      let bank = [];
      value.forEach((question) => {
        bank.push(question.QuestionText);
      });
      setPredefinedQuestions(bank);
    });
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (selectedJob.length != 0) {
      setAllJob(selectedJob);
      setJobInputText(selectedJob['Job'].Title);
      setClearSelect(true);
    }
  };

  const handleStep = (step) => () => {
    console.log('step', step);
    setActiveStep(step);
    if (step == 0) {
      if (selectedJob.length != 0) {
        setClearSelect(true);
      }
    }
  };

  const handleUpload = async (question, video1Blob) => {
    // setSpinner(true);
    if (question == 'Q1') {
      await AWS_Storage.videoWhatsUp(
        video1Blob,
        'video1/' + selectedEmail[0]['candidate']['id'].toString()
      ).then((video1Url) => {
        console.log('video1Url', video1Url);
        setVideo1Url(video1Url);
        // setSpinner(false);
        return video1Url;
      });
    }
    if (question == 'Q2') {
      await AWS_Storage.videoWhatsUp(
        video1Blob,
        'video4/' + selectedEmail[0]['candidate']['id'].toString()
      ).then((video1Url) => {
        console.log('video1Url', video1Url);
        setVideo2Url(video1Url);
        // setSpinner(false);
        return video1Url;
      });
    }
    if (question == 'Q3') {
      await AWS_Storage.videoWhatsUp(
        video1Blob,
        'video5/' + selectedEmail[0]['candidate']['id'].toString()
      ).then((video1Url) => {
        console.log('video1Url', video1Url);
        setVideo3Url(video1Url);
        // setSpinner(false);
        return video1Url;
      });
    }
    if (question == 'Q4') {
      await AWS_Storage.videoWhatsUp(
        video1Blob,
        'video6/' + selectedEmail[0]['candidate']['id'].toString()
      ).then((video1Url) => {
        console.log('video1Url', video1Url);
        setVideo4Url(video1Url);
        // setSpinner(false);
        return video1Url;
      });
    }
  };
  const handleComplete = async () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    // handleNext();
    console.log('Hi');
    if (video1Url != '') {
      var candidateVideo = {
        Video_1: video1Url,
        Video2_: '',
        Video_3: '',
      };
      await CandidateDataService.updateVideo(
        selectedEmail[0]['candidate']['id'],
        candidateVideo
      ).then((val) => {
        console.log('self video', val);
      });
    }
    if (text2 != '') {
      if (text3 == '' && text4 === '') {
        var QuestionData = new Interview({
          CreatedTime: new Date().toLocaleString(),
          Question1: text2,
          Question2: 'No question',
          Question3: 'No question',
          ActionStatus: ActionStatus.CREATED,
          CompletedTime: '',
          CandidateName:
            selectedEmail[0]['candidate']['FirstName'] +
            ' ' +
            selectedEmail[0]['candidate']['LastName'],
          CandidateEmail: selectedEmail[0]['candidate']['Email'],
          RecruiterName: recruiterDetails.FirstName + recruiterDetails.LastName,
          RecruiterEmail: recruiterDetails.Email,
          RecruiterCompany: recruiterDetails.CompanyName,
          Recruiter: recruiter,
          recruiterID: recruiter,
          DecisionStatus: DecisionStatus.PENDING,
          Video1: video2Url,
          jobID: selectedJob[0]['Job'].id,
          JobReferenceID: selectedJob[0]['Job'].InternalID,
          JobTitle: selectedJob[0]['Job'].Title,
          Candidates: selectedEmail[0]['candidate']['id'],
          interviewCandidatesId: selectedEmail[0]['candidate']['id'],
        });
        console.log('Interview', QuestionData);
        let interviewUpdate = await JobBasedInterviewScheduleService.save(
          QuestionData
        );
        if (interviewUpdate == 'success') {
          navigate('/newInterviewPage');
        }
      } else {
        var QuestionData = new Interview({
          CreatedTime: new Date().toLocaleString(),
          Question1: text2,
          Question2: text3,
          Question3: text4,
          ActionStatus: ActionStatus.CREATED,
          CompletedTime: '',
          CandidateName:
            selectedEmail[0]['candidate']['FirstName'] +
            ' ' +
            selectedEmail[0]['candidate']['LastName'],
          CandidateEmail: selectedEmail[0]['candidate']['Email'],
          RecruiterName: recruiterDetails.FirstName + recruiterDetails.LastName,
          RecruiterEmail: recruiterDetails.Email,
          RecruiterCompany: recruiterDetails.CompanyName,
          Recruiter: recruiter,
          recruiterID: recruiter,
          DecisionStatus: DecisionStatus.PENDING,
          Video1: video2Url,
          Video2: video3Url,
          Video3: video4Url,
          jobID: selectedJob[0]['Job'].id,
          JobReferenceID: selectedJob[0]['Job'].InternalID,
          Candidates: selectedEmail[0]['candidate']['id'],
          JobTitle: selectedJob[0]['Job'].Title,
          interviewCandidatesId: selectedEmail[0]['candidate']['id'],
        });
        console.log('Interview', QuestionData);
        let interviewUpdate = await JobBasedInterviewScheduleService.save(
          QuestionData
        );
        if (interviewUpdate == 'success') {
          navigate('/newInterviewPage');
        }
      }
    }
  };

  const handleReset = () => {
    navigate('/newInterviewPage');
  };
  useEffect(async () => {
    // const interval = setInterval(async () => {
    await getRecruiter();
    await getJob();
    await getEmail();
    await getQuestion();
    if (jobId != undefined) {
      await getJobByID(jobId);
    }
    if (candidateId != undefined) {
      await getCandidateByID(candidateId);
    }
    // }, 1000);

    // Simulate a long-running task
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Cleanup function to stop the timer
    // return () => clearInterval(interval);
  }, []);
  const getJobByID = async (JobID) => {
    let jobArr = [];
    await Jobs.getJobsByID(JobID).then(async (job) => {
      console.log('job', job);
      // job.forEach( (Job) => {

      jobArr.push({
        Job: job,
        Interviews: job.Interviews.values,
      });
      // });
      setAllJob(jobArr);
      setSelectedJob([jobArr]);
      setJobInputText(jobArr[0].Job.Title);
      console.log('jobs', jobArr);
    });
  };
  const getCandidateByID = async (CandidateID) => {
    let candidateArr = [];
    await CandidateDataService.getCandidateByID(CandidateID).then(
      async (candidate) => {
        console.log('candidate', candidate);

        await Storage.get(candidate.ProfilePic).then((val) => {
          candidateArr.push({ candidate: candidate, img: val });
        });

        setAllEmail(candidateArr);
      }
    );
  };

  const getRecruiter = async () => {
    var rec = localStorage.getItem('recruiterId');
    setRecruiter(rec);
    let email = localStorage.getItem('email');
    await AwsAmplify.getRecruiter(email).then((userValue) => {
      setRecruiterDetails(userValue);
    });
  };
  const getJob = async () => {
    // let jobID = location.state != null ? location.state.id:undefined
    let companyId = localStorage.getItem('companyId');
    await Jobs.getLast10JobsByCompany(companyId).then(async (allJob) => {
      let job = [];
      console.log('allJobs', allJob);
      allJob.forEach((Job) => {
        job.push({
          Job: Job,
          Interviews: Job.Interviews.values,
        });
      });
      if (job.length == allJob.length) {
        setAllJob(job);
        console.log('jobs', job);
      }
    });
  };
  const handlePreset = (question) => {
    switch (selectedField) {
      case 1:
        if (question !== text2 && question !== text3 && question !== text4) {
          setText1(question);
        }
        break;
      case 2:
        if (question !== text1 && question !== text3 && question !== text4) {
          setText2(question);
        }
        break;
      case 3:
        if (question !== text1 && question !== text2 && question !== text4) {
          setText3(question);
        }
        break;
      case 4:
        if (question !== text1 && question !== text2 && question !== text3) {
          setText4(question);
        }
        break;
      default:
        break;
    }
  };

  const handleFieldSelection = (field) => {
    setSelectedField(field);
  };
  const getEmail = async () => {
    await candidateService.getLast10Candidate().then((allCandidates) => {
      let user = [];
      allCandidates.forEach(async (candidate) => {
        await Storage.get(candidate.ProfilePic).then((val) => {
          user.push({ candidate: candidate, img: val });
        });
        if (user.length == allCandidates.length) {
          setAllEmail(user);
          console.log('user list', user);
        }
      });
    });
  };
  const handleSubmit = async () => {
    const newQuestion =
      selectedField === 1 ? text1 : selectedField === 2 ? text2 : text3;
    if (!predefinedQuestions.includes(newQuestion)) {
      await questions
        .save(new QuestionBank({ QuestionText: newQuestion }))
        .then((value) => {
          console.log('new question', value);
          setPredefinedQuestions([...predefinedQuestions, newQuestion]);
        });
      //pass to validation
    }
  };
  const [jobInputText, setJobInputText] = React.useState('');
  const [emailInputText, setEmailInputText] = React.useState('');
  const [clearSelect, setClearSelect] = React.useState(false);
  let jobInputHandler = (e) => {
    //convert input text to lower case
    setClearSelect(false);
    setSelectedJob([]);
    var lowerCase = e.target.value.toLowerCase();
    setJobInputText(e.target.value);
    searchJobSchedule(e.target.value);
  };

  const searchJobSchedule = async (text) => {
    let Job = [];
    let companyID = localStorage.getItem('companyId');
    await Jobs.searchJobSchedule(text, companyID).then((data) => {
      console.log('result', data);

      data.forEach((element) => {
        Job.push({
          Job: element,
        });
        if (Job.length == data.length) {
          setAllJob(Job);
          console.log('searchJob', Job);
        }
      });
    });
  };
  const handleOnClick = (job, selectedCard) => {
    console.log('Job', job);
    setAllJob([job]);
    setJobInputText(job['Job'].Title);
    setSelectedJob([job]);
    setClearSelect(selectedCard);
    if (selectedCard === false) {
      handleClear();
    }
  };

  const handleClear = async () => {
    setJobInputText('');
    setSelectedJob([]);
    await getJob();
    setClearSelect(false);
  };

  let emailInputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setEmailInputText(e.target.value);
    searchCandidate(e.target.value);
  };
  const searchCandidate = async (text) => {
    let Candidate = [];
    await candidateService.SearchCandidate(text).then((data) => {
      console.log('result', data);
      data.forEach(async (element) => {
        await Storage.get(element.ProfilePic).then((val) => {
          Candidate.push({
            candidate: element,
            img: val,
          });
        });
        if (Candidate.length == data.length) {
          setAllEmail(Candidate);
          console.log('searchCandidate', Candidate);
        }
      });
    });
  };

  const handleOnClickEmail = (email) => {
    console.log('Job', email);
    setAllEmail([email]);
    setEmailInputText(email['candidate']['FirstName'].toLowerCase()); // edit
    setSelectedEmail([email]); //////////////edit
  };
  const handleClearEmail = async () => {
    setEmailInputText('');
    setSelectedEmail([]);
    await getEmail();
  };

  if (spinner) {
    return <div>Uploading...</div>;
  }

  return (
    <div>
      {loading ? (
        <div>
          <div></div>
          <CircularProgress
            size={50}
            sx={{
              color: '#6366f1',
              position: 'fixed',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        </div>
      ) : (
        <div>
          <Navbar />
          <Container className="jobs_header">Schedule</Container>
          <Container fixed>
            {' '}
            <Box sx={{ width: '100%' }}>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset}>Done</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography className="step_s" sx={{ mt: 2, mb: 1, py: 1 }}>
                      Step {activeStep + 1}
                    </Typography>
                    {activeStep == 0 && (
                      <>
                        <Container className="total_q_container_1" fixed>
                          <Grid container className="job_row">
                            <Grid item xs={12} md={6}>
                              {/* <Paper
                                className="search_bar"
                                component="form"
                                sx={{
                                  p: '2px 4px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: 800,
                                }}
                              >
                                <InputBase
                                  onChange={jobInputHandler}
                                  sx={{ ml: 1, flex: 1 }}
                                  value={jobInputText}
                                  placeholder="Search Jobs...."
                                  InputProps={{
                                    endAdornment: (
                                      <>
                                        {jobInputText !== '' && (
                                          <IconButton onClick={handleClear}>
                                            <CloseIcon />
                                          </IconButton>
                                        )}
                                      </>
                                    ),
                                  }}
                                />
                                <IconButton
                                  type="button"
                                  sx={{ p: '10px' }}
                                  aria-label="search"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </Paper> */}
                              <TextField
                                onChange={jobInputHandler}
                                variant="outlined"
                                fullWidth
                                placeholder="Search Jobs..."
                                value={jobInputText}
                                InputProps={{
                                  startAdornment: (
                                    <IconButton>
                                      <SearchRoundedIcon />
                                    </IconButton>
                                  ),
                                  endAdornment: (
                                    <>
                                      {jobInputText !== '' && (
                                        <IconButton onClick={handleClear}>
                                          <CloseRoundedIcon />
                                        </IconButton>
                                      )}
                                    </>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={2}></Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              lg={4}
                              className="create_button_stepper_1"
                            >
                              <Button
                                className="create_job_btn"
                                variant="outlined"
                                onClick={(e) =>
                                  // handleClickOpen
                                  navigate('/recruiter/job/newCreate')
                                }
                              >
                                <AddIcon />
                                Create
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid className="search_filter_txt" item xs={12}>
                              {jobInputText == '' && (
                                <div>{`Recent jobs "${
                                  allJob != undefined && allJob != null
                                    ? allJob.length
                                    : 0
                                }"`}</div>
                              )}
                              {selectedJob.length == 0 &&
                                jobInputText != '' && (
                                  <div>
                                    {allJob.length}{' '}
                                    {allJob.length > 1 ? `Jobs` : `Job`}
                                    {` has been found for "${jobInputText}"`}
                                  </div>
                                )}
                              {selectedJob.length != 0 && (
                                <div>
                                  {' '}
                                  {` ${selectedJob[0]['Job'].Title} job has been selected`}
                                </div>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12} md={12}>
                              {allJob.length != 0 && (
                                <>
                                  <Paper
                                    variant="outlined"
                                    className="scrollable-window"
                                    style={{
                                      maxHeight: '500px',
                                      overflowY: 'auto',
                                      padding: '0px 16px 0px 16px',
                                    }}
                                    elevation={0}
                                  >
                                    <List
                                      input={jobInputText}
                                      data={allJob}
                                      onClick={handleOnClick}
                                      state={clearSelect}
                                      display={true}
                                    />
                                  </Paper>
                                </>
                              )}
                            </Grid>
                          </Grid>

                          <br></br>
                        </Container>
                      </>
                    )}

                    {activeStep == 1 && (
                      <>
                        <Container className="total_q_container_2" fixed>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className="choose_candidate_grid_item"
                            >
                              <div className="search">
                                <TextField
                                  id="outlined-basic"
                                  onChange={emailInputHandler}
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Search Candidates..."
                                  value={emailInputText}
                                  InputProps={{
                                    startAdornment: (
                                      <IconButton>
                                        <SearchRoundedIcon />
                                      </IconButton>
                                    ),
                                    endAdornment: (
                                      <>
                                        {emailInputText !== '' && (
                                          <IconButton
                                            onClick={handleClearEmail}
                                          >
                                            <CloseRoundedIcon />
                                          </IconButton>
                                        )}
                                      </>
                                    ),
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={2}></Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              className="create_button_stepper_2"
                            >
                              <Button
                                className="create_user_btn"
                                variant="outlined"
                                onClick={(e) =>
                                  // handleClickOpen
                                  navigate('/recruiter/newCandidate/create')
                                }
                              >
                                <AddIcon />
                                Create
                              </Button>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                className="user_search_filter_txt"
                                xs={12}
                              >
                                {emailInputText == '' && (
                                  <div>{`Recent candidates "${
                                    allEmail != undefined && allEmail != null
                                      ? allEmail.length
                                      : 0
                                  }"`}</div>
                                )}
                                {selectedEmail.length == 0 &&
                                  emailInputText != '' && (
                                    <div>
                                      {allJob.length}{' '}
                                      {allJob.length > 1
                                        ? `Candidates`
                                        : `Candidate`}
                                      {` has been found for "${emailInputText}"`}
                                    </div>
                                  )}
                                {selectedEmail.length != 0 && (
                                  <div>
                                    {' '}
                                    {` ${selectedEmail[0]['candidate'][
                                      'FirstName'
                                    ].toLowerCase()} candidate has been selected`}
                                  </div>
                                )}

                                {allEmail.length != 0 && (
                                  <Paper
                                    variant="outlined"
                                    className="scrollable-window"
                                    style={{
                                      maxHeight: '500px',
                                      overflowY: 'auto',
                                      padding: '0px 16px 0px 16px',
                                      marginTop: '1rem',
                                    }}
                                    elevation={0}
                                  >
                                    <ChooseCandidateCard
                                      input={emailInputText}
                                      data={allEmail}
                                      onClick={handleOnClickEmail}
                                    />
                                  </Paper>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>

                          <br></br>
                        </Container>
                      </>
                    )}
                    {activeStep == 2 && (
                      <>
                        {/* <Container className="total_q_container_3" fixed>
                          <Grid className="q_area" container>
                            <Grid className="question_field_col" item xs={6}>
                              <Grid className="question_feild_3" container>
                                <Grid>
                                  <Grid className="question_feild_3">
                                   
                                  </Grid>
                                  <Grid
                                    className="question_field_col"
                                    item
                                    xs={2}
                                  >
                                    <div>
                                      <input
                                        type="file"
                                        accept="video/*"
                                        onChange={(e) =>
                                          handleUpload('Q1', e.target.files[0])
                                        }
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid className="question_field_col" item xs={6}>
                                <Grid className="question_feild_1" container>
                                  <Grid className="question_field_col_1">
                                    <Stack sx={{ width: 350 }}>
                                      <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        options={top100Films.map(
                                          (option) => option.title
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Search input"
                                            InputProps={{
                                              ...params.InputProps,
                                              type: 'search',
                                            }}
                                          />
                                        )}
                                      />
                                      <TextField
                                        label="Question1"
                                        value={text2}
                                        onChange={(e) =>
                                          setText2(e.target.value)
                                        }
                                        onClick={() => handleFieldSelection(2)}
                                      />
                                    </Stack>
                                  </Grid>
                                </Grid>
                                <Grid
                                  className="question_field_col"
                                  item
                                  xs={2}
                                >
                                  <div>
                                    <input
                                      type="file"
                                      accept="video/*"
                                      onChange={(e) =>
                                        handleUpload('Q2', e.target.files[0])
                                      }
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid className="question_feild_2" container>
                                <Grid>
                                  <Stack sx={{ width: 350 }}>
                                    <TextField
                                      label="Question2"
                                      value={text3}
                                      onChange={(e) => setText3(e.target.value)}
                                      onClick={() => handleFieldSelection(3)}
                                    />
                                  </Stack>
                                </Grid>
                              </Grid>
                              <Grid className="question_field_col" item xs={2}>
                                <div>
                                  <input
                                    type="file"
                                    accept="video/*"
                                    onChange={(e) =>
                                      handleUpload('Q3', e.target.files[0])
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid className="question_feild_3" container>
                                <Grid>
                                  <Stack sx={{ width: 350 }}>
                                    <TextField
                                      label="Question4"
                                      value={text4}
                                      onChange={(e) => setText4(e.target.value)}
                                      onClick={() => handleFieldSelection(4)}
                                    />
                                  </Stack>
                                </Grid>
                              </Grid>
                              <Grid className="question_field_col" item xs={2}>
                                <div>
                                  <input
                                    type="file"
                                    accept="video/*"
                                    onChange={(e) =>
                                      handleUpload('Q4', e.target.files[0])
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid className="pre_questions_col" item xs={6}>
                              <QuestionAnswerIcon /> Pre-defined Questions
                              <ul>
                                {predefinedQuestions.map((question, index) => (
                                  <li
                                    key={index}
                                    onClick={() => handlePreset(question)}
                                  >
                                    {question}
                                  </li>
                                ))}
                              </ul>
                            </Grid>
                          </Grid>
                        </Container> */}

                        <Container className="total_q_container_3">
                          <Grid container>
                            <Grid item xs={12} md={12} className="ques_txt">
                              Default Question
                            </Grid>
                          </Grid>
                          <Grid
                            className="disabled_default_question_row"
                            container
                          >
                            <Grid
                              className="disabled_default_question_col"
                              item
                              xs={12}
                              md={9}
                            >
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="outlined-read-only-input"
                                defaultValue="Tell me About Yourself ?"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className="question_default_col_2"
                            >
                              <Button
                                className="ans_upload_btn"
                                variant="outlined"
                                component="label"
                              >
                                <PublishIcon /> Upload
                                <input
                                  hidden
                                  accept="image/*"
                                  multiple
                                  type="file"
                                />
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid className="question_1_row" container>
                            <Grid container>
                              <Grid item xs={12} md={12} className="ques_txt">
                                Question 1 :
                              </Grid>
                            </Grid>
                            <Grid
                              className="question_1_col_1"
                              item
                              xs={12}
                              md={9}
                            >
                              <Autocomplete
                                freeSolo
                                variant="outlined"
                                id="free-solo-2-demo"
                                disableClearable
                                options={top100Films.map(
                                  (option) => option.title
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Choose your Question"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                    }}
                                    value={text1}
                                    onChange={(e) => setText2(e.target.value)}
                                    onClick={(e) => {
                                      setText2(e.target.value);
                                      handleFieldSelection(1);
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              className="question_1_col_2"
                              item
                              xs={12}
                              md={3}
                            >
                              <Button
                                className="ans_upload_btn"
                                variant="outlined"
                                component="label"
                              >
                                <PublishIcon /> Upload
                                <input
                                  hidden
                                  accept="image/*"
                                  multiple
                                  type="file"
                                />
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid className="question_2_row" container>
                            <Grid container>
                              <Grid item xs={12} md={12} className="ques_txt">
                                Question 2 :
                              </Grid>
                            </Grid>
                            <Grid
                              className="question_2_col_1"
                              item
                              xs={12}
                              md={9}
                            >
                              <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                disableClearable
                                options={top100Films.map(
                                  (option) => option.title
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Choose your Question"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                    }}
                                    value={text2}
                                    onChange={(e) => setText3(e.target.value)}
                                    onClick={(e) => {
                                      setText3(e.target.value);
                                      handleFieldSelection(2);
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              className="question_2_col_2"
                              item
                              xs={12}
                              md={3}
                            >
                              <Button
                                className="ans_upload_btn"
                                variant="outlined"
                                component="label"
                              >
                                <PublishIcon /> Upload
                                <input
                                  hidden
                                  accept="image/*"
                                  multiple
                                  type="file"
                                />
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid className="question_3_row" container>
                            <Grid container>
                              <Grid item xs={12} md={12} className="ques_txt">
                                Question 3 :
                              </Grid>
                            </Grid>
                            <Grid
                              className="question_3_col_1"
                              item
                              xs={12}
                              md={9}
                            >
                              <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                disableClearable
                                options={top100Films.map(
                                  (option) => option.title
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Choose your Question"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                    }}
                                    value={text3}
                                    onChange={(e) => setText4(e.target.value)}
                                    onClick={(e) => {
                                      setText4(e.target.value);
                                      handleFieldSelection(3);
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              className="question_3_col_2"
                              item
                              xs={12}
                              md={3}
                            >
                              <Button
                                className="ans_upload_btn"
                                variant="outlined"
                                component="label"
                              >
                                <PublishIcon /> Upload
                                <input
                                  hidden
                                  accept="image/*"
                                  multiple
                                  type="file"
                                />
                              </Button>
                            </Grid>
                          </Grid>
                        </Container>
                      </>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        className="stepper_back_btn"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {activeStep != 2 ? (
                        <Button
                          className="stepper_next_btn"
                          onClick={handleNext}
                          sx={{ mr: 1 }}
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          className="stepper_schedule_btn"
                          onClick={(e) => handleComplete(e)}
                          sx={{ mr: 1 }}
                        >
                          Schedule
                        </Button>
                      )}
                      {/* {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <></>
                    ) : (
                      <Button
                        className="stepper_next_btn"
                        onClick={handleComplete}
                      >
                        {completedSteps() === totalSteps() - 1
                          ? 'Finish'
                          : 'Next'}
                      </Button>
                    ))} */}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>
          </Container>
        </div>
      )}
    </div>
  );
}
export default NewSchedulePage;

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
];
