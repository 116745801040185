import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Registration from './components/LoginSystem/Registration';
import LoginPage from './components/LoginSystem/LoginPage';
import RecordPage from './components/Webcam/RecordPage';
import RecordSpecificQuestion from './components/Webcam/RecordUserSpecificQues';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './components/HomePage/Profile';
import RecruiterHome from './components/RecruiterPage/RecruiterHome';
import RecruiterProfile from './components/RecruiterPage/RecruiterProfile';
import Navbar from '../src/components/HomePage/Navbar';
import Home from './components/HomePage/Home';
import ProfileView from './components/HomePage/ProfileView';
import RecruiterProfileView from './components/RecruiterPage/RecruiterProfileView';
import RecruiterHomePage from './components/RecruiterPage/RecruiterHomePage';
import InterviewPage from './components/HomePage/InterviewPage';
import CompanyProfile from './components/HomePage/CompanyProfile';
import SchedulePage from './components/RecruiterPage/SchedulePage';
import InstructionPage from './components/Webcam/InstructionPage';
import InsrtuctionPageSpecificQ from './components/Webcam/InsrtuctionPageSpecificQ';
import Timeline from './components/HomePage/Timeline';
import Verification from './components/LoginSystem/Verification';
import TermsandCondition from './components/HomePage/TermsandCondition';
import PrivacyPolicy from './components/HomePage/PrivacyPolicy';
import AwsAuth from '../src/components/Service/AWS_Auth/authenticationServices';
import { useState, useEffect } from 'react';
import EditScheduleInterview from './components/RecruiterPage/EditInterview';
import JobList from './components/RecruiterPage/JobList';
import ViewJob from './components/RecruiterPage/ViewJob';
// import EditJob from './components/RecruiterPage/EditJob';
import QuestionPage from './components/RecruiterPage/JobScheduleStepper';
import ChooseProfile from './components/LoginSystem/ChooseProfile';
import AboutUs from './components/HomePage/AboutUs';
import RecruiterAboutUs from './components/RecruiterPage/RecruiterAboutUs';
import Card from './components/HomePage/Card';
import Test from './components/HomePage/Test';
import NewSchedulePage from './components/RecruiterPage/NewSchedulePage';
import ChooseCandidateCard from './components/RecruiterPage/ChooseCandidateCard';
import ViewJobCard from './components/RecruiterPage/ViewJobCard';
import NewInterviewPage from './components/RecruiterPage/NewInterviewPage';
import NewCandidateProfile from './components/RecruiterPage/NewCandidateProfile';
import Feedback from './components/RecruiterPage/Feedback';

import NewProfile from './components/HomePage/NewProfile';
import NewEditProfile from './components/HomePage/NewEditProfile';
import NewCreateJob from './components/RecruiterPage/NewCreateJob';
import NewRecruiterProfile from './components/RecruiterPage/NewRecruiterProfile';
import ForgotPassword from './components/LoginSystem/ForgotPassword';
import CheckMail from './components/LoginSystem/CheckMail';
import CreatePassword from './components/LoginSystem/CreatePassword';
import NewProfileShare from './components/HomePage/NewProfileShare';
import NewProfileView from './components/HomePage/NewProfileView';
import NewJobPage from './components/RecruiterPage/NewJobPage';
import NewCandidateProfileView from './components/RecruiterPage/NewCandidateProfileView';
import ShareOptionDialog from './components/RecruiterPage/ShareOptionDialog';
import InterviewEdit from './components/RecruiterPage/InterviewEdit';
import RecruiterNewProfileEdit from './components/RecruiterPage/RecruiterNewProfileEdit';
import RecruiterNewProfileCreate from './components/RecruiterPage/RecruiterNewProfileCreate';
import FeedbackCount from './components/RecruiterPage/FeedbackCount';
import CandidateNavbar from './components/HomePage/CandidateNavbar';
import CandidateInterviewPage from './components/HomePage/CandidateInterviewPage';
import NewCreateCandidate from './components/RecruiterPage/NewCandidateCreate';
import NewCandidateCreateProfile from './components/RecruiterPage/NewCandidateCreateProfile';
import WebcamSpecificQuestionNoAuth from './components/Webcam/RecordSpecificNonAuth';

function App() {
  // const [status,setStatus]=useState(false);
  const PrivateRoute = ({ children }) => {
    if (localStorage.getItem('AuthStatus') == 'true') {
      return children;
    } else {
      return <Navigate to="/" />;
    }
  };
  // useEffect(async () => {
  //   const auth = await AwsAuth.loginConfirm();
  //   localStorage.setItem("AuthStatus",auth);
  //   setStatus(auth);
  // }, []);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          //**!COMMON-ROUTES**/
          <Route exact path="/" element={<Home />} />
          <Route exact path="/card" element={<Card />} />
          <Route exact path="/feedback" element={<Feedback />} />
          <Route
            exact
            path="/chooseCandidateCard"
            element={<ChooseCandidateCard />}
          />
          <Route exact path="/viewJobCard" element={<ViewJobCard />} />
          <Route exact path="/register" element={<Registration />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/checkmail" element={<CheckMail />} />
          <Route exact path="/createpassword" element={<CreatePassword />} />
          <Route exact path="/navbar" element={<Navbar />} />
          <Route exact path="/newSchedulePage" element={<NewSchedulePage />} />
          <Route exact path="/CandidateNavbar" element={<CandidateNavbar />} />
          <Route
            exact
            path="/candidate/interviewPage"
            element={<CandidateInterviewPage />}
          />
          <Route
            exact
            path="/newInterviewPage"
            element={<NewInterviewPage />}
          />
          <Route
            exact
            path="/newCandidateProfile"
            element={<NewCandidateProfile />}
          />
          <Route
            exact
            path="/recruiter/candidate/create"
            element={<NewCreateCandidate />}
          />
          <Route exact path="/timeline" element={<Timeline />} />
          <Route exact path="/verification" element={<Verification />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/termsandconditions"
            element={<TermsandCondition />}
          />
          //**!CANDIDATE-ROUTES**/
          <Route exact path="/candidate/profile" element={<ProfileView />} />
          <Route exact path="/candidate/newprofile" element={<NewProfile />} />
          <Route
            exact
            path="/candidate/instruction"
            element={<InstructionPage />}
          />
          <Route exact path="/instruction" element={<InstructionPage />} />
          <Route
            exact
            path="/candidate/interviews"
            element={<InterviewPage />}
          />
          <Route
            exact
            path="/candidate/company_profile"
            element={<CompanyProfile />}
          />
          <Route
            exact
            path="/candidate/company_profile"
            element={
              <PrivateRoute>
                <CompanyProfile />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/candidate/instruction"
            element={
              <PrivateRoute>
                <InstructionPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/candidate/interview/record"
            element={
              <PrivateRoute>
                <RecordSpecificQuestion />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/candidate/interview/direct/record"
            element={<WebcamSpecificQuestionNoAuth />}
          />
          <Route
            exact
            path="/candidate/interview/instructions"
            element={
              <PrivateRoute>
                <InsrtuctionPageSpecificQ />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/candidate/edit_profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/candidate/profile/record"
            element={
              <PrivateRoute>
                <RecordPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/candidate/interview/instructions"
            element={<InsrtuctionPageSpecificQ />}
          />
          <Route path="/newprofileshare" element={<NewProfileShare />} />
          <Route
            exact
            path="/candidate/profile"
            element={
              <PrivateRoute>
                <ProfileView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/candidate/interviews"
            element={
              <PrivateRoute>
                <InterviewPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/candidate/neweditprofile"
            element={<NewEditProfile />}
          />
          <Route
            exact
            path="/candidate/newprofileview"
            element={<NewProfileView />}
          />
          //**!RECRUITER-ROUTES**/
          <Route exact path="/recruiter/home" element={<RecruiterHomePage />} />
          <Route exact path="/recruiter/home" element={<RecruiterHomePage />} />
          <Route exact path="/recruiter/schedule" element={<SchedulePage />} />
          <Route
            exact
            path="/recruiter/newProfile/create"
            element={<RecruiterNewProfileCreate />}
          />
          <Route
            exact
            path="/recruiter/newCandidate/create"
            element={<NewCandidateCreateProfile />}
          />
          <Route
            exact
            path="/NewCandidateProfileView"
            element={<NewCandidateProfileView />}
          />
          <Route exact path="/newJobPage" element={<NewJobPage />} />
          <Route
            path="/recruiter/interviews"
            element={
              <PrivateRoute>
                <RecruiterHome />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/recruiter/edit_profile"
            element={
              <PrivateRoute>
                <RecruiterProfile />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/recruiter/newprofile"
            element={<NewRecruiterProfile />}
          />
          <Route
            exact
            path="/recruiter/profile"
            element={<RecruiterProfileView />}
          />
          <Route
            exact
            path="/recruiter/profile"
            element={
              <PrivateRoute>
                {' '}
                <RecruiterProfileView />{' '}
              </PrivateRoute>
            }
          />
          <Route
            path="/recruiter/schedule"
            element={
              <PrivateRoute>
                {' '}
                <SchedulePage />{' '}
              </PrivateRoute>
            }
          />
          <Route
            path="/recruiter/schedule/edit"
            element={
              <PrivateRoute>
                {' '}
                <EditScheduleInterview />{' '}
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/recruiter/newedit"
            element={<RecruiterNewProfileEdit />}
          />
          <Route exact path="/recruiter/joblist" element={<JobList />} />
          {/* <Route exact path="/recruiter/job/create" element={<CreateJob />} /> */}
          <Route exact path="/interviewedit" element={<InterviewEdit />} />
          <Route
            exact
            path="/recruiter/job/newcreate"
            element={<NewCreateJob />}
          />
          <Route exact path="/recruiter/job/view" element={<ViewJob />} />
          {/* <Route exact path="/recruiter/job/edit" element={<EditJob />} /> */}
          <Route exact path="/sharedialog" element={<ShareOptionDialog />} />
          <Route
            exact
            path="/recruiter/job/schedule"
            element={<QuestionPage />}
          />
          <Route exact path="/chooseprofile" element={<ChooseProfile />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/feedbackcount" element={<FeedbackCount />} />
          <Route
            exact
            path="/recruiter/aboutus"
            element={<RecruiterAboutUs />}
          />
          <Route exact path="/test" element={<Test />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
