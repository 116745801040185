import { DataStore } from '@aws-amplify/datastore';
import {Job } from '../../../models'
import { Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
Amplify.configure(awsconfig);

class JobAmplify {
  async save(data) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.save(data).then((user) => {
          console.log(user);
          resolve('success');
        });
      } catch (e) {
        console.log('Job save', e);
        reject(e);
      }
    });
  }

  async update(data, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const original = await DataStore.query(Job, id);
        console.log('original', original);

        const updatedJob = Job.copyOf(original, (updated) => {
          updated.Title = data.Title;
          updated.Description = data.Description;
          updated.RemoteWorking = data.RemoteWorking;
          updated.Location = data.Location;
          updated.MaximumExperience = data.MaximumExperience;
          updated.MinimumExperience = data.MinimumExperience;
          updated.InternalID = data.InternalID;
          updated.HiringManagerEmail = data.HiringManagerEmail;
          updated.HiringManagerName = data.HiringManagerName;
          updated.Skills = data.Skills;
          updated.Gender = data.Gender;
        });

        const updatedObject = await DataStore.save(updatedJob);
        console.log('Job update', updatedObject);

        resolve('success');
      } catch (error) {
        console.error('Error updating Job:', error);
        reject(error);
      }
    });
  }
  async getAllJobsByCompany(companyID) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Job, (c) => c.jobCompanyId.eq(companyID), {
          consistent: true,
          sort: (s) => s.createdAt('desc'),
        }).then((jobs) => {
          console.log(jobs);
          resolve(jobs);
        });
      } catch (e) {
        console.log('InterviewSchedule update', e);
        reject(e);
      }
    });
  }

  async getLast10JobsByCompany(companyID) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Job, (c) => c.jobCompanyId.eq(companyID), {
          sort: (s) => s.createdAt('desc'),
          limit: 10,
        }).then((jobs) => {
          console.log(jobs);
          resolve(jobs);
        });
      } catch (e) {
        console.log('getLast10JobsByCompany', e);
        reject(e);
      }
    });
  }

  async getJobsByID(jobID) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(Job, (c) => c.id.eq(jobID), {
          consistent: true,
        }).then((job) => {
          console.log(job);
          resolve(job[0]);
        });
      } catch (e) {
        console.log('InterviewSchedule update', e);
        reject(e);
      }
    });
  }

  async searchJobSchedule(text, companyId) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(
          Job,
          (c) =>
            c.jobCompanyId.eq(companyId) &&
            c.or((c) => [
              c.InternalID.contains(text),
              c.Title.contains(text),
              c.HiringManagerName.contains(text),
              c.HiringManagerEmail.contains(text),
            ]),
          { consistent: true, sort: (s) => s.createdAt('desc') }
        ).then((result) => {
          resolve(result);
        });
      } catch (e) {
        console.log('InterviewSchedule search', e);
        reject(e);
      }
    });
  }

  async searchJobScheduleTab(status, jobInputText, companyId) {
    return new Promise(async (resolve, reject) => {
      try {
        await DataStore.query(
          Job,
          (c) => c.jobCompanyId.eq(companyId) && c.Status.eq(status),

          { consistent: true, sort: (s) => s.createdAt('desc') }
        ).then((result) => {
          console.log('searchJobScheduleTab search', result);
          const newData = [];

          for (let i = 0; i < result.length; i++) {
            if (result[i].InternalID.includes(jobInputText)) {
              newData.push(result[i]);
            } else if (result[i].Title.includes(jobInputText)) {
              newData.push(result[i]);
            } else if (result[i].HiringManagerName.includes(jobInputText)) {
              newData.push(result[i]);
            } else if (result[i].HiringManagerEmail.includes(jobInputText)) {
              newData.push(result[i]);
            }
          }
          console.log('newData', newData);
          resolve(newData);
        });
      } catch (e) {
        console.log('searchJobScheduleTab search', e);
        reject(e);
      }
    });
  }
}
export default new JobAmplify();