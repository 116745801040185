/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import './Profile.css';
import Navbar from './Navbar';
import profile from '../../assets/images/profile.png';
import { Container, Row, Col, FormControl } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState, useCallback } from 'react';
import jwtDecode from 'jwt-decode';
import firebaseConfig from '../Firebase/firebaseConfig';
import { ref, set, get, update, remove, child } from 'firebase/database';
import firebaseStorage from '../Firebase/firebaseStorage';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { async } from '@firebase/util';
import { useNavigate } from 'react-router-dom';
import './InterviewPage.scss';
import AwsAmplify from '../Service/AWS_Auth/CandidateDataService';
import { Candidate } from '../../models';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import AWS_Storage from '../Service/AWS_Auth/VideoStorage';
import { Amplify } from 'aws-amplify';
import { Image } from '@aws-amplify/ui-react';
import awsconfig from '../../aws-exports';
import { Storage } from '@aws-amplify/storage';
import { toast } from 'react-toastify';
export default function Profile() {
  Amplify.configure(awsconfig);
  const navigate = useNavigate();
  const [user, setUser] = React.useState({});
  const [firstName, setFirstName] = React.useState('');
  const [id, setID] = React.useState();
  const [lastName, setLastName] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [homeTown, setHomeTown] = React.useState('');
  const [currentLocation, setCurrentLocation] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [video, setVideo] = React.useState('');
  const [qualificationChoice, setQualificationChoice] = React.useState('');
  const [course, setCourse] = React.useState('');
  const [clgName, setClgName] = React.useState('');
  const [clgCity, setClgCity] = React.useState('');
  const [CGPA, setCGPA] = React.useState(0);
  const [skill, setSkill] = React.useState([]);
  const [experience, setExperience] = React.useState('');
  const [resume, setResume] = React.useState();
  const [userDetails, setUserDetails] = React.useState();
  const [fresherProjectName, setFresherProjectName] = React.useState('');
  const [fresherProjectDetails, setFresherProjectDetails] = React.useState('');
  const [fresherProjectTools, setFresherProjectTools] = React.useState('');
  const [YOE, setYOE] = React.useState(0);
  const [companyName, setCompanyName] = React.useState('');
  const [role, setRole] = React.useState('');
  const [linkedin, setLinkedin] = React.useState();
  const [github, setGithub] = React.useState();
  const [youtube, setYoutube] = React.useState();
  const [profilePic, setProfilePic] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [picUrl, setPicUrl] = React.useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorResume, setErrorResume] = useState(false);
  const [errorPic, setErrorPic] = useState(null);
  // var userDecoded ;
  var video_url;
  const [isFresher, setIsFresher] = useState(false);
  const [isExperienced, setIsExperienced] = useState(false);
  // const [Experience,setExperience]  = useState(false);
  const inputRef = useRef(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(phone);
  // Upload button validation open -----------------------------------------
  // const types = ['image/png', 'image/jpeg', 'image/gif'];
  // const [file, setFile] = useState(null);

  // -------------------------------------------- Upload button validation close

  // React-Select dropdown Validation open -----------------------------
  // const [qualificationChoice,setQualificationChoice] = useState({qualification: null });
  // const onValidate = (value, name) => {
  //   setError((prev) => ({
  //     ...prev,
  //     [name]: { ...prev[name], errorMsg: value },
  //   }));
  // };

  // const [error, setError] = useState({
  //   qualification: {
  //     isReq: true,
  //     errorMsg: '',
  //     onValidateFunc: onValidate,
  //   }
  // });

  // const onHandleChange = useCallback((value, name) => {
  //   setQualificationChoice((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // }, []);

  // const validateForm = () => {
  //   let isInvalid = false;
  //   Object.keys(error).forEach((x) => {
  //     const errObj = error[x];
  //     if (errObj.errorMsg) {
  //       isInvalid = true;
  //     } else if (errObj.isReq && !qualificationChoice[x]) {
  //       isInvalid = true;
  //       onValidate(true, x);
  //     }
  //   });
  //   return !isInvalid;
  // };

  // const handleSubmit = () => {
  //   const isValid = validateForm();
  //   if (!isValid) {
  //     console.error('Invalid Form!');
  //     return false;
  //   }

  //   console.log('Data:', form);
  // };
  // -------------------------------- React-Select dropdown Validation close.

  const dataUpload = async (event) => {
    if (!selectedFile) {
      setErrorResume('Please select a file to upload');
      return;
    }
    if (picUrl == '') {
      setErrorPic('Please select a file to upload');
      return;
    }
    if (selectedFile != null && picUrl != '') {
      try {
        let resumeUpload = await AWS_Storage.Resume(resume); //await firebaseStorage("Resume",resume);

        console.log(resumeUpload);
        var data = new Candidate({
          FirstName: firstName,
          Phone: phone,
          Email: email,
          DOB: dob,
          Gender: gender,
          Qualification: qualificationChoice,
          LastName: lastName,
          HomeLocation: homeTown,
          CurrentLocation: currentLocation,
          College: clgName,
          CollegeCity: clgCity,
          Course: course,
          CGPA: CGPA,
          Experience: experience,
          Resume: resumeUpload,
          FresherProjectDetails: fresherProjectDetails,
          FresherProjectName: fresherProjectName,
          FresherProjectTools: fresherProjectTools,
          YOE: YOE,
          CompanyName: companyName,
          CurrentRole: role,
          Github: github,
          Linkedin: linkedin,
          Youtube: youtube,
          ProfilePic: profilePic,
          Status: true,
        });
        // let userValue =userDetails!=null ? await UserProfile.updateProfile("Seeker",firstName,lastName,dob,homeTown,currentLocation,phone,gender,qualificationChoice,course,clgName,clgCity,CGPA,"skill",experience,resumeUpload,fresherProjectName,fresherProjectDetails,fresherProjectTools,YOE,companyName,role,user.email,github,youtube,linkedin,profilePic,(user.picture),(user.sub))
        // :await UserProfile.create(data,(user.sub))
        // if(userValue==="success"){
        //   navigate("/instructionPage");
        // }

        //   setValidated(true);
        console.log(id);

        if (id == undefined) {
          await AwsAmplify.save(data)
            .then((user) => {
              if (user.length != 0) {
                toast.success('Your data has been saved successfully');
                navigate('/candidate/profile');
                // navigate("/instruction",{ state:{id:user.id}});
              }
            })
            .catch((err) => {
              toast.error(err.toString());
            });
        } else {
          await AwsAmplify.update(id, data).then((user) => {
            if (user == 'success') {
              toast.success('Your data has been saved successfully');
              navigate('/candidate/profile');
              // navigate("/instruction",{ state:{id:id}});
            }
          });
        }
      } catch (e) {
        console.log(e);
        toast.error(e.toString());
      }
    }
  };

  useEffect(async () => {
    // userDecoded = jwtDecode(localStorage.getItem("user"));
    // setUser(userDecoded);
    // let userValue =await UserProfile.getUserById(userDecoded.sub);
    let email = localStorage.getItem('email');
    setEmail(email);
    await AwsAmplify.getCandidate(email)
      .then(async (userValue) => {
        setUserDetails(userValue);
        if (userValue.length != 0) {
          if (userValue.Status == true) {
            setID(userValue.id);
            setFirstName(userValue.FirstName);
            setLastName(userValue.LastName);
            setDob(userValue.DOB);
            setHomeTown(userValue.HomeLocation);
            setCurrentLocation(userValue.CurrentLocation);
            setPhone(userValue.Phone);
            setGender(userValue.Gender);
            setQualificationChoice(
              userValue.Qualification != null ? userValue.Qualification : ''
            );
            // setQualification(userValue.UserProfile.Qualification)
            setCourse(userValue.Course);
            setClgName(userValue.College);
            setClgCity(userValue.CollegeCity);
            setCGPA(userValue.CGPA);
            setExperience(userValue.Experience);
            setResume(userValue.Resume);
            if (userValue.Resume != null && userValue.Resume != '') {
              setErrorResume(false);
              setSelectedFile(true);
            } else {
              setErrorResume(true);
              setSelectedFile(false);
            }
            setFresherProjectDetails(userValue.FresherProjectDetails);
            setFresherProjectName(userValue.FresherProjectName);
            setFresherProjectTools(userValue.FresherProjectTools);
            setYOE(userValue.YOE);
            setCompanyName(userValue.CompanyName);
            setRole(userValue.CurrentRole);
            setGithub(userValue.Github != null ? userValue.Github : '');
            setLinkedin(userValue.Linkedin != null ? userValue.Linkedin : '');
            setYoutube(userValue.Youtube != null ? userValue.Youtube : '');
            setProfilePic(
              userValue.ProfilePic != null ? userValue.ProfilePic : null
            );
            if (userValue.Experience == 'Fresher') {
              Fresher();
            }
            if (userValue.Experience == 'Experience') {
              Experienced();
            }
            localStorage.setItem('candidateId', userValue.id);
            localStorage.setItem('fname', userValue.FirstName);
            if (userValue.ProfilePic != null) {
              await Storage.get(userValue.ProfilePic).then((val) => {
                setPicUrl(val);
                setErrorPic(false);
              });
            }
          } else {
            setID(userValue.id);
            setEmail(userValue.Email);
          }
        }
      })
      .catch((err) => {
        toast.error(err.toString());
      });

    let defaultValues = {};
    reset({ ...defaultValues });
  }, []);

  const Fresher = () => {
    setIsFresher(true);
    setIsExperienced(false);
    setExperience('Fresher');
  };
  const Experienced = () => {
    setIsExperienced(true);
    setIsFresher(false);
    setExperience('Experience');
  };
  const handleClick = (e) => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };

  const handleFileChangeSubmit = (event) => {
    setSelectedFile(event.target.files[0]);
    setResume(event.target.files[0]);
    setErrorResume(false);
  };
  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = null;
    let pictureUpload = await AWS_Storage.ProfilePic(fileObj).then(
      async (val) => {
        setProfilePic(val);
        await Storage.get(val).then((val) => {
          setPicUrl(val);
          setErrorPic(false);
        });
      }
    ); //await firebaseStorage("Profile_Picture",fileObj);
  };
  return (
    <div>
      <Navbar />
      <Container fluid>
        <Row className="profile_editInfo_text">
          <Col>
            <h2> Edit Info</h2>
          </Col>
        </Row>
        <br></br>
      </Container>
      <Container className="profile_info_page">
        <Row>
          <Col className="edit_info" md={3}>
            <Row>
              <Col className="profile_pic_card" md={12}>
                <Image
                  className="profile_pic"
                  src={picUrl}
                  height="300px"
                  width="300px"
                />
                {errorPic && <p className="text-danger">{errorPic}</p>}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {/* <button className="slide profile_pic_btn">Take Photo</button> */}
                <div className="choose_profile_pic">
                  <input
                    style={{ display: 'none' }}
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  {/* { error && <div className="error">{error}</div> }
                  { file && <div>{file.name}</div> } */}
                  <button
                    className=" slide profile_pic_btn_html"
                    onClick={handleClick}
                  >
                    Choose from Gallery
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="profile_details" md={9}>
            <Form noValidate onSubmit={handleSubmit(dataUpload)}>
              <Row className="mb-3">
                <h5>Personal Info :</h5>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>First name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    {...register('firstName', {
                      required: true,
                      maxLength: 20,
                    })}
                    aria-invalid={errors.firstName ? 'true' : 'false'}
                    onChange={(ev) => setFirstName(ev.target.value)}
                    value={firstName}
                    renderValue={(value) => `${value}`}
                  />
                  {errors.firstName?.type === 'required' && (
                    <p className="error_msg">Please check the First Name</p>
                  )}
                  {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Last name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    {...register('lastName', { required: true, maxLength: 20 })}
                    aria-invalid={errors.lastName ? 'true' : 'false'}
                    onChange={(ev) => setLastName(ev.target.value)}
                    value={lastName}
                    renderValue={(value) => `${value}`}
                  />
                  {errors.lastName?.type === 'required' && (
                    <p className="error_msg">Please check the Last Name</p>
                  )}
                  {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Date of Birth *</Form.Label>
                  <Form.Control
                    type="date"
                    {...register('dob', { required: 'true' })}
                    aria-invalid={errors.dob ? 'true' : 'false'}
                    onChange={(ev) => setDob(ev.target.value)}
                    value={dob}
                    renderValue={(value) => `${value}`}
                  />
                  {errors.dob && (
                    <p className="error_msg">Please check the DOB</p>
                  )}

                  {/* <Form.Control.Feedback type="invalid">
         
          <Form.Control.Feedback type="invalid">
            Please provide a valid date of birth
          </Form.Control.Feedback> */}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom03">
                  <Form.Label>Hometown *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Hometown"
                    {...register('hometown', { required: true, maxLength: 30 })}
                    aria-invalid={errors.hometown ? 'true' : 'false'}
                    onChange={(ev) => setHomeTown(ev.target.value)}
                    value={homeTown}
                    renderValue={(value) => `${value}`}
                  />
                  {errors.hometown && (
                    <p className="error_msg">Please check the Hometown</p>
                  )}

                  {/* <Form.Control.Feedback type="invalid">
          <Form.Label>Hometown</Form.Label>
          
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom04">
                  <Form.Label>Current Location *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Current location"
                    {...register('location', { required: true, maxLength: 30 })}
                    aria-invalid={errors.location ? 'true' : 'false'}
                    onChange={(ev) => setCurrentLocation(ev.target.value)}
                    value={currentLocation}
                    renderValue={(value) => `${value}`}
                  />
                  {errors.location && (
                    <p className="error_msg">
                      Please check the Current Location
                    </p>
                  )}

                  {/* <Form.Control.Feedback type="invalid">
          <Form.Label>Current Location</Form.Label>
          <Form.Control type="text" placeholder="Current location" onChange={(ev)=> setCurrentLocation(ev.target.value)} value={currentLocation}
            renderValue={(value) => `${value}`} required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid City.
          </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationCustomUsername"
                >
                  <Form.Label>Phone Number *</Form.Label>

                  {/* <Form.Control
              type="number"
              placeholder="+91"
              aria-describedby="inputGroupPrepend"
             {...register("phone", { required: true, maxLength:10, minLength:10 })}
              aria-invalid={errors.phone ? "true" : "false"} 
              required
              onChange={(ev)=> setPhone( (ev.target.value))}
              defaultValue={phone}
              value={phone}
              renderValue={(value) => `${value}`}
            /> */}
                  <PhoneInput
                    country="IN"
                    value={phone}
                    onChange={(ev) => setPhone(ev)}
                  />

                  {errors.phone && (
                    <p className="error_msg">Please check the Phone Number</p>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please enter your phone number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Col sm={true}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label as="legend" column sm={2}>
                      Gender *
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        type="radio"
                        label="Female"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                        checked={gender === 'Female'}
                        onChange={(ev) => setGender('Female')}
                        // {...register("gender", { required: true})}
                        // aria-invalid={errors.gender? "true" : "false"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Male"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios2"
                        checked={gender === 'Male'}
                        onChange={(ev) => setGender('Male')}
                        // {...register("gender", { required: true})}
                        // aria-invalid={errors.gender? "true" : "false"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Others"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios3"
                        checked={gender === 'Other'}
                        onChange={(ev) => setGender('Other')}
                        // {...register("gender", { required: true})}
                        // aria-invalid={errors.gender? "true" : "false"}
                      />
                      {errors.gender && (
                        <p className="error_msg">Please check the Gender</p>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <h5>Education :</h5>

                <Form.Group as={Col} md="6">
                  <Form.Label>Highest Qualification *</Form.Label>
                  <select
                    id="qualification"
                    onChange={(e) => {
                      setQualificationChoice(e.target.value);
                    }}
                    value={qualificationChoice}
                    required
                  >
                    <option>Choose your Highest Qualification</option>
                    <option value="Bachelor's">Bachelor's</option>
                    <option value="Master's">Master's</option>
                    <option value="Others">Others</option>
                  </select>
                  {errors.qualification && (
                    <p className="error_msg">Please check the Qualification</p>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Course Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Course Name"
                    {...register('course', { required: true, maxLength: 30 })}
                    aria-invalid={errors.course ? 'true' : 'false'}
                    onChange={(ev) => setCourse(ev.target.value)}
                    value={course}
                    renderValue={(value) => `${value}`}
                    required
                  />
                  {errors.course && (
                    <p className="error_msg">Please check the Course Name</p>
                  )}

                  {/* <Form.Control.Feedback type="invalid">
          <Form.Control type="text" placeholder="Course Name" onChange={(ev)=> setCourse(ev.target.value)} value={course}
            renderValue={(value) => `${value}`}required />
          <Form.Control.Feedback type="invalid">
            Please provide your Course name
          </Form.Control.Feedback> */}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom03">
                  <Form.Label>College Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="College Name"
                    {...register('collegename', {
                      required: true,
                      maxLength: 30,
                    })}
                    aria-invalid={errors.collegename ? 'true' : 'false'}
                    onChange={(ev) => setClgName(ev.target.value)}
                    value={clgName}
                    renderValue={(value) => `${value}`}
                    required
                  />
                  {errors.collegename && (
                    <p className="error_msg">Please check the College Name</p>
                  )}
                  {/* <Form.Control.Feedback type="invalid">
          <Form.Control type="text" placeholder="College Name" onChange={(ev)=> setClgName(ev.target.value)} value={clgName}
            renderValue={(value) => `${value}`}required />
          <Form.Control.Feedback type="invalid">
            Please provide your College name
          </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom04">
                  <Form.Label>City *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    {...register('city', { required: true, maxLength: 30 })}
                    aria-invalid={errors.city ? 'true' : 'false'}
                    onChange={(ev) => setClgCity(ev.target.value)}
                    value={clgCity}
                    renderValue={(value) => `${value}`}
                    required
                  />
                  {errors.city && (
                    <p className="error_msg">Please check the City</p>
                  )}
                  {/* <Form.Control.Feedback type="invalid">
          <Form.Label>City</Form.Label>
          <Form.Control type="text" placeholder="City" onChange={(ev)=> setClgCity(ev.target.value)} value={clgCity}
            renderValue={(value) => `${value}`} required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid City.
          </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationCustomUsername"
                >
                  <Form.Label>Percentage/CGPA *</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="%"
                    aria-describedby="inputGroupPrepend"
                    {...register('cgpa', { required: true, maxLength: 99 })}
                    aria-invalid={errors.cgpa ? 'true' : 'false'}
                    required
                    onChange={(ev) => setCGPA(Number(ev.target.value))}
                    value={CGPA}
                    renderValue={(value) => `${value}`}
                  />
                  {errors.cgpa && (
                    <p className="error_msg">
                      Please check the Percentage/CGPA
                    </p>
                  )}
                  {/* <Form.Control.Feedback type="invalid">
              Please enter your Percentage/CGPA
            </Form.Control.Feedback> */}
                </Form.Group>
              </Row>

              {/* <Row className="mb-3">
        <h5>Skill Set :</h5>
        <Col sm={3}>
        <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="JavaScript" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="Node.js" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="React" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="Angular" />
      </Form.Group>
      </Col>
      <Col sm={3}>
        <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="C" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="C++" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="Java" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="Python" />
      </Form.Group>
      </Col>
      <Col sm={3}>
        <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="Rust" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="Django" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="Go" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="C#" />
      </Form.Group>
      </Col>
      <Col sm={3}>
        <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="HTML" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="CSS" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="MySQL" />
      </Form.Group>
      <Form.Group  id="formGridCheckbox">
        <Form.Check type="checkbox" label="JavaScript" />
      </Form.Group>
      </Col>
      </Row> */}

              <Row className="mb-3">
                <h5>Experience :</h5>
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      I am a
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        type="radio"
                        label="Fresher"
                        name="formHorizontalRadios1"
                        id="formHorizontalRadios5"
                        // {...register("experience", { required: true})}
                        // aria-invalid={errors.experience? "true" : "false"}
                        onChange={(ev) => setExperience('Fresher')}
                        onClick={Fresher}
                        checked={experience === 'Fresher'}
                        //   value={experience}
                        //  renderValue={(value) => `${value}`}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Experience"
                        name="formHorizontalRadios1"
                        id="formHorizontalRadios6"
                        // {...register("experience", { required: true})}
                        // aria-invalid={errors.experience? "true" : "false"}
                        onChange={(ev) => setExperience('Experience')}
                        onClick={Experienced}
                        checked={experience === 'Experience'}

                        // value={experience}
                        // renderValue={(value) => `${value}`}
                      />
                      {experience !== null && errors.experience && (
                        <p className="error_msg">Please check the Experience</p>
                      )}
                      <Row>
                        <Col className="select_experienced" display sm={true}>
                          {isExperienced && (
                            <div>
                              <Form.Group as={Col} md="7">
                                <Form.Label>Year of Experience</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Year of Experience"
                                  onChange={(ev) =>
                                    setYOE(Number(ev.target.value))
                                  }
                                  value={YOE}
                                  renderValue={(value) => `${value}`}
                                />
                              </Form.Group>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="select_experienced" sm={true}>
                          {isExperienced && (
                            <div>
                              <Form.Group as={Col} md="7">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Company Name"
                                  onChange={(ev) =>
                                    setCompanyName(ev.target.value)
                                  }
                                  value={companyName}
                                  renderValue={(value) => `${value}`}
                                />
                              </Form.Group>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="select_experienced" sm={true}>
                          {isExperienced && (
                            <div>
                              <Form.Group as={Col} md="7">
                                <Form.Label>Role</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Your Role"
                                  onChange={(ev) => setRole(ev.target.value)}
                                  value={role}
                                  renderValue={(value) => `${value}`}
                                />
                              </Form.Group>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="select_fresher" sm={true}>
                          {isFresher && (
                            <div>
                              <Form.Group as={Col} md="7">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Project Name"
                                  onChange={(ev) =>
                                    setFresherProjectName(ev.target.value)
                                  }
                                  value={fresherProjectName}
                                  renderValue={(value) => `${value}`}
                                />
                              </Form.Group>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="select_fresher" sm={true}>
                          {isFresher && (
                            <div>
                              <Form.Group
                                as={Col}
                                md="7"
                                controlId="validationCustom03"
                              >
                                <Form.Label>Project Details</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  onChange={(ev) =>
                                    setFresherProjectDetails(ev.target.value)
                                  }
                                  value={fresherProjectDetails}
                                  renderValue={(value) => `${value}`}
                                />
                              </Form.Group>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="select_fresher" sm={true}>
                          {isFresher && (
                            <div>
                              <Form.Group
                                as={Col}
                                md="7"
                                controlId="validationCustom03"
                              >
                                <Form.Label>Tools Used</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Tools used"
                                  onChange={(ev) =>
                                    setFresherProjectTools(ev.target.value)
                                  }
                                  value={fresherProjectTools}
                                  renderValue={(value) => `${value}`}
                                />
                              </Form.Group>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom03">
                  <Form.Label>Linkedin</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Linkedin"
                    // {...register("Linkedin", { required: true, maxLength: 100 })}
                    //  aria-invalid={errors.linkedin? "true" : "false"}
                    onChange={(ev) => setLinkedin(ev.target.value)}
                    value={linkedin}
                    renderValue={(value) => `${value}`}
                  />
                  {/* {errors.linkedin && <p className="error_msg">Please enter linkedin address</p>} */}
                  {/* <Form.Control.Feedback type="invalid">
          <Form.Control type="text" placeholder="College Name" onChange={(ev)=> setClgName(ev.target.value)} value={clgName}
            renderValue={(value) => `${value}`}required />
          <Form.Control.Feedback type="invalid">
            Please provide your College name
          </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom04">
                  <Form.Label> GitHub</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="GitHub"
                    // {...register("GitHub", { required: true, maxLength: 30 })}
                    //  aria-invalid={errors.github? "true" : "false"}
                    onChange={(ev) => setGithub(ev.target.value)}
                    value={github}
                    renderValue={(value) => `${value}`}
                  />
                  {/* {errors.github && <p className="error_msg">Please enter your github address</p>} */}
                  {/* <Form.Control.Feedback type="invalid">
          <Form.Label>City</Form.Label>
          <Form.Control type="text" placeholder="City" onChange={(ev)=> setClgCity(ev.target.value)} value={clgCity}
            renderValue={(value) => `${value}`} required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid City.
          </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationCustomUsername"
                >
                  <Form.Label>Youtube channel</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Youtube"
                    //  aria-invalid={errors.cgpa? "true" : "false"}
                    onChange={(ev) => setYoutube(ev.target.value)}
                    value={youtube}
                    renderValue={(value) => `${value}`}
                  />
                  {/* {errors.cgpa && <p className="error_msg">Please check the Percentage/CGPA</p>} */}
                  {/* <Form.Control.Feedback type="invalid">
              Please enter your Percentage/CGPA
            </Form.Control.Feedback> */}
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <h5>Resume :</h5>
                <Col sm={true}>
                  <Form.Group controlId="formFile">
                    <Form.Label>Upload your resume :</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".doc,.docx,.pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
                      onChange={handleFileChangeSubmit}
                    />
                    {errorResume && (
                      <p className="text-danger">{errorResume}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="save_btn" sm={true}>
                  <button className="slide continue_btn" type="submit">
                    Save & Continue
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
