import React from 'react';
import Navbar from './Navbar';
import './Home.css';
import './HomeResponsive.css';
import Container from '@mui/material/Container';
import img from '../../assets/images/Interview_home.gif';
import { Await, Link } from 'react-router-dom';
import Footer from './Footer';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import LoginIcon from '@mui/icons-material/Login';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import WorkIcon from '@mui/icons-material/Work';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Timeline from './Timeline';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { orange } from '@mui/material/colors';
import AwsAmplify from '../Service/AWS_Auth/CompanyDataService';
import AwsAuth from '../Service/AWS_Auth/authenticationServices';
import { Grid } from '@mui/material';
import AboutUs from './AboutUs';
function Home() {
  const user = localStorage.getItem('user');
  return (
    <>
      <Container maxWidth className="total_home_pg">
        <Navbar />
        <Container fixed className="home_page_heading_container">
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className="home_txt">Cut Your Hiring Time by 30%</div>
              <div className="home_details_txt">
                We help companies fill open job positions fast.
              </div>
              {user == null ? (
                <Grid container spacing={2} className="home_login_btn">
                  <Grid item xs={12} md={12}>
                    <div>
                      <Link to="/chooseprofile">
                        <button className="home_btn_hire">Get Started</button>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <div></div>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <img className="home_gif_img" src={img} width="100%"></img>
            </Grid>
          </Grid>
          <br />
          <AboutUs />
        </Container>
        <Footer />
      </Container>
    </>
  );
}

export default Home;
