import { DataStore } from '@aws-amplify/datastore';
import {Recruiter } from '../../../models'
import { Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
Amplify.configure(awsconfig);

class RecruiterAmplify{

async save (data){
  return new Promise(async (resolve,reject )=>{ 
  try{
    await DataStore.save(
        data
).then(user=>{
  console.log(user)
  localStorage.setItem("recruiterId",user.id);
  resolve("success");
}) }
  catch(e){
    console.log("Recruiter save", e)
   reject(e)
  }
});
}

async  update(id, data) {
  return new Promise(async (resolve,reject )=>{ 
  try{
    await DataStore.query(Recruiter, id).then(async original=>{
      await DataStore.save(
        Recruiter.copyOf(original, updated => {
          updated.FirstName=data.FirstName
          updated.LastName=data.LastName
          updated.Phone=data.Phone
          updated.Email=data.Email
          updated.Linkedin=data.Linkedin
          updated.Profilepic=data.Profilepic
        }
      )
      ).then(user=>{
        console.log("Recruiter update",user)
        resolve("success");
      })
    })
    }
    catch(e){
      console.log("Recruiter update", e)
      reject(e)
    }
  });
  }


async getRecruiter(email){
  return new Promise(async (resolve,reject )=>{ 
    try {
      await DataStore.query(Recruiter, c => c.Email.eq(email),{  consistent: true }).then(user=>{
        if(user.length!=0){
        console.log(user[0]);
        resolve(user[0]);
        }
        else
        {
          resolve([]);
        }
      })
      
    }
   catch(e){
    console.log('error getRecruiter: ', e);
    reject(e);
   }
  });
}

async getRecruiterById(recruiterId){
  return new Promise(async (resolve,reject )=>{ 
    try {
        await DataStore.query(Recruiter, c => c.id.eq(recruiterId),{  consistent: true }).then(user=>{
          console.log(user[0]);
          resolve(user[0]);
        })
    }
    catch(e){
      console.log('error getRecruiterById: ', e);
      reject(e);
     }
});
}

}
export default new RecruiterAmplify();