import { React, useState } from 'react';
import './RecordPage.css';
import VideoRecorder from 'react-video-recorder';
import { Container, Grid } from '@mui/material';
import Navbar from '../HomePage/Navbar';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import AWS_Storage from '../Service/AWS_Auth/VideoStorage';
import AwsAmplify from '../Service/AWS_Auth/CandidateDataService';

const Webcam = () => {
  const location = useLocation();
  const id = location.state.id;
  const navigate = useNavigate();
  const [video1Url, setVideo1Url] = useState('');
  const [video2Url, setVideo2Url] = useState('');
  const [video3Url, setVideo3Url] = useState('');
  const [video1Blob, setVideo1Blob] = useState();
  const [video2Blob, setVideo2Blob] = useState();
  const [video3Blob, setVideo3Blob] = useState();
  //  var userDecoded = jwtDecode (localStorage.getItem("user"));

  const downloadVideo1 = async () => {
    let video1Url = await AWS_Storage.videoBlob(
      video1Blob,
      'video1/' + id.toString()
    );
    setVideo1Url(video1Url);
    return video1Url;
  };
  const downloadVideo2 = async () => {
    let video2Url = await AWS_Storage.videoBlob(
      video2Blob,
      'video2/' + id.toString()
    );
    setVideo2Url(video2Url);
    return video2Url;
  };
  const downloadVideo3 = async () => {
    let video3Url = await AWS_Storage.videoBlob(
      video3Blob,
      'video3/' + id.toString()
    );
    setVideo3Url(video3Url);
    return video3Url;
  };

  const onSubmit = async (val) => {
    let data = {
      Video_1: val,
      // "Video_2":video2Url,
      // "Video_3":video3
    };
    console.log(data);
    let userValue = await AwsAmplify.updateVideo(id, data); //await UserProfile.update(data,(userDecoded.sub))
    if (userValue === 'success') {
      navigate('/candidate/profile');
    }
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const tabCount = 1;

  return (
    <div>
      <Container className="zero_padding">
        <Navbar />
        <Container>
          <Grid container className="qest_txt">
            <Grid item>
              <h2> Record Screen</h2>
            </Grid>
          </Grid>
          <br></br>
        </Container>
        <Container className="record_video_view">
          <Tabs defaultTab={selectedTab.toString()}>
            <TabList className="tab_view">
              {/* <Tab tabFor="0">Tab 1</Tab>
      <Tab tabFor="1">Tab 2</Tab> */}
              <Tab tabFor="0">Tab 1</Tab>
            </TabList>

            <TabPanel tabId="0">
              <Grid container>
                <Grid item md="12">
                  <div className="Q_txt">
                    1.Question :<strong>Tell me about yourself ?</strong>
                    <div className="tips_txt">
                      <p>Tips :</p>
                      <ul>
                        <li>Do not recite your resume</li>
                        <li>
                          Provide a concise, thoughtful overview of your past,
                          present and future
                        </li>
                        <li>
                          Provide details about your past experience, projects
                          you worked and your role
                        </li>
                        <li>Keep it short - 60 secs to a minute</li>
                        <li>Communicate things that will give you an edge</li>
                      </ul>
                    </div>
                    <div className="media_position">
                      <br></br>

                      <Grid container>
                        <Grid item md={1}></Grid>
                        <Grid item className="video_screen_size" md={10}>
                          <Grid container>
                            <Grid item className="video_height">
                              <div style={{ height: '100%' }}>
                                {/* <h1>One minute</h1> */}
                                <VideoRecorder
                                  isOnInitially
                                  isFliped
                                  showReplayControls
                                  // mimeType={text('mimeType')}
                                  countdownTime="3000"
                                  timeLimit="60000"
                                  onRecordingComplete={(videoBlob) => {
                                    // Do something with the video...
                                    console.log('videoBlob', videoBlob);
                                    setVideo1Blob(videoBlob);
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={1}></Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>

          {/* <div className="video_bottom_txt">{<Button 
      >Save & Continue</Button>}</div> */}

          <div className="video_bottom_txt">
            {selectedTab == 0 && video1Blob != undefined && (
              <Button
                onClick={() => {
                  if (video1Blob != undefined) {
                    downloadVideo1().then((val) => {
                      onSubmit(val);
                    });
                  }
                }}
              >
                Submit
              </Button>
            )}
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default Webcam;
