import * as React from 'react';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styles from './NewJobPage.module.css';
import { Grid } from '@mui/material';
import { TabContext, TabPanel } from '@material-ui/lab';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Navbar from '../HomePage/Navbar';
import { NavLink } from 'react-router-dom';
import Jobs from '../Service/AWS_Auth/JobDataService';
import CloseIcon from '@mui/icons-material/Close';
import List from './JobSearchList';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

function NewJobPage() {
  const [value, setValue] = React.useState('All');
  const [allJob, setAllJob] = React.useState({});
  const [jobInputText, setJobInputText] = React.useState('');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    searchJobSchedule('');
  }, []);
  const searchJobSchedule = async (text) => {
    let Job = [];
    let companyId = localStorage.getItem('companyId');
    await Jobs.searchJobSchedule(text, companyId).then((data) => {
      console.log('result', data);
      if (data.length != 0) {
        data.forEach((element) => {
          Job.push({
            Job: element,
          });
          if (Job.length == data.length) {
            setAllJob(Job);
            console.log('searchJob', Job);
          }
        });
      } else {
        setAllJob([]);
      }
    });
  };
  const searchJobScheduleStatus = async (status) => {
    let Job = [];
    let companyId = localStorage.getItem('companyId');
    await Jobs.searchJobScheduleTab(status, jobInputText, companyId).then(
      (data) => {
        console.log('result', data);

        data.forEach((element) => {
          Job.push({
            Job: element,
          });
          if (Job.length == data.length) {
            setAllJob(Job);
            console.log('searchJob', Job);
          }
        });
        if (data.length == 0) {
          setAllJob([]);
        }
      }
    );
  };
  const handleClear = async () => {
    setJobInputText('');
    searchJobSchedule('');
    setValue('All');
  };
  let jobInputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setJobInputText(e.target.value);
    searchJobSchedule(e.target.value);
    setValue('All');
  };
  return (
    <>
      <Navbar />
      <Container maxWidth={'lg'}>
        {' '}
        <Grid container>
          <Grid item xs={6}>
            {' '}
            <div className={styles.title_txt}> Jobs</div>
          </Grid>
          <Grid className={styles.schedule_interview_btn_col} item xs={6}>
            {' '}
            <NavLink to="/recruiter/job/newCreate">
              <Button
                className={styles.schedule_interview_btn}
                variant="contained"
                size="large"
              >
                <AddIcon className={styles.add_icon} /> JOB
              </Button>
            </NavLink>
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={styles.header_col_2} item xs={12} md={6}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                className={styles.indicator_bg}
                aria-label="secondary tabs example"
              >
                <Tab
                  sx={{ bgcolor: '#ffffff' }}
                  value="All"
                  label="All"
                  onClick={(e) => searchJobSchedule(jobInputText)}
                />
                <Tab
                  sx={{ bgcolor: '#ffffff' }}
                  value="Open"
                  label="Open"
                  onClick={(e) => searchJobScheduleStatus('OPEN')}
                />
                <Tab
                  sx={{ bgcolor: '#ffffff' }}
                  value="On-Hold"
                  label="On-Hold"
                  onClick={(e) => searchJobScheduleStatus('HOLD')}
                />
                <Tab
                  sx={{ bgcolor: '#ffffff' }}
                  value="Close"
                  label="Close"
                  onClick={(e) => searchJobScheduleStatus('CLOSE')}
                />
              </Tabs>
            </Box>
          </Grid>
          <Grid item md={2}></Grid>
          <Grid className={styles.header_col_3} item xs={12} md={4}>
            <TextField
              sx={{}}
              className={styles.search_textfield_styles}
              id="outlined-basic"
              onChange={jobInputHandler}
              variant="outlined"
              fullWidth
              placeholder="Search Jobs..."
              value={jobInputText}
              InputProps={{
                startAdornment: (
                  <IconButton className={styles.search_icon_btn_search_bar}>
                    <SearchRoundedIcon />
                  </IconButton>
                ),
                endAdornment: (
                  <>
                    {jobInputText !== '' && (
                      <IconButton onClick={handleClear}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
          </Grid>
        </Grid>
        <br></br>
        <Container className={styles.card_cont} maxWidth={'lg'}>
          <Grid container>
            <Grid item xs={12}>
              <TabContext value={value}>
                {value == 'All' && jobInputText == '' && (
                  <div>{`Recent jobs "${allJob.length}"`}</div>
                )}
                {value == 'Open' && jobInputText == '' && (
                  <div>{`Recent open jobs "${allJob.length}" `}</div>
                )}
                {value == 'On-Hold' && jobInputText == '' && (
                  <div>{`Recent hold jobs "${allJob.length}" `}</div>
                )}
                {value == 'Close' && jobInputText == '' && (
                  <div>{`Recent close jobs "${allJob.length}"`} </div>
                )}
                {jobInputText != '' && (
                  <div>
                    {allJob.length} {allJob.length > 1 ? `Jobs` : `Job`}
                    {` has been found for "${jobInputText}"`}
                  </div>
                )}
                <TabPanel className={styles.job_card_tabpanel} value="All">
                  {allJob.length != 0 && (
                    <List input={jobInputText} data={allJob} display={false} />
                  )}
                </TabPanel>
                <TabPanel className={styles.job_card_tabpanel} value="Open">
                  {' '}
                  {allJob.length != 0 && (
                    <List input={jobInputText} data={allJob} display={false} />
                  )}
                </TabPanel>
                <TabPanel className={styles.job_card_tabpanel} value="On-Hold">
                  {allJob.length != 0 && (
                    <List input={jobInputText} data={allJob} display={false} />
                  )}
                </TabPanel>
                <TabPanel className={styles.job_card_tabpanel} value="Close">
                  {allJob.length != 0 && (
                    <List input={jobInputText} data={allJob} display={false} />
                  )}{' '}
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>{' '}
        </Container>
      </Container>
    </>
  );
}

export default NewJobPage;