import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import TuneIcon from '@mui/icons-material/Tune';
import './ShareOptionDialog.css';
import { Container, Grid } from '@mui/material';

function ShareOptionDialog() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <br></br>
      <Container>
        <div>
          <Button
            className="share_options_btn"
            variant="outlined"
            onClick={handleClickOpen}
          >
            Share
          </Button>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle className="dialog_title" id="responsive-dialog-title">
              <TuneIcon /> {'Share Options'}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <div className="dialog_title_txt">Detailed View :</div>
                  <div className="details_txt">
                    To share all the info of the candidate.
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={10}>
                  {' '}
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '40ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl disabled>
                      <InputLabel htmlFor="component-outlined">Link</InputLabel>
                      <OutlinedInput
                        id="component-outlined"
                        defaultValue="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                        label="Name"
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid className="icon_grid" item xs={1}>
                  {' '}
                  <IconButton className="copylink_option_view_icon">
                    <LinkIcon />
                  </IconButton>
                </Grid>
                <Grid className="icon_grid" item xs={1}>
                  {' '}
                  <IconButton className="share_option_view_icon">
                    {' '}
                    <ShareIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  {' '}
                  <div className="dialog_title_txt">Abstract View :</div>{' '}
                  <div className="details_txt">
                    To hide the personal info and show only the necessary
                    details of the candidate.
                  </div>
                </Grid>{' '}
              </Grid>
              <Grid container>
                <Grid item xs={10}>
                  {' '}
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '40ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl disabled>
                      <InputLabel htmlFor="component-outlined">Link</InputLabel>
                      <OutlinedInput
                        id="component-outlined"
                        defaultValue="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                        label="Name"
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid className="icon_grid" item xs={1}>
                  {' '}
                  <IconButton className="copylink_option_view_icon">
                    <LinkIcon />
                  </IconButton>
                </Grid>
                <Grid className="icon_grid" item xs={1}>
                  {' '}
                  <IconButton className="share_option_view_icon">
                    {' '}
                    <ShareIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {/* <Button autoFocus onClick={handleClose}>
                Disagree
              </Button>
              <Button onClick={handleClose} autoFocus>
                Agree
              </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </>
  );
}

export default ShareOptionDialog;
