import { DataStore } from '@aws-amplify/datastore';
import {InterviewSchedule } from '../../../models'
import { Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
Amplify.configure(awsconfig);

class InterviewAmplify{

async save (data){
  return new Promise(async (resolve, reject) => {
  try{
      await DataStore.save(
        data
      ).then(user=>{
        console.log(user)
        resolve("success");
      })
    }
  catch(e){
    console.log("InterviewSchedule save", e)
    reject(e);
  }
});
}

async updateSpecificVideo(id, data) {
  try {
    const original = await DataStore.query(InterviewSchedule, id);
    console.log("original", original);

    const updatedInterviewSchedule = InterviewSchedule.copyOf(original, updated => {
      updated.Status=data.Status
      updated.Video4 = data.Video4;
      updated.Video5 = data.Video5;
      updated.Video6 = data.Video6;
      updated.CompletedTime=data.CompletedTime
    });

    const updatedObject = await DataStore.save(updatedInterviewSchedule);
    console.log("InterviewSchedule update", updatedObject);

    return "success";
  } catch (error) {
    console.error("Error updating InterviewSchedule:", error);
    throw error;
  }
}
async updateSpecificVideoStatus(id, data) {
  try {
    const original = await DataStore.query(InterviewSchedule, id);
    console.log("original", original);

    const updatedInterviewSchedule =  InterviewSchedule.copyOf(original, updated => {
      updated.Status = data.Status;
      updated.CompletedTime = data.CompletedTime;
    });

    const updatedObject = await DataStore.save(updatedInterviewSchedule);
    console.log("InterviewSchedule update status", updatedObject);

    return "success";
  } catch (error) {
    console.error("Error updating InterviewSchedule status:", error);
    throw error;
  }
}


  async  updateInterview(id, data) {
    return new Promise(async (resolve, reject) => {
    try{
    await DataStore.query(InterviewSchedule, id).then( async original =>{
      console.log("original",original)
      await DataStore.save(
        InterviewSchedule.copyOf(original, updated => {
          updated.Question4=data.Question4
          updated.Question5=data.Question5
          updated.Question6=data.Question6
        }
      )
      ).then(user=>{
        console.log("InterviewSchedule update",user)
         resolve("success");
      })
    } )
     
      }
      catch(e){
        console.log("InterviewSchedule update", e)
       reject(e)
      }
    });
    }

  async  declineInterview(id, data) {
    return new Promise(async (resolve, reject) => {
    try{
      await DataStore.query(InterviewSchedule, id).then( async original =>{
        await DataStore.save(
          InterviewSchedule.copyOf(original, updated => {
            updated.Status=data.Status
          }
        )
        ).then(user=>{
          console.log("InterviewSchedule update",user)
          resolve("success");
        })
      } )
      
      }
      catch(e){
        console.log("InterviewSchedule update", e)
        reject(e)
      }
    })
    }

    async  viewInterview(id, data) {
      return new Promise(async (resolve, reject) => {
      try{
        await DataStore.query(InterviewSchedule, id).then( async original =>{
          await DataStore.save(
            InterviewSchedule.copyOf(original, updated => {
              updated.Status=data.Status
            }
          )
          ).then(user=>{
            console.log("InterviewSchedule update",user)
            resolve("success");
          })
        } )
        
        }
        catch(e){
          console.log("InterviewSchedule update", e)
          reject(e)
        }
      })
      }
      
    async getInterviewSchedule(email){
      try{
      await DataStore.query(InterviewSchedule, c => c.Email.eq(email),{ consistent: true }).then(user =>{
        console.log(user[0]);
        return user[0];
      })
      }
      catch(e){
        console.log("InterviewSchedule update", e)
        return(e)
      }
    }

  
}
export default new InterviewAmplify();