import React from 'react';
import './Home.css';
import './AboutUs.css';
import './HomeResponsive.css';
import Footer from './Footer';
import Recruiter from '../../assets/images/recruiter.gif';
import Employee from '../../assets/images/candidate.gif';
import { styled } from '@mui/material/styles';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import LoginIcon from '@mui/icons-material/Login';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import WorkIcon from '@mui/icons-material/Work';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Timeline from './Timeline';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';

function AboutUs() {
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'none',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'none',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'orange',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));

  function ColorlibStepIcon1(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <LoginIcon />,
      2: <VideoLabelIcon />,
      3: <GroupAddIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  function ColorlibStepIcon2(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <LoginIcon />,
      2: <VideoCameraFrontIcon />,
      3: <WorkIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const stepsrecruiter = ['Sign-up', 'Create interview', 'Hire candidate'];
  const stepsemployee = [
    'Sign-in using invitation',
    'Attend the interview',
    'Get a job',
  ];
  return (
    <div>
      <>
        <Container fixed>
          <Grid container spacing={2} className="aboutus_text">
            <Grid item xs={12} md={12} className="about_us_grid">
              <h2>About Us</h2>
            </Grid>
          </Grid>
        </Container>
        <Container fixed className="home_page_heading_container">
          <Grid container spacing={2} className="recruiter_steps_row">
            <Grid item className="recruiter_col_img" md={5}>
              <img src={Recruiter} width="100%"></img>
            </Grid>
            <Grid item className="recruiter_col_txt" md={7}>
              <h2>Recruiter</h2>
              <h4>Are you dealing with this everyday?</h4>
              <div>
                <Grid container spacing={2} className="three_steps">
                  <Grid item md={12}>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          Hiring Manager is busy with a deadline/project
                          delivery
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          Candidate not available take calls during business
                          hours
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          Mutual time not available with Candidate and Hiring
                          manager
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          No-Show Candidates
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          Internet/Video/Audio Problems
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <span className="orange_txt">InterviewTape</span>{' '}
                        <span className="details_txt_last">
                          solves these for you
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid item className="employee_steps_img_mob" md={6}>
            <img src={Employee} width="100%"></img>
          </Grid>
          <Grid container spacing={2} className="employee_row">
            <Grid item className="employee_col_txt" md={7}>
              <h2>Candidate</h2>
              <h4>Have you ever wished that</h4>

              <div>
                <Grid container spacing={2} className="three_steps">
                  <Grid item md={12}>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          I need a new job, but I can't interview in my work
                          hours
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          Recruiter wants to do live interview on my laptop with
                          camera ON
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          I am not my best after a long day of work
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          Hiring manager not available after work hours and
                          weekends to{' '}
                          <span className="empty_space">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          do an interview
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          I want to be able to show my best skills{' '}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item className="step_details_txt" md={12}>
                        <RadioButtonCheckedIcon sx={{ color: '#6366f1' }} />
                        <span className="about_details_txt">
                          I wish I could rephrase my answer
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item className="employee_steps_img" md={5}>
              <img src={Employee} width="100%"></img>
            </Grid>
          </Grid>
        </Container>
        <hr></hr>
        <Timeline />
        <br></br>
        <br></br>
        <Footer />
      </>
    </div>
  );
}

export default AboutUs;
