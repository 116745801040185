/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './HomeResponsive.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Navbar from './Navbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import './InterviewPage.css';
import inovark_logo from '../../assets/images/inovark_logo.png';
import CandidateDataService from '../Service/AWS_Auth/CandidateDataService';
import { Candidate, InterviewSchedule, InterviewStatus } from '../../models';
import interviewService from '../Service/AWS_Auth/interviewService';
import { Image } from '@aws-amplify/ui-react';
import { Storage } from '@aws-amplify/storage';
function InterviewPage() {
  //for job seeker
  const [userDetails, setUserDetails] = React.useState();
  const [user, setUser] = React.useState({});
  const [allJobs, setAllJobs] = React.useState([]);
  const [jobsPendingList, setJobsPendingList] = React.useState([]);
  const [jobsCompletedList, setJobsCompletedList] = React.useState([]);
  const [jobsRejectedList, setJobsRejectedList] = React.useState([]);
  const [jobsDeclinedList, setJobsDeclinedList] = React.useState([]);
  var jobsPending = [];
  var jobsCompleted = [];
  var jobsRejected = [];
  var jobsDeclined = [];
  const getAllJobs = async (candidateId) => {
    await CandidateDataService.jobs(candidateId).then((jobList) => {
      setAllJobs(jobList);

      // console.log(all)
      jobList.forEach(async (job) => {
        // console.log(childData)
        if (
          job.Status.Create == true &&
          job.Status.Completed == false &&
          job.Status.Declined == false
        ) {
          await job.Recruiter.then(async (recruiter) => {
            await job.Candidate.then(async (candidate) => {
              await Storage.get(recruiter.Profilepic).then((val) => {
                jobsPending.push({
                  CompanyName: recruiter.CompanyName,
                  RecruiterEmail: recruiter.Email,
                  CompanyPhone: recruiter.Phone,
                  CreatedTimeStamp: job.CreatedTime,
                  CompanyPicture: val,
                  Question1: job.Question4,
                  Question2: job.Question5,
                  Question3: job.Question6,
                  RecruiterID: recruiter.id,
                  InterviewID: job.id,
                  CandidateID: candidate.id,
                });
              });
              // setJobsPendingList(jobsPending);
              setJobsPendingList([...jobsPendingList, ...jobsPending]);
            });
          });
        }
        if (
          job.Status.Create == true &&
          job.Status.Viewed == true &&
          job.Status.Completed == true &&
          job.Status.Declined == false
        ) {
          await job.Recruiter.then(async (recruiter) => {
            await job.Candidate.then(async (candidate) => {
              await Storage.get(recruiter.Profilepic).then((val) => {
                jobsCompleted.push({
                  CompanyName: recruiter.CompanyName,
                  RecruiterEmail: recruiter.Email,
                  CompanyPhone: recruiter.Phone,
                  CreatedTimeStamp: job.CreatedTime,
                  CompanyPicture: val,
                  Question1: job.Question4,
                  Question2: job.Question5,
                  Question3: job.Question6,
                  RecruiterID: recruiter.id,
                  InterviewID: job.id,
                  CandidateID: candidate.id,
                });
                //  setJobsCompletedList(jobsCompleted);
                setJobsCompletedList([...jobsCompletedList, ...jobsCompleted]);
              });
            });
          });
        }
        if (
          job.Status.Create == true &&
          job.Status.Viewed == true &&
          job.Status.Completed == true &&
          job.Status.Rejected == true
        ) {
          await job.Recruiter.then(async (recruiter) => {
            await job.Candidate.then(async (candidate) => {
              await Storage.get(recruiter.Profilepic).then((val) => {
                jobsRejected.push({
                  CompanyName: recruiter.CompanyName,
                  RecruiterEmail: recruiter.Email,
                  CompanyPhone: recruiter.Phone,
                  CreatedTimeStamp: job.CreatedTime,
                  CompanyPicture: val,
                  Question1: job.Question4,
                  Question2: job.Question5,
                  Question3: job.Question6,
                  RecruiterID: recruiter.id,
                  InterviewID: job.id,
                  CandidateID: candidate.id,
                });
                //  setJobsRejectedList(jobsRejected);
                setJobsRejectedList([...jobsRejectedList, ...jobsRejected]);
              });
            });
          });
        }
        if (
          job.Status.Create == true &&
          job.Status.Viewed == true &&
          job.Status.Completed == false &&
          job.Status.Declined == true
        ) {
          await job.Recruiter.then(async (recruiter) => {
            await job.Candidate.then(async (candidate) => {
              await Storage.get(recruiter.Profilepic).then((val) => {
                jobsDeclined.push({
                  CompanyName: recruiter.CompanyName,
                  RecruiterEmail: recruiter.Email,
                  CompanyPhone: recruiter.Phone,
                  CreatedTimeStamp: job.CreatedTime,
                  CompanyPicture: val,
                  Question1: job.Question4,
                  Question2: job.Question5,
                  Question3: job.Question6,
                  RecruiterID: recruiter.id,
                  InterviewID: job.id,
                  CandidateID: candidate.id,
                });
                //  setJobsDeclinedList(jobsDeclined);
                setJobsDeclinedList([...jobsDeclinedList, ...jobsDeclined]);
              });
            });
          });
        }
      });
    });
  };

  useEffect(async () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let candidateId = localStorage.getItem('candidateId');
    setUser(candidateId);

    getAllJobs(candidateId);
  }, []);

  const declineJob = async (job) => {
    let data = new InterviewSchedule({
      Status: new InterviewStatus({
        Create: true,
        Viewed: true,
        Completed: false,
        Declined: true,
        Selected: false,
        Rejected: false,
      }),
    });
    await interviewService
      .declineInterview(job.InterviewID, data)
      .then((userValue) => {
        if (userValue === 'success') {
          window.location.reload();
        }
      });
  };

  const viewJob = async (job) => {
    let data = new InterviewSchedule({
      Status: new InterviewStatus({
        Create: true,
        Viewed: true,
        Completed: false,
        Declined: false,
        Selected: false,
        Rejected: false,
      }),
    });
    await interviewService
      .viewInterview(job.InterviewID, data)
      .then((userValue) => {
        if (userValue === 'success') {
          window.location.reload();
        }
      });
  };

  return (
    <div>
      <Navbar></Navbar>
      <Container fluid>
        <Row>
          <Col className="interview_heading_txt">
            <h2>Interviews </h2>
          </Col>
        </Row>
      </Container>
      <Container className="interview_page_body">
        <Row>
          <Col>
            <Tabs
              defaultActiveKey="pending"
              id="justify-tab-example"
              className="mb-3 action_bar"
              justify
            >
              <Tab eventKey="pending" title="Pending">
                {jobsPendingList.length ? (
                  jobsPendingList.map((job) => {
                    return (
                      <div>
                        <Card className="card_content">
                          <Card.Body className="card_body_content">
                            <Row>
                              <Col className="company_logo" md={3}>
                                <Image
                                  className="company_pic"
                                  src={job.CompanyPicture}
                                  height="200px"
                                  width="300px"
                                />
                                ;
                              </Col>
                              <Col className="company_details" md={7}>
                                <ul>
                                  <li className="card_company_name">
                                    {job.CompanyName}
                                  </li>
                                  <li className="card_company_email_name">
                                    <span class="ti-email"></span>
                                    {'  ' + job.RecruiterEmail}
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-mobile"></span>
                                    {job.CompanyPhone}
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-time">
                                      {' '}
                                      {job.CreatedTimeStamp}
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col className="respond_btns" md={2}>
                                <Row>
                                  <Col>
                                    <NavLink
                                      to={'/candidate/interview/instructions'}
                                      state={{ jobDetails: job }}
                                    >
                                      <button
                                        className="slide_accept btn-size accept"
                                        variant="primary"
                                        onClick={(ev) => {
                                          viewJob(job);
                                        }}
                                      >
                                        Start
                                      </button>
                                    </NavLink>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <button
                                      className="slide_decline btn-size decline"
                                      onClick={(ev) => {
                                        declineJob(job);
                                      }}
                                      variant="primary"
                                    >
                                      Decline
                                    </button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <NavLink
                                      to="/candidate/company_profile"
                                      state={{
                                        RecruiterDetails: job.RecruiterID,
                                      }}
                                    >
                                      <button
                                        className="slide btn-size"
                                        variant="primary"
                                      >
                                        View
                                      </button>
                                    </NavLink>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <Card className="card_content">
                      <Card.Body className="card_body_content">
                        <h4>No Job post available</h4>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Tab>
              <Tab eventKey="completed" title="Completed">
                {jobsCompletedList.length ? (
                  jobsCompletedList.map((job) => {
                    return (
                      <div>
                        <Card className="card_content">
                          <Card.Body className="card_body_content">
                            <Row>
                              <Col className="company_logo" md={3}>
                                <Image
                                  className="company_pic"
                                  src={job.CompanyPicture}
                                  height="200px"
                                  width="300px"
                                />
                                ;
                              </Col>
                              <Col className="company_details" md={7}>
                                <ul>
                                  <li className="card_company_name">
                                    {job.CompanyName}
                                  </li>
                                  <li className="card_company_email_name">
                                    <span class="ti-email"></span>
                                    {'  ' + job.RecruiterEmail}
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-mobile"></span>
                                    {job.CompanyPhone}
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-time">
                                      {' '}
                                      {job.CreatedTimeStamp}
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col className="respond_btns" md={2}>
                                <Row>
                                  <Col>
                                    <NavLink
                                      to="/candidate/company_profile"
                                      state={{
                                        RecruiterDetails: job.RecruiterID,
                                      }}
                                    >
                                      <button
                                        className="slide btn-size"
                                        variant="primary"
                                      >
                                        View
                                      </button>
                                    </NavLink>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <Card className="card_content">
                      <Card.Body className="card_body_content">
                        <h4>No Job post available</h4>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Tab>

              <Tab eventKey="rejected" title="Rejected">
                {jobsRejectedList.length ? (
                  jobsRejectedList.map((job) => {
                    return (
                      <div>
                        <Card className="card_content">
                          <Card.Body className="card_body_content">
                            <Row>
                              <Col className="company_logo" md={3}>
                                <Image
                                  className="company_pic"
                                  src={job.CompanyPicture}
                                  height="200px"
                                  width="300px"
                                />
                                ;
                              </Col>
                              <Col className="company_details" md={7}>
                                <ul>
                                  <li className="card_company_name">
                                    {job.CompanyName}
                                  </li>
                                  <li className="card_company_email_name">
                                    <span class="ti-email">
                                      {'  ' + job.RecruiterEmail}
                                    </span>{' '}
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-mobile">
                                      {job.CompanyPhone}
                                    </span>{' '}
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-time">
                                      {' '}
                                      {job.CreatedTimeStamp}
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col className="respond_btns" md={2}>
                                <NavLink
                                  to="/candidate/company_profile"
                                  state={{ RecruiterDetails: job.RecruiterID }}
                                >
                                  <button
                                    className="slide btn-size"
                                    variant="primary"
                                  >
                                    View
                                  </button>
                                </NavLink>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>{' '}
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <Card className="card_content">
                      <Card.Body className="card_body_content">
                        <h4>No Job post available</h4>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Tab>
              <Tab eventKey="declined" title="Declined">
                {jobsDeclinedList.length ? (
                  jobsDeclinedList.map((job) => {
                    return (
                      <div>
                        <Card className="card_content">
                          <Card.Body className="card_body_content">
                            <Row>
                              <Col className="company_logo" md={3}>
                                <Image
                                  className="company_pic"
                                  src={job.CompanyPicture}
                                  width="300px"
                                  height="300px"
                                />
                                ;
                              </Col>
                              <Col className="company_details" md={7}>
                                <ul>
                                  <li className="card_company_name">
                                    {job.CompanyName}
                                  </li>
                                  <li className="card_company_email_name">
                                    <span class="ti-email">
                                      {'  ' + job.RecruiterEmail}
                                    </span>{' '}
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-mobile">
                                      {job.CompanyPhone}
                                    </span>{' '}
                                  </li>
                                  <li className="card_company_Phone_no">
                                    <span class="ti-time">
                                      {' '}
                                      {job.CreatedTimeStamp}
                                    </span>
                                  </li>
                                </ul>
                              </Col>
                              <Col>
                                <NavLink
                                  to="/candidate/company_profile"
                                  state={{ RecruiterDetails: job.RecruiterID }}
                                >
                                  <button
                                    className="slide btn-size"
                                    variant="primary"
                                  >
                                    View
                                  </button>
                                </NavLink>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>{' '}
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <Card className="card_content">
                      <Card.Body className="card_body_content">
                        <h4>No Job post available</h4>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default InterviewPage;
