import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import NewCandidateProfile from './NewCandidateProfile';
import styles from './NewCandidateProfileView.module.css';
import Navbar from '../HomePage/Navbar';
import VideoModal from '../HomePage/VideoModal';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Feedback from './Feedback';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function NewCandidateProfileView() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Navbar />
      <Container className={styles.total_container} fixed>
        <Grid className={styles.title_row} container>
          <Grid className={styles.interview_txt_title} item xs={12}>
            {' '}
            Interview Details
          </Grid>
        </Grid>

        <Grid className={styles.details_cont} container fixed>
          <Grid item sm={12}>
            <NewCandidateProfile />
          </Grid>
        </Grid>

        <h4 className={styles.tape_title_txt}>Tapes</h4>
        <Container maxWidth="md">
          <Grid className={styles.details_cont} container>
            <Grid className={styles.tape_view_card_1} item sm={6}>
              <Card sx={{ maxWidth: 345 }}>
                <Grid container>
                  <Grid item xs={8}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: '#6366F1' }} aria-label="recipe">
                          J
                        </Avatar>
                      }
                      // action={
                      //   <IconButton aria-label="settings">
                      //     <MoreVertIcon />
                      //   </IconButton>
                      // }
                      title="Tell me About Yourself ?"
                      subheader="September 14, 2016"
                    />
                  </Grid>
                  <Grid className={styles.video_view_modal_col} item xs={4}>
                    <VideoModal />
                  </Grid>
                </Grid>

                {/* <CardMedia
                    component="img"
                    height="194"
                    image="/static/images/cards/paella.jpg"
                    alt="Paella dish"
                  /> */}
                <CardContent>
                  <Feedback />
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton
                    className={styles.comment_icons}
                    aria-label="add to favorites"
                  >
                    <ThumbUpIcon />
                  </IconButton>
                  <IconButton
                    className={styles.comment_icons}
                    aria-label="add to favorites"
                  >
                    <ThumbsUpDownIcon />
                  </IconButton>
                  <IconButton
                    className={styles.comment_icons}
                    aria-label="add to favorites"
                  >
                    <ThumbDownIcon />
                  </IconButton>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent className={styles.comments_history}>
                    <Typography paragraph>Comments:(0)</Typography>

                    <Typography>(empty)</Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
            <Grid className={styles.tape_view_card_2} item sm={6}>
              <Card sx={{ maxWidth: 345 }}>
                <Grid container>
                  <Grid item xs={8}>
                    <CardHeader
                      className={styles.tape_question_header_txt}
                      avatar={
                        <Avatar sx={{ bgcolor: '#6366F1' }} aria-label="recipe">
                          J
                        </Avatar>
                      }
                      // action={
                      //   <IconButton aria-label="settings">
                      //     <MoreVertIcon />
                      //   </IconButton>
                      // }
                      title="Question 1 ?"
                      subheader="Uploaded On"
                    />
                  </Grid>
                  <Grid className={styles.video_view_modal_col} item xs={4}>
                    <VideoModal />
                  </Grid>
                </Grid>

                {/* <CardMedia
                    component="img"
                    height="194"
                    image="/static/images/cards/paella.jpg"
                    alt="Paella dish"
                  /> */}
                <CardContent>
                  {' '}
                  <IconButton
                    className={styles.comment_icons}
                    aria-label="add to favorites"
                  >
                    <ThumbUpIcon />
                  </IconButton>
                  <IconButton
                    className={styles.comment_icons}
                    aria-label="add to favorites"
                  >
                    <ThumbsUpDownIcon />
                  </IconButton>
                  <IconButton
                    className={styles.comment_icons}
                    aria-label="add to favorites"
                  >
                    <ThumbDownIcon />
                  </IconButton>
                </CardContent>
                <CardActions disableSpacing>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent className={styles.comments_history}>
                    <Typography paragraph>Comments:(0)</Typography>

                    <Typography>(empty)</Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <br></br>
      <br></br>
    </>
  );
}

export default NewCandidateProfileView;
