import React from 'react';
import Navbar from '../HomePage/Navbar';

import './InstructionPage.css';
import Footer from '../HomePage/Footer';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Container } from '@mui/material';

function InstructionPage() {
  const location = useLocation();
  return (
    <div>
      <Navbar />
      <Container>
        <Grid container className="note_title_txt">
          <Grid item xs={12}>
            <h2> Instruction</h2>
          </Grid>
        </Grid>
        <br></br>
      </Container>
      <Container>
        <Grid container className="note_container">
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8} className="note_instruction_txt">
            <h3>
              <u>Note</u>
            </h3>
            <Grid container className="note_row">
              <Grid item>
                {/* <span className='note_txt'>Note :</span> */}
                <Grid container>
                  <Grid item>
                    <ul className="note_list_txt">
                      <li>
                        Find a quiet, private, well-lit place, free from
                        possible interruptions.
                      </li>
                      <li>Ensure your internet connection is stable.</li>
                      <li>
                        Check that your computer's / phone's audio is working.
                      </li>
                      <li>Test your computer's webcam / phone's camera.</li>
                      <li>
                        Close any unnecessary web browser tabs and applications.
                      </li>
                      <li>Place your phone in silent mode.</li>
                    </ul>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item className="note_btn_row">
                    <Link
                      to="/candidate/profile/record"
                      state={{ id: location.state.id }}
                    >
                      <button className="slide note_btn">Continue</button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Container>
      <br></br>
      <br></br>
      <Footer />
    </div>
  );
}

export default InstructionPage;
