import React, { useState } from 'react';
import './RecruiterNavbar.css';
import { NavLink } from 'react-router-dom';
import AwsAuth from '../Service/AWS_Auth/authenticationServices';
import logo from '../../assets/images/new_logo.png';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const RecruiterNavbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [setIsButtonVisible, setSetIsButtonVisible] = useState(true);
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = () => {
    setSetIsButtonVisible(false);
  };

  const user = localStorage.getItem('user');

  return (
    <div className="title_txt">
      <nav className="home_nav_bar">
        <a href="/">
          <span>
            <img src={logo} height="50px" />
          </span>
          <span className="app_name">
            Interview<span className="tape_txt">Tape</span>
          </span>{' '}
        </a>
        <div>
          <ul id="Navbar" className={clicked ? '#Navbar active ' : '#Navbar'}>
            {user ? (
              <li>
                <NavLink activeClassName=" active" to="/newJobPage">
                  Jobs
                </NavLink>
              </li>
            ) : (
              <div></div>
            )}
            {user ? (
              <li>
                <NavLink activeClassName=" active" to="/newInterviewPage">
                  Interviews
                </NavLink>
              </li>
            ) : (
              <div></div>
            )}
            {user ? (
              <li>
                <div>
                  <IconButton
                    className="account_Icon_btn"
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle className="account_icon" />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <NavLink to={'/candidate/newprofileview'}>
                      {' '}
                      <MenuItem onClick={handleClose}>Profile</MenuItem>
                    </NavLink>
                    <NavLink to={'/aboutus'}>
                      {' '}
                      <MenuItem onClick={handleClose}>About us</MenuItem>
                    </NavLink>
                    <NavLink>
                      {' '}
                      <MenuItem
                        onClick={(e) => {
                          handleClose();
                          localStorage.clear();
                          AwsAuth.signOut();
                          setTimeout(() => (window.location.href = '/'), 500);
                        }}
                      >
                        Logout
                      </MenuItem>
                    </NavLink>
                  </Menu>
                </div>
              </li>
            ) : (
              <div></div>
            )}

            {user == null ? (
              <div>
                <NavLink to="/chooseprofile">
                  <Button
                    onClick={handleButtonClick}
                    className="nav_signup_btn"
                  >
                    Sign Up
                  </Button>
                </NavLink>
              </div>
            ) : (
              <div></div>
            )}

            {user ? (
              <></>
            ) : (
              // <div>
              //   <li className="nav_logout_btn">
              //     <NavLink
              //       activeClassName=" active"
              //       to="/"
              //       onClick={(e) => {
              //         localStorage.clear();
              //         AwsAuth.signOut();
              //         setTimeout(() => (window.location.href = '/'), 500);
              //       }}
              //     >
              //       Logout
              //     </NavLink>
              //   </li>

              //   <li className="nav_logout_icon">
              //     <Avatar sx={{ m: 1, bgcolor: 'black' }}>
              //       <LogoutIcon
              //         onClick={(e) => {
              //           localStorage.clear();
              //           AwsAuth.signOut();
              //           setTimeout(() => (window.location.href = '/'), 500);
              //         }}
              //       ></LogoutIcon>
              //     </Avatar>
              //   </li>
              // </div>
              <div></div>
            )}
          </ul>
        </div>

        <div id="mobile" onClick={handleClick}>
          {/* <i class="fas fa-align-justify"></i>
   <i class="fas fa-times"></i> */}
          <i
            id="bar"
            className={clicked ? 'fas fa-times ' : 'fas fa-align-justify'}
          ></i>
        </div>
      </nav>
    </div>
  );
};

export default RecruiterNavbar;
