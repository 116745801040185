import React, { useState } from 'react';
import './RecruiterNewProfileEdit.css';
import Navbar from '../HomePage/Navbar';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/system';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import BusinessIcon from '@mui/icons-material/Business';
import MuiPhoneNumber from 'material-ui-phone-number';
import { isValidPhoneNumber } from 'libphonenumber-js';
import ContactEmergencyRoundedIcon from '@mui/icons-material/ContactEmergencyRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import AWS_Storage from '../Service/AWS_Auth/VideoStorage';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Storage } from '@aws-amplify/storage';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Recruiter } from '../../models';
import AwsAmplify from '../Service/AWS_Auth/RecruiterDataService';
import CompanyAmplify from '../Service/AWS_Auth/CompanyDataService';
import authenticationServices from '../Service/AWS_Auth/authenticationServices';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function RecruiterNewProfileEdit() {
  Amplify.configure(awsconfig);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [id, setID] = React.useState();
  const [allCompany, setAllCompany] = React.useState('');
  const [website, setWebsite] = React.useState('');
  const [companyExist, setCompanyExist] = React.useState(false);
  const [companyID, setCompanyID] = React.useState('');
  const [currentPassword, setCurrentPassword] = React.useState();
  const [newPassword, setNewPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();
  const [currentPasswordErr, setCurrentPasswordErr] = React.useState(false);
  const [newPasswordErr, setNewPasswordErr] = React.useState(false);
  const [confirmPasswordErr, setConfirmPasswordErr] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const validateForm = () => {
    if (confirmPassword == null) {
      setConfirmPasswordErr(true);
      return false;
    }
    if (currentPassword == null) {
      setCurrentPasswordErr(true);
      return false;
    }
    if (newPassword == null) {
      setNewPasswordErr(true);
      return false;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordErr(true);
      setNewPasswordErr(true);
      return false;
    }
    if (
      currentPassword != null &&
      currentPassword.length > 0 &&
      newPassword != null &&
      newPassword.length > 0 &&
      newPassword === confirmPassword
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleChangePassword = async (event) => {
    event.preventDefault();
    let email = localStorage.getItem('email');
    console.log('came');
    if (validateForm()) {
      await authenticationServices
        .changePassword(email, currentPassword, newPassword)
        .then((value) => {
          toast.success('Successfully password updated');
        })
        .catch((e) => {
          toast.error(e.toString());
        });
    } else {
      console.log('Invalid form inputs');
      if (confirmPassword == null) {
        setConfirmPasswordErr(true);
      } else {
        setConfirmPasswordErr(false);
      }
      if (currentPassword == null) {
        setCurrentPasswordErr(true);
      } else {
        setCurrentPasswordErr(false);
      }
      if (newPassword == null) {
        setNewPasswordErr(true);
      } else {
        setNewPasswordErr(false);
      }
      if (newPassword !== confirmPassword) {
        setConfirmPasswordErr(true);
        setNewPasswordErr(true);
      }
    }
  };
  const [formValues, setFormValues] = useState({
    email: {
      value: '',
      error: false,
      errorMessage: 'You must enter your registered emailID',
    },
    firstName: {
      value: '',
      error: false,
      errorMessage: 'You must enter a first name',
    },
    lastName: {
      value: '',
      error: false,
      errorMessage: 'You must enter a last name',
    },
    phone: {
      value: '',
      error: false,
      errorMessage: 'You must enter a phone number',
    },
    companyName: {
      value: '',
      error: false,
      errorMessage: 'You must select a company name',
    },
    profilePic: {
      value: '',
      error: false,
      picURL: '',
      errorMessage: 'You must select a profile picture',
    },
    linkedin: {
      value: '',
      error: false,
      errorMessage: 'You must enter a linkedin ID',
    },
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dataUploadPic = async (event) => {
    try {
      if (formValues.profilePic.value == '') {
        setFormValues((prevState) => {
          return {
            ...prevState,
            profilePic: {
              error: true,
              errorMessage: 'You must select your profile image',
            },
          };
        });
        return;
      } else {
        var data = new Recruiter({
          FirstName: formValues.firstName.value,
          LastName: formValues.lastName.value,
          Phone: formValues.phone.value,
          Email: formValues.email.value,
          Linkedin: '',
          CompanyName: formValues.companyName.value,
          recruiterCompanyId: companyID,
          Profilepic: formValues.profilePic.picURL,
        });
        console.log('data', data);
        console.log('id', id);

        if (id == undefined) {
          await AwsAmplify.save(data);
        } else {
          await AwsAmplify.update(id, data);
        }
      }
    } catch (e) {
      toast.error(e.toString());
    }
  };

  const dataUploadRecruiter = async (event) => {
    try {
      var data = new Recruiter({
        FirstName: formValues.firstName.value,
        LastName: formValues.lastName.value,
        Phone: formValues.phone.value,
        Email: formValues.email.value,
        Linkedin: formValues.linkedin.value,
        CompanyName: formValues.companyName.value,
        recruiterCompanyId: companyID,
        Profilepic: formValues.profilePic.picURL,
      });
      console.log('data', data);
      console.log('id', id);

      if (id == undefined) {
        await AwsAmplify.save(data);
      } else {
        await AwsAmplify.update(id, data);
      }
    } catch (e) {
      toast.error(e.toString());
    }
  };

  const getAllCompany = async () => {
    let email = localStorage.getItem('email');
    await CompanyAmplify.getALL()
      .then((allCompany) => {
        let company = [];
        // let emails = [];
        let i = 0;
        allCompany.forEach((child) => {
          company.push({
            id: i,
            companyId: child.id,
            name: child.Name,
            Phone: child.Phone,
            Website: child.Website,
            Email: child.Email.split('@')[1],
          });
          i++;
          // emails.push(child.Email.split('@')[1])
        });
        setAllCompany(company);
        if (company.length === allCompany.length) {
          company.some((element) => {
            if (element.Email === email.split('@')[1]) {
              console.log(element.Email);
              setCompanyExist(true);
              setCompanyID(element.companyId);
              setFormValues({
                ...formValues,
                companyName: {
                  value: element.name,
                  error: false,
                  errorMessage: 'You must select a company name',
                },
                email: formValues.email,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                phone: formValues.phone,
                profilePic: formValues.profilePic,
                linkedin: formValues.linkedin,
              });
              return element;
            } else {
              console.log(email.split('@')[1].split('.')[0]);
              setCompanyExist(false);
              setFormValues({
                ...formValues,
                companyName: {
                  value: email.split('@')[1].split('.')[0],
                  error: false,
                  errorMessage: 'You must select a company name',
                },
                email: formValues.email,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                phone: formValues.phone,
                profilePic: formValues.profilePic,
                linkedin: formValues.linkedin,
              });
            }
          });
        }
        return email;
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };
  useEffect(async () => {
    let email = localStorage.getItem('email');
    await AwsAmplify.getRecruiter(email)
      .then(async (userValue) => {
        let allEmail = await getAllCompany();

        if (userValue != undefined && userValue.length != 0) {
          setID(userValue.id);
          localStorage.setItem('recruiterId', userValue.id);
          localStorage.setItem('fname', userValue.FirstName);
          localStorage.setItem('lname', userValue.LastName);
          await Storage.get(userValue.Profilepic).then((pic) => {
            localStorage.setItem('profilePic', pic);
          });
          localStorage.setItem('companyId', userValue.recruiterCompanyId);
          console.log(email.split('@')[1]);

          if (userValue.Profilepic != null) {
            await Storage.get(userValue.Profilepic).then((val) => {
              setCompanyID(userValue.recruiterCompanyId);
              setFormValues((prevState) => {
                return {
                  ...prevState,
                  email: {
                    value: email,
                    error: false,
                    errorMessage: 'You must enter your registered emailID',
                  },
                  firstName: {
                    value: userValue.FirstName,
                    error: false,
                    errorMessage: 'You must enter a first name',
                  },
                  lastName: {
                    value: userValue.LastName,
                    error: false,
                    errorMessage: 'You must enter a last name',
                  },
                  phone: {
                    value: userValue.Phone,
                    error: false,
                    errorMessage: 'You must enter a phone number',
                  },
                  profilePic: {
                    value: val,
                    error: false,
                    picURL: userValue.Profilepic,
                    errorMessage: 'You must select a profile picture',
                  },
                  linkedin: {
                    value: userValue.Linkedin,
                    error: false,
                    errorMessage: 'You must enter your linkedin url',
                  },
                };
              });
            });
          }
        } else {
          setFormValues((prevState) => {
            return {
              ...prevState,
              email: {
                value: email,
                error: false,
                errorMessage: 'You must enter your registered emailID',
              },
            };
          });
        }
        console.log(
          'name',
          formValues.firstName.value,
          formValues.lastName.value
        );
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  }, []);

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    // 👇️ reset file input
    event.target.value = null;

    // 👇️ is now empty

    // 👇️ can still access file object here
    let pictureUpload = await AWS_Storage.ProfilePic(fileObj).then(
      async (value) => {
        await Storage.get(value).then(async (val) => {
          setFormValues((prevState) => {
            return {
              ...prevState,
              profilePic: {
                value: val,
                error: false,
                picURL: value,
                errorMessage: 'You must select your profile image',
              },
            };
          });
          dataUploadPic(value);
        });
      }
    );
  };
  return (
    <div>
      <Navbar />

      <Container maxWidth className="bg_color">
        <div>
          <br />
          <Container fixed>
            <Grid container spacing={2} sx={{ pb: 2 }}>
              <Grid item md={12}>
                <div className="edit_profile_title_txt">Edit Profile</div>
              </Grid>
            </Grid>
            <Box>
              <Grid container spacing={6}>
                <Grid item xs={12} md={3}>
                  <Grid container className="tabs_style">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <label htmlFor="contained-button-file">
                          <Avatar
                            className="avatar_style"
                            alt={formValues.firstName.value}
                            src={formValues.profilePic.value}
                            sx={{ width: 175, height: 175 }}
                          />

                          <IconButton
                            className="camera_icon_overlay"
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              onChange={(e) => {
                                handleFileChange(e);
                              }}
                            />
                            <AddAPhotoRoundedIcon />
                          </IconButton>
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={12}></Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={12}></Grid>
                    </Grid>
                    <Grid item xs={12} md={12} className="tabs_list_grid">
                      <Tabs
                        indicatorColor="primary"
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        <Tab
                          className="tab_list_style"
                          icon={<ContactEmergencyRoundedIcon />}
                          iconPosition="start"
                          label="Personal Info"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="tab_list_style"
                          icon={<BusinessIcon />}
                          iconPosition="start"
                          label="Company Details"
                          {...a11yProps(1)}
                        />

                        <Tab
                          className="tab_list_style"
                          icon={<PeopleRoundedIcon />}
                          iconPosition="start"
                          label="Social Links "
                          {...a11yProps(2)}
                        />

                        <Tab
                          className="tab_list_style"
                          icon={<PasswordRoundedIcon />}
                          iconPosition="start"
                          label="Change Password"
                          {...a11yProps(3)}
                        />
                      </Tabs>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Grid container>
                    <Grid item md={12} className="input_field_style">
                      <TabPanel value={value} index={0}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Set Up Your Recruiter Profile
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          onSubmit={handleSubmit(dataUploadRecruiter)}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="First Name"
                                variant="outlined"
                                value={formValues.firstName.value}
                                error={formValues.firstName.error}
                                helperText={
                                  formValues.firstName.error &&
                                  formValues.firstName.errorMessage
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { position: 'absolute' },
                                }}
                                onChange={(e) => {
                                  setFormValues((prevState) => {
                                    return {
                                      ...prevState,
                                      firstName: {
                                        value: e.target.value,
                                        error: false,
                                        errorMessage:
                                          'You must enter a first name',
                                      },
                                    };
                                  });
                                }}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                required
                                name="lastName"
                                value={formValues.lastName.value}
                                error={formValues.lastName.error}
                                helperText={
                                  formValues.lastName.error &&
                                  formValues.lastName.errorMessage
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { position: 'absolute' },
                                }}
                                onChange={(e) => {
                                  setFormValues((prevState) => {
                                    return {
                                      ...prevState,
                                      lastName: {
                                        value: e.target.value,
                                        error: false,
                                        errorMessage:
                                          'You must enter a last name',
                                      },
                                    };
                                  });
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item md={6} xs={12}>
                              <MuiPhoneNumber
                                required
                                fullWidth
                                label="Phone Number"
                                variant="outlined"
                                defaultCountry={'in'}
                                value={formValues.phone.value}
                                error={formValues.phone.error}
                                helperText={
                                  formValues.phone.error &&
                                  formValues.phone.errorMessage
                                }
                                onChange={(c, t) => {
                                  console.log(c, t, isValidPhoneNumber(c));
                                  if (isValidPhoneNumber(c)) {
                                    setFormValues((prevState) => {
                                      return {
                                        ...prevState,
                                        phone: {
                                          value: c,
                                          error: false,
                                          errorMessage:
                                            'You must enter your registered emailID',
                                        },
                                      };
                                    });
                                    return true;
                                  }
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={4}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item md={4} xs={12} className="submit_btn">
                              <Button
                                className="profile_save_btn"
                                variant="contained"
                                type="submit"
                              >
                                Save
                              </Button>
                              <Button
                                className="cancel_btn"
                                variant="contained"
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Set Up Your Company
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          // onSubmit={handleSubmit(dataUpload)}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid item md={3} className="grid_item_mob_view">
                              {' '}
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Company Name"
                                variant="outlined"
                                required
                                name="CompanyName"
                                disabled
                                value={formValues.companyName.value}
                                error={formValues.companyName.error}
                                helperText={
                                  formValues.companyName.error &&
                                  formValues.companyName.errorMessage
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { position: 'absolute' },
                                }}
                                onChange={(e) => {
                                  setFormValues((prevState) => {
                                    return {
                                      ...prevState,
                                      companyName: {
                                        value: e.target.value,
                                        error: false,
                                        errorMessage:
                                          'You must enter a company name',
                                      },
                                    };
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item md={3} className="grid_item_mob_view">
                              {' '}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item md={4}></Grid>
                            <Grid item md={4} xs={12} className="submit_btn">
                              <Button
                                className="profile_save_btn"
                                variant="contained"
                                type="submit"
                              >
                                Save
                              </Button>
                              <Button
                                className="cancel_btn"
                                variant="contained"
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item md={4}></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>

                      <TabPanel value={value} index={2}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Set Up Your Social Links
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          onSubmit={(e) => handleSubmit(dataUploadRecruiter)}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="LinkedIn"
                                variant="outlined"
                                required
                                name="LinkedIn"
                                onChange={(e) => {
                                  setFormValues((prevState) => {
                                    return {
                                      ...prevState,
                                      linkedin: {
                                        value: e.target.value,
                                        error: false,
                                        errorMessage:
                                          'You must enter your Linkedin url',
                                      },
                                    };
                                  });
                                }}
                                value={formValues.linkedin.value}
                                error={formValues.linkedin.error}
                                helperText={
                                  formValues.linkedin.error &&
                                  formValues.linkedin.errorMessage
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { position: 'absolute' },
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={4}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item md={4} xs={12} className="submit_btn">
                              <Button
                                className="profile_save_btn"
                                variant="contained"
                                type="submit"
                              >
                                Save
                              </Button>
                              <Button
                                className="cancel_btn"
                                variant="contained"
                              >
                                Cancel
                              </Button>
                            </Grid>

                            <Grid
                              item
                              md={4}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>

                      <TabPanel value={value} index={3}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt"> Change Password</div>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2} sx={{ pb: 2 }}>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              required
                              id="outlined-basic"
                              label="Current Password"
                              variant="outlined"
                              onChange={(e) => {
                                setCurrentPassword(e.target.value);
                              }}
                              type="password"
                              error={currentPasswordErr}
                              helperText={
                                currentPasswordErr &&
                                'Enter the current password'
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ pb: 2 }}>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="New Password"
                              variant="outlined"
                              type="password"
                              required
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                              }}
                              error={newPasswordErr}
                              helperText={
                                newPasswordErr && 'Enter the new password'
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ pb: 2 }}>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Confirm Password"
                              variant="outlined"
                              type="password"
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                              required
                              error={confirmPasswordErr}
                              helperText={
                                confirmPasswordErr &&
                                'Enter the confirm password ,same as new password'
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            md={3}
                            className="grid_item_mob_view"
                          ></Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            md={4}
                            className="grid_item_mob_view"
                          ></Grid>
                          <Grid item md={4} xs={12} className="submit_btn">
                            <Button
                              className="profile_save_btn"
                              variant="contained"
                              type="submit"
                              onClick={(e) => handleChangePassword(e)}
                            >
                              Save
                            </Button>
                            <Button className="cancel_btn" variant="contained">
                              Cancel
                            </Button>
                          </Grid>

                          <Grid
                            item
                            md={4}
                            className="grid_item_mob_view"
                          ></Grid>
                        </Grid>
                      </TabPanel>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </div>
      </Container>
    </div>
  );
}

export default RecruiterNewProfileEdit;
