import { DataStore } from '@aws-amplify/datastore';
import { QuestionBank } from '../../../models'
import { Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
Amplify.configure(awsconfig);

class QuestionAmplify{

async save (data){
    return new Promise(async (resolve, reject) => {
    try{
        await DataStore.save(
            data
        ).then(question=>{
            console.log(question)
            resolve(question);
        })
        }
    catch(e){
        console.log("question save", e)
        reject(e);
    }
    });
    }
async getALL(){
    return new Promise(async (resolve, reject) => {
        try {
    await DataStore.query(QuestionBank).then(models=>{
        console.log(models);
        resolve(models);
    })}
    catch(e){
        console.log("error getAll",e);
        reject(e);
    }
})
}
}
export default new QuestionAmplify();