/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Navbar from './Navbar';
import ModalVideo from 'react-modal-video';
import './ProfileView.css';
// import profile_pic from "../../assets/images/user-profile.png";
import './themify-icons.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Home.css';
import { MDBCard, MDBCardBody, MDBCardFooter } from 'mdb-react-ui-kit';
import VideoModal from './VideoModal';
import jwtDecode from 'jwt-decode';
import Card from 'react-bootstrap/Card';
import { NavLink } from 'react-router-dom';
import CompanyProfile from './CompanyProfile';
import firebaseStorage from '../Firebase/firebaseStorage';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import AwsAmplify from '../Service/AWS_Auth/CandidateDataService';
import { async } from '@firebase/util';
import { Image } from '@aws-amplify/ui-react';
import { Storage } from '@aws-amplify/storage';
function ProfileView() {
  const [isOpen, setisOpen] = useState(false);

  const [optSmModal, setOptSmModal] = useState(false);
  const toggleShow = () => setOptSmModal(!optSmModal);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [currentLocation, setCurrentLocation] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [role, setRole] = React.useState('');
  const [linkedin, setLinkedin] = React.useState('');
  const [github, setGithub] = React.useState('');
  const [youtube, setYoutube] = React.useState('');
  const [YOE, setYOE] = React.useState('');
  const [qualificationChoice, setQualificationChoice] = React.useState('');
  const [experience, setExperience] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [user, setUser] = React.useState({});
  const [userDetails, setUserDetails] = React.useState();
  const [video1, setVideo1] = React.useState();
  const [video2, setVideo2] = React.useState();
  const [video3, setVideo3] = React.useState();
  const [profile_pic, setProfile_pic] = React.useState('');
  const [picUrl, setPicUrl] = React.useState('');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(phone);

  useEffect(async () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let email = localStorage.getItem('email');
    setEmail(email);
    // setUser(userDecoded);
    // let userValue =await UserProfile.getUserById(userDecoded.sub);
    let userValue = await AwsAmplify.getCandidate(email);
    setUserDetails(userValue);
    if (userValue.length != 0) {
      localStorage.setItem('candidateId', userValue.id);
      localStorage.setItem('fname', userValue.FirstName);
      setFirstName(userValue.FirstName);
      setLastName(userValue.LastName);
      setCurrentLocation(userValue.CurrentLocation);
      setPhone(userValue.Phone);
      setExperience(userValue.Experience);
      setYOE(userValue.YOE);
      setRole(userValue.CurrentRole);
      setGithub(userValue.Github != null ? userValue.Github : '');
      setLinkedin(userValue.Linkedin != null ? userValue.Linkedin : '');
      setYoutube(userValue.Youtube != null ? userValue.Youtube : '');
      setQualificationChoice(
        userValue.Qualification != null ? userValue.Qualification : ''
      );
      // setVideo1(userValue.Video1!=null?userValue.Video1:"")
      // setVideo2(userValue.Video2!=null?userValue.Video2:"")
      // setVideo3(userValue.Video3!=null?userValue.Video3:"")
      // getAllJobs(userValue.UserProfile.UID)
      setProfile_pic(userValue.ProfilePic); // !=null?userValue.UserProfile.ProfilePicture:userDecoded.picture)
      await Storage.get(userValue.ProfilePic).then((val) => {
        setPicUrl(val);
      });
      await Storage.get(userValue.Video1).then((val) => {
        setVideo1(val);
      });
      await Storage.get(userValue.Video2).then((val) => {
        setVideo2(val);
      });
      await Storage.get(userValue.Video3).then((val) => {
        setVideo3(val);
      });
    }
  }, []);

  const openModal = () => {
    setisOpen(true);
  };
  return (
    <div>
      <Navbar />
      <Container fluid>
        <Row>
          <Col className="employee_details_txt">
            <h2>Candidate Details</h2>
          </Col>
        </Row>
        <Container>
          <Row className="user_profile_details">
            <Col>
              <Row className="profile_details_row">
                <Col sm={4} className="profile_pic_col">
                  <Image
                    className="profile_pic"
                    src={picUrl}
                    width="300px"
                    height="300px"
                  />
                </Col>
                <Col className="emp_details_col" sm={8}>
                  <Row className="emp_details_row">
                    <Col sm={6} md={6}>
                      <h4>{firstName + ' ' + lastName}</h4>
                    </Col>
                    <Col className="edit_profile_txt" sm={6} md={6}>
                      <NavLink to="/candidate/edit_profile">
                        <li className="profile_edit_icon">
                          <span class="ti-pencil-alt"></span>
                        </li>
                      </NavLink>
                    </Col>
                  </Row>
                  <Row className="emp_details_row">
                    <Col sm={12}>
                      <Button className="emp_details_btn">
                        {experience == 'Fresher' ? 'Fresher' : role}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} className="details_list">
                      <ul>
                        <li>
                          <span class="ti-credit-card"></span>
                          {currentLocation}
                        </li>
                        <li>
                          <span class="ti-mobile"></span>
                          {phone}
                        </li>

                        {/* <li>
                          <span class="ti-pencil-alt"></span>
                          {qualificationChoice}
                        </li> */}
                      </ul>
                    </Col>
                    <Col sm={6} className="details_list">
                      <ul>
                        <li>
                          <span class="ti-email"></span>
                          {email}
                        </li>
                        <li>
                          <span class="ti-shield"></span>Year of Experience :
                          {experience == 'Fresher' ? 'Fresher' : YOE}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row className="btn_links">
                    <Col sm={6}>
                      <NavLink to="">
                        <Button className="github_btn">
                          <span class="ti-github"> </span>
                        </Button>
                      </NavLink>

                      <Button className="linkedin_btn">
                        <a href={'https://www.' + linkedin} target="_blank">
                          <span class="ti-linkedin"></span>
                        </a>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <Container>
    <Row>
    <Col className='video_container-Col' md={4}>
    <div>
   
    <MDBCard  >
<div class="thumbnail_container">
  <Image className="profile_pic"  src={picUrl} width="100%" height="245px" />
  <div class="bg-text">
  <h2>Tell me About Your self ?</h2>
</div>
</div>
    
      <MDBCardBody>       
      
      </MDBCardBody>
      <MDBCardFooter ><VideoModal video = {video1}></VideoModal></MDBCardFooter>
      </MDBCard>
  
      </div>
    </Col>
    
    </Row>
    </Container> */}

          <br></br>
        </Container>
      </Container>
    </div>
  );
}

export default ProfileView;
