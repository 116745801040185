import React, { useState } from 'react';
import './NewCandidateCreateProfile.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/system';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MuiPhoneNumber from 'material-ui-phone-number';
import { isValidPhoneNumber } from 'libphonenumber-js';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FileUpload from 'react-material-file-upload';
import ContactEmergencyRoundedIcon from '@mui/icons-material/ContactEmergencyRounded';
import WorkHistoryRoundedIcon from '@mui/icons-material/WorkHistoryRounded';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import AwsAmplify from '../Service/AWS_Auth/CandidateDataService';
import AWS_Storage from '../Service/AWS_Auth/VideoStorage';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Storage } from '@aws-amplify/storage';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Candidate } from '../../models';
import Navbar from '../HomePage/Navbar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function NewCandidateCreateProfile() {
  Amplify.configure(awsconfig);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [homeTown, setHomeTown] = React.useState('');
  const [currentLocation, setCurrentLocation] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [experience, setExperience] = React.useState('Fresher');
  const [isFresher, setIsFresher] = useState(true);
  const [isExperienced, setIsExperienced] = useState(false);
  const [files, setFiles] = useState([]);
  const [resume, setResume] = React.useState();
  const [userDetails, setUserDetails] = React.useState();
  const [fresherProjectName, setFresherProjectName] = React.useState('');
  const [fresherProjectDetails, setFresherProjectDetails] = React.useState('');
  const [fresherProjectTools, setFresherProjectTools] = React.useState('');
  const [YOE, setYOE] = React.useState(0);
  const [companyName, setCompanyName] = React.useState('');
  const [role, setRole] = React.useState('');
  const [profilePic, setProfilePic] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [picUrl, setPicUrl] = React.useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorResume, setErrorResume] = useState(false);
  const [errorPic, setErrorPic] = useState(null);
  const [datechange, setDatechange] = React.useState(null);
  const [relocation, setRelocation] = useState('No');
  const [work, setWork] = useState('Office');
  const [currentSalary, setCurrentSalary] = useState('');
  const [expectedSalary, setExpectedSalary] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(phone);

  const validateEmail = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };
  const resumeUpload = async (file) => {
    setSelectedFile(file);
    if (file) {
      await AWS_Storage.Resume(file).then(async (value) => {
        setResume(value);
      });
    }
  };
  const handlePhoneNumberChange = (value) => {
    if (isValidPhoneNumber(value)) {
      setIsValidPhone(false);
      setPhone(value);
    } else {
      setIsValidPhone(true);
    }
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    setIsValid(validateEmail(inputEmail));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Fresher = () => {
    setIsFresher(true);
    setIsExperienced(false);
    setExperience('Fresher');
  };
  const Experienced = () => {
    setIsExperienced(true);
    setIsFresher(false);
    setExperience('Experience');
  };
  const dataUpload = async (event) => {
    event.preventDefault();
    console.log('hi', dob);
    if (!selectedFile) {
      setErrorResume('Please select a file to upload');
      return;
    }
    if (selectedFile != null) {
      try {
        var data = new Candidate({
          FirstName: firstName,
          Phone: phone,
          Email: email,
          // DOB: datechange,
          Gender: gender,
          LastName: lastName,
          HomeLocation: homeTown,
          CurrentLocation: currentLocation,
          Experience: experience,
          Resume: resume,
          FresherProjectDetails: fresherProjectDetails,
          FresherProjectName: fresherProjectName,
          FresherProjectTools: fresherProjectTools,
          YOE: YOE,
          CompanyName: companyName,
          CurrentRole: role,
          Relocation: relocation === 'Yes' ? true : false,
          Work: work,
          CurrentSalary: currentSalary,
          ExpectedSalary: expectedSalary,
          ProfilePic: profilePic,
          Status: false,
        });

        await AwsAmplify.save(data)
          .then((user) => {
            if (user.length != 0) {
              toast.success('Candidate data has been saved successfully');
              navigate('/newSchedulePage', { state: { candidate: data.id } });
              // navigate("/instruction",{ state:{id:user.id}});
            }
          })
          .catch((err) => {
            toast.error(err.toString());
          });
      } catch (e) {
        console.log(e);
        toast.error(e.toString());
      }
    }
  };
  const ProfileUpload = async (file) => {
    console.log('file', file);
    if (file) {
      await AWS_Storage.ProfilePic(file).then(async (value) => {
        await Storage.get(value).then(async (val) => {
          setPicUrl(val);
          console.log('url', val);
        });
        setProfilePic(value);
      });
    }
  };
  return (
    <div>
      <Navbar />
      <Container maxWidth className="bg_color" sx={{ height: '100vh' }}>
        <div>
          <br />
          <Container fixed>
            <Grid container spacing={2} sx={{ pb: 2 }}>
              <Grid item md={12} xs={12}>
                <div className="edit_profile_title_txt">Create Candidate</div>
              </Grid>
            </Grid>
            <Box>
              <Grid container spacing={6}>
                <Grid item xs={12} md={3}>
                  <Grid container className="tabs_style">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <label htmlFor="contained-button-file">
                          <Avatar
                            className="avatar_style"
                            sx={{ width: 175, height: 175 }}
                            src={picUrl}
                          />
                          <IconButton
                            className="camera_icon_overlay"
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              onChange={async (e) => {
                                const file = e.target.files[0];
                                ProfileUpload(file);
                              }}
                              type="file"
                            />
                            <AddAPhotoRoundedIcon />
                          </IconButton>
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item x={12} md={12} sx={{ pb: 2 }}></Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} sx={{ pb: 2 }}></Grid>
                    </Grid>
                    <Grid item xs={12} md={12} className="tabs_list_grid">
                      <Tabs
                        indicatorColor="primary"
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        <Tab
                          className="tab_list_style"
                          icon={<ContactEmergencyRoundedIcon />}
                          iconPosition="start"
                          label="Personal Info"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="tab_list_style"
                          icon={<WorkHistoryRoundedIcon />}
                          iconPosition="start"
                          label="Experience"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="tab_list_style"
                          icon={<SummarizeRoundedIcon />}
                          iconPosition="start"
                          label="Resume"
                          {...a11yProps(2)}
                        />
                      </Tabs>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container>
                    <Grid item md={12} xs={12} className="input_field_style">
                      <TabPanel value={value} index={0}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Set Up Your Personal Information
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          onSubmit={(e) => setValue(1)}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                type="email"
                                onChange={handleEmailChange}
                                value={email}
                                error={!isValid}
                                helperText={
                                  !isValid
                                    ? 'Please enter a valid email address'
                                    : ''
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="First Name"
                                variant="outlined"
                                onChange={(ev) => setFirstName(ev.target.value)}
                                value={firstName}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                onChange={(ev) => setLastName(ev.target.value)}
                                value={lastName}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-label"
                                  required
                                >
                                  Gender
                                </InputLabel>
                                <Select
                                  required
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="gender"
                                  onChange={(e) => {
                                    setGender(e.target.value);
                                  }}
                                  value={gender}
                                >
                                  <MenuItem>
                                    <em>Select an option</em>
                                  </MenuItem>
                                  <MenuItem value="Female">Female</MenuItem>
                                  <MenuItem value="Male">Male</MenuItem>
                                  <MenuItem value="Other">Other</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              xs={12}
                              md={3}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl
                                fullWidth
                                required
                                error={!isValidPhone}
                              >
                                <MuiPhoneNumber
                                  value={phone}
                                  fullWidth
                                  label="Phone Number"
                                  variant="outlined"
                                  defaultCountry={'in'}
                                  error={isValidPhone}
                                  helperText={
                                    isValidPhone &&
                                    'Please enter a valid phone number'
                                  }
                                  onChange={(value) =>
                                    handlePhoneNumberChange(value)
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Hometown"
                                variant="outlined"
                                onChange={(ev) => setHomeTown(ev.target.value)}
                                value={homeTown}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                fullWidth
                                id="outlined-basic"
                                label="Current Location"
                                variant="outlined"
                                onChange={(ev) =>
                                  setCurrentLocation(ev.target.value)
                                }
                                value={currentLocation}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              className="grid_item_mob_view"
                              md={3}
                              xs={12}
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={4} className="submit_btn">
                                <Button
                                  className="profile_save_btn"
                                  variant="contained"
                                  type="submit"
                                >
                                  Next
                                </Button>
                              </Grid>
                              <Grid item xs={12} md={4} className="submit_btn">
                                <Button
                                  className="profile_next_btn"
                                  variant="contained"
                                  type="submit"
                                  onClick={(e) => {
                                    navigate('/newSchedulePage');
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Set Up Your Experience
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          onSubmit={(e) => {
                            setValue(2);
                          }}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={experience}
                                  onChange={(event) =>
                                    setExperience(event.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    defaultChecked
                                    value="Fresher"
                                    control={<Radio />}
                                    label="Fresher"
                                    onClick={Fresher}
                                    checked={experience === 'Fresher'}
                                  />
                                  <FormControlLabel
                                    value="Experience"
                                    control={<Radio />}
                                    label="Experienced"
                                    onClick={Experienced}
                                    checked={experience === 'Experience'}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          {isFresher && (
                            <>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Project Name"
                                    variant="outlined"
                                    required
                                    onChange={(ev) =>
                                      setFresherProjectName(ev.target.value)
                                    }
                                    value={fresherProjectName}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Project Details"
                                    variant="outlined"
                                    required
                                    onChange={(ev) =>
                                      setFresherProjectDetails(ev.target.value)
                                    }
                                    value={fresherProjectDetails}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Skills/Tools"
                                    variant="outlined"
                                    required
                                    onChange={(ev) =>
                                      setFresherProjectTools(ev.target.value)
                                    }
                                    value={fresherProjectTools}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                            </>
                          )}
                          {isExperienced && (
                            <>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Years of Experinece"
                                    variant="outlined"
                                    type="number"
                                    required
                                    onChange={(ev) =>
                                      setYOE(Number(ev.target.value))
                                    }
                                    value={YOE}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Company Name"
                                    variant="outlined"
                                    required
                                    onChange={(ev) =>
                                      setCompanyName(ev.target.value)
                                    }
                                    value={companyName}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Role"
                                    variant="outlined"
                                    required
                                    onChange={(ev) => setRole(ev.target.value)}
                                    value={role}
                                    renderValue={(value) => `${value}`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  className="grid_item_mob_view"
                                ></Grid>
                              </Grid>
                            </>
                          )}
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  Relocation
                                </FormLabel>
                                <RadioGroup
                                  required
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={relocation}
                                  onChange={(event) =>
                                    setRelocation(event.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  Work Preference
                                </FormLabel>
                                <RadioGroup
                                  required
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={work}
                                  onChange={(event) =>
                                    setWork(event.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value="Hybrid"
                                    control={<Radio />}
                                    label="Hybrid"
                                    // onChange={(ev) => setWork('Hybrid')}
                                    checked={work === 'Hybrid'}
                                  />
                                  <FormControlLabel
                                    value="Office"
                                    control={<Radio />}
                                    label="Office"
                                    // onChange={(ev) => setWork('Office')}
                                    checked={work === 'Office'}
                                  />
                                  <FormControlLabel
                                    value="WorkFromHome"
                                    control={<Radio />}
                                    label="Work From Home"
                                    // onChange={(ev) => setWork('WorkFromHome')}
                                    checked={work === 'WorkFromHome'}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Current Salary"
                                variant="outlined"
                                onChange={(ev) =>
                                  setCurrentSalary(ev.target.value)
                                }
                                value={currentSalary}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Expected Salary"
                                variant="outlined"
                                onChange={(ev) =>
                                  setExpectedSalary(ev.target.value)
                                }
                                value={expectedSalary}
                                renderValue={(value) => `${value}`}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={4} className="submit_btn">
                                <Button
                                  className="profile_save_btn"
                                  variant="contained"
                                  type="submit"
                                >
                                  Next
                                </Button>
                              </Grid>
                              <Grid item xs={12} md={4} className="submit_btn">
                                <Button
                                  className="profile_next_btn"
                                  variant="contained"
                                  type="submit"
                                  onClick={(e) => {
                                    navigate('/newSchedulePage');
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>

                      <TabPanel value={value} index={2}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="sub_head_txt">
                              {' '}
                              Set Up Your Experience
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          component="form"
                          onSubmit={(e) => {
                            console.log('hi');
                            dataUpload(e);
                          }}
                          autoComplete="off"
                        >
                          <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                            <Grid item xs={12} md={6}>
                              <FileUpload
                                title="click to select file"
                                required
                                error={errorResume}
                                accept=".doc,.docx,.pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
                                value={files}
                                onChange={(e) => {
                                  console.log('file', e[0]);
                                  const file = e;
                                  setFiles(file);
                                  resumeUpload(file[0]);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={12} md={4} className="submit_btn">
                                <Button
                                  className="profile_save_btn"
                                  variant="contained"
                                  type="submit"
                                >
                                  Save
                                </Button>
                              </Grid>
                              <Grid item xs={12} md={4} className="submit_btn">
                                <Button
                                  className="profile_next_btn"
                                  variant="contained"
                                  type="submit"
                                  onClick={(e) => {
                                    navigate('/newSchedulePage');
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={12}
                              className="grid_item_mob_view"
                            ></Grid>
                          </Grid>
                        </Box>
                      </TabPanel>
                    </Grid>
                  </Grid>
                  <br />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </div>
      </Container>
    </div>
  );
}

export default NewCandidateCreateProfile;
