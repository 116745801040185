import React, { useState } from 'react';
import './CandidateNavbar.css';
import { NavLink } from 'react-router-dom';
import AwsAuth from '../Service/AWS_Auth/authenticationServices';
import logo from '../../assets/images/new_logo.png';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const CandidateNavbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [setIsButtonVisible, setSetIsButtonVisible] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleHideClick = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const user = localStorage.getItem('user');
  const fname = localStorage.getItem('fname');
  const candidateID = localStorage.getItem('candidateId');
  return (
    <div className="title_txt">
      <nav className="home_nav_bar">
        <a href="/">
          <span>
            <img src={logo} height="50px" />
          </span>
          <span className="app_name">
            Interview<span className="tape_txt">Tape</span>
          </span>{' '}
        </a>
        <div>
          <ul id="navbar" className={clicked ? '#navbar active ' : '#navbar'}>
            {user ? (
              <li>
                <NavLink
                  activeClassName=" active"
                  to="/candidate/interviewPage"
                >
                  Interviews
                </NavLink>
              </li>
            ) : (
              <div></div>
            )}
            {user ? (
              <li>
                <div>
                  <IconButton
                    className="account_Icon_btn"
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    {candidateID != null && fname != null ? (
                      <Avatar
                        alt={fname}
                        src="/broken-image.jpg"
                        sx={{
                          fontSize: '20px',
                          bgcolor: '#6366f1',
                          width: 30,
                          height: 30,
                        }}
                      ></Avatar>
                    ) : (
                      <AccountCircle className="account_icon" />
                    )}
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <NavLink to={'/candidate/newprofileview'}>
                      {' '}
                      <MenuItem
                        className="dropdown_menu_items"
                        onClick={handleClose}
                      >
                        My Profile
                      </MenuItem>
                    </NavLink>
                    <NavLink to={'/aboutus'}>
                      {' '}
                      {/* <MenuItem
                        className="dropdown_menu_items"
                        onClick={handleClose}
                      >
                        About us
                      </MenuItem> */}
                    </NavLink>
                    <NavLink>
                      {' '}
                      <MenuItem
                        className="dropdown_menu_items"
                        onClick={(e) => {
                          handleClose();
                          localStorage.clear();
                          AwsAuth.signOut();
                          setTimeout(() => (window.location.href = '/'), 500);
                        }}
                      >
                        Logout
                      </MenuItem>
                    </NavLink>
                  </Menu>
                </div>
              </li>
            ) : (
              <div></div>
            )}

            {user == null ? (
              <div>
                <NavLink to="/chooseprofile">
                  <Button onClick={handleHideClick} className="nav_signup_btn">
                    Sign Up
                  </Button>{' '}
                </NavLink>
              </div>
            ) : (
              <div></div>
            )}

            {user ? (
              <></>
            ) : (
              // <div>
              //   <li className="nav_logout_btn">
              //     <NavLink
              //       activeClassName=" active"
              //       to="/"
              //       onClick={(e) => {
              //         localStorage.clear();
              //         AwsAuth.signOut();
              //         setTimeout(() => (window.location.href = '/'), 500);
              //       }}
              //     >
              //       Logout
              //     </NavLink>
              //   </li>

              //   <li className="nav_logout_icon">
              //     <Avatar sx={{ m: 1, bgcolor: 'black' }}>
              //       <LogoutIcon
              //         onClick={(e) => {
              //           localStorage.clear();
              //           AwsAuth.signOut();
              //           setTimeout(() => (window.location.href = '/'), 500);
              //         }}
              //       ></LogoutIcon>
              //     </Avatar>
              //   </li>
              // </div>
              <div></div>
            )}
          </ul>
        </div>

        <div id="mobile" onClick={handleClick}>
          {/* <i class="fas fa-align-justify"></i>
   <i class="fas fa-times"></i> */}
          <i
            id="bar"
            className={clicked ? 'fas fa-times ' : 'fas fa-align-justify'}
          ></i>
        </div>
      </nav>
    </div>
  );
};

export default CandidateNavbar;
