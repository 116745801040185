import React from 'react';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import Navbar from '../HomePage/Navbar';
import './ChooseProfile.css';
import GIF from '../../assets/images/Designcommunity_chooserole.gif';
import recruiter_img from '../../assets/images/recruiter_choose_profile.gif';
import candidate_img from '../../assets/images/candidate_choose_profile.gif';
import { Link } from 'react-router-dom';
import Footer from '../HomePage/Footer';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

function ChooseProfile() {
  const user = localStorage.getItem('user');

  return (
    <div>
      <Navbar />
      <Container maxWidth className="set_bg_color">
        <Grid sx={{ height: '100vh' }} container spacing={2}>
          <Grid item className="chooseprofile_txt_col" md={6} xs={12}>
            <h2>Please tell us about your yourself</h2>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className="content_txt">
                  Choose your role by clicking any one of the buttons below
                </div>
              </Grid>
            </Grid>
            <br />
            <br />

            <Grid container spacing={2} className="chooseprofile_btn_row">
              <Grid item md={12} className="chooseprofile_btn_col">
                {user == null ? (
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Link to="/login">
                        <Card
                          sx={{ display: 'flex' }}
                          variant="outlined"
                          onClick={(ev) =>
                            localStorage.setItem('role', 'Recruiter')
                          }
                          className="choose_role_card_style"
                        >
                          <CardMedia
                            component="img"
                            sx={{ width: 151 }}
                            image={recruiter_img}
                            alt="recuiter"
                          />
                          <Box
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <CardContent sx={{ flex: '1 0 auto' }}>
                              <div className="recruiter_card_txt">
                                I'm a Recruiter
                              </div>
                            </CardContent>
                          </Box>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid item md={12}>
                      <Link to="/login">
                        <Card
                          sx={{ display: 'flex' }}
                          variant="outlined"
                          onClick={(ev) => {
                            localStorage.setItem('role', 'Employee');
                          }}
                          className="choose_role_card_style"
                        >
                          <CardMedia
                            component="img"
                            sx={{ width: 151 }}
                            image={candidate_img}
                            alt="candidate"
                          />
                          <Box
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <CardContent sx={{ flex: '1 0 auto' }}>
                              <div className="candidate_card_txt">
                                I'm a Candidate
                              </div>
                            </CardContent>
                          </Box>
                        </Card>
                      </Link>
                    </Grid>
                  </Grid>
                ) : (
                  <div></div>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6} className="choose_profile_gif">
            <img className="loginPage_GIF" src={GIF} width="100%" alt="" />
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </div>
  );
}

export default ChooseProfile;
