import { React, useState } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Chip from '@mui/material/Chip';
import { Container } from '@mui/system';
import Button from '@mui/material/Button';
import FlagIcon from '@mui/icons-material/Flag';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmailIcon from '@mui/icons-material/Email';
import SchoolIcon from '@mui/icons-material/School';
import './JobSearchList.css';
import WcIcon from '@mui/icons-material/Wc';
import ShieldIcon from '@mui/icons-material/Shield';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';
import CardContent from '@mui/material/CardContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function List(props) {
  const [expanded, setExpanded] = useState(false);

  const handleButtonClick = (e) => {
    console.log('ev', job);
    props.onClick(job, !props.state);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [open, setOpen] = useState(true);
  let job = [];
  var filteredData =
    Array.isArray(props.data) &&
    props.data.length !== 0 &&
    props.data.filter((el) => {
      return el;
    });

  console.log('filteredData', filteredData, props.state);
  return (
    <div>
      <ul className="job_card_container">
        {filteredData &&
          filteredData.map((item) => (
            <Grid container>
              <Grid item xs={12}>
                <div
                  onClick={() => {
                    console.log('sec', item);
                    job = item;
                  }}
                >
                  <div>
                    <CssBaseline />{' '}
                    <Container
                      className={
                        props.state
                          ? 'full_card_body_active'
                          : 'new_full_card_body'
                      }
                      // onClick={handleClick}
                      disableGutters={true}
                      fixed
                    >
                      <Container className="card_top_bar">
                        <Grid container spacing={0}>
                          <Grid className="top_bar_item_1" item xs={12} md={6}>
                            {item['Job'].Title}{' '}
                            {`( ${item['Job'].InternalID} )  `}
                            <Chip
                              className="status_chip"
                              label={` ${item['Job'].Status.toLowerCase()}`}
                            />
                          </Grid>
                          {props.display && (
                            <Grid
                              className="top_bar_item_2"
                              item
                              xs={12}
                              md={6}
                            >
                              <Button
                                onClick={(e) => {
                                  job = item;
                                  handleButtonClick(e);
                                }}
                                className="select_btn"
                              >
                                {' '}
                                {props.state ? 'SELECTED' : 'SELECT'}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Container>

                      <Container className="new_card_content" maxWidth="lg">
                        <Grid container>
                          <Grid
                            className="content_area_col_1"
                            item
                            xs={12}
                            md={4}
                            lg={4}
                          >
                            <ul>
                              <li>
                                <PersonIcon className="jobs_icon_styles" />{' '}
                                {item['Job'].HiringManagerName}
                              </li>
                              <li>
                                <EmailIcon className="jobs_icon_styles" />{' '}
                                {item['Job'].HiringManagerEmail}
                              </li>
                            </ul>
                          </Grid>
                          <Grid
                            className="content_area_col_2"
                            item
                            xs={12}
                            md={4}
                            lg={4}
                          >
                            <ul>
                              <li>
                                <PlaceIcon className="jobs_icon_styles" />{' '}
                                {item['Job'].Location}
                              </li>
                              <li>
                                <WcIcon className="jobs_icon_styles" />{' '}
                                {item['Job'].Gender}
                              </li>
                            </ul>
                          </Grid>
                          <Grid
                            className="content_area_col_3"
                            item
                            xs={12}
                            md={4}
                            lg={4}
                          >
                            <ul>
                              <li>
                                <NotListedLocationIcon className="jobs_icon_styles" />{' '}
                                {item['Job'].RemoteWorking}
                              </li>
                              <li>
                                <ShieldIcon className="jobs_icon_styles" />{' '}
                                {item['Job'].MinimumExperience}-
                                {item['Job'].MaximumExperience}
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            {' '}
                            <Box sx={{ width: '100%' }}>
                              {/* <Button
                                className="job_desc_btn"
                                disabled={open}
                                variant="outlined"
                                onClick={() => {
                                  setOpen(true);
                                }}
                              >
                                expand
                              </Button> */}

                              <Collapse
                                in={expanded}
                                timeout="auto"
                                unmountOnExit
                              >
                                <CardContent></CardContent>
                              </Collapse>
                              {/* <Collapse in={open}>
                                <Alert
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      size="small"
                                      onClick={() => {
                                        setOpen(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                  sx={{ mb: 2 }}
                                >
                                  {item['Job'].Description}
                                </Alert>
                              </Collapse> */}
                            </Box>
                          </Grid>
                        </Grid>
                      </Container>
                      <Container className="new_card_bottom_bar" maxWidth="lg">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={9}
                            className="skills_txt_card_bottom"
                          >
                            <SchoolIcon className="jobs_icon_styles" />{' '}
                            <span>
                              <span className="card_botom_skills_txt">
                                Skills - {''}
                              </span>
                              {item['Job'].Skills} {}
                            </span>
                          </Grid>
                          <Grid
                            className="bottom_card_col_2"
                            item
                            xs={12}
                            md={3}
                          >
                            <IconButton className="view_icon_btn">
                              <Tooltip title="View">
                                <VisibilityIcon />
                              </Tooltip>
                            </IconButton>
                            <IconButton className="flag_icon_btn">
                              <Tooltip title="Archive">
                                <FlagIcon />
                              </Tooltip>
                            </IconButton>
                            <ExpandMore
                              className="expand_icon_btn"
                              expand={expanded}
                              onClick={handleExpandClick}
                              aria-expanded={expanded}
                              aria-label="show more"
                            >
                              <Tooltip title="Expand">
                                <ExpandMoreIcon />
                              </Tooltip>
                            </ExpandMore>
                          </Grid>
                        </Grid>
                      </Container>
                    </Container>
                  </div>
                </div>
              </Grid>
            </Grid>
          ))}
      </ul>
    </div>
  );
}

export default List;
